import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ButtonFilled from '../../Dashboard/Templates/Buttons/ButtonFilled';
import ButtonTransparent from '../../Dashboard/Templates/Buttons/ButtonTransparent';

const CardStep = ({ tag, title, desc, t, redirectWithUTMUrl }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      zIndex={1}
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width={mobile ? '100%' : '30%'}
      marginBottom={mobile ? '2rem' : 0}
      padding="2rem"
      textAlign="left"
      color="white"
      style={{
        background:
          'radial-gradient(rgb(39 180 239 / 10%), rgba(39, 187, 248, 1%))',
        borderRadius: '32px',
      }}
    >
      <Typography
        variant="body1"
        style={{ color: '#68bdf1', textTransform: 'uppercase' }}
      >
        {t(tag)}
      </Typography>
      <Typography variant="h3" style={{ margin: '1.5rem 0', fontWeight: 500 }}>
        {t(title)}
      </Typography>
      <Typography variant="body1" style={{ marginBottom: '1.5rem' }}>
        {t(desc)}
      </Typography>
      <ButtonTransparent
        title={t('Commencer')}
        arrow
        path={redirectWithUTMUrl('secure')}
      />
    </Box>
  );
};

const HomeSteps = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const redirectWithUTMUrl = url => {
    const target_url = (url += document.location.search || '');
    return target_url;
  };

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Typography
        variant="h2"
        style={{
          width: '100%',
          textAlign: 'center',
          margin: 'auto',
          color: '#fff',
          fontWeight: 500,
          fontSize: '3rem',
        }}
      >
        {t('Découvrez pourquoi des milliers d’utilisateurs aiment Datawin')}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-around"
        flexWrap="wrap"
        width="100%"
        margin="4rem 0rem"
      >
        <CardStep
          tag="SPORT"
          title="De nombreux sports disponibles"
          desc="Football, Tennis, Basketball, NFL et NHL"
          t={t}
          redirectWithUTMUrl={redirectWithUTMUrl}
        />
        <CardStep
          tag="DATA"
          title="Des statistiques détaillées"
          desc="Des données exclusives sur chaque match"
          t={t}
          redirectWithUTMUrl={redirectWithUTMUrl}
        />
        <CardStep
          tag="BONUS"
          title="Ne manquez rien de vos matchs"
          desc="Replay, bonnes infos, actualités"
          t={t}
          redirectWithUTMUrl={redirectWithUTMUrl}
        />
      </Box>
      <Box margin="auto">
        <ButtonFilled
          title={t('Démarrer')}
          path={redirectWithUTMUrl('secure')}
        />
      </Box>
    </Box>
  );
};

export default HomeSteps;
