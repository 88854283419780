import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardGeneral from '../../CardGeneral';
import CardPronoPourcentage from '../../CardPronoPourcentage';
import CardPronoGoals from '../../CardPronoGoals';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    padding: '2rem',
  },
  itemStats: {
    padding: '2rem',
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0',
    },
  },
  title: {
    margin: '.75rem 0 1.5rem 0',
  },
  title2: {
    margin: '4rem 0 1rem 0',
  },
}));

export const SportGPTPredictionsFootball: React.FC<{
  prono: any;
}> = ({ prono }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  const team1_name = t(prono?.player1?.name);
  const team2_name = t(prono?.player2?.name);

  const { i18n } = useTranslation();

  const winnerTeam =
    prono?.football?.player1Wins > prono?.football?.player2Wins
      ? team1_name
      : team2_name;
  const winnerValue =
    prono?.football?.player1Wins > prono?.football?.player2Wins
      ? prono?.football?.player1Wins
      : prono?.football?.player2Wins;

  const loserTeam =
    prono?.football?.player1Wins < prono?.football?.player2Wins
      ? team1_name
      : team2_name;
  const loserValue =
    prono?.football?.player1Wins < prono?.football?.player2Wins
      ? prono?.football?.player1Wins
      : prono?.football?.player2Wins;

  return (
    <>
      <Grid container item xs={12} data-tour="presentation-algo-step">
        <Typography variant="h3" className={classes.title2}>
          {t("Probabilités calculées par l'algorithme :")}
        </Typography>
        <CardGeneral
          blue={!mobile}
          transparent={mobile}
          className={classes.itemStats}
        >
          <Grid
            container
            xs={12}
            justify="space-between"
            className="noMargin margin-bottom2rem"
          >
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                paddingRight: mobile ? '0rem' : '1rem',
                marginBottom: mobile ? '2rem' : 0,
              }}
            >
              <CardPronoPourcentage
                team1={winnerTeam}
                team2={loserTeam}
                value={winnerValue}
                value2={loserValue}
                legend1={winnerTeam}
                legend2={t('Nul')}
                legend3={loserTeam}
                title={t('Chances de victoire')}
                subtitle={t('pour chaque équipe')}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              style={{
                paddingLeft: mobile ? '0rem' : '1rem',
              }}
            >
              <CardPronoPourcentage
                team1={team1_name}
                team2={team2_name}
                value={prono?.football?.bothTeamsScore}
                legend1={'Oui'}
                legend2={'Non'}
                title={t('Les deux équipes marquent')}
                singleText
              />
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            justify="space-between"
            spacing={mobile ? 2 : 0}
            className="noMargin"
          >
            <CardPronoGoals
              title={t('Nombre de buts')}
              prono1={prono?.football?.goals1}
              prono2={prono?.football?.goals2}
              prono3={prono?.football?.goals3}
            />
          </Grid>
        </CardGeneral>
      </Grid>
    </>
  );
};

export default SportGPTPredictionsFootball;
