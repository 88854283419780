import { handleActions } from 'redux-actions';
import {Sport} from "components/Dashboard/Home/ToggleSport";

const initialState = {
    selectedSport: "FOOTBALL" as Sport
};

export const sportsReducer = handleActions(
    {
        'CHANGE_SPORT': (state: any, action: any) => {
            return {
                ...state,
                selectedSport: action.payload.newSport,
            }
        },
    },
    initialState
);
