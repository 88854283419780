import React from 'react';
import firebase from 'firebase';
import * as firebaseui from 'firebaseui';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    firebaseUi: {
      '& .firebaseui-idp-button, .firebaseui-tenant-button': {
        maxWidth: 250,
      },
      '& .firebaseui-idp-list, .firebaseui-tenant-list': {
        margin: '5px 0',
      },
    },
  }),
);

const SIGN_LABELS = {
  register: {
    google: {
      fr: 'Inscription avec Google',
      en: 'Sign in with Google',
      es: 'Regístrese con Google',
      it: 'Registrati con Google',
    },
    facebook: {
      fr: 'Inscription avec Facebook',
      en: 'Sign in with Facebook',
      es: 'Regístrese con Facebook',
      it: 'Registrati con Facebook',
    },
  },
  login: {
    google: {
      fr: 'Connexion avec Google',
      en: 'Sign up with Google',
      es: 'Conectar con Google',
      it: 'Collegati con Google',
    },
    facebook: {
      fr: 'Connexion avec Facebook',
      en: 'Sign up with Facebook',
      es: 'Conectar con Facebook',
      it: 'Collegati con Facebook',
    },
  },
};

type OauthLoginsProps = {
  typeAuth: 'register' | 'login';
};

const OauthLogins: React.FC<OauthLoginsProps> = ({ typeAuth }) => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const classes = useStyles();

  const language = i18n.language;

  const uiConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        fullLabel: SIGN_LABELS[typeAuth].google[language],
      },
      // {
      //   provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      //   fullLabel: SIGN_LABELS[typeAuth].facebook[language],
      // },
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult): boolean => {
        if (authResult.additionalUserInfo.isNewUser) {
          history.push({
            pathname: '/paiement-offre-1-euro/oauth',
            state: { isNewUser: true },
          });
        } else {
          history.push('/paiement-offre-1-euro');
        }
        return false;
      },
    },
  };

  return (
    <StyledFirebaseAuth
      uiConfig={uiConfig}
      className={classes.firebaseUi}
      firebaseAuth={firebase.auth()}
    />
  );
};

export default React.memo(OauthLogins);
