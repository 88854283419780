import React, { useState, useEffect } from 'react';
import Review from './Review';
import { Grid, Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Navbar from '../Dashboard/Templates/Navbar/Navbar';
import NavbarHome from '../Home/NavbarHome/NavbarHome';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import PreviewTitle from '../Dashboard/Templates/Misc/PreviewTitle';
import { Fire } from 'services';
import { ReactTitle } from 'react-meta-tags';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles({
  reviews: {
    background: '#fcfcfa',
    maxWidth: '100vw',
    minHeight: '100vh',
  },
  container: {
    padding: '0 10px',
    maxWidth: 1300,
    margin: 'auto',
    width: '100%',
  },
  reviewsContainer: {
    marginRight: '0 !important',
    marginLeft: '0 !important',
    marginTop: '.5rem',
    padding: '0 !important',
  },
  paragraph: {
    fontSize: '1.3rem',
    fontWeight: 500,
    margin: '1rem 0 1.3rem',
    color: '#3c4045',
  },
});

export default function ReviewScreen() {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useSelector((state: any) => state.authReducer.user);
  const spacing = mobile ? 3 : 5;
  const { t } = useTranslation();
  const [reviews, setReviews] = useState<any>(null);
  const [proportionRating, setProportionRating] = useState<any>(null);

  const getReviews = async () => {
    const ref = Fire.store().collection('avis').orderBy('date', 'desc');
    const newReviews = await Fire.list(ref);
    setReviews(newReviews);
  };

  const getProportionRating = () => {
    const newProportionRating = [
      {
        title: 'Excellent',
        number: reviews.filter(review => review.note === 5).length,
        purcentage:
          (reviews.filter(review => review.note === 5).length /
            reviews.length) *
          100,
      },
      {
        title: 'Très bien',
        number: reviews.filter(review => review.note === 4).length,
        purcentage:
          (reviews.filter(review => review.note === 4).length /
            reviews.length) *
          100,
      },
      {
        title: 'Bien',
        number: reviews.filter(review => review.note === 3).length,
        purcentage:
          (reviews.filter(review => review.note === 3).length /
            reviews.length) *
          100,
      },
      {
        title: 'Bas',
        number: reviews.filter(review => review.note === 2).length,
        purcentage:
          (reviews.filter(review => review.note === 2).length /
            reviews.length) *
          100,
      },
      {
        title: 'Mauvais',
        number: reviews.filter(review => review.note === 1).length,
        purcentage:
          (reviews.filter(review => review.note === 1).length /
            reviews.length) *
          100,
      },
    ];
    setProportionRating(newProportionRating);
  };

  useEffect(() => {
    getReviews();
  }, []);

  useEffect(() => {
    if (reviews && reviews.length !== 0) getProportionRating();
  }, [reviews]);

  return (
    <div className={classes.reviews}>
      <Helmet
        script={[
          helmetJsonLdProp({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'Datawin',
            url: 'https://datawin-pronos.com/',
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: '5',
              reviewCount: '5',
            },
            sameAs: [
              'https://twitter.com/DatawinP',
              'https://www.facebook.com/Datawin-pronos-110499897757925',
            ],
          }),
        ]}
      />
      <ReactTitle title="Datawin : Avis clients" />
      <Fade in timeout={500}>
        <>
          {!user && <NavbarHome blue />}
          <Grid container spacing={spacing} className={classes.container}>
            {user && (
              <Grid item xs={12}>
                <Navbar />
              </Grid>
            )}
            <Grid
              item
              container
              xs={12}
              spacing={spacing}
              className={classes.reviewsContainer}
            >
              <Grid item xs={12}>
                <PreviewTitle
                  typography="h1"
                  title={t('Datawin avis : témoignages clients')}
                />
                <p className={classes.paragraph}>
                  {t(
                    'Datawin est un algorithme d\'analyse automatisé pour les paris sportifs, développé par des ingénieurs français et capable de réaliser des séries avec 78% debons pronostics sur 1 500 rencontres. Aujourd’hui, Datawin est le seul site qui propose une solution de ce type, basée sur l’IA et le machine learning. Et parce que nous ne vous demandons pas de nous croire sur parole, nous vous invitons à lire lestémoignages et avis sur Datawin postés par des personnes qui ont essayé la solution.',
                  )}
                </p>
                <PreviewTitle
                  typography="h2"
                  title={t(
                    'Des pronostics ultra performants grâce à l’algorithme le plus puissantjamais créé',
                  )}
                  fontWeight={600}
                />
                <p className={classes.paragraph}>
                  {t(
                    'Développé par des ingénieurs français, Datawin se positionne aujourd’hui comme l’algorithme d\'analyse automatisé pour paris sportifs le plus puissant jamais créé. Simple et intuitive, la solution propose des analyses au quotidien sur les résultats des matchs des plus grands championnats de football. L’objectif du site est d’offrir les cotes les plus sûres et les plus sécurisées possibles, de sorte à garantir un bon retour sur investissement sur le moyen terme. Datawin est basé surl’intelligence artificielle, qui sélectionne des cotes pour des paris sécurisés, en plus des paris plus risqués. Ces derniers, de toute évidence, ont des taux d\'analyse plus faibles.',
                  )}
                </p>
                <PreviewTitle
                  typography="h2"
                  title={t(
                    'Un taux d\'analyses exactes de 78% sur 1 500 rencontres',
                  )}
                  fontWeight={600}
                />
                <p className={classes.paragraph}>
                  {t(
                    'Depuis le départ, l’objectif de l’équipe Datawin était de concevoir un algorithme plus fiable et plus précis que les meilleurs parieurs professionnels. Pour y arriver, des ingénieurs français ont travaillé durant un an et demi au développement de la solution, avant de procéder à des tests de l’algorithme : sur une série de 1 500 matchs de football, Datawin a enregistré un impressionnant taux d\'analyses exactes de 78% ! Pendant un an et demi donc, l’équipe de data scientists de Datawin a collecté toutes sortes de données sur les rencontres de football : nombre de buts et de passes, possession de balle, buts de la tête, tirs à l’intérieur de la surface de réparation, fautes commises, schémas tactiques, occasions franches... Ce n’est qu’après avoir collecté un gros volume de données sur des milliers de matchs que l’algorithme a été créé, en se basant sur l’IA et le machine learning.',
                  )}
                  <br /> <br />
                  {t(
                    'Il ne faut toutefois pas penser que Datawin va vous rendre riche, ce n’est pas la vocation de l’algorithme. La solution que nous proposons a pour objectif de permettre aux joueurs réguliers qui l’utilisent de gagner un complément de salaire entre 300 et 400 euros par mois, en fonction du niveau de mise engagée.',
                  )}
                </p>
                <PreviewTitle
                  typography="h2"
                  title={t('Datawin, chaque avis client compte')}
                  fontWeight={600}
                />
                <p className={classes.paragraph}>
                  {t(
                    'Datawin a certes mené des tests exhaustifs de son algorithme avant de le mettre en ligne, avec les résultats que vous connaissez : 78% d\'analyses exactes sur une série de 1 500 matchs de football. Mais ce qui compte avant tout, c’est l’avis des utilisateurs de Datawin. Seuls les utilisateurs et les utilisatrices de la solution peuvent témoigner de l’efficacité de l’algorithme. Voici, justement, quelques avis sur Datawin et quelques témoignages clients.',
                  )}
                </p>
              </Grid>
              <Grid item xs={12}>
                <Box
                  width="100%"
                  display="flex"
                  borderRadius="11px"
                  boxShadow="4px 4px 9px 1px rgba(0, 0, 0, 0.1)"
                  bgcolor="white"
                  padding={mobile ? '2rem' : '1.5rem'}
                >
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    flexDirection="column"
                    textAlign="left"
                  >
                    <Box display="flex" justifyContent="flex-start">
                      <Typography
                        variant="h3"
                        component={'span'}
                        display="inline"
                      >
                        <Box color="#73738F" fontWeight={600}>
                          {reviews?.length}
                        </Box>
                      </Typography>
                      <Typography
                        variant="h3"
                        component={'span'}
                        display="inline"
                      >
                        <Box
                          marginLeft=".5rem"
                          color="#3C4045"
                          fontWeight={600}
                        >
                          {t('Avis')}
                        </Box>
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      width="100%"
                    >
                      {proportionRating?.map(rating => {
                        return (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                            maxWidth="350px"
                            marginTop=".75rem"
                            key={rating.title}
                          >
                            <Box width="25%" color="#5C5C70">
                              <Typography variant="body1" component={'span'}>
                                <Box fontWeight={600} color="#5C5C70">
                                  {t(rating.title)}
                                </Box>
                              </Typography>
                            </Box>
                            <Box width="60%" height="75%">
                              <Box
                                width="90%"
                                margin="auto"
                                height="100%"
                                bgcolor="#E5E5E5"
                                borderRadius="3px"
                                style={{
                                  filter:
                                    'drop-shadow(4px 4px 9px rgba(0, 0, 0, 0.05))',
                                }}
                              >
                                <Box
                                  width={rating.purcentage + '%'}
                                  height="100%"
                                  bgcolor="#BBBBBB"
                                  borderRadius="3px"
                                ></Box>
                              </Box>
                            </Box>
                            <Box
                              width="15%"
                              paddingLeft=".5rem"
                              textAlign="left"
                            >
                              <Typography variant="body2" component={'span'}>
                                <Box fontWeight={600} color="#5C5C70B2">
                                  {Math.round(rating.purcentage)}%
                                </Box>
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                  {/*
                                    <Box width="65%"></Box>
                                    */}
                </Box>
              </Grid>
              {reviews?.map(review => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={review.id}>
                    <Review {...review} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </>
      </Fade>
    </div>
  );
}
