// Anonymous pages
import HomePage from 'components/Home/HomePage';
import BonusScreen from './components/Bonus/BonusScreen';
import TermsScreenFrench from './components/Terms_French/TermsScreenFrench';
import PaymentScreen from './components/Payment/PaymentScreen';
import PrivacyScreen from './components/PrivacyPolicy/PrivacyScreen';
import RegisterScreen from './components/Register/RegisterScreen';
import LoginScreen from './components/Login/LoginScreen';
import Library from './components/Dashboard/Library/Library';
import PageMatch from './components/Dashboard/PageMatch/PageMatch';
import PagePlayer from './components/Dashboard/PagePlayer/PagePlayer';
import PageTeam from './components/Dashboard/PageTeam/PageTeam';
import ReviewScreen from './components/Reviews/ReviewScreen';
import PresseScreen from './components/Presse/PresseScreen';
import FAQ from './components/FAQ/FAQ';
import Blog from './components/Blog/Blog';
import PageArticle from './components/Blog/PageArticle/PageArticle';

// Logged pages
// import AccountCancellation from './components/Logged/Account/AccountCancellation'
import AddCardScreen from './components/Logged/AddCard/AddCardScreen';
// ux test
// import UXTest from './components/Home_hold/UXTest'
// import LoggedTermsScreenFrench from './components/Logged/Terms_French/TermsScreenFrench'
// import LoggedPrivacyScreen from './components/Logged/PrivacyPolicy/PrivacyScreen'
// import Loading from './components/Loading/Loading'
// import PaymentScreenL from './components/Logged/Payment/old/PaymentScreen'
// import CancelScreen from './components/Logged/Cancel/CancelScreen'
// import PronosScreen from './components/Logged/Pronos/PronosScreen'
// import Highlights from './components/Logged/Highlights/Highlights'
// import LoggedBonusScreen from './components/Logged/Bonus/BonusScreen'
import PageBonus from './components/Dashboard/Bonus/PageBonus';
import Page404 from './components/Page404/Page404';
import Dashboard from './components/Dashboard/Dashboard';
import PageHighlights from './components/Dashboard/Highlights/PageHighlights';
import PageLivescore from './components/Dashboard/Livescore/PageLivescore';
import Account from './components/Dashboard/Account/Account';
import PagePronos from './components/Dashboard/Pronos/Pronos';
import SinglePronoTennis from 'components/Dashboard/SinglePronoTennis/SinglePronoTennis';
import SinglePronoNBA from 'components/Dashboard/SinglePronoNBA/SinglePronoNBA';
import SinglePronoNHL from 'components/Dashboard/SinglePronoNHL/SinglePronoNHL';
import SinglePronoNFL from 'components/Dashboard/SinglePronoNFL/SinglePronoNFL';
import PaymentScreenOauth from 'components/Payment/PaymentScreenOauth/PaymentScreenOauth';
import PageLiveScoreTennis from 'components/Dashboard/Livescore/PageLiveScoreTennis/PageLiveScoreTennis';
import ExternPayment from 'components/Dashboard/ExternPayment/ExternPayment';
import PaymentScreenExtern from 'components/Payment/PaymentScreenExtern/PaymentScreenExtern';
import SinglePronoBASEBALL from 'components/Dashboard/SinglePronoBASEBALL/SinglePronoBASEBALL';
import PaymentStripe from 'components/Payment/PaymentStripe/PaymentStripe';
import PaymentStripePromo from 'components/Payment/PaymentStripe/PaymentStripePromo';

const routes: any[] = [
  /**
   * ANONYMOUS ROUTES
   */
  {
    path: '/',
    userLogged: false,
    component: HomePage,
  },
  {
    userLogged: false,
    path: '/bonus',
    component: BonusScreen,
  },
  {
    userLogged: false,
    path: '/terms',
    component: TermsScreenFrench,
  },
  {
    userLogged: false,
    path: '/privacy',
    component: PrivacyScreen,
  },
  {
    userLogged: false,
    path: '/register',
    component: RegisterScreen,
  },
  {
    both: true,
    userLogged: false,
    path: '/login',
    component: LoginScreen,
  },
  {
    both: true,
    userLogged: false,
    path: '/paiement-offre-1-euro',
    component: PaymentScreen,
  },
  {
    both: true,
    userLogged: false,
    path: '/payment-offer-1-euro',
    component: PaymentScreen,
  },
  {
    both: true,
    userLogged: false,
    path: '/secure',
    component: PaymentStripe,
  },
  {
    both: true,
    userLogged: false,
    path: '/secure/:lang',
    component: PaymentStripe,
  },
  {
    both: true,
    userLogged: false,
    path: '/offer',
    component: PaymentStripePromo,
  },
  {
    both: true,
    userLogged: false,
    path: '/offer/:lang',
    component: PaymentStripePromo,
  },
  {
    both: true,
    userLogged: false,
    path: '/oferta-pago-1-euro',
    component: PaymentScreen,
  },
  {
    both: true,
    userLogged: false,
    path: '/offerta-pagamento-1-euro',
    component: PaymentScreen,
  },
  {
    both: true,
    userLogged: false,
    path: '/paiement-offre-1-euro/oauth',
    component: PaymentScreenOauth,
  },
  {
    both: true,
    userLogged: false,
    path: '/payment-offer-1-euro/oauth',
    component: PaymentScreenOauth,
  },
  {
    both: true,
    userLogged: false,
    path: '/oferta-pago-1-euro/oauth',
    component: PaymentScreenOauth,
  },
  {
    both: true,
    userLogged: false,
    path: '/offerta-pagamento-1-euro/oauth',
    component: PaymentScreenOauth,
  },
  {
    both: true,
    path: '/extern-payment/:email',
    component: ExternPayment,
  },
  {
    both: true,
    path: '/extern-payment',
    component: PaymentScreenExtern,
  },
  {
    both: true,
    userLogged: false,
    path: '/reviews',
    component: ReviewScreen,
  },
  {
    both: true,
    userLogged: false,
    path: '/avis',
    component: ReviewScreen,
  },
  {
    both: true,
    userLogged: false,
    path: '/presse',
    component: PresseScreen,
  },
  {
    both: true,
    userLogged: false,
    path: '/faq',
    component: FAQ,
  },
  // {
  //   both: true,
  //   userLogged: false,
  //   path: '/blog',
  //   component: Blog,
  // },
  // {
  //   both: true,
  //   userLogged: false,
  //   path: '/blog/:id',
  //   component: PageArticle,
  // },

  /**
   * LOGGED USER ROUTES
   */
  {
    userLogged: true,
    path: '/account',
    component: Account,
  },
  {
    userLogged: true,
    path: '/changePaymentMethod/:id',
    component: AddCardScreen,
  },
  {
    userLogged: true,
    path: '/cancellation/:id',
    component: Account,
  },
  {
    userLogged: true,
    path: '/',
    component: Dashboard,
  },
  {
    userLogged: true,
    path: '/pronos',
    component: PagePronos,
  },
  {
    userLogged: true,
    path: '/pronos/:id',
    component: PagePronos,
  },
  {
    userLogged: true,
    path: '/pronos_tennis/:id',
    component: SinglePronoTennis,
  },
  {
    userLogged: true,
    path: '/pronos_nba/:id',
    component: SinglePronoNBA,
  },
  {
    userLogged: true,
    path: '/pronos_nfl/:id',
    component: SinglePronoNFL,
  },
  {
    userLogged: true,
    path: '/pronos_nhl/:id',
    component: SinglePronoNHL,
  },
  {
    userLogged: true,
    path: '/pronos_baseball/:id',
    component: SinglePronoBASEBALL,
  },
  {
    userLogged: true,
    path: '/bonus',
    component: PageBonus,
  },
  {
    userLogged: true,
    path: '/highlights',
    component: PageHighlights,
  },
  {
    userLogged: true,
    path: '/livescore/football',
    component: PageLivescore,
  },
  {
    userLogged: true,
    path: '/livescore/tennis',
    component: PageLiveScoreTennis,
  },
  {
    userLogged: true,
    path: '/livescore/nba',
    component: PageLivescore,
  },
  {
    userLogged: true,
    path: '/livescore/nfl',
    component: PageLivescore,
  },
  {
    userLogged: true,
    path: '/dashboard',
    component: Dashboard,
  },
  // {
  //   userLogged: true,
  //   path: '/match/:srEventId',
  //   component: PageMatch,
  // },
  // {
  //   userLogged: true,
  //   path: '/library/:type?/:league?',
  //   component: Library,
  // },
  // {
  //   userLogged: true,
  //   path: '/player/:playerId',
  //   component: PagePlayer,
  // },
  // {
  //   userLogged: true,
  //   path: '/team/:competitorId',
  //   component: PageTeam,
  // },
  {
    both: true,
    userLogged: false,
    component: Page404,
  },
];

export default routes;
