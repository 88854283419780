const cfg = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "datawin.io",
  databaseURL: "https://datawin-prod.firebaseio.com",
  projectId: "datawin-prod",
  storageBucket: "datawin-prod.appspot.com",
  appId: "1:929072590751:web:68999f93a191550a7897aa",
  measurementId: "G-F0XEPXDCY3"
};

export default cfg;
