import React from 'react';
import Box from '@material-ui/core/Box';

type DoubleImageProps = {
  srcHome: string;
  srcAway: string;
};

const DoublesImages: React.FC<DoubleImageProps> = ({ srcHome, srcAway }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      width="80px"
    >
      <Box
        width="50%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          borderRadius="50%"
          height="40px"
          width="40px"
          bgcolor="white"
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxShadow="0 0 33px 0 rgb(255 255 255 / 11%)"
        >
          <img
            alt="https://cdn.sportmonks.com/images/soccer/placeholder.png"
            src={srcHome}
            height="100%"
            style={{ borderRadius: '50%' }}
          />
        </Box>
      </Box>
      <Box
        width="50%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ transform: 'translate(-30%)' }}
      >
        <Box
          borderRadius="50%"
          height="40px"
          width="40px"
          bgcolor="white"
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxShadow="0 0 33px 0 rgb(255 255 255 / 11%)"
        >
          <img
            alt="https://cdn.sportmonks.com/images/soccer/placeholder.png"
            src={srcAway}
            height="100%"
            style={{ borderRadius: '50%' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DoublesImages;
