import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import './Page404.scss'
import { useSelector } from 'react-redux'
import Loading from '../Loading/Loading'



const Page404 = (props) => {

    const history = useHistory()
    const { t } = useTranslation();
    const [user_already, setUser_already] = useState(useSelector((state: any) => state.authReducer.user))
    // const [userIsLogged, setUserIsLogged] = React.useState(user_already ? true : false)
    const url = props.location.pathname;
    const [loader, setLoader] = useState(true)

    setTimeout(() => {
        setLoader(false)
    }, 1000)

    const redirect = () => {
        if ((window.location.pathname === url && window.location.pathname != "/pronos")) {
            history.push('/')
        }
    }

    // React.useEffect(() => {
    //     if (user_already) {
    //         console.log("set user logged")
    //         setUserIsLogged(true)
    //     }
    //     else {
    //         console.log("set user not logged")
    //         setUserIsLogged(false)
    //     }
    //     console.log("User has change")
    // }, [user_already])


    if (!user_already) {
        setTimeout(() => {
            if (!user_already) {
                redirect()
            }
        }, 3000)
    }
    else {
        setTimeout(() => {
            redirect()
        }, 3000)
    }



    return (
        <React.Fragment>
            {
                loader ?
                    <Loading />
                    :
                    <div className="page404">
                        <span className="img404">{t("Page non trouvée")}</span>
                        <br /><br />
                        <p>
                            {t("Oops, You got lost, you will be redirected to the home page in a few seconds")}.
                            <br />
                            {t("If you don't get redirected, click on the button below")}
                        </p>
                        <br /><br />
                        <button className="btn404" onClick={() => history.push('/')}>{t("Go to the Home Page")}</button>
                    </div>
            }
        </React.Fragment>
    )
}

export default Page404
