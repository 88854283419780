import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Fire, Mail } from 'services';
import ExternPaymentLoading from './ExternPaymentLoading/ExternPaymentLoading';
import { User } from '../../../types/user';

type ExternPaymentParams = { email: string };

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ExternPayment: React.FC = () => {
  const { email } = useParams<ExternPaymentParams>();
  const history = useHistory();
  const query = useQuery();

  const sources = {
    utm_source: query.get('utm_source'),
    utm_medium: query.get('utm_medium'),
    utm_campaign: query.get('utm_campaign'),
    utm_term: query.get('utm_term'),
    utm_content: query.get('utm_content'),
  };

  useEffect(() => {
    const handleToken = async () => {
      if (email) {
        await Fire.auth().signOut();
        const token = await Fire.cloud('createExternTokenFromEmail', {
          email,
          sources,
        });
        Mail.send({ email } as User, 5);
        Fire.auth().signInWithCustomToken(token);
        history.push('/extern-payment');
      }
    };
    handleToken();
  }, [history, email, sources]);

  return <ExternPaymentLoading />;
};

export default ExternPayment;
