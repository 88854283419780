import React from 'react';
import './Loading_payment.scss';
import datawin_logo from '../../images/datawin_logo.png'


function Loading_payment(){
    return(
        <div className="payment_loading">
            <div className="payment_loading_loader"></div>
        </div>
    );
}

export default Loading_payment;