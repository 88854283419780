import React from 'react';
import { makeStyles, createStyles, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    welcomeTitle: {
      color: '#fff',
      fontWeight: 700,
      lineHeight: '31px',
      marginBottom: 15,
    },
    welcomeSubTitle: {
      color: '#fff  ',
      fontWeight: 500,
      lineHeight: '31px',
      marginRight: 10,
      marginBottom: 15,
    },
    welcomeBtn: {
      color: '#fff !important',
      marginRight: '2rem',

      '&:hover': {
        backgroundColor: '#0b1847d1',
      },
    },
  }),
);

const Welcome: React.FC = () => {
  const classes = useStyles();
  const user = useSelector((state: any) => state.authReducer.user);
  const info = { ...user };
  const history = useHistory();

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Typography variant="h1" className={classes.welcomeTitle}>
        {t('Bonjour')} {info.first_name},
      </Typography>

      <Typography variant="h5" className={classes.welcomeSubTitle}>
        {t(
          'Pour pouvoir accéder à Datawin, vous devez vous abonner. Vous pouvez tout de même récupérer des bonus sur la page bonus !',
        )}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        href="/bonus"
        size="large"
        className={classes.welcomeBtn}
      >
        {t('Voir les bonus')}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          return history.push('/secure');
        }}
        size="large"
        className={classes.welcomeBtn}
      >
        {t("S'abonner")}
      </Button>
      <br />
      <br />
      <Typography
        variant="body1"
        style={{
          color: '#fff',
        }}
      >
        {t('Un problème ?')}{' '}
        <a
          href="mailto:support@datawin-prediction.com"
          className="link"
          style={{ color: '#fff' }}
        >
          {t('Contactez-nous')}
        </a>
      </Typography>
    </React.Fragment>
  );
};

export default Welcome;
