import React, { useCallback, useEffect, useState } from 'react';
import tick from 'images/tick2.png';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Button, Input } from '../../Reusable';
import { useTranslation } from 'react-i18next';
import PaymentScreenFooter from './PaymentScreenFooter/PaymentScreenFooter';
import { useDispatch, useSelector } from 'react-redux';
import { Fire } from 'services';
import { finishLogin, logout } from 'actions/auth.action';
import { TFunction } from 'i18next';
import BackButton from '../../Dashboard/Templates/BackButton/BackButton';
import { useHistory } from 'react-router-dom';
import ArrowImage from 'images/fleche.png';
import Fade from '@material-ui/core/Fade';
import { getFeeAndSubTypeAndTrialPrice } from '../../../utils/countryCodeAPI';

const getError = (user, t: TFunction, termsChecked: boolean) => {
  if (!user.first_name?.trim().length) {
    return t('Veuillez saisir votre prénom');
  }
  if (!user.last_name?.trim().length) {
    return t('Veuillez saisir votre nom');
  }
  if (!user.email?.trim().length)
    return t('Veuillez saisir votre email de connexion');
  if (!termsChecked) return t('Veuillez accepter les termes et conditions');
  return null;
};

const PaymentScreenOauth: React.FC = props => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  // @ts-ignore
  const createdUser = useSelector(state => state.authReducer.user);
  const history = useHistory();
  const [price, setPrice] = useState<string>('');
  const [user, setUser] = useState({
    first_name: createdUser?.first_name || '',
    last_name: createdUser?.last_name || '',
    email: createdUser?.email || '',
  });
  const [error, setError] = useState<string | null>(null);
  const [termsChecked, setTermsChecked] = useState<boolean>(false);

  const getAllPriceInfos = async () => {
    const priceInfos = await getFeeAndSubTypeAndTrialPrice();
    setPrice(priceInfos[3] || '1€');
  };

  React.useEffect(() => {
    getAllPriceInfos();
  }, []);

  // @ts-ignore
  const isNewUser = props.location?.state?.isNewUser;

  const promptLogout = () => {
    const confirm = window.confirm(
      t('Êtes-vous sûr de vouloir vous déconnecter ?'),
    );
    if (confirm) {
      dispatch(logout());
      history.replace('/');
    }
  };

  const handleLangsURL = useCallback(() => {
    const url = history.location.pathname;
    switch (i18n.language) {
      case 'fr': {
        if (url !== '/paiement-offre-1-euro/oauth')
          history.replace({
            pathname: '/paiement-offre-1-euro/oauth',
            state: { isNewUser },
          });
        break;
      }
      case 'en': {
        if (url !== '/payment-offer-1-euro/oauth')
          history.replace({
            pathname: '/payment-offer-1-euro/oauth',
            state: { isNewUser },
          });
        break;
      }
      case 'es': {
        if (url !== '/oferta-pago-1-euro/oauth')
          history.replace({
            pathname: '/oferta-pago-1-euro/oauth',
            state: { isNewUser },
          });
        break;
      }
      case 'it': {
        if (url !== '/offerta-pagamento-1-euro/oauth')
          history.replace({
            pathname: '/offerta-pagamento-1-euro/oauth',
            state: { isNewUser },
          });
        break;
      }
    }
  }, [history, i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handlePage = () => {
      if (createdUser) {
        if (
          !isNewUser &&
          !!createdUser.email?.trim().length &&
          !!createdUser.last_name?.trim().length &&
          !!createdUser.first_name?.trim().length
        )
          return history.push('/paiement-offre-1-euro');
        const error = getError(createdUser, t, termsChecked);
        if (error) setError(error);
        return handleLangsURL();
      }
    };
    handlePage();
  }, [createdUser, handleLangsURL, history, t]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateUser = async () => {
    if (user.email !== createdUser.email) {
      await Fire.auth().currentUser?.updateEmail(user.email);
    }
    await Fire.store()
      .collection('users')
      .doc(createdUser.id)
      .set({ ...createdUser, ...user });
  };

  const onSubmit = async () => {
    try {
      setError(null);
      const error = getError(user, t, termsChecked);
      if (error) return setError(error);
      await updateUser();
      dispatch(finishLogin(i18n.language));
      history.push('/paiement-offre-1-euro');
    } catch (e) {
      if ((e as { code: string }).code === 'auth/email-already-in-use')
        setError(t("L'email entré est déjà utilisé. Connectez-vous."));
    }
  };

  return (
    <React.Fragment>
      <div className="paiement">
        <BackButton label="Quitter" onClick={promptLogout} />
        <section className="section-0">
          <img className="logo" src={require('images/logo_datawin.png')} />
        </section>
        <section className="section-1">
          <div className="infos">
            <h1>{t('Remise massive pour les nouveaux membres')}</h1>
            <h2 style={{ gap: '30px', fontSize: '50px' }}>
              <span
                style={{ color: '#f9f9f9' }}
                className="old_price"
              >{`39.90`}</span>
              <div>
                <img src={ArrowImage} style={{ width: '80px' }} alt="" />
              </div>
              <Fade in={true} timeout={1500}>
                <span
                  style={{ color: 'rgb(138 232 100)' }}
                  className="prix_reduit"
                >
                  {price}
                </span>
              </Fade>
            </h2>
            <p>
              {t('Accès complet à l’ensemble des fonctionnalités :')}
              <br />
            </p>
            <div className="ticks">
              <div className="step">
                <img src={tick} className="tick" />
                <div className="step-text">{t('Analyses')}</div>
              </div>
              <div className="step">
                <img src={tick} className="tick" />
                <div className="step-text">
                  {t('Database')}
                </div>
              </div>
              <div className="step">
                <img src={tick} className="tick" />
                <div className="step-text">
                  {t('Bonus')}
                </div>
              </div>
            </div>
          </div>
          <div className="formulaire">
            <form>
              <div className="top-head">{t('DEVENIR MEMBRE PREMIUM')}</div>
              <div className="form-tabs">
                <ProgressBar animated striped now={60} label={`${60}%`} />
              </div>
              <div className="fields">
                <h3 className="form_paiement_title">
                  {t('Créez votre compte Datawin')}
                </h3>
                <div className="name">
                  <Input
                    title={t('Prénom')}
                    value={user.first_name}
                    onChange={first_name => setUser({ ...user, first_name })}
                  />
                  <Input
                    title={t('Nom')}
                    value={user.last_name}
                    onChange={last_name => setUser({ ...user, last_name })}
                  />
                </div>
                <div className="name">
                  <Input
                    title={t('Email')}
                    value={user.email}
                    onChange={email => setUser({ ...user, email })}
                  />
                </div>
                <p
                  className="paiement_terms"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setTermsChecked(!termsChecked)}
                >
                  <input
                    style={{ cursor: 'pointer' }}
                    checked={termsChecked}
                    type="checkbox"
                    name="cgv"
                    data-testid="termsChecked"
                    className="paiement_terms_check"
                    id="paiement_terms_check"
                  />
                  <span className="paiement_terms_text">
                    {t("J'accepte les")}{' '}
                    <a
                      target="_blank"
                      href="/terms#anchor"
                      className="paiement_terms_link"
                    >
                      {t('Termes et conditions')}
                    </a>
                  </span>
                </p>
                {error && (
                  <div className="error-box">
                    <div data-testid="error-text" className="error">
                      {error}
                    </div>
                  </div>
                )}
                <Button
                  title={t("S'INSCRIRE POUR 1€")}
                  onPress={() => onSubmit()}
                  subTitle={t('OFFRE EXCLUSIVE LIMITÉE')}
                  classes={['linear-btn', 'big-btn', 'red']}
                />
              </div>
            </form>
          </div>
        </section>
      </div>
      <PaymentScreenFooter t={t} />
    </React.Fragment>
  );
};

export default PaymentScreenOauth;
