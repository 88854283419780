import { Fire } from 'services';

const COUNTRY_CODE_LOCATION = {
  FR: 'FR',
  BL: 'FR',
  GP: 'FR',
  MF: 'FR',
  MQ: 'FR',
  NC: 'FR',
  PF: 'FR',
  PM: 'FR',
  RE: 'FR',
  TF: 'FR',
  WF: 'FR',
  IT: 'IT',
  ES: 'ES',
  UK: 'UK',
  GB: 'UK',
  BE: 'EU',
  EL: 'EU',
  LT: 'EU',
  PT: 'EU',
  BG: 'EU',
  LU: 'EU',
  RO: 'EU',
  CZ: 'EU',
  HU: 'EU',
  SI: 'EU',
  DK: 'EU',
  HR: 'EU',
  MT: 'EU',
  SK: 'EU',
  DE: 'EU',
  NL: 'EU',
  FI: 'EU',
  EE: 'EU',
  CY: 'EU',
  AT: 'EU',
  SE: 'EU',
  IE: 'EU',
  LV: 'EU',
  PL: 'EU',
  IS: 'EU',
  NO: 'EU',
  LI: 'EU',
  CH: 'EU',
};

const pricing = {
  FR: '1€',
  IT: '1€',
  ES: '1€',
  EU: '1€',
  UK: '£1',
  WW: '$1',
};

const getIp = async () => {
  try {
    let ip;
    await fetch('https://api.ipify.org/?format=json', { method: 'get' })
      .then(res => res.json())
      .then(data => (ip = data.ip));
    return ip;
  } catch (error) {
    return null;
  }
};

export const getUserLocation = async () => {
  const ip = await getIp();
  const data = ip ? await Fire.cloud('getUserLocation', ip) : null;
  return data;
};

export const getTrialPriceFromCountryCode = countryCodeProps => {
  const countryCode = countryCodeProps;
  const price = pricing[COUNTRY_CODE_LOCATION[countryCode] || 'WW'];
  return price;
};

export const getTrialPriceFromLocation = async () => {
  const ip = await getIp();
  const data = ip
    ? await Fire.cloud('getTrialPriceFromLocation', ip)
    : ['FR', '1€'];
  return data;
};

export const getFeeAndSubTypeAndTrialPrice = async () => {
  const ip = await getIp();
  const data = ip
    ? await Fire.cloud('getFeeAndSubTypeAndTrialPrice', ip)
    : ['FR', 'feeFR', 'monthFR', '1€'];
  return data;
};
