import React from 'react';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as PlayBtn } from 'images/btn_play_red.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CardGeneral from '../../../Templates/Cards/CardGeneral';
import Logo from '../../../Templates/Logos/Logo';
import { LivescoreTennis } from '../../../../../types/liveScore';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: 'inherit',
      boxShadow: '0 5px 10px 0 rgba(11, 18, 42, .06)',
    },
    content: {
      height: '100%',
      padding: '1rem .5rem',
      backgroundColor: '#f8f9fa',
      borderRadius: '15px',
    },
    matchLogo: {
      width: 'initial',
      height: '85%',
      borderRadius: '2rem',
    },
  }),
);

type BigCardLiveScoreProps = {
  livescore: LivescoreTennis;
};

const BigCardLiveScore: React.FC<BigCardLiveScoreProps> = ({ livescore }) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallComputer = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.content}
    >
      <Grid item xs={2} sm={1}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          {livescore.status === 'not_started' ? (
            <Typography variant="body2" component={'span'}>
              <Box color="#848ca5">
                {moment(livescore.startedAt).format('HH:mm')}
              </Box>
            </Typography>
          ) : livescore.status === 'live' ? (
            <Box
              bgcolor="#e93e5519"
              borderRadius={7}
              p="5px"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1" component={'span'}>
                <Box
                  color="#e93e55"
                  fontWeight={600}
                  justifyContent="space-around"
                  alignItems="center"
                  display="flex"
                >
                  <PlayBtn
                    fill="red"
                    height="100%"
                    style={{ marginRight: '.5rem' }}
                  />
                  Live
                </Box>
              </Typography>
            </Box>
          ) : (
            <strong>{t('Fini')}</strong>
          )}
        </Box>
      </Grid>
      {!mobile && <Grid xs={1} />}
      <Grid container item xs={10} sm={8}>
        <Grid item container xs={3} justify="flex-start" alignItems="center">
          <Typography variant="h6" component={'span'}>
            <Box
              width="100%"
              textAlign="flex-start"
              color="primary"
              fontWeight={700}
            >
              {livescore.players[0].name}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {/*<Logo src={''} imgHeight="85%" width={mobile ? 40 : 50} />*/}
        </Grid>
        <Grid container item xs={2} justify="center" alignItems="center">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              bgcolor="#e6e9ef"
              borderRadius={25}
              padding={livescore.status === 'live' ? '2px 9px' : '.4rem .8rem'}
              width="fit-content"
              height="fit-content"
            >
              {livescore.status ? (
                <Typography
                  variant={smallComputer ? 'h6' : 'h5'}
                  component="span"
                >
                  <Box
                    fontWeight="fontWeightBold"
                    color={theme.palette.text.primary}
                  >
                    {livescore.scores
                      ? livescore.scores.map(score => (
                          <Box
                            fontWeight="fontWeightBold"
                            color={theme.palette.text.primary}
                          >
                            {score[0]} : {score[1]}
                          </Box>
                        ))
                      : '-'}
                  </Box>
                </Typography>
              ) : (
                <Typography variant="body2" component="span">
                  <Box
                    fontWeight={600}
                    color={theme.palette.text.secondary}
                    style={{ textTransform: 'uppercase' }}
                  >
                    {mobile ? '-' : t('Prochainement')}
                  </Box>
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2}>
          {/*<Logo src={''} imgHeight="85%" width={mobile ? 40 : 50} />*/}
        </Grid>
        <Grid item container xs={3} justify="flex-end" alignItems="center">
          <Typography variant="h6" component={'span'}>
            <Box
              width="100%"
              textAlign="flex-end"
              color="primary"
              fontWeight={700}
            >
              {livescore.players[1].name}
            </Box>
          </Typography>
        </Grid>
      </Grid>
      {!mobile && <Grid item xs={2}></Grid>}
    </Grid>
  );

  return (
    <CardGeneral className={classes.root} shadow light>
      <CardContent className={classes.content}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="space-between"
          width="100%"
          height="100%"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            marginBottom={1}
          >
            <Box
              bgcolor="#e93e5519"
              borderRadius={7}
              p="5px"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1" component={'span'}>
                <Box
                  color="#e93e55"
                  fontWeight={600}
                  justifyContent="space-around"
                  alignItems="center"
                  display="flex"
                >
                  {livescore.status === 'not_started' ? (
                    moment(livescore.startedAt).format('HH:mm')
                  ) : livescore.status === 'live' ? (
                    <React.Fragment>
                      <PlayBtn
                        fill="red"
                        height="100%"
                        style={{ marginRight: '.5rem' }}
                      />
                      Live
                    </React.Fragment>
                  ) : (
                    <strong>{t('Fini')}</strong>
                  )}
                </Box>
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" component={'span'}>
                <Box color="#b7becc" fontWeight="fontWeightSemiBold">
                  {moment(livescore.startedAt).format('HH:mm')}
                </Box>
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              {/*<Logo src={''} imgHeight="85%" width={mobile ? 40 : 50} />*/}
              <Typography variant="body2" component="span">
                <Box
                  color={theme.palette.text.primary}
                  fontWeight="fontWeightSemiBold"
                  marginTop={1}
                >
                  {livescore.players[0].name}
                </Box>
              </Typography>
            </Grid>
            <Grid container justify="center" alignItems="center">
              <Box
                bgcolor="#e6e9ef"
                borderRadius={25}
                padding="6px 9px"
                width="fit-content"
                height="fit-content"
              >
                <Typography
                  variant={smallComputer ? (mobile ? 'h3' : 'h6') : 'h5'}
                  component="span"
                >
                  <Box
                    fontWeight="fontWeightBold"
                    color={theme.palette.text.primary}
                  >
                    {`${livescore.scores || 0} : ${livescore.scores || 0}`}
                  </Box>
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              {/*<Logo src={''} imgHeight="85%" width={mobile ? 40 : 50} />*/}
              <Typography variant="body2" component="span">
                <Box
                  color={theme.palette.text.primary}
                  fontWeight="fontWeightSemiBold"
                  marginTop={1}
                >
                  {livescore.players[1].name}
                </Box>
              </Typography>
            </Grid>
          </Box>
        </Box>
      </CardContent>
    </CardGeneral>
  );
};

export default BigCardLiveScore;
