import React, { useState } from 'react';
import { Typography, Button, Box, Link } from '@material-ui/core/';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple, green, pink } from '@material-ui/core/colors';

type reviewProps = {
  id: number;
  journal: string;
  place: string;
  comment: string;
  file: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      backgroundColor: 'white',
      '&>img': {
        objectFit: 'contain',
      },
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
    pink: {
      color: theme.palette.getContrastText(pink[500]),
      backgroundColor: pink[500],
    },
    green: {
      color: '#fff',
      backgroundColor: green[500],
    },

    red: {
      backgroundColor: '#FCECE3',
      color: '#A14326',
    },
    blue: {
      backgroundColor: '#E1F0EE',
      color: '#125960',
    },
    yellow: {
      backgroundColor: '#FAF7D9',
      color: '#DA9640',
    },
  }),
);

const Review = props => {
  const { journal, comment, file, title, link } = props;
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [readMore, setReadMore] = useState(false);
  const { t } = useTranslation();
  const limitCaracters = 140;

  return (
    <Box
      boxShadow="4px 4px 9px 1px rgba(0, 0, 0, 0.1);"
      borderRadius="11px"
      padding="1.5rem"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
      minHeight={mobile ? '' : '240px'}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        flexWrap="nowrap"
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Avatar
            src={file || ''}
            alt="pseudo"
            className={classes.img}
            style={{ fontWeight: 600, width: 50, height: 50, fontSize: 22 }}
          />
        </Box>
        <Box
          width={'auto'}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          marginLeft={'1rem'}
          textAlign="left"
          height="100%"
        >
          <Typography variant={mobile ? 'h2' : 'h3'} component={'span'}>
            <Box color="#3C4045" fontWeight={600}>
              {journal}
            </Box>
          </Typography>
        </Box>
      </Box>
      <Box color="#6a6f75" marginTop={'1rem'} textAlign="left">
        <Typography variant={mobile ? 'h4' : 'h6'} component={'span'}>
          <Box fontWeight={600} lineHeight="30px">
            {t(title)}
          </Box>
        </Typography>
        <Typography variant={mobile ? 'h4' : 'h6'} component={'span'}>
          <Box fontWeight={500} lineHeight="24px">
            {comment?.length < limitCaracters ? (
              comment
            ) : readMore ? (
              <>
                {comment}
                <Typography variant="h6" component={'span'}>
                  <Box
                    fontWeight={500}
                    lineHeight="24px"
                    color="#28c5fb"
                    width="max-content"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setReadMore(false)}
                  >
                    {t('Lire moins')}
                  </Box>
                </Typography>
              </>
            ) : (
              <>
                {comment?.substring(0, limitCaracters) + ' ... '}
                <Typography variant="h6" component={'span'}>
                  <Box
                    fontWeight={500}
                    lineHeight="24px"
                    color="#28c5fb"
                    display="inline"
                    style={{ cursor: 'pointer', zIndex: 10 }}
                    onClick={() => setReadMore(true)}
                  >
                    {t('Lire plus')}
                  </Box>
                </Typography>
              </>
            )}
          </Box>
        </Typography>
      </Box>
      {link && (
        <a target="_blank" href={link}>
          <Button>
            {t("Lire l'article ")}
            <strong>&nbsp;{t(journal)}&nbsp;</strong>
            {' sur Datawin'}
          </Button>
        </a>
      )}
    </Box>
  );
};

export default Review;
