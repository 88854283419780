import { useState, useEffect } from 'react';

export type TUseAsync2<T, E> = {
  data: T;
  loading: boolean;
  error: E | null;
};

const useAsync2 = <T, E>(
  request: Promise<T>,
  defaultValue: T,
): TUseAsync2<T, E> => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<E | null>(null);
  const [data, setData] = useState<T>(defaultValue);

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await request;
        setData(res);
      } catch (err) {
        console.error(err);
        setError(err);
      }
      setLoading(false);
    };
    fetch();
  }, [request]);

  return { data, loading, error };
};

export default useAsync2;
