import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { capitalize } from '@material-ui/core';
import { getInfosPronoLibre } from '../../../../../../utils/pronoLibreSoccer';
import CardGeneral from '../../CardGeneral';
import BorderLinearProgress from '../../../Progress/BorderLinearProgress';
import CardPronoPourcentage from '../../CardPronoPourcentage';
import CardPronoGoals from '../../CardPronoGoals';
import { isTooLong } from '../../../../../../utils/logic';
import SportGPTPredictionsFootball from './SportGPTPredictionsFootball';
import SportGPTPredictionsTennis from './SportGPTPredictionsTennis';
import SportGPTPredictionsBasket from './SportGPTPredictionsBasket';
import SportGPTMainProno from './SportGPTProno';

const useStyles = makeStyles((theme: Theme) => ({
  outBox: {
    borderRadius: 25,
    height: '100%',
    width: '100%',
  },
  item: {
    padding: '2rem',
  },
  itemStats: {
    padding: '2rem',
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0',
    },
  },
  title: {
    margin: '.75rem 0 1.5rem 0',
  },
  title2: {
    margin: '4rem 0 1rem 0',
  },
  riskText: {
    marginBottom: '.5rem',
    marginLeft: '.5rem',
    fontWeight: 400,
    fontSize: '1.3rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1rem',
    },
  },
  greenText: {
    color: '#10ff00',
  },
  orangeText: {
    color: '#ffa500',
  },
}));

export const SportGPTSinglePronoPredictions: React.FC<{
  prono: any;
}> = ({ prono }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  const team1_name = t(prono?.player1?.name);
  const team2_name = t(prono?.player2?.name);

  const { i18n } = useTranslation();
  const language = i18n.language;

  return (
    <>
      <Typography variant="h3" className={classes.title}>
        {t("Analyse de l'algorithme :")}
      </Typography>
      <CardGeneral
        blue
        className={classes.item}
        data-tour="presentation-prono-step"
      >
        <Typography
          className={clsx(
            classes.riskText,
            prono?.percentage >= 50 ? classes.greenText : classes.orangeText,
          )}
          variant="h4"
        >
          {prono?.percentage >= 50 ? '' : 'Risque élevé'}
        </Typography>
        <div
          className={clsx(
            classes.outBox,
            prono?.percentage >= 50
              ? 'animateBackgroundGreen'
              : 'animateBackgroundOrange',
          )}
        >
          <Box
            height={mobile ? '9rem' : '4.5rem'}
            width="100%"
            fontWeight="bold"
            style={{
              backgroundColor: '#18244f',
              backgroundClip: 'padding-box',
            }}
            borderRadius="25px"
            border="3px solid transparent"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            color="white"
            padding={mobile ? '4rem 2rem' : '4rem 0'}
            textAlign="center"
            className={clsx(
              prono?.percentage >= 50
                ? 'animateBoxShadowGreen'
                : 'animateBoxShadowOrange',
            )}
          >
            {/* <Typography
              variant={mobile ? 'h3' : 'h2'}
              style={{
                marginBottom: mobile ? '.4rem' : '-1rem',
                maxWidth: '65%',
              }}
            >
              PRONO
            </Typography> */}
            <SportGPTMainProno prono={prono} />
            <Box maxWidth="80%" width="100%">
              {prono?.percentage && (
                <BorderLinearProgress
                  pct={prono?.percentage}
                  green={prono?.percentage >= 50}
                  orange={prono?.percentage < 50}
                  shadow
                  legend
                  animate
                />
              )}
            </Box>
          </Box>
        </div>
      </CardGeneral>

      {prono?.sport === 'football' && (
        <SportGPTPredictionsFootball prono={prono} />
      )}
      {prono?.sport === 'tennis' && <SportGPTPredictionsTennis prono={prono} />}
      {prono?.sport === 'basketball' && (
        <SportGPTPredictionsBasket prono={prono} />
      )}
    </>
  );
};

export default SportGPTSinglePronoPredictions;
