import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useTranslation } from 'react-i18next';
import { Fire } from 'services';
import { useAsync2 } from './index';
import { useMemo } from 'react';
import { Faq, FaqTheme } from '../types/faq';

type UseFaq = {
  faq: Faq[];
  loading: boolean;
  error: Error | null;
  themes: FaqTheme[];
};

const useFaq: () => UseFaq = () => {
  const request = useMemo(() => Fire.list(Fire.store().collection('faq')), []);
  const { data: faq, loading, error } = useAsync2<Faq[], Error>(request, []);

  const requestFaqThemes = useMemo(
    () => Fire.list(Fire.store().collection('faqThemes')),
    [],
  );
  const { data: themes } = useAsync2<FaqTheme[], Error>(requestFaqThemes, []);

  return {
    faq,
    themes,
    loading,
    error,
  };
};

export default useFaq;
