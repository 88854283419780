import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { authReducer } from 'actions/auth.action';
import { cardsReducer } from 'actions/cards.action';
import { sportsReducer } from 'actions/sportsReducer.action';
import { pronosReducer } from 'actions/pronosReducer.action';
import { geolocationReducer } from 'actions/geolocation.action';

export type RootState = ReturnType<typeof persistedReducer>;

const persistConfig = {
  key: 'root',
  storage,
};

export const persistedReducer = persistCombineReducers(persistConfig, {
  // @ts-ignore
  cardsReducer, // @ts-ignore
  authReducer, // @ts-ignore
  sportsReducer, // @ts-ignore
  pronosReducer, // @ts-ignore
  geolocationReducer,
});

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

const persistor = persistStore(store);
export { store, persistor };
