import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardGeneral from '../../../Templates/Cards/CardGeneral';
import Loading from '../../../../Loading/Loading';
import { LivescoreTennis } from '../../../../../types/liveScore';
import CardLivescoreTennis from './CardLiveScoreTennis/CardLivescoreTennis';
import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core/';
import { Fire } from 'services';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      flexDirection: 'row',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    card: {
      width: '100%',
      flexBasis: '100%',
    },
  }),
);

const LiveScoreTennis: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: livescores, isLoading } = useQuery(
    'tennis:live-matches',
    () => Fire.cloud<LivescoreTennis[]>('tennis', { method: 'getLiveScores' }),
    { staleTime: 900000 /* 5 mn */ },
  );

  if (isLoading || !livescores) return <Loading nobg />;
  const scores = livescores
    .filter(({ status }) => status === 'live')
    .slice(0, 2);
  return (
    <React.Fragment>
      <Grid
        container
        className={classes.root}
        spacing={2}
        style={{ justifyContent: 'space-between' }}
        wrap="nowrap"
        data-tour="outils-livescore-step"
      >
        {scores?.length ? (
          scores.slice(0, 3).map((score, i) => (
            <Grid
              container
              item
              xs={12}
              md={4}
              key={i}
              style={{ justifyContent: 'center', alignItems: 'flex-start' }}
            >
              <CardLivescoreTennis score={score} />
            </Grid>
          ))
        ) : (
          <Box
            height="33.33%"
            width="80%"
            margin="auto"
            display="flex"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Typography variant="h4">
              {t('Pas de match en live pour le moment')}
            </Typography>
          </Box>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default LiveScoreTennis;
