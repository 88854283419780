import React from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import PreviewTitle from '../../../Templates/Misc/PreviewTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { GridSpacing } from '@material-ui/core/Grid/Grid';
import { TFunction } from 'i18next';
import moment from 'moment';
import { PronoNHL } from '../../../../../types/pronoNHL';
import CardPredictionNHL from '../../../Templates/Cards/CardPredictionNHL/CardPredictionNHL';
import SimpleButtonYellow from '../../../Templates/Buttons/SimpleButtonYellow';
import { Fire } from 'services';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    singleCard: {
      maxWidth: 400,
      minWidth: 280,
      width: '30%',
      marginRight: '2rem',
      [theme.breakpoints.down('sm')]: {
        marginRight: '2rem',
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 280,
      },
    },
    last: {
      [theme.breakpoints.down('md')]: {
        margin: 'auto',
      },
    },
    fullWidth: {
      [theme.breakpoints.down('xs')]: {
        marginRight: '0px !important',
      },
    },
    singleCardContainer: {
      flexFlow: 'row nowrap',
      overflowX: 'scroll',
      marginBottom: '2rem',
      scrollbarColor: theme.palette.text.secondary + ' transparent',
      scrollbarWidth: 'thin',
      paddingBottom: '2rem',
      paddingTop: '1rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      position: 'relative',
      '&::-webkit-scrollbar': {
        height: '10px',
        paddingBottom: '1rem',
      },

      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.text.secondary,
        transition: 'background-color .3s ease-in-out',
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: theme.palette.text.primary,
        },
      },
    },
  }),
);

type NHLPreviewProps = {
  res: PronoNHL[];
  spacing: GridSpacing;
  bigComputer: boolean;
  smallComputer: boolean;
  tablette: boolean;
  mobile: boolean;
  t: TFunction;
};

const orderPronos = (
  res: PronoNHL[],
  isProUser: boolean | undefined,
): PronoNHL[] => {
  const now = moment();
  const pronosToShow = res
    .filter(prono => {
      if ((prono.isPronoTest || prono.name_inactive) && !isProUser)
        return false;
      if (!prono.winner) return false;
      if (!prono.dateHideUntil) return true;
      return now.isAfter(prono.dateHideUntil.toDate());
    })
    .sort((a, b) => a.date?.toMillis() - b.date?.toMillis());
  const pronosToHide = res
    .filter(prono => {
      if ((prono.isPronoTest || prono.name_inactive) && !isProUser)
        return false;
      return now.isBefore(prono.dateHideUntil?.toDate());
    })
    .sort((a, b) => a.dateHideUntil?.toMillis() - b.dateHideUntil?.toMillis());
  return [...pronosToShow, ...pronosToHide];
};

const NHLPreview: React.FC<NHLPreviewProps> = ({
  res,
  spacing,
  mobile,
  smallComputer,
  tablette,
  bigComputer,
  t,
}) => {
  const [customText, setCustomText] = React.useState(
    "Pas d'analyse disponible pour le moment",
  );
  const [isProUser, setIsProUser] = React.useState<boolean>();
  const user = useSelector((state: any) => state.authReducer.user);
  const pronos = orderPronos(res, isProUser);
  const classes = useStyles();
  const now = new Date().getTime() / 1000;
  const theme = useTheme();

  const getCustomText = async () => {
    try {
      const ref = Fire.store().collection('custom_text').doc('preview_pronos');
      const text = await Fire.doc(ref);
      if (text.nhl) setCustomText(text.nhl);
    } catch (err) {
      console.log(err);
    }
  };

  const verifyIsProUser = async () => {
    const ref = Fire.store()
      .collection('pros')
      .where('email', '==', user.email);
    const list = await Fire.list(ref);
    setIsProUser(list.length !== 0);
  };

  React.useEffect(() => {
    getCustomText();
    verifyIsProUser();
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
      >
        <PreviewTitle title="Analyses" marginBottom />
        <SimpleButtonYellow
          title={t("d'analyses")}
          addIcon={true}
          path="pronos"
        />
      </Box>
      <Grid
        item
        container
        xs={12}
        className={clsx(classes.singleCardContainer, 'noPaddingRight')}
      >
        {pronos.length !== 0 ? (
          pronos.map((prono, index) => (
            <Grid
              item
              xs={12}
              sm={4}
              className={clsx(
                classes.singleCard,
                index === pronos.length - 1 &&
                  pronos.length % 2 === 1 &&
                  pronos.length !== 1 &&
                  classes.last,
                pronos.length === 1 && classes.fullWidth,
              )}
              key={index}
            >
              <CardPredictionNHL
                future={prono.dateHideUntil?.seconds > now}
                prono={prono}
              />
            </Grid>
          ))
        ) : (
          <Typography variant="h6" component={'span'}>
            <Box fontWeight={500} color={theme.palette.text.secondary}>
              {t(customText)}
              {/* {t('La NHL reprend le 19 octobre')} */}
            </Box>
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default NHLPreview;
