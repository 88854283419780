import React, { useCallback, useEffect, useState } from 'react';
import {
  useTheme,
  createStyles,
  fade,
  Theme,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import TitleCard from '../Templates/Account/TitleCard';
import '../../../theme/datawinTheme.css';
import { useTranslation, initReactI18next } from 'react-i18next';
import { Typography, MenuItem } from '@material-ui/core';
import { updateUser } from 'actions/auth.action';
import { useSelector, useDispatch } from 'react-redux';
import Select from '@material-ui/core/Select';
import { Fire } from 'services';

const countryListAlpha2 = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas (the)',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia (Plurinational State of)',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory (the)',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands (the)',
  CF: 'Central African Republic (the)',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands (the)',
  CO: 'Colombia',
  KM: 'Comoros (the)',
  CD: 'Congo (the Democratic Republic of the)',
  CG: 'Congo (the)',
  CK: 'Cook Islands (the)',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  CI: "Côte d'Ivoire",
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic (the)',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (the) [Malvinas]',
  FO: 'Faroe Islands (the)',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories (the)',
  GA: 'Gabon',
  GM: 'Gambia (the)',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See (the)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran (Islamic Republic of)',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea (the Democratic People's Republic of)",
  KR: 'Korea (the Republic of)',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic (the)",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands (the)',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia (Federated States of)',
  MD: 'Moldova (the Republic of)',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands (the)',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger (the)',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands (the)',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines (the)',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  MK: 'Republic of North Macedonia',
  RO: 'Romania',
  RU: 'Russian Federation (the)',
  RW: 'Rwanda',
  RE: 'Réunion',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan (the)',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands (the)',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates (the)',
  GB: 'United Kingdom of Great Britain and Northern Ireland (the)',
  UM: 'United States Minor Outlying Islands (the)',
  US: 'United States of America (the)',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (Bolivarian Republic of)',
  VN: 'Viet Nam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AX: 'Åland Islands',
  NONE: 'Non renseigné',
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },

      fontWeight: 600,
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      border: '1px solid #A4A4A4',
      fontSize: 16,
      width: '100%',
      padding: '10px 12px',
      marginTop: '.5rem',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.secondary.main,
      },

      '&.Mui-disabled': {
        backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
        color: '#a2a3a5 !important',
        border: 'none',
      },
    },
  }),
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
    },
    margin: {
      margin: theme.spacing(1),
    },
    label: {
      fontWeight: 600,
      transform: 'translate(0, 1.5px) scale(1)',
      transformOrigin: 'top left',
      color: 'white',
      '&.Mui-focused': {
        color: 'white !important',
      },
    },
    snackbar: {
      position: 'static',
    },
    select: {
      marginTop: theme.spacing(3),
      transform: 'translate(0, .5rem)',
      fontWeight: 600,
      '& .MuiSelect-root': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        border: '1px solid #A4A4A4',
        padding: '11px 12px',
      },

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
      },
    },
  }),
);

type Severity = 'error' | 'success' | 'info' | 'warning' | undefined;

const Infos = () => {
  const [loading, setLoading] = React.useState(false);

  const user = useSelector((state: any) => state.authReducer.user);
  const dispatch = useDispatch();
  const [info, setInfo] = React.useState<any>({ ...user });
  const [changed, setChanged] = React.useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [messageOpen, setMessageOpen] = React.useState(false);
  const [message, setMessage] = useState('');
  const [messageSeverity, setMessageSeverity] = useState<Severity>('success');
  const [states, setStates] = React.useState<any>([]);

  const refreshStates = useCallback(async () => {
    setLoading(true);
    try {
      const stateListRef = Fire.store().collection('usStates');
      const data = await Fire.list(stateListRef);
      const newStatesList = data.map(state => ({
        ...state,
      }));
      setStates(newStatesList);
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  }, []);

  React.useEffect(() => {
    refreshStates();
  }, [refreshStates]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    handleMessage(false);
  };

  const handleMessage = (
    isOpen,
    msg = '',
    severity: Severity = messageSeverity,
  ) => {
    setMessage(msg);
    setMessageOpen(isOpen);
    setMessageSeverity(severity);
  };

  const spacing = mobile ? 1 : 5;

  const { t, i18n } = useTranslation();

  const setValue = (key: string, val: any) => {
    if (key === 'phone') {
      let phone = val.target.value;
      phone = phone.toString().replace(/ /g, '').split('.').join('');
      info[key] = phone;
      setInfo({ ...info });
      setChanged(true);
    } else {
      info[key] = val.target.value;
      setInfo({ ...info });
      setChanged(true);
    }
  };

  const setUserCountryCode = (val: any) => {
    info.location = {
      ...info.location,
      countryCode: val.target.value,
    };
    setInfo({ ...info });
    setChanged(true);
  };

  const setUserState = (val: any) => {
    info.location = {
      ...info.location,
      state: val.target.value,
    };
    setInfo({ ...info });
    setChanged(true);
  };

  const save = async () => {
    setLoading(true);
    if (
      !info['phone'].match(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
      )
    ) {
      handleMessage(
        true,
        "Votre numéro de téléphone n'est pas valide !",
        'error',
      );
      return;
    }
    try {
      const payload = {
        ...info,
      };
      await dispatch(updateUser(payload));
      handleMessage(true, 'Vos informations ont bien été enregistrées');
      setChanged(false);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <Box width="100%" height="100%" padding="2rem 0 0">
      <TitleCard title={t('Mes informations personnelles')} />
      <form className={classes.form} noValidate autoComplete="off">
        <Grid item container spacing={spacing}>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.margin} fullWidth>
              <InputLabel htmlFor="lastname" className={classes.label}>
                {t('Nom')}
              </InputLabel>
              <BootstrapInput
                defaultValue={info.last_name}
                id="lastname"
                onChange={val => setValue('last_name', val)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.margin} fullWidth>
              <InputLabel htmlFor="firstname" className={classes.label}>
                {t('Prénom')}
              </InputLabel>
              <BootstrapInput
                defaultValue={info.first_name}
                id="firstname"
                onChange={val => setValue('first_name', val)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.margin} fullWidth>
              <InputLabel htmlFor="email" className={classes.label}>
                {t('Email')}
              </InputLabel>
              <BootstrapInput defaultValue={info.email} id="email" disabled />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl className={classes.margin} fullWidth>
              <InputLabel htmlFor="phone" className={classes.label}>
                {t('Téléphone')}
              </InputLabel>
              <BootstrapInput
                defaultValue={info.phone}
                id="phone"
                onChange={val => setValue('phone', val)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl className={classes.margin} fullWidth>
              <InputLabel htmlFor="country" className={classes.label}>
                {t('Pays')}
              </InputLabel>
              <Select
                variant="outlined"
                value={info.location?.countryCode || 'NONE'}
                onChange={val => setUserCountryCode(val)}
                className={classes.select}
              >
                <MenuItem aria-label="None" value="NONE">
                  Non renseigné
                </MenuItem>
                {Object.keys(countryListAlpha2).map(key => (
                  <MenuItem value={key} key={key}>
                    {countryListAlpha2[key]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {info.location?.countryCode === 'US' && (
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.margin} fullWidth>
                <InputLabel htmlFor="country" className={classes.label}>
                  {t('Etat')}
                </InputLabel>
                <Select
                  variant="outlined"
                  value={info.location?.state || 'NONE'}
                  onChange={val => setUserState(val)}
                  className={classes.select}
                >
                  <MenuItem aria-label="None" value="NONE">
                    Non renseigné
                  </MenuItem>
                  {states &&
                    states?.map(key => (
                      <MenuItem value={key.name}>{key.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box width="max-content" marginLeft="auto">
              <Typography color="textSecondary" variant="h5" component="span">
                <Box color="white" fontWeight="500">
                  <Button disabled={!changed} onClick={save}>
                    <Box>{t('Enregistrer')}</Box>
                  </Button>
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={messageOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        key={'bottom'}
        className={classes.snackbar}
      >
        <Alert onClose={handleClose} severity={messageSeverity}>
          {t(message)}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Infos;
