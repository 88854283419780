import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DoublesImages from '../DoublesImages/DoublesImages';
import React from 'react';
import {
  Gender,
  PronoTennisCardPlayer,
} from '../../../../../types/pronoTennis';
import PlayerPhoto from '../../../Templates/Tennis/PlayerPhoto';

type OtherPronoTeamRowProps = {
  teams: PronoTennisCardPlayer[][];
  gender: Gender;
};

const OtherPronoTeamRow: React.FC<OtherPronoTeamRowProps> = ({
  teams,
  gender,
}) => {
  const [team1, team2] = teams;

  return (
    <Grid container xs={12} direction="column">
      <Grid container item xs={12} justifyContent="space-around">
        <Grid container item xs={5} alignItems="center" justifyContent="center">
          <DoublesImages
            player1Id={team1[0].id}
            player2Id={team1[1].id}
            gender={gender}
          />
        </Grid>
        <Grid container item xs={7} alignItems="center" justifyContent="center">
          <Box fontWeight={600} textAlign="center">
            <Typography variant="body2">
              {team1[0].firstName} {team1[0].lastName} <br />
            </Typography>
            <Typography variant="body2" style={{ marginTop: '.5rem' }}>
              {team1[1].firstName}
              {team1[1].lastName}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Typography
          variant="body1"
          style={{ margin: '1rem 0 1rem 1.25rem', fontWeight: 'bold' }}
        >
          VS
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="space-around">
        <Grid container item xs={5} alignItems="center" justifyContent="center">
          <DoublesImages
            player1Id={team2[0].id}
            player2Id={team2[1].id}
            gender={gender}
          />
        </Grid>
        <Grid container item xs={7} alignItems="center" justifyContent="center">
          <Box fontWeight={600} textAlign="center">
            <Typography variant="body2">
              {team2[0].firstName} {team2[0].lastName} <br />
            </Typography>
            <Typography variant="body2" style={{ marginTop: '.5rem' }}>
              {team2[1].firstName}
              {team2[1].lastName}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OtherPronoTeamRow;
