import React from 'react';
import './Footer.scss';
import { useTranslation, initReactI18next } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import begambleaware from 'images/homePage/begambleaware.png';

function Footer() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  return (
    <div className="footer-wrapper" id="footer">
      {/* <div className="background"></div> */}
      <div className="border-pay">
        <div className="pay-secure">{t('Paiement sécurisé')}</div>
        <div className="pay-secure-img"></div>
      </div>
      <div className="col-center">
        {/* <div className="sep"></div> */}
        <img
          className="logo"
          src={require('../../../images/logo_datawin.png')}
        />
        {/* <div className="row-center-responsive">
            <a className="link" href="cgu.html">CGU</a>
            <span className="link-sep">/</span> */}
        {/* <a className="link" href="">Contact</a>
            <span className="link-sep">/</span> */}
        {/* <a className="link" href="">Brochures</a>
            <span className="link-sep">/</span> */}
        {/* <a className="link" href="">Mentions légales</a> */}
        {/* <span className="link-sep">/</span> */}
        {/* <a className="link" href="">Presse</a> */}
        {/* </div> */}
      </div>
      <div className="col-center hide footer-links">
        <div style={{ display: 'inherit' }}>
          {/* <a className="link" href="terms.html">{t("CGU")}</a>
          <span className="link-sep">/</span>
          <a className="link" href="confidentialité.html">{t("Politique de confidentialité")}</a>
        <span className="link-sep">/</span> */}
          <a href="/terms" target="_blank" className="link">
            CGV & Mentions légales
          </a>{' '}
          /
          <a href="/privacy" target="_blank" className="link">
            CONFIDENTIALITÉ
          </a>{' '}
          /
          {/* <div className="link" onClick={() => history.push('/terms')}>
            {t('CGU & Mentions légales')}
          </div> / 
          <div className="link" onClick={() => history.push('/privacy')}>
            {t('Confidentialité')}
          </div> / */}
          <a className="link" href="mailto:support@datawin-prediction.com">
            {t('Contactez-nous')}
          </a>
          <span className="link-sep">/</span>
          {/* <a className="link" href="">{t("Brochures")}</a>
          <span className="link-sep">/</span>
          <a className="link" href="">{t("Mentions légales")}</a>
        <span className="link-sep">/</span>*/}
          {/* <a
            className="link"
            href="https://partenaires.lepoint.fr/quand-lia-et-le-machine-learning-sattaquent-aux-paris-sportifs"
            target="_blank"
          >
            {t('Presse')}
          </a>
          <a className="link" href="/avis" target="_blank">
            {t('Avis')}
          </a> */}
        </div>
        <div className="copyright">
          © 2021 DATAWIN. {t('Tous droits réservés')}
        </div>
      </div>
      {i18n.language === 'en' ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          alignItems="center"
          marginTop="1rem"
        >
          {/* <img src={begambleaware} alt="" style={{ maxWidth: '200px' }} />
          <Typography variant="body1" component={'span'}>
            <Box
              fontSize=".85rem"
              color="white"
              textAlign="center"
              marginTop="1rem"
            >
              <a
                href="https://www.begambleaware.org/"
                style={{ color: '#bbb', textDecoration: 'none' }}
                target="_blank"
              >
                https://www.begambleaware.org/
              </a>
            </Box>
          </Typography> */}
        </Box>
      ) : (
        <Typography variant="body1" component={'span'}>
          <Box fontSize="9px" color="#848ca5" textAlign="center">
            {t(
              'Les informations diffusées sur le Site datawin.io n’engagent en aucun cas la responsabilité de l’équipe du Site datawin.io, il ne s’agit en aucun cas d’un encouragement à placer des paris en ligne. Ce site ne doit pas être considéré comme un conseil en investissement.',
            )}
          </Box>
        </Typography>
      )}
      {/* 
      <div className="border-pay-responsive">
        <div className="pay-secure">PAIEMENT SÉCURISÉ</div>
        <div className="pay-secure-img">.</div>
      </div> */}

      {/* <div className="copyright">Powered @LIKEWEB AGENCY</div> */}
    </div>
  );
}

export default Footer;
