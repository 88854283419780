import React from 'react';
import './CircleData.scss';

type Props = {
  value: string;
  title: string;
}
function CircleData(props: Props) {
  return (
    <div className="circle-data">
      <div className="circle-value">{props.value}</div>
      <div className="circle-title">{props.title}</div>
    </div>
  );
}

export default CircleData;
