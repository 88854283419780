import React from 'react';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    grayScale: {
      filter: 'grayscale(1)',
    },
    backgroundGrey: {
      background: '#d2d2d2',
    },
  }),
);

type ClubLogoProps = {
  future: boolean;
  mobile: boolean;
  src: string;
};

const ClubLogo: React.FC<ClubLogoProps> = ({ future, mobile, src }) => {
  const classes = useStyles();
  return (
    <Box
      width="33%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginTop={!mobile && '10px'}
    >
      <Box
        borderRadius="50%"
        height="50px"
        width="50px"
        bgcolor="white"
        display="flex"
        justifyContent="center"
        alignItems="center"
        boxShadow={future ? '' : '0 0 33px 0 rgb(0 0 0 / 11%)'}
        className={future ? classes.backgroundGrey : ''}
      >
        <img
          alt=""
          src={src}
          className={future ? classes.grayScale : ''}
          style={{
            maxWidth: '95%',
            maxHeight: '95%',
            borderRadius: '50%',
          }}
        />
      </Box>
    </Box>
  );
};

export default ClubLogo;
