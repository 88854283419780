import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment';
import { Helmet } from 'react-helmet';

// Material UI
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Particles from 'react-tsparticles';
import { Typography, Box } from '@material-ui/core/';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';

// Components Custom
import Navbar from './NavbarHome/NavbarHome';
import Title from './Templates/Title';
import CircularHome from './Templates/CircularHome';
import ButtonFilled from '../Dashboard/Templates/Buttons/ButtonFilled';
import ButtonOutlinedGrey from '../Dashboard/Templates/Buttons/ButtonOutlinedGrey';
import Review from './Templates/Review';
import Logo from '../Dashboard/Templates/Logos/Logo';
import HomePerformance from './HomePerformance/HomePerformance';
import HomeSteps from './HomeSteps/HomeSteps';

// Images
import Home_background_particles from 'images/homePage/Home_Background_Particles.svg';
import BeinSport_Logo from 'images/homePage/BeinSport_Logo.svg';
import Lequipe_Logo from 'images/homePage/Lequipe_Logo.svg';
import RmcSport_Logo from 'images/homePage/RmcSport_Logo.svg';
import Eurosport_Logo from 'images/homePage/Eurosport_Logo.svg';
import Automatisation from 'images/homePage/Automatisation.svg';
import Statistiques from 'images/homePage/Statistiques.svg';
import Reussite from 'images/homePage/Reussite.svg';
import Stabilite from 'images/homePage/Stabilite.svg';
import HomePhonePngIT from 'images/homePage/HomePhonePngIT.png';
import HomePhonePngES from 'images/homePage/HomePhonePngES.png';
import HomePhonePngEN from 'images/homePage/HomePhonePngEN.png';
import HomePhonePng from 'images/homePage/HomePhonePng.png';
import Home_Background_Particles2 from 'images/homePage/Home_Background_Particles2.svg';
import Home_Background_Particles4 from 'images/homePage/Home_Background_Particles4.svg';
import HomeMaquette from 'images/homePage/HomeMaquette.png';
import HomeMaquetteIT from 'images/homePage/home_maquette_it.png';
import HomeMaquetteES from 'images/homePage/HomeMaquetteES.png';
import HomeMaquetteEN from 'images/homePage/HomeMaquetteEN.png';
import Home_Background_Bookmakers_Mobile from 'images/homePage/Home_Background_Bookmakers_Mobile.png';
import Home_Background_Bookmakers_Desktop from 'images/homePage/Home_Background_Bookmakers_Desktop.png';
import LaLigua_Logo from 'images/homePage/LaLigua_Logo.svg';
import UefaEuropaLeague_Logo from 'images/homePage/UefaEuropaLeague_Logo.svg';
import UefaChampionsLeague_Logo from 'images/homePage/UefaChampionsLeague_Logo.svg';
import Ligue1_Logo from 'images/homePage/Ligue1_Logo.svg';
import PremierLeague_Logo from 'images/homePage/PremierLeague_Logo.svg';
import HomeGraph from 'images/homePage/HomeGraph.svg';
import Datawin_Logo_YellowShiny from 'images/homePage/Datawin_Logo_YellowShiny.svg';
import SocialLogos_linkedin from 'images/homePage/SocialLogos_linkedin.svg';
import SocialLogos_pinterest from 'images/homePage/SocialLogos_pinterest.svg';
import SocialLogos_youtube from 'images/homePage/SocialLogos_youtube.svg';
import SocialLogos_instagram from 'images/homePage/SocialLogos_instagram.svg';
import SocialLogos_googleplus from 'images/homePage/SocialLogos_googleplus.svg';
import SocialLogos_twitter from 'images/homePage/SocialLogos_twitter.svg';
import SocialLogos_facebook from 'images/homePage/SocialLogos_facebook.svg';
import logo_belgique from 'images/logo_belgique.png';
import logo_italie from 'images/logo_italie.png';
import logo_forbes_small from 'images/logo_forbes_small.png';
import logo_lePoint from 'images/logo_lePoint.png';
import logo_ilSole from 'images/logo_ilSole.png';
import logo_laRepublica from 'images/logo_laRepublica.png';
import logo_capital from 'images/logo_capital.svg';
import logo_rai from 'images/logo_rai.png';
import logo_tuttosport from 'images/logo_tuttosport.png';
import logo_laGazzettaDelloSport from 'images/logo_laGazzettaDelloSport.png';
import mastercard from 'images/homePage/datawin-mastercard-secure.png';
import visa from 'images/homePage/datawin-visa-secure.png';
import begambleaware from 'images/homePage/begambleaware.png';
import imgHeroSection from 'images/img-hero-section.webp';
import ImageAlgo from 'images/img-algorithme.webp';
import ImageInterface from 'images/img-interface.webp';
import ImgConcept from 'images/img-concept.webp';
import Trustpilot from 'images/Trustpilot.png';

//Call API
import {
  getTrialPriceFromLocation,
  getTrialPriceFromCountryCode,
} from '../../utils/countryCodeAPI';
import { Fire } from 'services';
import { PronoSoccer } from '../../../../cloud/functions/src/types/sportradar/soccer/prono-soccer';
import HomeTabsInfos from './HomeTabsInfos/HomeTabsInfos';
import HomeInfosCards from './HomeInfosCards/HomeInfosCards';
import NewInPronos from './NewInPronos/NewInPronos';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    home: {
      backgroundColor: '#0a1437',
      minHeight: '100vh',
    },
    particles: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    homePadding: {
      padding: '4rem 2rem',
      [theme.breakpoints.up('sm')]: {
        padding: '4rem 5rem 2rem',
      },
    },
    headerTop: {
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexWrap: 'nowrap',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },

      '& a': {
        minWidth: 150,
        [theme.breakpoints.down('sm')]: {
          minWidth: 135,
        },
      },
    },
    headerBackground: {
      position: 'absolute',
      background: `url(${Home_background_particles}) no-repeat right`,
      backgroundSize: 'contain',
      top: '10%',
      right: '-2rem',
      left: 0,
      bottom: '5%',
    },
    headerCard: {
      border: '3px solid rgb(9, 28, 59, .8)',
      background: 'rgba(9, 28, 59, .8)',
      borderRadius: '.75rem',
      width: '100%',
      maxWidth: '750px',
      margin: '1rem auto',
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    headerCard1: {
      width: '100%',
      marginBottom: '1rem',
      [theme.breakpoints.up('sm')]: {
        width: '60%',
        paddingRight: '3rem',
      },
    },
    headerCard2: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '40%',
      },
    },
    outbox: {
      background:
        'linear-gradient(to bottom, #68ead1 100%, #67c3e6 51%, #68bdf1 0%)',
      borderRadius: '15px',
      boxShadow: '0 0 18px 0 rgba(138, 225, 255, 0.25)',
      padding: '3px',
    },
    outboxHeaderCard: {
      opacity: '0.26',
      filter: 'blur(50px)',
      backgroundIimage:
        'radial-gradient(circle at 50% 64%, #4cd9f7, rgba(37, 177, 236, 0) 139%)',
    },
    logosContainer: {
      maxWidth: '900px',
      margin: 'auto',
      marginTop: '3rem',
      '&>img': {
        maxWidth: '21%',
        height: 'unset !important',
      },
    },
    logosContainer2: {
      maxWidth: '1250px',
      margin: 'auto',
      marginTop: '3rem',
      '&>img': {
        maxHeight: '90px',
        maxWidth: '170px',
        height: 'unset !important',
        [theme.breakpoints.down('xs')]: {
          maxHeight: '70px',
          minWidth: '26%',
          maxWidth: '132px',
          margin: '.5rem auto',
          flexGrow: '33%',
        },
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
    bgRadial: {
      backgroundImage:
        'radial-gradient(circle at 50% 64%, #4cd9f74d, rgba(37, 177, 236, 0) 70%)',
      filter: 'blur(90px)',
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 0,
      [theme.breakpoints.down('sm')]: {
        backgroundImage:
          'radial-gradient(circle at 50% 64%, #4cd9f729, rgba(37, 177, 236, 0) 70%) !important',
      },
    },
    bgPhone: {
      position: 'absolute',
      backgroundImage:
        'radial-gradient(circle at 25% 54%, #51e1fe -10%, rgba(40, 191, 252, 0.4) 27%,rgba(40, 191, 252, 0) 64%)',
      filter: 'blur(50px)',
      height: '100%',
      width: '50%',
      transform: 'translate(45%, 0)',
    },
    bgPhoneParticles: {
      backgroundImage: `url(${Home_Background_Particles2})`,
    },
    bgBookmakers: {
      backgroundImage: `url(${Home_Background_Bookmakers_Mobile})`,
      paddingTop: '270px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',

      [theme.breakpoints.up('sm')]: {
        backgroundImage: `url(${Home_Background_Bookmakers_Desktop})`,
        paddingTop: '4rem',
        backgroundSize: 'cover',
      },
    },
    bgGraph: {
      background: `url(${HomeGraph}) no-repeat`,
      backgroundPosition: 'bottom right',
      backgroundSize: 'contain',
      [theme.breakpoints.down('sm')]: {
        backgroundPosition: 'bottom center',
      },
    },
    tabContainer: {
      '&>div[role=tabpanel]>div': {
        padding: '2rem 0',
      },
    },
    tabsContainer: {
      borderBottom: 'solid 3px rgba(131, 140, 168, .8);',
    },
    tabs: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      textTransform: 'none',
      fontWeight: 500,
      color: 'white !important',
    },
    appBar: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      marginTop: '3.5rem',
    },
    reviewsContainer: {
      overflowX: 'scroll',
      padding: '2rem',
      paddingBottom: '12rem !important',
      '-ms-overflow-style': 'none',

      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    bottomCard: {
      backgroundImage: `url(${Home_Background_Particles4})`,
      backgroundSize: 'contain',
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('sm')]: {
        backgroundImage: `url(${Home_background_particles})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom right',
      },
    },
    topFooter: {
      paddingTop: '37rem !important',

      [theme.breakpoints.up('sm')]: {
        paddingTop: '12rem !important',
      },
    },
  }),
);

const socialNetworks = [
  {
    link: 'https://www.facebook.com/Datawinio-110499897757925',
    image: SocialLogos_facebook,
  },
  {
    link: 'https://twitter.com/DatawinP',
    image: SocialLogos_twitter,
  },
  {
    link: 'https://www.instagram.com/datawin.io',
    image: SocialLogos_instagram,
  },
  {
    link: 'https://www.youtube.com/channel/UCtNW59TJoSghVrYSR-ttUSA',
    image: SocialLogos_youtube,
  },
];

const trElements = {
  maquette: {
    FR: ImageInterface,
    IT: ImageInterface,
    ES: ImageInterface,
    EN: ImageInterface,
  },
  phone: {
    FR: ImageAlgo,
    IT: ImageAlgo,
    ES: ImageAlgo,
    EN: ImageAlgo,
  },
};

const average = (numbers: number[]) =>
  numbers.reduce((a, b) => a + b) / numbers.length;

const HomePage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const smallComputer = useMediaQuery(theme.breakpoints.only('sm'));
  const [pronoHome, setPronoHome] = React.useState<PronoSoccer | null>(null);
  const { t, i18n } = useTranslation();
  const language = i18n.language.toUpperCase();

  const [price, setPrice] = useState('');
  const [userCountryCode, setUserCountryCode] = useState(language);

  const getPrice = async () => {
    try {
      const [countryCodeObject, trialPrice] = await getTrialPriceFromLocation();
      setPrice(trialPrice);
      setUserCountryCode(countryCodeObject.countryCode);
    } catch (err) {
      console.log(err);
    }
  };

  const getHomeProno = async () => {
    const [receivedProno] = await Fire.list(
      Fire.store().collection('pronos').where('isPronoHome', '==', true),
    );
    setPronoHome(receivedProno);
  };

  const getCote = (): string => {
    if (!pronoHome?.cotes) return '-';
    const isWinnerDom = pronoHome.domicile.id === pronoHome.team1.id;
    const allCotes = Object.values<[number, number, number] | null>(
      pronoHome.cotes,
    );
    const winnerCotes = (
      allCotes.filter(cotes => cotes?.length === 3) as [
        number,
        number,
        number,
      ][]
    ).map(([dom, , ext]) => (isWinnerDom ? dom : ext));
    return average(winnerCotes).toFixed(2);
  };

  React.useEffect(() => {
    getPrice();
    getHomeProno();
  }, []);

  const reviews = [
    {
      name: 'Forbes',
      date: '08/03/2021',
      review:
        'Datawin est un précurseur, de ceux qui peuvent transformer un marché. Ses analyses sportives et ses infos sont indispensables.',
      logo: logo_forbes_small,
      width: '65%',
      borderRadius: '2rem',
      mark: 5,
    },
    {
      name: i18n.language === 'it' ? 'il Sole' : 'Le Point',
      date: '24/02/2021',
      review:
        "L'équipe de Datawin garde jalousement sa recette et ne souhaite pas confier le moindre indice afin de conserver son avance",
      logo: i18n.language === 'it' ? logo_ilSole : logo_lePoint,
      width: '85%',
      borderRadius: '0',
      mark: 4,
    },
    {
      name: i18n.language === 'it' ? 'La Republica' : 'Capital',
      date: '12/09/2020',
      review:
        'L’équipe ne compte pas uniquement sur son algorithme, mais aussi sur son offre qui rassemble tout ce que les amateurs de sports désirent.',
      logo: i18n.language === 'it' ? logo_laRepublica : logo_capital,
      width: '85%',
      borderRadius: '0',
      mark: 5,
    },
  ];

  const redirectWithUTMUrl = url => {
    const target_url = (url += document.location.search || '');
    return target_url;
  };

  const containerImage = (title, text, img, index) => {
    return (
      <Box
        display="flex"
        width="100%"
        alignItems={mobile ? 'center' : 'flex-start'}
        textAlign={mobile ? 'center' : 'left'}
        justifyContent="center"
        flexDirection="column"
        marginTop="3rem"
        maxWidth={mobile ? '100%' : '230px'}
        position="relative"
        zIndex="1"
        key={index}
      >
        <img src={img} alt="Automatisation" />
        <Typography variant={mobile ? 'h2' : 'h3'} component={'span'}>
          <Box color="white" margin="1.5rem 0" position="relative" zIndex="1">
            {t(title)}
          </Box>
        </Typography>
        <Typography variant={mobile ? 'h3' : 'h5'} component={'span'}>
          <Box
            color="rgba(255, 255, 255, .9)"
            fontWeight="400"
            lineHeight="2rem"
            position="relative"
            zIndex="1"
          >
            {t(text)}
          </Box>
        </Typography>
      </Box>
    );
  };

  const containers = [
    {
      title: 'Analyses',
      text: 'Algorithme d’analyses sportives de dernière génération',
      img: Automatisation,
    },
    {
      title: 'Statistiques',
      text: 'Des statistiques détaillées sur chaque match',
      img: Statistiques,
    },
    {
      title: 'Actualités',
      text: 'Les meilleures infos sportives du moment',
      img: Reussite,
    },
    {
      title: 'Replay',
      text: 'Découvrez les moments fort des rencontres',
      img: Stabilite,
    },
  ];
  const locales = ['fr', 'en', 'es', 'it'];

  return (
    <div className={classes.home}>
      <Helmet>
        {locales.map(locale => {
          return (
            <link
              rel="alternate"
              href={'https://datawin.io/?lang=' + locale}
              hrefLang={locale}
              key={locale}
            />
          );
        })}
      </Helmet>
      <Navbar />
      <Box position="relative">
        <Particles
          id="tsparticles"
          className={classes.particles}
          options={{
            fpsLimit: 60,
            interactivity: {
              detectsOn: 'canvas',
              events: {
                onClick: {
                  enable: true,
                  mode: 'repulse',
                },
                onHover: {
                  enable: false,
                  mode: 'repulse',
                },
                resize: true,
              },
              modes: {
                bubble: {
                  distance: 400,
                  duration: 2,
                  opacity: 0.8,
                  size: 0,
                },
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: '#3fdadf',
              },
              links: {
                color: '#3fdadf',
                distance: 200,
                enable: true,
                opacity: 0.5,
                width: 3,
              },
              collisions: {
                enable: true,
              },
              move: {
                direction: 'none',
                enable: true,
                outMode: 'bounce',
                random: true,
                speed: 1,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  value_area: 800,
                },
                value: mobile ? 15 : 25,
              },
              opacity: {
                value: 1,
                random: true,
              },
              shape: {
                type: 'circle',
              },
              size: {
                random: true,
                value: 5,
              },
            },
            detectRetina: true,
          }}
        />

        <div className={clsx(classes.headerTop, classes.homePadding)}>
          <Title
            textAlign={'left'}
            title="Les meilleures analyses et infos sportives"
            subTitle="Une base de données combinant les meilleures actualités du moment, le classement des équipes, les replays des buts et des statistiques détaillées sur différents sports grâce à un puissant algorithme !"
            smallSubTitle
            buttonText={t('Démarrer')}
            fadeButton
            buttonPath={redirectWithUTMUrl('secure')}
            maxWidth={mobile ? '100%' : '50%'}
            image2={Trustpilot}
          />
          <Box
            width={mobile ? '100%' : '50%'}
            display="flex"
            marginTop={mobile ? '4rem' : 0}
          >
            <img
              src={imgHeroSection}
              alt="Illustration de l'app datawin"
              style={{ maxWidth: '80%', margin: 'auto' }}
            />
          </Box>
        </div>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
        className={clsx(classes.logosContainer, classes.homePadding)}
      >
        {i18n.language === 'it' ? (
          <>
            <img src={BeinSport_Logo} alt="Logo BeinSport" />
            <img src={logo_rai} alt="Logo Rai" style={{ maxWidth: '54px' }} />
            <img src={logo_tuttosport} alt="Logo Tuttosport" />
            <img
              src={logo_laGazzettaDelloSport}
              alt="Logo La gazzetta dello Sport"
            />
          </>
        ) : (
          <>
            <img src={BeinSport_Logo} alt="Logo BeinSport" />
            <img src={Lequipe_Logo} alt="Logo l'equipe" />
            <img src={RmcSport_Logo} alt="Logo RMC sport" />
            <img src={Eurosport_Logo} alt="Logo Eurosport" />
          </>
        )}
      </Box>
      {/* <Box className={classes.homePadding}>
        <HomePerformance />
      </Box> */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        marginTop="4rem"
        maxWidth="1250px"
        margin="auto"
        position="relative"
        className={classes.homePadding}
        id="avantages"
      >
        <Box className={classes.bgRadial} />
        {/* <Title
          textAlign={'center'}
          title="Les performances au service des paris sportifs"
        /> */}
        <Box
          marginTop="2rem"
          display="flex"
          flexDirection={mobile ? 'column' : 'row'}
          alignItems="baseline"
          justifyContent="space-between"
          width="100%"
          marginBottom="2rem"
          position="relative"
          zIndex="1"
        >
          {containers.map((container, index) => {
            return containerImage(
              container.title,
              container.text,
              container.img,
              index,
            );
          })}
        </Box>
        {!mobile && (
          <ButtonFilled
            title={`${t('Démarrer pour')} ${price}`}
            path={redirectWithUTMUrl('secure')}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection={mobile ? 'column' : 'row'}
        justifyContent="center"
        alignItems="center"
        marginTop={mobile ? '2rem' : '4rem'}
        className={classes.homePadding}
        paddingBottom={mobile ? '4rem !important' : '9rem !important'}
        maxWidth="1250px"
        margin="auto"
      >
        <Box
          width={mobile ? '100%' : '52%'}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Title
            textAlign={mobile ? 'center' : 'left'}
            title="La plus grande base de données sportives disponible !"
            subTitle="Développé par des ingénieurs, DATAWIN est un logiciel d’analyse sportif. Chaque jour, des matchs sont analysés sur une solution simple et intuitive, afin de vous donner les meilleures infos et les conseils de l’algorithme."
            buttonText={`${t('Démarrer pour')} ${price}`}
            buttonPath={redirectWithUTMUrl('secure')}
          />
        </Box>
        <Box
          width={mobile ? '90%' : '48%'}
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          marginTop={mobile ? '4rem' : '0'}
        >
          <Box position="absolute" className={classes.bgPhone}></Box>
          <Box position="absolute" className={classes.bgPhoneParticles}></Box>
          <img
            src={trElements.phone[language] || ImageAlgo}
            style={{ maxWidth: '280px', zIndex: 1 }}
            alt="Phone with datawin plateform"
          />
        </Box>
      </Box>
      <Box
        width="100%"
        bgcolor="white"
        padding={mobile ? '4rem 2rem' : '6rem 5rem'}
        position="relative"
      >
        {mobile ? (
          <Title
            title="Une interface simple et intuitive"
            subTitle="Accédez à des informations indispensables pour chaque match. Suivez l’actualité de vos joueurs et de vos équipes préférées, redécouvrez les moments forts des plus belles rencontres, accédez à des analyses complètes."
            buttonText={t('Démarrer')}
            buttonPath={redirectWithUTMUrl('secure')}
            blue
            image={trElements.maquette[language] || ImageInterface}
            textAlign={mobile ? 'center' : 'left'}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              width="55%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={trElements.maquette[language] || ImageInterface}
                style={{ maxWidth: '90%' }}
                alt="Tablet with Datawin plateform"
              />
            </Box>
            <Box
              width="45%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Title
                title="Une interface simple et intuitive"
                subTitle="Accédez à des informations indispensables pour chaque match. Probabilités de victoire de chaque équipe, le nombre de buts, les scores les plus probables, l’historique des rencontres entre deux équipes…"
                buttonText={t('Démarrer')}
                buttonPath={redirectWithUTMUrl('secure')}
                blue
                maxWidth="500px"
              />
            </Box>
          </Box>
        )}
      </Box>
      {/* <Box
        className={classes.homePadding}
        style={{
          background: mobile ? '' : `url(${Home_background_particles})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '130%',
          paddingBottom: '4rem',
        }}
      >
        <HomeSteps />
      </Box> */}
      {/* <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={mobile ? 'column' : 'row'}
        className={clsx({
          [classes.homePadding]: true,
          // [classes.bgBookmakers]: !mobile,
        })}
        position="relative"
        style={{ backgroundColor: 'white' }}
      >
        <Box
          paddingTop={'30px'}
          marginRight={mobile ? 'inherit' : 'auto'}
          width={mobile ? '100%' : '65%'}
        >
          <Title
            title="Découvrez le concept !"
            interText={`${t('7 jours pour seulement')} ${price}`}
            subTitle={
              userCountryCode === 'UK'
                ? '£230 of free bets'
                : userCountryCode === 'IT'
                ? '670€ di scommesse gratuite'
                : userCountryCode === 'ES'
                ? '320€ de apuestas gratis'
                : '1000€ de paris offerts'
            }
            buttonText="Démarrer"
            buttonPath={redirectWithUTMUrl('https://datawin-pronos.net/sales-page')}
            blue
            textAlign={mobile ? 'center' : 'left'}
          />
        </Box>
        <Box
          width={mobile ? '80%' : '35%'}
          margin={mobile ? '1rem auto 0' : 'auto'}
        >
          <img src={ImgConcept} style={{ maxWidth: '100%' }} />
        </Box>
      </Box> */}
      <Box
        className={clsx(classes.homePadding, classes.tabContainer)}
        maxWidth="1250px"
        margin="auto"
      >
        <HomeInfosCards />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap={mobile ? 'wrap' : 'nowrap'}
        justifyContent="space-between"
        className={clsx(classes.logosContainer2, classes.homePadding)}
      >
        <img src={LaLigua_Logo} alt="Logo la ligua" />
        <img src={UefaEuropaLeague_Logo} alt="Logo uefa europa league" />
        <img src={UefaChampionsLeague_Logo} alt="Logo uefa champions league" />
        <img src={Ligue1_Logo} alt="Logo ligue 1" />
        <img src={PremierLeague_Logo} alt="Logo Premier league" />
      </Box>
      <Box className={classes.homePadding}>
        <NewInPronos />
      </Box>
      {/* <Box className={classes.homePadding}>
        <Box
          display="flex"
          flexDirection={mobile && !smallComputer ? 'column' : 'row'}
          justifyContent="center"
          alignItems="center"
          maxWidth={mobile && !smallComputer ? '300px' : '750px'}
          width="100%"
          margin="auto"
          bgcolor="rgba(8, 26, 54, .67)"
          borderRadius="19px"
          padding={'2rem'}
          boxShadow="rgb(40 191 252 / 11%) 0px 36px 57px 20px"
        >
          <Box
            width={mobile ? '100%' : '50%'}
            height={mobile && !smallComputer ? '160px' : '270px'}
            color="white"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography variant="h2" component={'span'}>
              <Box fontWeight={600} width={mobile ? '100%' : 'max-content'}>
                {t('Nouveau dans les pronos ?')}
              </Box>
            </Typography>
            <Typography variant="h3" component={'span'}>
              <Box fontWeight={600}>{t('Découvrez Datawin')}</Box>
            </Typography>
            <Typography variant="h5" component={'span'}>
              <Box fontWeight={500}>
                {t(
                  'Pariez intelligemment en suivant les conseils de l’algorithme',
                )}
              </Box>
            </Typography>
            <Box display={mobile && !smallComputer ? 'none' : 'inherit'}>
              <ButtonFilled
                title={`${t('Démarrer pour')} ${price}`}
                path={redirectWithUTMUrl('https://datawin-pronos.net/sales-page')}
                blank
              />
            </Box>
          </Box>
          <Box
            width={mobile && !smallComputer ? '100%' : '300px'}
            height={mobile && !smallComputer ? '180px' : '290px'}
            className={classes.bgGraph}
          ></Box>
        </Box>
      </Box> */}
      <Box bgcolor="white" textAlign="center" position="relative">
        <Box className={classes.homePadding}>
          <Typography variant="h1" component={'span'}>
            <Box color="#1a2844" fontWeight={600}>
              {t('Ils parlent de nous')}
            </Box>
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={mobile ? 'flex-start' : 'center'}
          className={classes.reviewsContainer}
        >
          {reviews.map((review, index) => {
            return <Review {...review} key={index} />;
          })}
        </Box>
      </Box>
      <Box
        className={clsx(classes.homePadding, classes.topFooter)}
        position="relative"
      >
        <Box
          position="absolute"
          top="0"
          left="50%"
          width={`calc(100% - (${mobile ? '2rem' : '5rem'})*2)`}
          maxWidth="1200px"
          margin="auto"
          bgcolor="#0a1437"
          borderRadius="30px"
          style={{ transform: `translate(-50%, ${mobile ? '-20%' : '-50%'})` }}
          display="flex"
          flexDirection={mobile ? 'column' : 'row'}
          justifyContent="center"
          alignItems="center"
          padding={mobile ? '3.5rem 2rem' : '2.5rem'}
          color="white"
          className={classes.bottomCard}
          boxShadow="rgb(40 191 252 / 11%) 0px 36px 57px 20px"
        >
          <Box
            display="flex"
            justifyContent="space-around"
            flexDirection="column"
            alignItems="flex-start"
            width={mobile ? '100%' : '70%'}
          >
            <Typography variant="h2" component={'span'}>
              <Box
                fontWeight={600}
                fontSize={mobile ? '28px' : '38px'}
                lineHeight={mobile ? '38px' : '47px'}
              >
                <span style={{ color: '#f2e657' }}>Datawin, </span>{' '}
                {t('le leader mondial des analyses sportives')}
              </Box>
            </Typography>
            {/* <Typography variant="h5" component={'span'}>
              <Box
                fontWeight={500}
                marginTop={mobile ? '2.5rem' : '1.5rem'}
                fontSize={mobile ? '16px' : '18px'}
                lineHeight={mobile ? '22px' : '29px'}
              >
                {t(
                  "Découvrez pourquoi des milliers d'utilisateurs dans le monde choisissent Datawin pour leurs paris sportifs",
                )}
              </Box>
            </Typography> */}
          </Box>
          <Box
            width={mobile ? '100%' : '30%'}
            display="flex"
            justifyContent={mobile ? 'left' : 'center'}
            margin={mobile ? '5rem 0' : 'auto'}
          >
            <ButtonFilled
              title={mobile ? 'Démarrer' : `${t('Je découvre pour')} ${price}`}
              path={redirectWithUTMUrl('secure')}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box margin="auto">
            <img src={Datawin_Logo_YellowShiny} alt="Datawin shiny logo" />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
            marginTop="2rem"
            width="100%"
            maxWidth="950px"
          >
            <Box
              width={mobile ? '50%' : '25%'}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-around"
              marginTop="3rem"
            >
              <Typography variant="h5" component="span">
                <Box color="white" fontWeight={600}>
                  {t('Créé en')}
                </Box>
              </Typography>
              <Typography variant="h2" component="span">
                <Box
                  color="#f2e657"
                  fontSize={mobile ? '30px' : '45px'}
                  fontWeight={700}
                  marginTop=".5rem"
                >
                  2019
                </Box>
              </Typography>
            </Box>
            <Box
              width={mobile ? '50%' : '25%'}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-around"
              marginTop="3rem"
            >
              <Typography variant="h5" component="span">
                <Box color="white" fontWeight={600}>
                  {t('Membres')}
                </Box>
              </Typography>
              <Typography variant="h2" component="span">
                <Box
                  color="#f2e657"
                  fontSize={mobile ? '30px' : '45px'}
                  fontWeight={700}
                  marginTop=".5rem"
                >
                  30 000
                </Box>
              </Typography>
            </Box>
            <Box
              width={mobile ? '50%' : '25%'}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-around"
              marginTop="3rem"
            >
              <Typography variant="h5" component="span">
                <Box color="white" fontWeight={600}>
                  {t('Analyses')}
                </Box>
              </Typography>
              <Typography variant="h2" component="span">
                <Box
                  color="#f2e657"
                  fontSize={mobile ? '30px' : '45px'}
                  fontWeight={700}
                  marginTop=".5rem"
                >
                  15 000
                </Box>
              </Typography>
            </Box>
            <Box
              width={mobile ? '50%' : '25%'}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-around"
              marginTop="3rem"
            >
              <Typography variant="h5" component="span">
                <Box color="white" fontWeight={600}>
                  Awards
                </Box>
              </Typography>
              <Typography variant="h2" component="span">
                <Box
                  color="#f2e657"
                  fontSize={mobile ? '30px' : '45px'}
                  fontWeight={700}
                  marginTop=".5rem"
                >
                  4
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        borderTop={mobile ? 'solid 1px rgba(177, 180, 191, 0.18)' : 'none'}
        className={classes.homePadding}
      >
        <Box
          display="flex"
          flexDirection={mobile ? 'column' : 'row'}
          flexWrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
          margin="auto"
          maxWidth="700px"
        >
          <Typography variant="body1" component={'span'}>
            <Box
              color="white"
              fontWeight={600}
              fontSize="12px"
              style={{
                cursor: 'pointer',
                textTransform: 'uppercase',
                minWidth: 160,
                textAlign: 'center',
              }}
              onClick={() => {
                window.open('/terms', '_blank');
              }}
            >
              {t('CGV & Mentions légales')}
            </Box>
          </Typography>
          <Typography variant="body1" component={'span'}>
            <Box
              color="white"
              fontWeight={600}
              fontSize="12px"
              style={{
                cursor: 'pointer',
                textTransform: 'uppercase',
                minWidth: 160,
                textAlign: 'center',
              }}
              onClick={() => {
                window.open('/privacy', '_blank');
              }}
            >
              {t('Confidentialité')}
            </Box>
          </Typography>
          <Typography variant="body1" component={'span'}>
            <Box
              color="white"
              fontWeight={600}
              fontSize="12px"
              style={{
                cursor: 'pointer',
                textTransform: 'uppercase',
                minWidth: 160,
                textAlign: 'center',
              }}
            >
              <a
                href="mailto:support@datawin-prediction.com"
                style={{ textDecoration: 'none', color: 'white' }}
              >
                {t('Contactez-nous')}
              </a>
            </Box>
          </Typography>
          {/* <Typography variant="body1" component={'span'}>
            <Box
              color="white"
              fontWeight={600}
              fontSize="12px"
              style={{ cursor: 'pointer', textTransform: 'uppercase' }}
              onClick={() => {
                history.push('/presse');
              }}
            >
              {t('Presse')}
            </Box>
          </Typography>
          <Typography variant="body1" component={'span'}>
            <Box
              color="white"
              fontWeight={600}
              fontSize="12px"
              style={{ cursor: 'pointer', textTransform: 'uppercase' }}
              onClick={() => {
                history.push('/avis');
              }}
            >
              {t('Avis')}
            </Box>
          </Typography> */}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          justifyContent="center"
          gridGap="25px"
          alignItems="center"
          maxWidth="300px"
          margin="auto"
          className="marginTop2rem"
        >
          {socialNetworks.map(network => {
            return (
              <Box
                width="26px"
                height="26px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                key={network.link}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.open(network.link, '_blank');
                }}
              >
                <img src={network.image} alt="Logo network" />
              </Box>
            );
          })}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin="auto"
          className="marginTop2rem"
        >
          <Typography variant="body1" component={'span'}>
            <Box fontSize="12px" color="#848ca5" textAlign="center">
              {t('© 2021 DATAWIN. Tous droits réservés')}
            </Box>
          </Typography>
        </Box>
        {/*<Box*/}
        {/*  display="flex"*/}
        {/*  alignItems="center"*/}
        {/*  justifyContent="center"*/}
        {/*  margin="auto"*/}
        {/*>*/}
        {/*  <Typography variant="body1" component={'span'}>*/}
        {/*    <Box fontSize="12px" color="#848ca5" textAlign="center">*/}
        {/*      {t('Société')} : DATAWIN, {t("Numéro d'immatriculation")}:*/}
        {/*      12889540, {t('Adresse')}: K219 Kensington High Street, Kensington, London, England, W8 6BD*/}
        {/*    </Box>*/}
        {/*  </Typography>*/}
        {/*</Box>*/}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin="auto"
          className={'marginTop1rem'}
          maxWidth="850px"
        >
          {i18n.language === 'en' ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
              alignItems="center"
              marginTop="1rem"
            >
              {/* <img src={begambleaware} alt="" style={{ maxWidth: '200px' }} />
              <Typography variant="body1" component={'span'}>
                <Box
                  fontSize=".85rem"
                  color="white"
                  textAlign="center"
                  marginTop="1rem"
                >
                  <a
                    href="https://www.begambleaware.org/"
                    style={{ color: '#bbb', textDecoration: 'none' }}
                    target="_blank"
                  >
                    https://www.begambleaware.org/
                  </a>
                </Box>
              </Typography> */}
            </Box>
          ) : (
            <Typography variant="body1" component={'span'}>
              <Box fontSize="9px" color="#848ca5" textAlign="center">
                {t(
                  'Les informations diffusées sur le Site datawin.io n’engagent en aucun cas la responsabilité de l’équipe du Site datawin.io, il ne s’agit en aucun cas d’un encouragement à placer des paris en ligne. Ce site ne doit pas être considéré comme un conseil en investissement.',
                )}
              </Box>
            </Typography>
          )}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          marginTop="1rem"
          justifyContent="center"
        >
          <img
            src={visa}
            alt=""
            style={{ maxHeight: '22px', marginRight: 10, opacity: 0.8 }}
          />
          <img
            src={mastercard}
            alt=""
            style={{ maxHeight: '64px', opacity: 0.8 }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default HomePage;
