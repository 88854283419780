import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';
import BigCardLivescoreFootball from '../Templates/Cards/BigCardLivescoreFootball';
import Loading from '../../Loading/Loading';
import { useTranslation } from 'react-i18next';
import '../../../theme/datawinTheme.css';
import Navbar from '../Templates/Navbar/Navbar';
import Fade from '@material-ui/core/Fade';
import moment from 'moment';
import { useQuery } from 'react-query';
import { Fire } from 'services';
import { SummaryWithLogos } from '../../../types/soccer/summary';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    background: {
      background: '#fcfcfa',
    },
    container: {
      maxWidth: 1300,
      margin: 'auto',
      width: '100%',
    },
    livescoreContainer: {
      position: 'relative',
      backgroundColor: 'white',
      padding: '1rem',
      borderRadius: '15px',
      [theme.breakpoints.down('sm')]: {
        padding: '1rem .5rem',
      },
    },
    teamName: {
      fontWeight: 700,
      color: 'white',
      width: 'max-content',
      margin: 'auto',
      marginTop: '10%',
    },
    smallTeamName: {
      width: 'initial',
    },
    bg: {
      backgroundImage:
        'linear-gradient(to bottom, rgba(11, 24, 71, 0) -16%, #0b1847 104%)',
      pointerEvents: 'none',
    },
    btn: {
      zIndex: 3,
      marginTop: '0 !important',
      marginLeft: '0 !important',
      transform: 'translate(-50%, -50%) !important',
      borderRadius: '50% !important',
      backgroundColor: 'rgba(255, 255, 255, 0.25) !important',

      ':before': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    bigBtn: {
      width: '4em !important',
      height: '4em !important',
    },
    smallBtn: {
      width: '2em !important',
      height: '2em !important',
    },
    card: {
      padding: 0,
    },
    littleCard: {
      height: 125,
      [theme.breakpoints.down('sm')]: {
        '&:nth-child(2n)': {
          paddingRight: 0,
          paddingLeft: 10,
        },
        '&:nth-child(2n+1)': {
          paddingLeft: 0,
          paddingRight: 10,
        },
      },
    },
    flag: {
      height: 80,
      width: 80,
      margin: 'auto',
      backgroundSize: 'contain',
    },
    smallFlag: {
      width: 30,
      height: 30,
    },
  }),
);

const COMPETITIONS = [
  'sr:competition:1', // Euro Cup
  'sr:competition:34', // ligue 1
  'sr:competition:203', // Premier League
  'sr:competition:35', // Bundesliga
  'sr:competition:23', // Serie A Men
  'sr:competition:8', // LaLiga
  'sr:competition:1794', // MLS
  'sr:competition:34900', // Liga Portugal 3
  'sr:competition:38', // Pro League
  'sr:competition:7', // UEFA Champions League
  'sr:competition:679', // UEFA Europa League
  'sr:competition:14', // World Cup Qualification, CONCACAF
  'sr:competition:16', // World Cup
  'sr:competition:357', // Club World Cup
  'sr:competition:290', // World Cup, Women
  'sr:competition:14', // World Cup Qualification, CONCACAF
  'sr:competition:308', // WC Qualification, AFC
  'sr:competition:309', // WC Qualification, OFC
  'sr:competition:11', // WC Qualification, UEFA
  'sr:competition:13', // WC Qualification, CAF
  'sr:competition:295', // WC Qual, CONMEBOL
  'sr:competition:20434', // WC Qu. Int-Conf. Playoff
];

export default function PageLivescore() {
  const spacing = 2;
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: matches, isLoading } = useQuery(
    'soccer:page-livescore',
    () =>
      Fire.cloud<SummaryWithLogos[]>('soccer', {
        method: 'getDailyMatchSummaries',
      }),
    { staleTime: 900000 /* 15 mn */ },
  );
  let filteredMatches;

  if (matches)
    filteredMatches = matches.filter(
      match =>
        COMPETITIONS.indexOf(
          match.sport_event.sport_event_context.competition.id,
        ) !== -1,
    );

  return (
    <Fade in timeout={500}>
      <Grid container className={classes.background}>
        <Grid container spacing={spacing} className={classes.container}>
          <Grid item xs={12}>
            <Navbar mediumExtend />
          </Grid>
          <Grid item xs={12} style={{ zIndex: 1 }}>
            <Box margin="auto" textAlign="center" padding="0 2rem" zIndex="1">
              <Typography variant="h1" component={'span'}>
                <Box
                  color="white"
                  textAlign="center"
                  width="100%"
                  marginBottom="2rem"
                >
                  {t('Live score')}
                </Box>
              </Typography>
              <Typography variant="body1" component={'span'}>
                <Box
                  color="white"
                  textAlign="center"
                  width="100%"
                  marginBottom="2rem"
                  lineHeight="1.5rem"
                >
                  {t(
                    'Suivez en temps réel l’évolution des scores de tous les matchs de foot',
                  )}
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid item container xs={12} justify="center">
            <Grid
              item
              container
              xs={12}
              sm={10}
              className={classes.livescoreContainer}
              spacing={spacing}
            >
              <Typography variant="h6" component={'span'}>
                <Box color="#848ca5" fontWeight={600} padding="1rem">
                  {moment().format('LL')}
                </Box>
              </Typography>
              {isLoading || !filteredMatches ? (
                <Grid container item xs={12}>
                  <Loading nobg />
                </Grid>
              ) : !filteredMatches.length ? (
                <Box margin="auto" textAlign="center">
                  <Typography variant="h5">
                    {t('Aucun match en live pour le moment')}
                  </Typography>
                </Box>
              ) : (
                filteredMatches.map(match => (
                  <Grid
                    container
                    item
                    xs={12}
                    key={match.sport_event.id}
                    justify="center"
                  >
                    <BigCardLivescoreFootball match={match} />
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  );
}
