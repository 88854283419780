import React from 'react';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import '../../../../theme/datawinTheme.css';
import CardGeneral from '../../Templates/Cards/CardGeneral';
import moment from 'moment';
import { PronoNFL } from '../../../../types/pronoNFL';
import ClubLogo from './ClubLogo/ClubLogo';
import { getNFLLogoURL } from '../../../../utils/nfl';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import { getProperName } from '../../../../utils/sportradar';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      height: '100%',
      boxShadow: '0 0 18px 0 rgba(138, 225, 255, 0.45)',
      border: '3px solid transparent',
      backgroundClip: 'padding-box',
      borderRadius: 25,
      backgroundColor: '#202f67',
    },
    content: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      height: '100%',
      padding: '1rem !important',
    },
    outBox: {
      background:
        'linear-gradient(98deg, #7489ff 2%, #86b4ff 35%, #7fc1ff 70%)',
      borderRadius: 25,
      height: '100%',
      width: '100%',
    },
  }),
);

type CardPronoTennisFille = {
  prono: PronoNFL;
};

const CardPronoTennisFille: React.FC<CardPronoTennisFille> = ({ prono }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const language = i18n.language.toUpperCase();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div className={clsx(classes.outBox)}>
      <CardGeneral className={classes.card} blue>
        <CardContent className={classes.content}>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box width="100%" marginBottom="3rem">
              <Box
                width="100%"
                height="auto"
                display="flex"
                flexDirection="row"
                flexWrap="nowrap"
                fontWeight={600}
                paddingBottom=".3rem"
                justifyContent="space-between"
              >
                <Box
                  color="white"
                  textAlign="left"
                  style={{ backgroundColor: 'rgba(255, 255, 255, .3)' }}
                  borderRadius="8px"
                  padding=".5rem 1rem !important"
                >
                  <Typography variant="body1" component="span">
                    <Box fontSize={'1rem'} fontWeight={500} lineHeight="1">
                      {moment(prono.date?.toDate()).format('LL')}
                    </Box>
                  </Typography>
                </Box>
                <Box color="#838ca6" textAlign="center" paddingLeft=".5rem">
                  <Typography variant="body1" component="span">
                    <Box fontSize={'1rem'} fontWeight={500}>
                      {prono.competitionName?.[language] ||
                        prono.competitionName}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box width="100%" paddingBottom="2rem">
              <Box width="100%" display="flex">
                <Box
                  width="33%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box style={{ height: 'auto', width: mobile ? 50 : 90 }}>
                    <img
                      alt=""
                      src={getNFLLogoURL(prono.match.value.home.id)}
                      height="100%"
                      width={'100%'}
                    />
                  </Box>{' '}
                </Box>
                <Box
                  width="33%"
                  height="100%"
                  color="#838ca6"
                  flexDirection="column"
                  textAlign="center"
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                  fontWeight="600"
                  marginTop="-10px"
                >
                  <Typography variant="body2" component="span">
                    <Box
                      fontSize="1rem"
                      fontWeight={500}
                      color="rgba(255, 255, 255, 0.7)"
                    >
                      {prono.round?.[language] || prono.round}
                    </Box>
                  </Typography>
                  <Typography variant="h3" component={'span'}>
                    <Box color="white" marginTop="1rem">
                      VS
                    </Box>
                  </Typography>
                </Box>
                <Box
                  width="33%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box style={{ height: 'auto', width: mobile ? 50 : 90 }}>
                    <img
                      alt=""
                      src={getNFLLogoURL(prono.match.value.away.id)}
                      height="100%"
                      width={'100%'}
                    />
                  </Box>
                </Box>
              </Box>
              <Box width="100%" marginTop=".75rem" display="flex">
                <Box
                  width="33%"
                  height="100%"
                  color={'#fff'}
                  display="flex"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant={'body1'}>
                    {t(prono.match.value.home?.name)}
                  </Typography>
                </Box>
                <Box width="33%" height="100%"></Box>
                <Box
                  width="33%"
                  height="100%"
                  color={'#fff'}
                  display="flex"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant={'body1'}>
                    {t(prono.match.value.away?.name)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {mobile && (
              <>
                <Box
                  width="100%"
                  height="1px"
                  style={{
                    background: theme.palette.text.secondary,
                    opacity: '40%',
                  }}
                  marginBottom="2rem"
                ></Box>
                <Box display="flex" flexDirection="column" width="100%">
                  <Box
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                    marginBottom="2rem"
                  >
                    <Typography component={'span'} variant="h6">
                      <Box color="#838ca6" fontWeight={600} textAlign="center">
                        {t('Stade')}
                      </Box>
                    </Typography>
                    <Typography component={'span'} variant="h5">
                      <Box color="white" fontWeight="bold" textAlign="center">
                        {prono.match.value.venue?.name
                          ? prono.match.value.venue.name
                          : t('Stade inconnu')}
                      </Box>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Typography component={'span'} variant="h6">
                      <Box color="#838ca6" fontWeight={600} textAlign="center">
                        {t('Arbitre')}
                      </Box>
                    </Typography>
                    <Typography component={'span'} variant="h5">
                      <Box color="white" fontWeight="bold" textAlign="center">
                        {prono.match.value.venue?.referees?.[0].name
                          ? getProperName(
                              prono.match.value.venue.referees[0].name,
                            )
                          : t('Non renseigné')}
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </CardContent>
      </CardGeneral>
    </div>
  );
};

export default CardPronoTennisFille;
