import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  typography: {
    fontFamily: '"Open Sans"',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: '#32E07D',
    },
    secondary: {
      main: '#282828',
    },
    text: {
      primary: '#282828',
      secondary: '#282828',
    },
    input: {
      backgroundColor: '#AA0000',
    },
    textField: {
      backgroundColor: '#AA0000',
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        backgroundColor: '#F6F8FA',
        'label + &': {
          // color: "red"
        },
      },
      root: {
        backgroundColor: '#F6F8FA',
        'label + &': {
          // color: "red"
        },
      },
    },
    MuiTypography: {
      h1: {
        // color: "red",
        // textTransform: "uppercase"
        // float: "left",
        // textAlign: "left"
      },
      h5: {
        // color: "red",
        fontWeight: 'bold',
        // float: "left",
        // textAlign: "left"
      },
    },
    MuiOutlinedInput: {
      // BORDURE DES INPUTS
      notchedOutline: {
        borderColor: '#E9EBEF',
      },
    },
    MuiButton: {
      contained: {
        fontWeight: '600',
        fontSize: '1rem !important',
        textTransform: 'none',
        backgroundColor: '#F7F8FA',
        boxShadow: '0px 0px 0px 0px',
        '&:hover': {
          //you want this to be the same as the backgroundColor above
          backgroundColor: '#282828',
          color: 'white',
        },
        '&:focus': {
          //you want this to be the same as the backgroundColor above
          backgroundColor: '#282828',
          color: 'white',
        },
      },
    },
    MuiFormLabel: {
      asterisk: {
        display: 'none',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.4em',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '1px solid slategrey',
        },
      },
    },
    MuiToggleButton: {
      root: {
        '&$selected': {
          backgroundColor: '#282828',
          color: 'white',
        },
        '&:hover': {
          backgroundColor: '#282828 !important',
          color: 'white',
        },
        border: '1px solid #E9EBEF',
        margin: '8px',
        flexBasis: '50%',
        backgroundColor: '#F6F8FA',
      },
    },
    MuiToggleButtonGroup: {
      root: {
        display: 'flex !important',
      },
      grouped: {
        borderRadius: '4px !important',
        borderLeft: '1px solid #E9EBEF !important',
      },
    },
    MuiRadio: {
      root: {
        color: '#C3C5CD',
      },
    },
    MuiAvatar: {
      root: {
        width: '48px',
        height: '48px',
      },
    },
  },
});
