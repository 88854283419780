
type PriceProps = {
  id: string;
  duration: string;
  price: number;
  list: string[];
  recommended?: string;
}

const prices: PriceProps[] = [
  {
    id: 'month',
    duration: '1 mois',
    price: 29.90,
    list: [
      'Algorithme de prédiction',
      'Variation de cotes en Live',
      'Bonus offerts avec les offres partenaires',
      'Parraine tes amis et reçoit 5€ chaque mois !',
    ],
  },
  {
    id: 'month2',
    duration: '1 mois',
    price: 39.90,
    list: [
      'Algorithme de prédiction',
      'Variation de cotes en Live',
      'Bonus offerts avec les offres partenaires',
      'Parraine tes amis et reçoit 5€ chaque mois !',
    ],
  },
  {
    id: 'monthIT',
    duration: '1 mois',
    price: 39.90,
    list: [
      'Algorithme de prédiction',
      'Variation de cotes en Live',
      'Bonus offerts avec les offres partenaires',
      'Parraine tes amis et reçoit 5€ chaque mois !',
    ],
  },
  // {
  //   id: 'year',
  //   duration: '1 an',
  //   price: 29,
  //   list: [
  //     'Accès VIP site',
  //     '100€ Bonus',
  //     'Pronostics sûrs'
  //   ],
  //   recommended: "240€ d'économies",
  // },
]

export default prices