import CardGeneral from '../../Templates/Cards/CardGeneral';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { PronoNBA } from '../../../../types/pronoNBA';

const useStyles = makeStyles(() =>
  createStyles({
    cardGeneral: {
      textAlign: 'end',
    },
  }),
);

type CardInfoMatchProps = {
  prono: PronoNBA;
};

const CardInfoMatch: React.FC<CardInfoMatchProps> = ({ prono }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const smallComputer = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  const { venue } = prono.match.value;
  return (
    <CardGeneral blue className={classes.cardGeneral}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        padding={smallComputer ? (mobile ? '2rem' : '1rem') : '1rem'}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          height="33%"
          alignItems="center"
        >
          <Typography component={'span'}>
            <Box color="#838ca6" fontWeight={600}>
              {t('Stade')}
            </Box>
          </Typography>
          <Typography component={'span'}>
            <Box color="white" fontWeight="bold" paddingLeft=".9rem">
              {venue ? `${venue.name} ${venue.city}` : t('Inconnu')}
            </Box>
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          height="33%"
          borderBottom="1px solid rgba(131, 140, 166, .3)"
          borderTop="1px solid rgba(131, 140, 166, .3)"
          alignItems="center"
        >
          <Typography component={'span'}>
            <Box color="#838ca6" fontWeight={600}>
              {t('Météo')}
            </Box>
          </Typography>
          <Typography component={'span'}>
            <Box
              color="white"
              fontWeight="bold"
              style={{ textTransform: 'capitalize' }}
              paddingLeft=".9rem"
            >
              {t('Météo à venir')}
            </Box>
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          height="33%"
          alignItems="center"
        >
          <Typography component={'span'}>
            <Box color="#838ca6" fontWeight={600}>
              {t('Arbitre')}
            </Box>
          </Typography>
          <Typography component={'span'}>
            <Box color="white" fontWeight="bold" paddingLeft=".9rem">
              {t('Non renseigné')}
            </Box>
          </Typography>
        </Box>
      </Box>
    </CardGeneral>
  );
};

export default CardInfoMatch;
