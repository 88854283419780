import React from 'react';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Gender } from '../../../../../../types/pronoTennis';
import PlayerPhoto from '../../../Tennis/PlayerPhoto';

const useStyles = makeStyles(() =>
  createStyles({
    grayScale: {
      filter: 'grayscale(1)',
    },
    backgroundGrey: {
      background: '#d2d2d2',
    },
  }),
);

type DoublesImagesProps = {
  future: boolean;
  mobile: boolean;
  gender: Gender;
  player1Id: string;
  player2Id: string;
  small?: boolean;
};

const DoublesImages: React.FC<DoublesImagesProps> = ({
  future,
  mobile,
  gender,
  player1Id,
  player2Id,
  small = false,
}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      marginTop={!mobile && '10px'}
    >
      <Box
        width="50%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          borderRadius="50%"
          height={small ? '30px' : '50px'}
          width={small ? '30px' : '50px'}
          bgcolor="white"
          overflow="hidden"
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxShadow={future ? '' : '0 0 33px 0 rgb(0 0 0 / 11%)'}
          className={future ? classes.backgroundGrey : ''}
        >
          <PlayerPhoto
            playerId={player1Id}
            gender={gender}
            width="100%"
            style={{
              borderRadius: '50%',
              maxHeight: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
      </Box>
      <Box
        width="50%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ transform: 'translate(-10%)' }}
      >
        <Box
          borderRadius="50%"
          height={small ? '30px' : '50px'}
          width={small ? '30px' : '50px'}
          bgcolor="white"
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxShadow={future ? '' : '0 0 33px 0 rgb(0 0 0 / 11%)'}
          className={future ? classes.backgroundGrey : ''}
        >
          <PlayerPhoto
            playerId={player2Id}
            gender={gender}
            width="100%"
            style={{
              borderRadius: '50%',
              maxHeight: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DoublesImages;
