import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './LoginScreen.scss';
import { useTranslation } from 'react-i18next';
import logo from '../../images/logo_datawin.png';
import { Fire } from 'services';
import Particles from '../Reusable/Particles/Particles';
import { Button, Input } from '../Reusable';
import OauthLogins from '../Reusable/OauthLogins/OauthLogins';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

function LoginScreen() {
  const { t } = useTranslation();

  const [userInfosConnexion, setUserInfosConnexion] = useState<any>({
    email: '',
    password: '',
  });

  const [loading, setLoading] = React.useState(false);
  const [forgotten, setForgotten] = React.useState(false);
  const [error, setError] = React.useState('');
  const history = useHistory();
  const user = useSelector((state: any) => state.authReducer.user);
  const [passwordType, setPasswordType] = React.useState('password');

  const showError = err => {
    setError(err);
    window.scrollTo(0, 0);
  };

  const login = async () => {
    if (forgotten) {
      forgot();
      return;
    }

    const { email, password } = userInfosConnexion;
    if (email === '' || password == '') {
      showError(t('Veuillez remplir tous les champs'));
      return;
    }

    setLoading(true);
    try {
      await Fire.auth().signInWithEmailAndPassword(email, password);
    } catch (err) {
      console.log(err);
      switch (err.code) {
        case 'auth/email-already-exists':
          showError(t("L'email entré est déjà utilisé"));
          break;

        case 'auth/user-not-found':
        case 'auth/invalid-password':
        case 'auth/wrong-password':
          showError(t('Identifiants invalides'));
          break;

        case 'auth/invalid-email':
          showError(t("L'email entré est invalide"));
          break;

        default:
          showError(t('Une erreur est survenue'));
      }
    }
    setLoading(false);
  };

  const forgot = async () => {
    const { email } = userInfosConnexion;
    if (email === '') {
      showError(t('Veuillez remplir votre email'));
      return;
    }

    setLoading(true);
    try {
      await Fire.auth().sendPasswordResetEmail(email);
      alert(
        t('Un mail de changement de mot de passe a été envoyé à : ') + email,
      );
    } catch (err) {
      switch (err.code) {
        case 'auth/email-already-exists':
          showError(t("L'email entré est déjà utilisé"));
          break;

        case 'auth/invalid-password':
          showError(t('Identifiants invalides'));
          break;

        case 'auth/user-not-found':
          showError(t('Identifiants invalides'));
          break;

        case 'auth/invalid-email':
          showError(t("L'email entré est invalide"));
          break;

        default:
          showError(t('Une erreur est survenue'));
      }
    }
    setLoading(false);
    setForgotten(false);
  };

  const setValue = (key: string, val: any) => {
    userInfosConnexion[key] = val;
    setUserInfosConnexion({ ...userInfosConnexion });
  };

  const onKeyPress = event => {
    if (event.charCode === 13) {
      login();
    }
  };

  if (user) history.push('/pronos');

  return (
    <div className="login-screen">
      {/* <HeaderBar /> */}
      <Particles />

      <div className="login-container background top-padding">
        <div className="row-center">
          <div className="login-outbox">
            <div className="info-login-form red-box">
              <div className="col-center login-logo-container">
                <img
                  className="logo"
                  onClick={() => history.push('/')}
                  src={logo}
                  width="100px"
                />
              </div>

              <div className="page-title">{t('Connexion')}</div>

              <div className="row-center noWrap">
                <Input
                  title={t('Email') + ' :'}
                  placeholder="example@gmail.com"
                  value={userInfosConnexion.email}
                  onKeyPress={onKeyPress}
                  onChange={val => setValue('email', val.trim())}
                  dark
                />
              </div>
              <div className="row-center noWrap">
                {!forgotten && (
                  <div className="password_container_login">
                    <Input
                      title={t('Mot de passe') + ' :'}
                      placeholder={t('Mot de passe')}
                      type={passwordType}
                      value={userInfosConnexion.password}
                      onKeyPress={onKeyPress}
                      onChange={val => setValue('password', val)}
                      icon={
                        passwordType === 'password' ? (
                          <Visibility
                            fontSize="inherit"
                            color="inherit"
                            onClick={() => setPasswordType('text')}
                            className="visibility-eye"
                            style={{ height: 40 }}
                          />
                        ) : (
                          <VisibilityOff
                            fontSize="inherit"
                            color="inherit"
                            onClick={() => setPasswordType('password')}
                            className="visibility-eye"
                            style={{ height: 40 }}
                          />
                        )
                      }
                    />
                  </div>
                )}
              </div>
              {error && (
                <div className="error-box" style={{ textAlign: 'center' }}>
                  <div className="error">{error}</div>
                </div>
              )}
              <div className="row-center" style={{ marginTop: 25 }}>
                <div
                  className="page-link login-page-link"
                  onClick={() => history.push('/paiement-offre-1-euro')}
                >
                  {t('Pas de compte ? Inscrivez-vous')}
                </div>
              </div>

              <div className="row-center" style={{ marginTop: 25 }}>
                <Button
                  title={
                    loading
                      ? t('Chargement...')
                      : forgotten
                      ? t('Demander un nouveau mot de passe')
                      : t('Me connecter')
                  }
                  onPress={login}
                  classes={['linear-btn', 'big-btn', 'login-btn']}
                  type="submit"
                />
              </div>
              <div className="row-center" style={{ marginTop: 20 }}>
                {forgotten ? (
                  <div
                    className="page-link login-page-link"
                    onClick={() => setForgotten(false)}
                  >
                    {t('Je connais mon mot de passe')}
                  </div>
                ) : (
                  <React.Fragment>
                    <div
                      className="page-link login-page-link"
                      onClick={() => setForgotten(true)}
                    >
                      {t('Mot de passe oublié ?')}
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div className="bar-divider" />
              <div className="row-center" style={{ marginTop: 25 }}>
                <OauthLogins typeAuth="login" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
}

export default LoginScreen;
