import { createTheme } from '@material-ui/core/styles';
import { BreakpointOverrides } from '@material-ui/core/styles/createBreakpoints';

const defaultTheme = createTheme();

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    ml: true;
    lg: true;
    xl: true;
  }
}

export const lightTheme = createTheme({
  typography: {
    fontFamily: '"AvenirNext"',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    // fontWeightSemiBold: 600,
    fontWeightBold: 700,
    // fontWeightExtraBold: 900,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1100,
      ml: 1300,
      lg: 1500,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#0b1847',
      dark: '#3c4045',
      light: '#67696b',
    },
    secondary: {
      main: '#fffa7e',
      dark: '#d8d366',
      light: '#fffddc',
    },
    text: {
      primary: '#3c4045',
      secondary: '#848ca5',
    },
    background: {
      default: '#fcfcfa',
      paper: '#fcfcfa',
    },
    type: 'light',
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: `2.5rem`, //40px
        fontWeight: 'bold',
        [defaultTheme.breakpoints.down('xl')]: {},
      },
      h2: {
        fontSize: `2rem`, //32px
        fontWeight: 'bold',
      },
      h3: {
        fontSize: `1.5625rem`, //25px
        fontWeight: 'bold',
      },
      h4: {
        fontSize: `1.375rem`, //22px
        fontWeight: 'bold',
      },
      h5: {
        fontSize: `1.125rem`, //18px
        fontWeight: 'bold',
      },
      h6: {
        fontSize: `1rem`, //16px
        fontWeight: 'bold',
      },
      body1: {
        fontSize: `.875rem`, //14px
        fontWeight: 'inherit',
      },
      body2: {
        fontSize: `.75rem`, //12px
        fontWeight: 'inherit',
      },
    },
    // MuiBox: {
    //   root: {
    //     margin: "inherit",
    //   },
    // },
    MuiMenuItem: {
      root: {
        borderRadius: 12,
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: 35,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 25,
        padding: '5px',
        transition: 'all .2s ease-in-out',
        boxShadow: '0 0 23px 0 rgba(10, 22, 62, 0.04)',
      },
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 'inherit',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
        minWidth: 'max-content',
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: '#fffddc',
        },
      },
      label: {
        alignItems: 'center',
        fontSize: 16,
        // color: defaultTheme.palette.text.secondary,
      },
    },
    MuiAppBar: {
      colorDefault: {
        color: defaultTheme.palette.text.primary,
        backgroundColor: 'transparent',
      },
    },
    MuiTabs: {
      root: {
        borderBottom: 'solid 2px #dbdfe8',
      },
      scrollButtons: {
        '&>svg': {
          fontSize: '2rem',
          color: 'white',
          background: '#7f869f',
          borderRadius: '50%',
          transition: 'all .2s ease-in-out',

          '&:hover': {
            background: '#7f869f50',
          },
        },
      },
    },
    MuiTab: {
      wrapper: {
        textTransform: 'initial',
        fontSize: `1rem`,
      },

      textColorInherit: {
        color: '#848ca5',
        fontWeight: 600,

        '&.Mui-selected': {
          color: '#3c4045',
          fontWeight: 'bold',
        },
      },
    },
    MuiListItemText: {
      root: {
        '&>span': {
          fontSize: '.9rem !important',
        },
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: 'none',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: 'solid 2px rgba(0,0,0,0.23) !important',
      },
    },
    MuiPopover: {
      root: {
        '&>div[aria-hidden=true]': {
          opacity: 0.66,
          backgroundColor: '#0b1847 !important',
        },
      },
      paper: {
        borderRadius: '1rem',
        backgroundColor: '#213072',
      },
    },
    // MuiCircularProgress: {
    //   stroke: "unset",
    // },
  },
});
