import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  createStyles,
  Theme,
  useTheme,
  makeStyles,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Navbar from '../Templates/Navbar/Navbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import TitleCard from '../Templates/Account/TitleCard';
import CardGeneral from '../Templates/Cards/CardGeneral';
import '../../../theme/datawinTheme.css';
import clsx from 'clsx';
import Infos from './Infos';
import Settings from './Settings';
import Membership from './Membership';
import Fade from '@material-ui/core/Fade';
import { useTranslation, initReactI18next } from 'react-i18next';
import { Fire } from 'services';
import { updateUser } from 'actions/auth.action';
import { matchPath } from 'react-router';
import LeftNavbar from '../Templates/Navbar/LeftNavbar';
import PreviewTitle from '../Templates/Misc/PreviewTitle';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 1300,
      margin: 'auto',
      width: '100%',
      overflow: 'hidden',
      color: 'white',
      padding: '0 1rem',
    },
    buttonYellow: {
      backgroundColor: theme.palette.secondary.main,
      border: `solid 2px ${theme.palette.secondary.main}`,
      borderRadius: '12px',
      borderBottomRightRadius: '23px',
      padding: '7px 20px',
      fontWeight: 'bold',
      minWidth: '150px',
      width: 'max-content',
      display: 'block',
      marginLeft: 'auto',
      marginRight: '.5rem',
      '&:hover': {
        backgroundColor: 'transparent',
        border: `solid 2px ${theme.palette.secondary.main}`,
        boxShadow: '0 12px 23px 0 rgb(10 22 62 / 16 %)',
      },
    },
    buttonGrey: {
      backgroundColor: 'transparent',
      border: 'solid 2px #dbdfe8',
      borderRadius: '12px',
      borderBottomRightRadius: '23px',
      padding: '7px 20px',
      fontWeight: 'bold',
      minWidth: '150px',
      width: 'max-content',
      display: 'block',
      marginRight: 'auto',
      marginLeft: '.5rem',

      '&:hover': {
        background: '#dbdfe8',
        boxShadow: '0 12px 23px 0 rgb(10 22 62 / 16 %)',
      },
    },
    button: {
      [theme.breakpoints.down('sm')]: {
        minWidth: 0,
        padding: '7px 1rem',
        '&>span': {
          fontSize: '1rem',
        },
      },
    },
    hidden: {
      display: 'none',
    },
    pageBackgroundBlue: {
      zIndex: -1,
      background: '#0a1437',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
    },
    pageBackgroundBlueGradient: {
      backgroundImage:
        'linear-gradient(108deg, #6d7fff9e -1%, rgba(84, 114, 255, 0.05) 46%, rgba(84, 114, 255, 0.05) 46%)',
      zIndex: 5,
      opacity: 0.36,
      content: '""',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
      pointerEvents: 'none',
    },
    appBarShift: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${theme.spacing(7) + 1}px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${theme.spacing(9) + 1}px)`,
      },
    },
    appBarShiftOpen: {
      width: `calc(100% - ${drawerWidth}px)`,
      '& > div': {
        width: '100%',
        margin: 0,
      },
    },
    appBarShiftMobile: {
      width: '100%',
    },
    card: {
      padding: '2.5rem',
    },
    root: {
      '& .Mui-selected': {
        color: 'white',
      },
    },
  }),
);

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      className="noOverflow"
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

type Props = {
  match: any;
};

export default function Account(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const smallComputer = useMediaQuery(theme.breakpoints.down('sm'));
  // let id = props.match.params.id || null;
  const history = useHistory();
  const dispatch = useDispatch();
  const spacing = smallComputer ? 4 : 8;
  const { t, i18n } = useTranslation();
  const [tab, setTab] = useState(0);
  const [value, setValue] = React.useState(0);
  const [cancellation, setCancellation] = React.useState(null);
  const user = useSelector((state: any) => state.authReducer.user);
  const [info, setInfo] = React.useState<any>({ ...user });
  const [open, setOpen] = useState(true);
  let id: any = null;
  try {
    const match: any = matchPath(document.location.pathname, {
      path: '/cancellation/:id',
      exact: true,
      strict: false,
    });
    id = match.params.id;
  } catch (err) {}
  if (!user.vip) history.push('/dashboard');

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const getCancellation = async () => {
    try {
      const ref = Fire.store().collection('cancellations').doc(id);
      const cancellation = await Fire.doc(ref);
      if (cancellation == null) {
        alert('LIEN INVALIDE');
        return;
      }
      if (cancellation.userId == user.id) {
        setCancellation(cancellation);
      } else {
        alert('LIEN INVALIDE');
        return;
      }
    } catch (err) {
      alert('LIEN INVALIDE');
      return;
    }
  };

  React.useEffect(() => {
    if (id != null) {
      getCancellation();
    }
  }, [getCancellation, id]);

  const promptCancel = async () => {
    info['state'] = { ...info.state, canceled: new Date() };
    info['vip'] = false;
    const payload = {
      ...info,
    };
    // console.log("info: ", info);
    setInfo({ ...info });
    const confirm = window.confirm(
      t('Êtes-vous sûr de vouloir vous désabonner définitivement ?'),
    );
    if (confirm) {
      try {
        const userSub = await Fire.cloud('unsubscribe', { userId: info.id });
        // console.log(userSub)
        await dispatch(updateUser(payload));
      } catch (error) {
        alert(
          'une erreur est survenue. merci de nous contacter sur support@datawin-prediction.com',
        );
      }
    }
  };

  return (
    <Fade in={true} timeout={500}>
      <>
        {mobile && <Navbar noRadius transparent collapsed />}
        <Box display="flex">
          <div className={classes.pageBackgroundBlue} />
          <div className={classes.pageBackgroundBlueGradient} />
          {!mobile && <LeftNavbar open={open} setOpen={setOpen} />}
          <Box
            className={clsx(classes.appBarShift, {
              [classes.appBarShiftOpen]: open,
              [classes.appBarShiftMobile]: mobile,
            })}
          >
            <Grid container spacing={spacing} className={classes.container}>
              <Grid item xs={12}>
                <Box
                  maxWidth="1250px"
                  margin="auto"
                  marginBottom="2rem"
                  marginTop="3rem"
                >
                  <Grid container spacing={spacing}>
                    <Grid item xs={12}>
                      <PreviewTitle title="Mon compte" typography="h2" />
                    </Grid>
                    <Grid item xs={12}>
                      <CardGeneral className={classes.card} lightBlue>
                        <Grid container>
                          <Grid item xs={12}>
                            <AppBar position="static" color="default">
                              <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                color="primary"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                                classes={{ root: classes.root }}
                                TabIndicatorProps={{
                                  style: {
                                    height: '4px',
                                    transform: 'translate(0, 75%)',
                                  },
                                }}
                                className="noOverflow"
                              >
                                <Tab
                                  label={t('Mes informations')}
                                  {...a11yProps(0)}
                                />
                                <Tab
                                  label={t('Paramètres')}
                                  {...a11yProps(1)}
                                />
                              </Tabs>
                            </AppBar>
                          </Grid>
                          <Grid item xs={12} className="noOverflow">
                            {!cancellation ? (
                              <SwipeableViews
                                axis={
                                  theme.direction === 'rtl' ? 'x-reverse' : 'x'
                                }
                                index={value}
                                onChangeIndex={handleChangeIndex}
                                className="noOverflow"
                              >
                                <TabPanel
                                  value={value}
                                  index={0}
                                  dir={theme.direction}
                                >
                                  <Infos />
                                </TabPanel>
                                <TabPanel
                                  value={value}
                                  index={1}
                                  dir={theme.direction}
                                >
                                  <Settings />
                                  <Box m="2rem"></Box>
                                  <Membership />
                                </TabPanel>
                              </SwipeableViews>
                            ) : (
                              <Box
                                display="flex"
                                minHeight="10rem"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                padding="2rem 1rem"
                              >
                                <Typography
                                  variant="h4"
                                  className="cancel-title"
                                >
                                  {user.vip
                                    ? t(
                                        'Êtes-vous sur de vouloir vous désabonner ?',
                                      )
                                    : t(
                                        "Vous n'avez pas / plus d'abonnement en cours",
                                      )}
                                </Typography>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  flexWrap="wrap"
                                  marginTop="2rem"
                                  justifyContent="center"
                                  minHeight="8rem"
                                  alignItems="center"
                                >
                                  {user.vip && (
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="secondary"
                                      href="#"
                                      size="small"
                                      className={clsx(
                                        classes.buttonYellow,
                                        classes.button,
                                      )}
                                      onClick={() => history.push('/pronos')}
                                      style={{
                                        margin: mobile
                                          ? '.5rem auto'
                                          : '1rem 2rem',
                                      }}
                                    >
                                      {' '}
                                      {t(
                                        'Non, je souhaite profiter de Datawin',
                                      )}
                                    </Button>
                                  )}
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    color="secondary"
                                    href="#"
                                    size="small"
                                    className={clsx(
                                      classes.buttonYellow,
                                      classes.button,
                                    )}
                                    onClick={promptCancel}
                                    style={{
                                      margin: mobile
                                        ? '.5rem auto'
                                        : '1rem 2rem',
                                    }}
                                  >
                                    {' '}
                                    {user.vip
                                      ? t('Annuler mon abonnement')
                                      : t('Déjà désabonné')}
                                  </Button>
                                </Box>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </CardGeneral>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    </Fade>
  );
}
