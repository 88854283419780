import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Card, CardActions, Typography, Button, Box } from '@material-ui/core/';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import ButtonFilled from '../../Dashboard/Templates/Buttons/ButtonFilled';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '3.5rem',
      color: 'white',
      fontWeight: 700,
      lineHeight: '4.5rem',
      position: 'relative',
    },
    smallTitle: {
      fontSize: '2.5rem',
      lineHeight: '1.21',
    },
    interText: {
      fontSize: '1.8rem',
      color: '#fffa7e',
      fontWeight: 600,
      marginTop: '1rem',
    },
    subTitle: {
      fontSize: '1rem',
      color: 'white',
      lineHeight: '1.5rem',
      fontWeight: 'normal',
      margin: '3rem 0',
      fontFamily: 'AvenirNext',
      position: 'relative',
      opacity: 0.8,
      maxWidth: '80%',
      [theme.breakpoints.down('sm')]: {
        margin: '4rem 0',
        fontSize: '1.5rem',
        lineHeight: '1.5',
        maxWidth: '100%',
      },
    },
    smallSubTitle: {
      fontSize: '1.15rem',
      color: 'white',
      lineHeight: '2rem',
      fontWeight: 'normal',
      margin: '2rem 0',
      fontFamily: 'AvenirNext',
      position: 'relative',
      opacity: 0.8,
    },
    blueTitle: {
      color: '#1a2844',
    },
    blueSubTitle: {
      color: '#6a6f75',
      opacity: 1,
      fontWeight: 500,
    },
  }),
);

const Title = props => {
  const {
    title,
    interText,
    subTitle,
    buttonText,
    buttonPath,
    blue,
    textAlign,
    marginTop = '1rem',
    image,
    image2,
    maxWidth,
    smallTitle,
    smallSubTitle,
    fadeButton,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  return (
    <Box
      textAlign={textAlign}
      maxWidth={maxWidth || 800}
      margin={
        textAlign === 'center'
          ? `${marginTop} auto 1rem`
          : textAlign === 'left'
          ? `${marginTop} auto 1rem 0`
          : `inherit`
      }
    >
      <h1
        className={clsx(
          classes.title,
          blue && classes.blueTitle,
          smallTitle && classes.smallTitle,
        )}
      >
        {t(title)}
      </h1>
      {interText && (
        <h2 className={clsx(classes.interText, blue && classes.blueTitle)}>
          {t(interText)}
        </h2>
      )}
      {subTitle && (
        <p
          className={clsx(
            classes.subTitle,
            blue && classes.blueSubTitle,
            smallSubTitle && classes.smallSubTitle,
          )}
        >
          {t(subTitle)}
        </p>
      )}
      {image && (
        <img
          src={image}
          alt="image"
          style={{ maxWidth: '100%', marginBottom: '3rem' }}
        />
      )}
      {buttonText && (
        <ButtonFilled title={buttonText} path={buttonPath} fade={fadeButton} />
      )}
      {image2 && (
        <img
          src={image2}
          alt="image"
          style={{
            maxWidth: '100%',
            marginLeft: mobile ? '1rem' : '2rem',
            maxHeight: '50px',
            transform: 'translate(0, 33%)',
            marginTop: mobile ? '2rem' : '0rem',
          }}
        />
      )}
    </Box>
  );
};

export default Title;
