import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/it';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Container,
  MenuItem,
  Menu,
  MenuList,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { PersonOutlineRounded } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import LeftDrawer from './LeftDrawer';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';
import { useTranslation } from 'react-i18next';
import logo_datawin_text from 'images/logo_datawin_text.svg';
import logo_datawin from 'images/logo_datawin.png';
import LangSelect from '../../Dashboard/Templates/Navbar/LangSelect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outsideContainer: {
      padding: 0,
    },
    blue: {
      backgroundColor: '#0a1437',
    },
    navbarDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`,
      alignItems: `center`,
      padding: `.5rem`,
      [theme.breakpoints.down('md')]: {
        padding: '15px 25px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '9px 1rem 5px',
      },
    },
    navDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`,
      alignItems: 'center',
      '&>*': {
        margin: '0 1rem',
      },
      '& > .MuiInput-underline:before': {
        content: 'none',
      },
    },
    linkText: {
      textDecoration: `none`,
      fontWeight: 500,
      color: '#848ca5 !important',
      fontSize: '1rem',
      padding: `8px 18px`,
      borderRadius: 20,

      '&.Mui-selected': {
        color: 'white !important',
        background: 'transparent',
      },

      '&:hover': {
        transition: 'color, .2s ease-in-out',
        color: 'white !important',
        background: 'rgba(0, 0, 0, 0.04)',
      },
    },
    appBar: {
      borderRadius: 25,
      zIndex: 1,
      backgroundColor: 'transparent',
    },
    title: {
      fontWeight: 700,
      fontSize: 21,
    },
    iconUser: {
      fontSize: 40,
      padding: 0,
      '&:hover': {
        boxShadow: '0 0 33px 0 rgb(255 255 255 / 11%)',
      },
    },
    popoverMenu: {
      '&>div:first-child': {
        backgroundColor: 'transparent !important',
      },
      '&>div.MuiPaper-root': {
        padding: '.5rem',
        backgroundColor: 'white',

        '&>ul': {
          padding: '0 !important',
          '&>li': {
            fontWeight: 600,
            color: theme.palette.primary.main,

            fontSize: '1.1rem',
            padding: '.5rem .75rem !important',
            minHeight: '0',
            // "&:hover": {
            //   backgroundColor: "#ecf0f8",
            // },
          },
        },
      },
    },
    outBox: {
      background:
        'linear-gradient(98deg, #7489ff 2%, #86b4ff 35%, #7fc1ff 70%)',
      borderRadius: 20,
    },
    outlineBlue: {
      color: 'white !important',
      position: 'relative',
      border: '2px solid transparent',
      borderRadius: 20,
      backgroundClip: 'padding-box',
      background: theme.palette.primary.main,
      boxShadow:
        '0 0 10px 0 rgb(138 225 255 / ²15%), 0 0 10px 0 rgb(138 225 255 / 15%) inset',
    },
    outlineGrey: {
      color: 'white !important',
      border: 'solid 2px #848ca5',
      boxShadow:
        '0 0 10px 0 rgb(138 225 255 / 10%), 0 0 10px 0 rgb(138 225 255 / 10%) inset',
      '&:hover': {
        background: '#848ca5',
      },
    },
    outlineWhite: {
      color: `${theme.palette.primary.main} !important`,
      background: 'white',
      border: 'white 1px solid',
      '&:hover': {
        background: theme.palette.primary.main,
        color: 'white !important',
      },
    },
  }),
);

export default function NavbarHome(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t, i18n } = useTranslation();

  const { blue } = props;

  //Drawer Account
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    moment.locale(lng);
  };

  const navLinks = [
    { title: t(`Accueil`), path: `/` },
    {
      title: t(`Analyses`),
      path: 'secure',
    },
    // { title: t(`Avantages`), path: `#avantages` },
    // { title: t(`Bonus`), path: `/bonus` },
    // { title: t(`FAQ`), path: `/faq` },
  ];

  const navLinksButtons = [
    {
      title: t(`S'inscrire`),
      path: 'secure',
      outline: 'white',
    },
    {
      title: t(`Se connecter`),
      path: `/login`,
      outline: 'blue',
    },
  ];

  const navLinksPhone = [
    { title: t(`Accueil`), path: `/` },
    {
      title: t(`Analyses`),
      path: 'secure',
    },
    // { title: t(`Avantages`), path: `/#avantages` },
    // { title: t(`Bonus`), path: `/bonus` },
    // { title: t(`FAQ`), path: `/faq` },
  ];

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectWithUTMUrl = url => {
    const target_url = (url += document.location.search || '');
    return target_url;
  };

  return (
    <Container
      className={clsx(classes.outsideContainer, classes.blue)}
      maxWidth={false}
    >
      <Fade in={true} timeout={500}>
        <Box position="relative">
          <AppBar position="static" className={classes.appBar}>
            <Toolbar style={{ zIndex: 1 }}>
              <Container className={classes.navbarDisplayFlex} maxWidth={false}>
                <Typography
                  variant="h4"
                  className={classes.title}
                  onClick={() =>
                    history.push(`/${document.location.search || ''}`)
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={mobile ? logo_datawin_text : logo_datawin}
                    alt="DATAWIN"
                    style={{ maxWidth: '100px' }}
                  />
                </Typography>
                {mobile ? (
                  <div>
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexWrap="nowrap"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        className={classes.iconUser}
                        onClick={handleMenu}
                        style={{ marginRight: '1rem' }}
                        // onClick={() => history.push('/account')}
                        color="inherit"
                      >
                        <PersonOutlineRounded fontSize="large" />
                      </IconButton>
                      <LeftDrawer
                        menu={navLinksPhone}
                        changeLanguage={changeLanguage}
                      />
                    </Box>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      keepMounted
                      getContentAnchorEl={null}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={open}
                      onClose={handleClose}
                      className={classes.popoverMenu}
                    >
                      <MenuItem
                        onClick={() => {
                          history.push('secure');

                          handleClose();
                        }}
                      >
                        {t("S'inscrire")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          history.push(redirectWithUTMUrl('login'));
                        }}
                      >
                        {t('Se connecter')}
                      </MenuItem>
                    </Menu>
                  </div>
                ) : (
                  <div
                    className={classes.navDisplayFlex}
                    style={{ width: '100%' }}
                  >
                    <MenuList
                      aria-labelledby="main navigation"
                      className={classes.navDisplayFlex}
                    >
                      {navLinks.map(({ title, path }, index) => (
                        <MenuItem
                          key={index}
                          // component={Link}
                          // to={redirectWithUTMUrl(path)}
                          onClick={() => {
                            history.push(redirectWithUTMUrl(path));
                          }}
                          className={clsx(classes.linkText)}
                          selected={
                            path === pathname ||
                            (path === '/dashboard' && pathname === '/')
                          }
                        >
                          <Typography variant="body1">{title}</Typography>
                        </MenuItem>
                      ))}
                    </MenuList>

                    <Box marginLeft={'auto'} className={classes.navDisplayFlex}>
                      <LangSelect />
                      {navLinksButtons.map(({ title, path, outline }, index) =>
                        outline === 'blue' ? (
                          <div className={classes.outBox} key={index}>
                            <MenuItem
                              key={index}
                              onClick={() => {
                                history.push(redirectWithUTMUrl(path));
                              }}
                              className={clsx(
                                classes.linkText,
                                outline === 'blue' && classes.outlineBlue,
                              )}
                              selected={
                                path === pathname ||
                                (path === '/dashboard' && pathname === '/')
                              }
                            >
                              <Typography variant="body1">{title}</Typography>
                            </MenuItem>
                          </div>
                        ) : (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              document.location.href = redirectWithUTMUrl(path);
                            }}
                            className={clsx(
                              classes.linkText,
                              outline === 'grey' && classes.outlineGrey,
                              outline === 'white' && classes.outlineWhite,
                            )}
                            selected={
                              path === pathname ||
                              (path === '/dashboard' && pathname === '/')
                            }
                          >
                            <Typography variant="body1">{title}</Typography>
                          </MenuItem>
                        ),
                      )}
                    </Box>
                  </div>
                )}
              </Container>
            </Toolbar>
          </AppBar>
        </Box>
      </Fade>
    </Container>
  );
}
