import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DoublesImages from '../DoublesImages/DoublesImages';
import React from 'react';
import { Match } from '../../../../../types/pronoNBA';
import { getNFLLogoURL } from '../../../../../utils/nfl';

const OtherPronoTeamRow: React.FC<{ match: Match }> = ({ match }) => {
  return (
    <Grid container item xs={12}>
      <Grid container item xs={4} alignItems="center" justify="flex-start">
        <Box fontWeight={600}>
          <Typography variant="body2">{match.value.home.name}</Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <DoublesImages
          srcHome={getNFLLogoURL(match.value.home?.id)}
          srcAway={getNFLLogoURL(match.value.away?.id)}
        />
      </Grid>
      <Grid container item xs={4} alignItems="center" justify="flex-end">
        <Box fontWeight={600} textAlign="end">
          <Typography variant="body2">{match.value.away.name}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OtherPronoTeamRow;
