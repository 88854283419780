import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import './i18n';
import Loading from 'components/Loading/Loading';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { Fire } from 'services';

const ErrorFallback = ({ error }) => {
  const history = useHistory();
  useEffect(() => {
    const refresh = async () => {
      await Fire.cloud('logError', { error: error.stack });
      history.replace('/');
      window.location.reload();
    };
    if (process.env.NODE_ENV === 'production') refresh();
  }, [history, error]);

  return <div role="alert" />;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.Fragment>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Suspense fallback={<Loading />}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <App />
              </ErrorBoundary>
            </Suspense>
          </Router>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </React.Fragment>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
