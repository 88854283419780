/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import {
  List,
  ListItem,
  IconButton,
  Typography,
  useTheme,
  Theme,
  createStyles,
  Box,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ImageButton from '../Templates/ImageButton';
import flag_fr from 'images/flag_fr.png';
import flag_en from 'images/flag_en.png';
import flag_it from 'images/flag_it.png';
import flag_es from 'images/flag_es.png';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation, initReactI18next } from 'react-i18next';
import logo_datawin_text from 'images/logo_datawin_text.svg';
import cross from 'images/cross.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: '100vw',
    },
    fullList: {
      width: 'auto',
    },
    drawer: {
      width: 200,
      flexShrink: 0,
    },
    drawerPaper: {
      width: 200,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    drawerLinkText: {
      textDecoration: `none`,
      textTransform: `capitalize`,
      fontWeight: 600,
      color: 'white',
      fontSize: '28px',
      padding: `10px 18px`,
      margin: '0 10px',

      '&.Mui-selected': {
        color: '#42aef1',
        backgroundColor: 'transparent',
      },
    },
    btnIcon: {
      color: 'white !important',
      [theme.breakpoints.down('xs')]: {
        padding: '5px 0',
      },
    },
    menuBackground: {
      background: theme.palette.primary.main,
    },
    drawerContainer: {
      '& > .MuiPaper-root': {
        background: '#0b1847',
        backgroundImage:
          'radial-gradient(circle at 50% 50%, #4cd9f739, rgb(37 177 236 / 0%) 100%)',
      },
    },
    menuItemText: {
      fontSize: '28px',
      fontFamily: 'AvenirNext',
      fontWeight: 600,
      lineHeight: '46px',
    },
  }),
);

type Anchor = 'left' | 'top' | 'right' | 'bottom';

export default function LeftDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const pathname = document.location.pathname;
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [state, setState] = React.useState({
    left: false,
  });

  const { menu, changeLanguage } = props;

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        padding="2rem 28px"
      >
        <img src={logo_datawin_text} alt="Logo datawin" />
        <img src={cross} alt="Close" />
      </Box>
      <List>
        {menu.map(item => (
          <ListItem
            onClick={() => {
              history.push(item.path);
            }}
            className={classes.drawerLinkText}
            selected={item.path === pathname}
          >
            <Typography variant="body1" className={classes.menuItemText}>
              {item.title}
            </Typography>
          </ListItem>
        ))}
        <ListItem className={classes.drawerLinkText}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <ImageButton
              height={21}
              width={21}
              onPress={() => changeLanguage('fr')}
              image={flag_fr}
              selected={i18n.language === 'fr'}
              marginRight="1rem"
            />
            <ImageButton
              height={21}
              width={21}
              onPress={() => changeLanguage('en')}
              image={flag_en}
              selected={i18n.language === 'en'}
              marginRight="1rem"
            />
            <ImageButton
              height={21}
              width={21}
              onPress={() => changeLanguage('es')}
              image={flag_es}
              selected={i18n.language === 'es'}
              marginRight="1rem"
            />
            <ImageButton
              height={21}
              width={21}
              onPress={() => changeLanguage('it')}
              image={flag_it}
              selected={i18n.language === 'it'}
            />
          </Box>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      {(['left'] as Anchor[]).map(anchor => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <IconButton
              aria-label="open drawer"
              edge="start"
              className={classes.btnIcon}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            className={classes.drawerContainer}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
