import React, { useEffect, useState } from 'react';
import {
  useTheme,
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Popover from '@material-ui/core/Popover';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Fire } from 'services';
import TitleCard from '../Templates/Account/TitleCard';
import '../../../theme/datawinTheme.css';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import Hidden from '@material-ui/core/Hidden';
import PopoverUnsuscribe from './PopoverUnsuscribe';
import { useSelector } from 'react-redux';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divAbo: {
      height: '7rem',
      border: 'solid 2px #394478',
      borderRadius: '.5rem',
    },
    left: {
      overflow: 'hidden',
      height: '100%',
    },
    snackbar: {
      position: 'static',
    },
    pen: {
      marginRight: '.5rem',
    },
  }),
);

const Membership = () => {
  const { t } = useTranslation();

  const user = useSelector((state: any) => state.authReducer.user);

  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [messageOpen, setMessageOpen] = React.useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [customerState, setCustomerState] = useState('');
  const [price, setPrice] = useState('');
  const [subId, setSubId] = useState<any>(null);
  let breakF = false;
  let breakT = false;
  const GetUserState = async () => {
    const prosRef = await Fire.store().collection('pros').doc(user?.id);
    await prosRef.get().then(pro => {
      if (pro.exists) {
        setCustomerState('Admin');
        return;
      }
    });
    try {
      const customerRef = await Fire.store()
        .collection('stripe_customers')
        .doc(user.id);
      const customer = await Fire.doc(customerRef);
      let customer_infos;
      if (customer) {
        customer_infos = await Fire.cloud('listSubsByCustomer', {
          customerId: customer.customer_id,
        });
      }
      customer_infos?.data?.forEach(sub => {
        sub.status !== 'trialing' && setCustomerState(sub.status);

        if (sub.status == 'active' && !breakF) {
          setSubId(sub.id);
          setCustomerState('PREMIUM');
          setPrice(
            Math.trunc(sub.plan.amount / 100) +
              '.' +
              (sub.plan.amount % 100) +
              (sub.plan.currency === 'eur' ? '€' : '$') +
              ' / mois',
          );
          breakF = true;
        } else if (sub.status == 'trialing' && !breakF) {
          setSubId(sub.id);
          if (
            Math.round((sub.trial_end - sub.trial_start) / (24 * 3600)) >= 10
          ) {
            setCustomerState('PREMIUM');
            setPrice(
              Math.trunc(sub.plan.amount / 100) +
                '.' +
                (sub.plan.amount % 100) +
                (sub.plan.currency === 'eur' ? '€' : '$') +
                ' / mois',
            );
          } else {
            setCustomerState('TRIAL');
            setPrice(t('1€ pour 1 semaine'));
          }
          breakT = true;
        } else if (!breakF && !breakT) {
          setCustomerState('PREMIUM');
          setPrice(
            Math.trunc(sub.plan.amount / 100) +
              '.' +
              (sub.plan.amount % 100) +
              (sub.plan.currency === 'eur' ? '€' : '$') +
              ' / mois',
          );
          setSubId(sub.id);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetUserState();
  }, []);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessageOpen(false);
  };

  const save = async () => {
    setIsPopoverOpen(true);
  };

  return (
    <Box width="100%" height="100%" paddingTop="2rem">
      <TitleCard title={t('Mon offre DATAWIN')} />
      <Grid item xs={12} container>
        <Grid container className={classes.divAbo}>
          <Grid item xs={mobile ? 5 : 2} className={classes.left}>
            <Box
              bgcolor="#394478"
              padding="2rem"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h5" component="span">
                <Box
                  fontWeight="700"
                  color="white"
                  lineHeight="2rem"
                  textAlign="center"
                >
                  {t('OFFRE EN COURS')}
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={mobile ? 1 : 7}>
              <Box
                height="100%"
                width="100%"
                padding="0 2rem"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h6" component="span">
                  <Box fontWeight="500" color="textPrimary" lineHeight="1.5rem">
                    {t(
                      'Vous profitez d’un accès illimité aux analyses de l’algorithme et aux avantages de la plateforme Datawin',
                    )}
                  </Box>
                </Typography>
              </Box>
            </Grid>
          </Hidden>
          <Grid item xs={mobile ? 7 : 3}>
            <Box
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h6" component="span">
                <Box color="white" fontSize="2rem" width="100%">
                  {customerState}
                </Box>
                <Box
                  color={theme.palette.text.secondary}
                  fontWeight={500}
                  textAlign="center"
                >
                  {t(price)}
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            width="max-content"
            height="100%"
            marginLeft="auto"
            marginTop="2rem"
          >
            <Typography color="textSecondary" variant="h5" component="span">
              <Box
                color={theme.palette.text.secondary}
                fontWeight="500"
                fontSize="1rem"
              >
                <Button onClick={save}>
                  <CreateIcon fontSize="inherit" className={classes.pen} />
                  {t('Gérer mon abonnement')}
                </Button>
              </Box>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Popover
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        open={isPopoverOpen}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={() => setIsPopoverOpen(false)}
      >
        <Box maxWidth="600px" bgcolor="transparent">
          <Box padding="1.8rem">
            <TitleCard title={t('Se désabonner')} />
            <PopoverUnsuscribe subId={subId} userId={user.id} />
          </Box>
        </Box>
      </Popover>
      <Snackbar
        open={messageOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        key={'bottom'}
        className={classes.snackbar}
      >
        <Alert onClose={handleClose} severity="success">
          {t('Votre mot de passe a bien été réinitialisé !')}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Membership;
