import {
  Grid,
  Card,
  CardMedia,
  Typography,
  CardContent,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import PreviewTitle from '../Templates/Misc/PreviewTitle';
import CardGeneral from '../Templates/Cards/CardGeneral';
import { useTranslation } from 'react-i18next';
import { Fire } from 'services';
import { useAsync2 } from 'hooks';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: '25px',
      padding: 0,
      background: 'rgba(24, 36, 79, 1)',
      paddingBottom: '1rem',
      cursor: 'pointer',
    },
    image: {
      height: 140,
      width: '100%',
    },
    topPreview: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
    },
  }),
);

export default function PreviewNews({ sport }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const language = i18n.language;
  const request = useMemo(
    () =>
      Fire.list(
        Fire.store()
          .collection(`${sport.toLowerCase()}_actus`)
          .where('language', '==', language === 'fr' ? 'fr' : 'en')
          .orderBy('pubDate', 'desc')
          .limit(3),
      ),
    [language, sport],
  );
  const { data: infos } = useAsync2<any[], any>(request, []);

  const actus = infos.map((info, index) => (
    <Grid item xs={12} sm={4} key={info.title}>
      <Card
        key={index}
        className={classes.card}
        onClick={() => window.open(info.link)}
      >
        <CardMedia
          className={classes.image}
          image={info.image}
          title={`actu${index}`}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            {moment(info.pubDate).format('DD/MM/YYYY')}
          </Typography>
          <Typography variant="h6" style={{ margin: '.5rem 0' }}>
            {info.title.substring(0, 80)}
          </Typography>
          <Typography variant="body2" component="p">
            {info.description
              .substring(0, 120)
              .replace('\\u0027', "'")
              .replace('"', '')}
            ...
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ));

  if (!actus.length) return null;

  return (
    <React.Fragment>
      <div className={classes.topPreview}>
        <PreviewTitle title={t('Actus')} marginBottom />
      </div>
      <Grid container alignItems="flex-start" spacing={4}>
        {actus}
      </Grid>
    </React.Fragment>
  );
}
