import React from 'react';
import Card from '@material-ui/core/Card';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import clsx from 'clsx';

interface Props {
  children?: React.ReactNode;
  className?: string;
  blue?: boolean;
  lightBlue?: boolean;
  shadow?: boolean;
  light?: boolean;
  cursorPointer?: boolean;
  maxHeight?: number | string;
  smallCorner?: boolean;
  style?: any;
  transparent?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 25,
      width: '100%',
      height: 'auto',
      padding: '.5rem',
      transition: 'all .2s ease-in-out',
      boxShadow: '0 0 23px 0 rgba(10, 22, 62, 0.08)',
      zIndex: 1,
      position: 'relative',
    },
    transparent: {
      backgroundColor: 'transparent',
    },
    blue: {
      backgroundColor: 'rgba(24,36,79,0.85)',
    },
    lightBlue: {
      backgroundColor: '#2e3d81a1',
    },
    shadow: {
      '&:hover': {
        boxShadow: '0 12px 23px 0 rgba(10, 22, 62, 0.16)',
      },
    },
    blueShadow: {
      '&:hover': {
        boxShadow: '0 12px 23px 0 rgb(11 18 42)',
      },
    },
    light: {
      backgroundColor: '#f8f9fa',
    },
    cursorPointer: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    smallCorner: {
      borderRadius: '12.5px',
    },
  }),
);

function CardGeneral(props: Props) {
  const {
    children,
    className,
    blue,
    lightBlue,
    transparent,
    shadow,
    light,
    cursorPointer,
    smallCorner,
    maxHeight,
    onClick,
    style,
    ...other
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isDark = theme.palette.type === 'dark';

  return (
    <Card
      className={clsx(
        classes.root,
        className,
        (blue || isDark) && classes.blue,
        lightBlue && classes.lightBlue,
        shadow && classes.shadow,
        transparent && classes.transparent,
        (blue || isDark) && shadow && classes.blueShadow,
        light && classes.light,
        cursorPointer && classes.cursorPointer,
        smallCorner && classes.smallCorner,
      )}
      style={{ maxHeight: maxHeight, ...style }}
      {...other}
      onClick={onClick}
    >
      {children}
    </Card>
  );
}

export default CardGeneral;
