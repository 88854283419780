import React from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardGeneral from './CardGeneral';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import '../../../../theme/datawinTheme.css';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Logo from '../Logos/Logo';
import { getProperName } from '../../../../utils/sportradar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      boxShadow: '0 0 18px 0 rgba(138, 225, 255, 0.45)',
      border: '3px solid transparent',
      backgroundClip: 'padding-box',
      borderRadius: 25,
      backgroundColor: '#202f67',
    },
    content: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      height: '100%',
      minHeight: '15rem',
      padding: '1rem !important',
    },
    bgBlue: {
      // width: "100%",
      // borderRadius: 25,
      // height: "100%",
      // maxWidth: "312px",
      // transition: "all .3s ease-in-out",
      // [theme.breakpoints.down('sm')]: {
      //     margin: "auto !important",
      //     width: "100%",
      //     maxWidth: 340,
      //     height: "250px !important",
      // }
    },
    outBox: {
      background:
        'linear-gradient(98deg, #7489ff 2%, #86b4ff 35%, #7fc1ff 70%)',
      borderRadius: 25,
      width: '100%',
    },
  }),
);

export default function CardPrediction(props) {
  const { name, tournament, domicile, ext, date, blue, team1, schedule } =
    props;
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const language = i18n.language.toUpperCase();
  const classes = useStyles();

  const infosLength: number =
    (name[language] || t(name)).length +
    (tournament?.name?.[language] || tournament.name || tournament).length;

  return (
    <div className={clsx(classes.bgBlue, classes.outBox)}>
      <CardGeneral className={classes.card} blue={blue}>
        <CardContent className={classes.content}>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box width="100%" marginBottom="3rem">
              <Box
                width="100%"
                height="auto"
                display="flex"
                flexDirection="row"
                flexWrap="nowrap"
                fontWeight={600}
                paddingBottom=".3rem"
                justifyContent="space-between"
              >
                <Box
                  color="white"
                  textAlign="left"
                  style={{ backgroundColor: 'rgba(255, 255, 255, .3)' }}
                  borderRadius="8px"
                  padding=".5rem 1rem !important"
                >
                  <Typography variant="body1" component="span">
                    <Box
                      fontSize={infosLength > 65 ? '.8rem' : '1rem'}
                      fontWeight={500}
                      lineHeight="1"
                    >
                      {moment(date.toDate()).format('LL')}
                    </Box>
                  </Typography>
                </Box>
                <Box color="#838ca6" textAlign="center" paddingLeft=".5rem">
                  <Typography variant="body1" component="span">
                    <Box
                      fontSize={infosLength > 65 ? '.8rem' : '1rem'}
                      fontWeight={500}
                    >
                      {tournament?.name?.[language] ||
                        tournament.name ||
                        tournament}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box width="100%" paddingBottom="2rem">
              <Box width="100%" height="70%" display="flex">
                <Box
                  width="33%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Logo
                    src={domicile.logo}
                    club={domicile.name !== domicile.country}
                    width={mobile ? 50 : 90}
                    disabled={team1.name === ext.name}
                  />
                </Box>
                <Box
                  width="33%"
                  height="100%"
                  color="#838ca6"
                  flexDirection="column"
                  textAlign="center"
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                  fontWeight="600"
                  marginTop="-10px"
                >
                  <Typography variant="body2" component="span">
                    <Box
                      fontSize="1rem"
                      fontWeight={500}
                      color="rgba(255, 255, 255, 0.7)"
                    >
                      {name[language] || t(name)}
                    </Box>
                  </Typography>
                  <Typography variant="h3" component={'span'}>
                    <Box color="white" marginTop="1rem">
                      VS
                    </Box>
                  </Typography>
                </Box>
                <Box
                  width="33%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Logo
                    src={ext.logo}
                    club={ext.name !== ext.country}
                    width={mobile ? 50 : 90}
                    disabled={team1.name === domicile.name}
                  />
                </Box>
              </Box>
              <Box width="100%" marginTop=".75rem" display="flex">
                <Box
                  width="33%"
                  height="100%"
                  color={'#fff'}
                  display="flex"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant={team1.name == domicile.name ? 'h6' : 'body1'}
                  >
                    {t(domicile.name)}
                  </Typography>
                </Box>
                <Box width="33%" height="100%"></Box>
                <Box
                  width="33%"
                  height="100%"
                  color={'#fff'}
                  display="flex"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant={team1.name == ext.name ? 'h6' : 'body1'}>
                    {t(ext.name)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* {mobile && (
              <>
                <Box
                  width="100%"
                  height="1px"
                  style={{
                    background: theme.palette.text.secondary,
                    opacity: '40%',
                  }}
                  marginBottom="2rem"
                ></Box>
                <Box display="flex" flexDirection="column" width="100%">
                  <Box
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                    marginBottom="2rem"
                  >
                    <Typography component={'span'} variant="h6">
                      <Box color="#838ca6" fontWeight={600} textAlign="center">
                        {t('Stade')}
                      </Box>
                    </Typography>
                    <Typography component={'span'} variant="h5">
                      <Box color="white" fontWeight="bold" textAlign="center">
                        {schedule.value.sport_event.venue?.name
                          ? schedule.value.sport_event.venue.name
                          : t('Stade inconnu')}
                      </Box>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Typography component={'span'} variant="h6">
                      <Box color="#838ca6" fontWeight={600} textAlign="center">
                        {t('Arbitre')}
                      </Box>
                    </Typography>
                    <Typography component={'span'} variant="h5">
                      <Box color="white" fontWeight="bold" textAlign="center">
                        {schedule.value.sport_event.sport_event_conditions
                          ?.referees?.[0].name
                          ? getProperName(
                              schedule.value.sport_event.sport_event_conditions
                                .referees[0].name,
                            )
                          : t('Non renseigné')}
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              </>
            )} */}
          </Box>
        </CardContent>
      </CardGeneral>
    </div>
  );
}
