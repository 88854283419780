import React from 'react';
import './ImageButton.scss';

type Props = {
    width: number;
    height: number;
    image: string;
    classes?: string[];
    onPress?: () => void;
    disabled?: boolean;
    label?: string;

}
function Button(props: Props) {
    let style = {
        width: props.width,
        height: props.height,
        background: `url(${props.image})`,
        cursor: "pointer",
        backgroundSize: "contain",
        border: "none"
    };
    return (
        <button
            className={[props.disabled ? "disabled" : ""].concat(props.classes || []).join(" ")}
            onClick={!props.disabled ? props.onPress : undefined}
            style={style}
        >
            {props.label ? props.label : ""}
        </button>
    );
}

export default Button;
