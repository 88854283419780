import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import GradientSVG from './GradientSVG';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxShadow: {
      boxShadow:
        '0 0 18px 0 rgba(138, 225, 255, 0.25), inset 0 0 18px 0 rgb(138 225 255 / 15%)',
      borderRadius: '50%',
    },
    root: {
      stroke: 'url(#gsvg) !important',
      strokeLinecap: 'round',
    },
    root2: {
      stroke: 'url(#gsvg2) !important',
      strokeLinecap: 'round',
    },
  }),
);

export default function CircularDeterminate(props) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);
  const [progress2, setProgress2] = React.useState(0);
  const {
    value,
    value2,
    text1,
    text1Bold,
    text1Thin,
    text2,
    small,
    verySmall,
  } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    setProgress(0);
    setProgress2(0);
  }, [value]);

  React.useEffect(() => {
    if (progress < value - 3) {
      const timer = setInterval(() => {
        setProgress(prevProgress =>
          prevProgress < value - 3 ? prevProgress + 3 : prevProgress,
        );
      }, 10);

      return () => {
        clearInterval(timer);
      };
    }
  }, [progress, props]);

  React.useEffect(() => {
    if (progress >= value - 3) {
      if (progress2 < value2 - 3) {
        const timer = setInterval(() => {
          setProgress2(prevProgress =>
            prevProgress < value2 - 2 ? prevProgress + 3 : prevProgress,
          );
        }, 20);

        return () => {
          clearInterval(timer);
        };
      }
    }
  }, [progress]);

  const colors = [
    {
      offset: '0%',
      color: '#74caff',
    },
    {
      offset: '40%',
      color: '#6ed7ff',
    },
    {
      offset: '60%',
      color: '#6cdcf3',
    },
    {
      offset: '100%',
      color: '#68ead1',
    },
  ];
  const colors2 = [
    {
      offset: '0%',
      color: '#fff',
    },
    {
      offset: '50%',
      color: '#e4e4e4',
    },
    {
      offset: '100%',
      color: '#fff',
    },
  ];

  return (
    <Box width="100%" height="100%" position="relative">
      <Box
        width={160}
        height={160}
        position="absolute"
        left="50%"
        style={{
          transform: `translate(-50% , ${
            small || verySmall ? '0' : '-2.5px'
          } )`,
        }}
        borderRadius="50%"
        border={`${
          mobile ? '10.5' : verySmall ? '7' : small ? '8' : '12'
        }px solid  #848ca5`}
        className={classes.boxShadow}
      ></Box>
      <Box
        width={mobile ? '150px' : '172px'}
        height={mobile ? '150px' : '172px'}
        position="absolute"
        left="50%"
        style={{ transform: 'translate(-50%,0)' }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        color="#69dad9"
      >
        {text1 && (
          <Typography variant="body1" component={'span'}>
            <Box
              fontWeight={text1Bold ? 600 : text1Thin ? 300 : 500}
              marginBottom="1rem"
            >
              {text1}
            </Box>
          </Typography>
        )}
        <Typography variant="h2" component={'span'}>
          <Box fontWeight={600}>{text2}</Box>
        </Typography>
      </Box>
      <Box
        // width={small ? "170px" : "175px"}
        //     height={small ? "143px" : "147px"}
        width={160}
        height={160}
        position="absolute"
        left="50%"
        // style={{ transform: "translate(-49%,-2.5px) rotate(20deg) scaleX(-1)" }}
        style={{
          transform: `translate(-50% , ${
            small || verySmall ? '0' : '-2.5px'
          } )`,
        }}
        borderRadius="50%"
        zIndex={2}
      >
        <GradientSVG colors={colors} idCSS={'gsvg'} rotation="90" />
        <CircularProgress
          className={classes.root}
          variant="static"
          thickness={small || verySmall ? 2 : 3}
          size="100%"
          value={progress}
        />
      </Box>
      {value2 && (
        <Box
          width={160}
          height={160}
          position="absolute"
          left="50%"
          // style={{ transform: "translate(-49%,-2.5px) rotate(20deg) scaleX(-1)" }}
          style={{
            transform: `translate(-50% , ${
              small || verySmall ? '0' : '-2.5px'
            } )`,
          }}
          borderRadius="50%"
          zIndex={1}
        >
          <GradientSVG colors={colors2} idCSS={'gsvg2'} rotation="90" />
          <CircularProgress
            className={classes.root2}
            variant="static"
            thickness={small || verySmall ? 2 : 3}
            size="100%"
            value={progress2}
            style={{ transform: `rotate(${value * 3.6 - 100}deg)` }}
          />
        </Box>
      )}
    </Box>
  );
}
