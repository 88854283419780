import React, { useEffect, useState } from 'react';
import { Gender } from '../../../../types/pronoTennis';

const MAN_DEFAULT_URL =
  'https://firebasestorage.googleapis.com/v0/b/datawin-prod.appspot.com/o/tennis%2FAvatar_homme.png?alt=media&token=62248208-5821-4848-8029-b9b743996ce9';
const WOMAN_DEFAULT_URL =
  'https://firebasestorage.googleapis.com/v0/b/datawin-prod.appspot.com/o/tennis%2FAvatar_femme.png?alt=media&token=79f7d2dd-7108-44da-994e-6539db7411d4';

type PlayerPhotoProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  gender: Gender;
  playerId: string;
};

const PlayerPhoto: React.FC<PlayerPhotoProps> = ({
  playerId,
  gender,
  ...imgProps
}) => {
  const [imgUrl, setImgUrl] = useState<string | null>(null);

  useEffect(() => {
    setImgUrl(
      `https://firebasestorage.googleapis.com/v0/b/datawin-prod.appspot.com/o/tennis%2Fplayers%2Fheadshots%2F${playerId}%2F80x80.jpg?alt=media`,
    );
  }, [playerId]);

  const getDefaultImg = () => {
    if (gender === 'men') return setImgUrl(MAN_DEFAULT_URL);
    return setImgUrl(WOMAN_DEFAULT_URL);
  };

  return (
    <img
      {...imgProps}
      src={imgUrl || undefined}
      style={{ objectFit: 'cover', ...imgProps.style }}
      onError={() => getDefaultImg()}
      alt=""
    />
  );
};

export default PlayerPhoto;
