import React from 'react';
import {
  makeStyles,
  useTheme,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardGeneral from '../CardGeneral';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ButtonFilled from '../../Buttons/ButtonFilled';
import clsx from 'clsx';
import '../../../../../theme/datawinTheme.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import backgroundFootball from 'images/backgroundFootball.svg';
import backgroundFootball_Dark from 'images/backgroundFootball_Dark.svg';
import backgroundTennis from 'images/backgroundTennis.svg';
import Logo from '../../Logos/Logo';
import moment from 'moment';
import SportGPTModalPrediction from './SportGPTModalPrediction';
import SportGPTMainProno from './SportGPTDetails/SportGPTProno';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '20rem',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        height: '26rem',
      },
    },
    content: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      height: '100%',
      minHeight: '15rem',
      position: 'relative',
    },
    backgroundFootball: {
      background: `url(${backgroundFootball}) no-repeat top right`,
      backgroundColor: '#dadde5',
    },
    backgroundFootball_Dark: {
      background: `url(${backgroundFootball_Dark}) no-repeat top right`,
      backgroundColor: 'rgba(91, 94, 105, .85)',
    },
    backgroundTennis: {
      background: `url(${backgroundTennis}) no-repeat top right`,
      backgroundColor: '#dadde5',
    },
    backgroundGrey: {
      background: '#d2d2d2',
    },
    greenShadow: {
      boxShadow: '0px 5px 17px 1px #04ff0063 !important',
    },
    orangeShadow: {
      boxShadow: '0px 5px 17px 1px #ffbc006b !important',
    },
    greenText: {
      color: '#10ff00',
    },
    orangeText: {
      color: '#ffb000b5',
    },
  }),
);

export const SportGPTCardPrediction: React.FC<{
  prono: any;
}> = ({ prono }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CardGeneral
        className={clsx(classes.card)}
        blue={true}
        onClick={handleClickOpen}
      >
        <CardContent className={classes.content}>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            position="relative"
          >
            <Box height="10%" width="100%">
              <Box
                width="100%"
                height="100%"
                display="flex"
                flexDirection="row"
                flexWrap="nowrap"
                fontWeight={600}
                justifyContent="space-between"
              >
                <Box color={'#838ca6'} textAlign="left" fontSize=".7rem">
                  <Typography variant={mobile ? 'body1' : 'body2'}>
                    {moment(prono?.date).format('DD/MM/YYYY')}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box height="20%" width="100%">
              <Box
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box color={'fff'} textAlign="center" width="100%">
                  {/* <Typography
                    variant={mobile ? 'h3' : 'h4'}
                    style={mobile ? {} : { fontSize: '1.24rem' }}
                  >
                    PRONOS
                  </Typography> */}
                  <SportGPTMainProno prono={prono} card={true} />
                </Box>
              </Box>
            </Box>

            <Box height="45%" width="100%">
              <Box width="100%" height="60%" display="flex">
                <Box
                  width="33%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    borderRadius="50%"
                    height="50px"
                    width="50px"
                    bgcolor="white"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    boxShadow={'0 0 33px 0 rgb(0 0 0 / 11%)'}
                  >
                    <Logo
                      src={prono?.player1?.imageUrl}
                      club={prono?.player1?.name}
                    />
                  </Box>
                </Box>
                <Box
                  width="33%"
                  height="100%"
                  color={'#838ca6'}
                  textAlign="center"
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                  fontWeight="600"
                >
                  <Typography variant={mobile ? 'body1' : 'body2'}>
                    {prono?.competition?.name}
                  </Typography>
                </Box>
                <Box
                  width="33%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    borderRadius="50%"
                    height="50px"
                    width="50px"
                    bgcolor="white"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    boxShadow={'0 0 33px 0 rgb(0 0 0 / 11%)'}
                  >
                    <Logo
                      src={prono?.player2?.imageUrl}
                      club={prono?.player2?.name}
                    />
                  </Box>
                </Box>
              </Box>
              <Box width="100%" height="40%" display="flex">
                <Box
                  width="33%"
                  height="100%"
                  color={'#fff'}
                  display="flex"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant={mobile ? 'h6' : 'body1'}
                    component={'span'}
                  >
                    <Box fontWeight="600">{prono?.player1?.name}</Box>
                  </Typography>
                </Box>
                <Box width="33%" height="100%"></Box>
                <Box
                  width="33%"
                  height="100%"
                  color={'#fff'}
                  display="flex"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant={mobile ? 'h6' : 'body1'}
                    component={'span'}
                  >
                    <Box fontWeight="600">{prono?.player2?.name}</Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              height="20%"
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ButtonFilled title="Voir les statistiques" blue={true} medium />
            </Box>
          </Box>
        </CardContent>
      </CardGeneral>
      <SportGPTModalPrediction
        open={open}
        onClose={handleClose}
        prono={prono}
      />
    </>
  );
};

export default SportGPTCardPrediction;
