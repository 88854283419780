import axios from 'axios';
import { Geolocation } from '../types/geolocation';

export const getDeviceGeoLocation = async () => {
  try {
    const { data } = await axios.get<Geolocation>(
      'https://geolocation-db.com/json/',
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const getUserIP = async () => {
  try {
    const { data } = await axios.get<Geolocation>(
      'https://geolocation-db.com/json/',
    );
    return data.IPv4;
  } catch (e) {
    return null;
  }
};
