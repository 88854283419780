import React from 'react';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as PlayBtn } from '../../../../../../images/btn_play_red.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardGeneral from '../../../../Templates/Cards/CardGeneral';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: 'inherit',
      boxShadow: '0 5px 10px 0 rgba(11, 18, 42, .06)',
    },
    content: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      height: '100%',
      padding: '9px !important',
    },
    divImg: {
      backgroundColor: 'white',
      borderRadius: '50%',
      boxShadow: '0 5px 19px 3px rgba(0, 0, 0, 0.15)',
      width: '50px',
      height: '50px',
    },

    matchLogo: {
      width: '85%',
      height: '85%',
      borderRadius: '2rem',
    },
  }),
);

type CardLivescoreFootballProps = {
  status: string;
  date: string;
  homeTeamName: string;
  awayTeamName: string;
  homeTeamLogo: string;
  awayTeamLogo: string;
  homeScore: number;
  awayScore: number;
};

const CardLivescoreFootball: React.FC<CardLivescoreFootballProps> = ({
  status,
  date,
  homeTeamName,
  homeTeamLogo,
  awayTeamName,
  awayTeamLogo,
  homeScore,
  awayScore,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDark = theme.palette.type === 'dark';
  const smallComputer = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  return (
    <CardGeneral
      className={classes.root}
      shadow
      light={theme.palette.type !== 'dark'}
      blue={theme.palette.type === 'dark'}
    >
      <CardContent className={classes.content}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="space-between"
          width="100%"
          height="100%"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            marginBottom={1}
          >
            <Box
              bgcolor={isDark ? '#0b183f' : 'transparent'}
              borderRadius={7}
              p="5px"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1" component={'span'}>
                <Box
                  color="#e93e55"
                  fontWeight={600}
                  justifyContent="space-around"
                  alignItems="center"
                  display="flex"
                >
                  {status === 'not_started' ? (
                    moment(date).format('HH:mm')
                  ) : status === 'live' ? (
                    <React.Fragment>
                      <PlayBtn
                        fill="red"
                        height="100%"
                        style={{ marginRight: '.5rem' }}
                      />
                      Live
                    </React.Fragment>
                  ) : (
                    <strong>{t('Fini')}</strong>
                  )}
                  {/* <PlayBtn fill="red" height="100%" />
                                    {props.time?.status === "NS" ? props.time?.starting_at?.time?.slice(0, 4) : "Live"} */}
                </Box>
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" component={'span'}>
                <Box color="#b7becc" fontWeight="fontWeightSemiBold">
                  {moment(date).calendar()}
                </Box>
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                container
                className={classes.divImg}
                alignItems="center"
                justify="center"
              >
                <img src={homeTeamLogo} className={classes.matchLogo} />
              </Grid>
              <Typography variant="body2" component="span">
                <Box
                  color={theme.palette.text.primary}
                  fontWeight="fontWeightSemiBold"
                  marginTop={1}
                >
                  {homeTeamName}
                </Box>
              </Typography>
            </Grid>
            <Grid container justify="center" alignItems="center">
              <Box
                bgcolor="#0b183f"
                borderRadius={25}
                padding="6px 9px"
                width="fit-contant"
                height="fit-content"
              >
                <Typography
                  variant={smallComputer ? (mobile ? 'h3' : 'h6') : 'h5'}
                  component="span"
                >
                  <Box
                    fontWeight="fontWeightBold"
                    color={isDark ? theme.palette.text.primary : '#fff'}
                  >
                    {homeScore || 0} : {awayScore || 0}
                  </Box>
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                container
                className={classes.divImg}
                alignItems="center"
                justify="center"
              >
                <img src={awayTeamLogo} className={classes.matchLogo} />
              </Grid>
              <Typography variant="body2" component="span">
                <Box
                  color={theme.palette.text.primary}
                  fontWeight="fontWeightSemiBold"
                  marginTop={1}
                >
                  {awayTeamName}
                </Box>
              </Typography>
            </Grid>
          </Box>
        </Box>
      </CardContent>
    </CardGeneral>
  );
};

export default CardLivescoreFootball;
