import React from 'react';
import { makeStyles, Button, Typography } from '@material-ui/core';
import { Theme, createStyles, useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontWeight: 700,
      borderRadius: 20,
      border: `2px solid ${theme.palette.secondary.main}`,
      background: 'transparent',
      height: 32,
      transition: 'all .2s ease-in-out',
      color: theme.palette.text.primary,
      '&:hover': {
        color:
          theme.palette.type === 'dark'
            ? '#3c4045'
            : theme.palette.text.primary,
        background: theme.palette.secondary.main,
      },
    },
    bgYellow: {
      background: theme.palette.secondary.main,

      '&:hover': {
        background: 'transparent',
      },
    },
  }),
);

type SimpleButtonYellowProps = {
  title: string;
  bgYellow?: boolean;
  addIcon?: boolean;
  path: string;
};

const SimpleButtonYellow: React.FC<SimpleButtonYellowProps> = ({
  title,
  bgYellow = false,
  addIcon = false,
  path,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Button
      disableElevation
      variant="contained"
      onClick={() => history.push(path)}
      size="small"
      className={clsx({
        [classes.button]: true,
        [classes.bgYellow]: bgYellow,
      })}
    >
      <Typography
        variant="h6"
        component={'span'}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {addIcon && <AddIcon fontSize="small" />} {title}
      </Typography>
    </Button>
  );
};

export default SimpleButtonYellow;
