import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DoublesImages from '../DoublesImages/DoublesImages';
import React from 'react';
import {
  Gender,
  PronoTennisCardPlayer,
} from '../../../../../types/pronoTennis';
import SinglesImages from '../../CardPronoTennisFille/SinglesImages/SinglesImages';
import PlayerPhoto from '../../../Templates/Tennis/PlayerPhoto';

type OtherPronoTeamRowProps = {
  players: PronoTennisCardPlayer[];
  gender: Gender;
};

const OtherPronoTeamRow: React.FC<OtherPronoTeamRowProps> = ({
  players,
  gender,
}) => {
  const [player1, player2] = players;
  return (
    <Grid container xs={12} direction="column">
      <Grid
        container
        item
        xs={12}
        justifyContent="space-around"
        style={{ marginBottom: '.75rem' }}
      >
        <Grid container item xs={5} alignItems="center" justify="center">
          <Box
            borderRadius="50%"
            height="50px"
            width="50px"
            bgcolor="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            boxShadow="0 0 33px 0 rgb(255 255 255 / 11%)"
            overflow="hidden"
          >
            <PlayerPhoto
              playerId={player1.id}
              gender={gender}
              style={{
                width: '100%',
                borderRadius: '50%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
        </Grid>
        <Grid container item xs={5} alignItems="center" justify="center">
          <Box
            borderRadius="50%"
            height="50px"
            width="50px"
            bgcolor="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            boxShadow="0 0 33px 0 rgb(255 255 255 / 11%)"
            overflow="hidden"
          >
            <PlayerPhoto
              playerId={player2.id}
              gender={gender}
              style={{
                width: '100%',
                borderRadius: '50%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} justify="space-around">
        <Grid container item xs={5} alignItems="flex-start" justify="center">
          <Box fontWeight={600} textAlign="center">
            <Typography variant="body2">
              {player1.firstName} {player1.lastName}
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs={5} alignItems="flex-start" justify="center">
          <Box fontWeight={600} textAlign="center">
            <Typography variant="body2">
              {player2.firstName} {player2.lastName}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* 
      <Grid container item xs={4} alignItems="center" justify="flex-start">
        <Box fontWeight={600}>
          <Typography variant="body2">
            {player1.firstName} {player1.lastName}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <DoublesImages
          gender={gender}
          player1Id={player1.id}
          player2Id={player2.id}
        />
      </Grid>
      <Grid container item xs={4} alignItems="center" justify="flex-end">
        <Box fontWeight={600} textAlign="end">
          <Typography variant="body2">
            {player2.firstName} {player2.lastName}
          </Typography>
        </Box>
      </Grid> */}
    </Grid>
  );
};

export default OtherPronoTeamRow;
