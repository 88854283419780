import React, { ChangeEvent, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useFaq from '../../hooks/useFaq';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme, createStyles, Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from '@material-ui/core/Fade';
import { Grid, Typography, Tabs, Tab } from '@material-ui/core';
import { lightTheme } from '../../theme/_datawinTheme';
import { darkTheme } from '../../theme/_datawinDarkTheme';
import '../../theme/datawinTheme.css';
import Particles from '../Reusable/Particles/Particles';
import NavbarHome from '../Home/NavbarHome/NavbarHome';
import Navbar from '../Dashboard/Templates/Navbar/Navbar';
import { Faq, FaqTheme } from '../../types/faq';
import { useHistory } from 'react-router';
import ToggleSport, { Sport } from '../Dashboard/Home/ToggleSport';
import LeftNavbar from '../Dashboard/Templates/Navbar/LeftNavbar';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dashboard: {
      maxWidth: '100vw',
      minHeight: '100vh',
    },
    bgDark: {
      background: '#0a1437',
      overflowX: 'hidden',
    },
    bgLight: {
      background: '#fcfcfa',
    },
    container: {
      maxWidth: 1300,
      margin: 'auto',
      width: '100%',
    },
    cardContainer: {
      maxWidth: 1500,
      margin: '3rem auto 0',
      width: '100%',
      padding: '2rem',
      // background: '#0B0D1E66',
      borderRadius: '30px',
    },
    pageBackgroundBlue: {
      zIndex: -1,
      background: '#0a1437',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
    },
    pageBackgroundBlueGradient: {
      backgroundImage:
        'linear-gradient(108deg, #6d7fff9e -1%, rgba(84, 114, 255, 0.05) 46%, rgba(84, 114, 255, 0.05) 46%)',
      zIndex: 2,
      opacity: 0.36,
      content: '""',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
      pointerEvents: 'none',
    },
    appBarShift: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${theme.spacing(7) + 1}px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${theme.spacing(9) + 1}px)`,
      },
    },
    appBarShiftOpen: {
      width: `calc(100% - ${drawerWidth}px)`,
      '& > div': {
        width: '100%',
        margin: 0,
      },
    },
    appBarShiftMobile: {
      width: '100%',
    },
  }),
);

type FaqSorted = {
  theme: FaqTheme;
  questions: Faq[];
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: '0 3' }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function FAQ() {
  const classes = useStyles();
  const theme = useTheme();
  const user = useSelector((state: any) => state.authReducer.user);
  const [isDarkTheme, setIsDarkTheme] = React.useState(true);
  const dispatch = useDispatch();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const history = useHistory();
  const spacing = mobile ? 3 : 6;
  const { faq, loading, error, themes } = useFaq();
  const [faqSorted, setFaqSorted] = useState<FaqSorted[]>([]);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(true);
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const [questionSelected, setQuestionSelected] = useState('');

  if (!user?.vip) history.push('/');

  React.useEffect(() => {
    if (faq && themes) {
      themes.map(theme => {
        setFaqSorted((prev: FaqSorted[]) => {
          const payload: FaqSorted[] = [
            ...prev,
            {
              theme: theme,
              questions: faq
                .filter(item => item.theme.label === theme.label)
                .sort((a, b) => a.order - b.order),
            },
          ];
          return payload;
        });
      });
    }
  }, [faq, themes]);

  // React.useEffect(() => {
  //   console.log(faqSorted);
  // }, [faqSorted]);

  const Answer = ({ answer }) => {
    return answer.type === 'file' ? (
      <img
        src={answer.image}
        style={{
          width: '100%',
          maxWidth: '600px',
          margin: '1rem auto',
        }}
      />
    ) : (
      <Typography
        variant="body1"
        style={{
          margin: '.5rem 0',
          fontSize: '1rem',
        }}
      >
        {t(answer.value)}
      </Typography>
    );
  };

  return (
    <React.Fragment>
      <Box
        className={clsx(
          user ? classes.dashboard : '',
          isDarkTheme ? classes.bgDark : classes.bgLight,
        )}
      >
        <Fade in timeout={500}>
          <>
            {isDarkTheme && (
              <>
                <div className={classes.pageBackgroundBlue} />
                <div className={classes.pageBackgroundBlueGradient} />
              </>
            )}
            {mobile && <Navbar noRadius transparent collapsed />}
            <Box display="flex">
              {!mobile && <LeftNavbar open={open} setOpen={setOpen} />}
              <Box
                className={clsx(classes.appBarShift, {
                  [classes.appBarShiftOpen]: open,
                  [classes.appBarShiftMobile]: mobile,
                })}
              >
                <Grid container>
                  <Grid
                    container
                    spacing={spacing}
                    className={user ? classes.container : ''}
                  >
                    <div className={classes.cardContainer}>
                      <Typography
                        variant="h2"
                        style={{ marginBottom: '3rem', color: 'white' }}
                      >
                        {t('FAQ')}
                      </Typography>

                      <Box sx={{ width: '100%' }}>
                        <Box
                          sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            marginBottom: mobile ? '2rem' : '3rem',
                          }}
                        >
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            color="primary"
                            textColor="secondary"
                            variant="fullWidth"
                            TabIndicatorProps={{
                              style: {
                                height: '4px',
                                transform: 'translate(0, 75%)',
                              },
                            }}
                            className="noOverflow"
                          >
                            {faqSorted.map((themeSection, index) => {
                              if (themeSection.questions.length === 0)
                                return (
                                  <Tab
                                    label={''}
                                    style={{ display: 'none' }}
                                    key={`pannel-${themeSection.theme.label}`}
                                  />
                                );

                              return (
                                <Tab
                                  key={`pannel-${themeSection.theme.label}`}
                                  style={{ minWidth: 0 }}
                                  label={t(themeSection.theme.label)}
                                  {...a11yProps(index)}
                                />
                              );
                            })}
                          </Tabs>
                        </Box>
                        {faqSorted.map((themeSection, index) => {
                          if (themeSection.questions.length === 0) return;

                          return (
                            <TabPanel
                              value={value}
                              index={index}
                              key={`questions-${themeSection.theme.id}`}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={10}
                                style={{
                                  padding: mobile ? '0' : '0 3rem',
                                  margin: 'auto',
                                }}
                                key={themeSection.theme.id}
                              >
                                {themeSection.questions.map(question => {
                                  return (
                                    <Box
                                      padding="1rem 3rem 1rem 2rem"
                                      marginBottom="1.5rem"
                                      border="1px solid #2F3251"
                                      borderRadius="16px"
                                      key={question.id}
                                      style={{
                                        cursor: 'pointer',
                                        background:
                                          question.id === questionSelected
                                            ? '#5F618026'
                                            : '',
                                        transition: 'all .2s ease-in-out',
                                      }}
                                      onClick={() =>
                                        setQuestionSelected(prev =>
                                          prev === question.id
                                            ? ''
                                            : question.id,
                                        )
                                      }
                                    >
                                      <Box
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Typography
                                          variant="h5"
                                          style={{
                                            color: 'white',
                                            marginBottom:
                                              question.id === questionSelected
                                                ? '1rem'
                                                : '0rem',
                                            transition: 'all .1s ease-in-out',
                                          }}
                                        >
                                          {t(question.question)}
                                        </Typography>
                                        <Typography
                                          variant="h3"
                                          style={{
                                            color: 'white',
                                            transform: `rotate(${
                                              question.id === questionSelected
                                                ? '0deg'
                                                : '90deg'
                                            })`,
                                            transition: 'all .2s ease-in-out',
                                          }}
                                        >
                                          {question.id === questionSelected
                                            ? '-'
                                            : '+'}
                                        </Typography>
                                      </Box>
                                      <Box
                                        style={{
                                          color: 'white',
                                          overflow: 'hidden',
                                          maxHeight:
                                            question.id === questionSelected
                                              ? 'fit-content'
                                              : '0vh',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                          alignItems: 'flex-start',
                                        }}
                                      >
                                        {question.answers
                                          ? question.answers[language]
                                              .length !== 0
                                            ? question.answers?.[language].map(
                                                (answer, index) => (
                                                  <Answer
                                                    key={`answer-${question.id}-${index}`}
                                                    answer={answer}
                                                  />
                                                ),
                                              )
                                            : question.answers?.['fr']
                                                .length !== 0 &&
                                              question.answers?.['fr'].map(
                                                (answer, index) => (
                                                  <Answer
                                                    key={`answer-${question.id}-${index}`}
                                                    answer={answer}
                                                  />
                                                ),
                                              )
                                          : question.answer &&
                                            (typeof question.answer ===
                                            'string' ? (
                                              <Typography
                                                key={`answer-${question.id}-0`}
                                                variant="body1"
                                              >
                                                {t(question.answer)}
                                              </Typography>
                                            ) : (
                                              question.answer.map(
                                                (answer, index) => (
                                                  <Answer
                                                    key={`answer-${question.id}-${index}`}
                                                    answer={answer}
                                                  />
                                                ),
                                              )
                                            ))}
                                      </Box>
                                    </Box>
                                  );
                                })}
                              </Grid>
                            </TabPanel>
                          );
                        })}
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        </Fade>
      </Box>
    </React.Fragment>
  );
}

export default FAQ;
