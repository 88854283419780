import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonPrevNext: {
      transition: 'all .2s ease-in-out',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
      },
    },
    buttonContainer: {
      padding: 0,
      margin: '5px 10px',
      height: 'fit-content',
      borderRadius: '2rem',
    },
    transparent: {
      backgroundColor: 'transparent',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        backgroundColor: 'transparent',
        color: 'white',
      },
    },
  }),
);

type BackButtonProps = {
  onClick?: () => void;
  label?: string;
  transparent?: boolean;
};

const BackButton: React.FC<BackButtonProps> = ({
  onClick,
  label = 'Retour',
  transparent,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const history = useHistory();

  return (
    <Button
      style={{ marginLeft: '5%' }}
      className={classes.buttonContainer}
      onClick={() => {
        if (onClick) onClick();
        else history.goBack();
      }}
    >
      <Box
        bgcolor="#242f56"
        color="white"
        padding=".5rem 1rem"
        borderRadius="2rem"
        alignItems="center"
        display="flex"
        fontWeight="600"
        className={clsx(
          classes.buttonPrevNext,
          transparent && classes.transparent,
        )}
      >
        <ArrowBackIcon fontSize="small" style={{ marginRight: '.5rem' }} />
        {!mobile && label}
      </Box>
    </Button>
  );
};

export default BackButton;
