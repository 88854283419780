import React from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardGeneral from '../../CardGeneral';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Logo from '../../../Logos/Logo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      boxShadow: '0 0 18px 0 rgba(138, 225, 255, 0.45)',
      border: '3px solid transparent',
      backgroundClip: 'padding-box',
      borderRadius: 25,
      backgroundColor: '#202f67',
    },
    content: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      height: '100%',
      minHeight: '15rem',
      padding: '1rem !important',
    },
    outBox: {
      background:
        'linear-gradient(98deg, #7489ff 2%, #86b4ff 35%, #7fc1ff 70%)',
      borderRadius: 25,
      width: '100%',
      margin: 'auto',
    },
  }),
);

export const SportGPTCardPronoFille: React.FC<{
  prono: any;
}> = ({ prono }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const language = i18n.language.toUpperCase();
  const classes = useStyles();

  // const infosLength: number =
  //   (name[language] || t(name)).length +
  //   (tournament?.name?.[language] || tournament.name || tournament).length;

  return (
    <div className={clsx(classes.outBox)}>
      <CardGeneral className={classes.card} blue={true}>
        <CardContent className={classes.content}>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box width="100%" marginBottom="3rem">
              <Box
                width="100%"
                height="auto"
                display="flex"
                flexDirection="row"
                flexWrap="nowrap"
                fontWeight={600}
                paddingBottom=".3rem"
                justifyContent="space-between"
              >
                <Box
                  color="white"
                  textAlign="left"
                  style={{ backgroundColor: 'rgba(255, 255, 255, .3)' }}
                  borderRadius="8px"
                  padding=".5rem 1rem !important"
                >
                  <Typography variant="body1" component="span">
                    <Box fontSize={'1rem'} fontWeight={500} lineHeight="1">
                      {moment(prono.date).format('LL')}
                    </Box>
                  </Typography>
                </Box>
                <Box color="#838ca6" textAlign="center" paddingLeft=".5rem">
                  <Typography variant="body1" component="span">
                    <Box fontSize={'1rem'} fontWeight={500}>
                      {prono?.competition?.name}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box width="100%" paddingBottom="2rem">
              <Box width="100%" height="70%" display="flex">
                <Box
                  width="33%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Logo
                    src={prono?.player1?.imageUrl}
                    club={prono?.player1?.name}
                    width={mobile ? 50 : 90}
                  />
                </Box>
                <Box
                  width="33%"
                  height="100%"
                  color="#838ca6"
                  flexDirection="column"
                  textAlign="center"
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                  fontWeight="600"
                  marginTop="-10px"
                >
                  <Typography variant="h3" component={'span'}>
                    <Box color="white" marginTop="1rem">
                      VS
                    </Box>
                  </Typography>
                </Box>
                <Box
                  width="33%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Logo
                    src={prono?.player2?.imageUrl}
                    club={prono?.player2?.name}
                    width={mobile ? 50 : 90}
                  />
                </Box>
              </Box>
              <Box width="100%" marginTop=".75rem" display="flex">
                <Box
                  width="33%"
                  height="100%"
                  color={'#fff'}
                  display="flex"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant={'body1'}>
                    {prono?.player1?.name}
                  </Typography>
                </Box>
                <Box width="33%" height="100%"></Box>
                <Box
                  width="33%"
                  height="100%"
                  color={'#fff'}
                  display="flex"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant={'body1'}>
                    {prono?.player2?.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </CardGeneral>
    </div>
  );
};

export default SportGPTCardPronoFille;
