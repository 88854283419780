import axios from 'axios';

export const getGeolocation = async () => {
  let res: any = null;
  const geo = await axios
    .get(
      'https://ipgeolocation.abstractapi.com/v1/?api_key=52bdeb6661d44de19d664bbba40d6dec',
    )
    .then(response => {
      res = response.data;
    })
    .catch(error => {
      console.log(error);
    });
  return res;
};
