import React, { useState } from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme, createStyles, Theme } from '@material-ui/core';
import { lightTheme } from '../../theme/_datawinTheme';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from '@material-ui/core/Fade';
import { useDispatch, useSelector } from 'react-redux';
import Welcome from './Home/Welcome';
import PreviewPronos from './Home/PreviewPronos/PreviewPronos';
import PreviewBonus from './Home/PreviewBonus';
import PreviewHighlights from './Home/PreviewHighlights';
import PreviewLibrary from './Home/PreviewLibrary/PreviewLibrary/PreviewLibrary';
import LiveScoreFootball from './Home/PreviewLivescore/LiveScoreFootball/LiveScoreFootball';
import PreviewNews from './Home/PreviewNews';
import Navbar from './Templates/Navbar/Navbar';
import LeftNavbar from './Templates/Navbar/LeftNavbar';
import PreviewTitle from './Templates/Misc/PreviewTitle';
import '../../theme/datawinTheme.css';
import ToggleSport, { Sport } from './Home/ToggleSport';
import PreviewLibraryTennis from './Home/PreviewLibrary/PreviewLibraryTennis/PreviewLibraryTennis';
import PreviewLibraryNBA from './Home/PreviewLibrary/PreviewLibraryNBA/PreviewLibraryNBA';
import PreviewLibraryNFL from './Home/PreviewLibrary/PreviewLibraryNFL/PreviewLibraryNFL';
import { useTranslation } from 'react-i18next';
import SimpleButtonYellow from './Templates/Buttons/SimpleButtonYellow';
import ButtonFilled from './Templates/Buttons/ButtonFilled';
import Particles from '../Reusable/Particles/Particles';
import LiveScoreTennis from './Home/PreviewLivescore/LiveScoreTennis/LiveScoreTennis';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { updateUser } from 'actions/auth.action';
import { useHistory } from 'react-router';
import { activateNextTour, disableTour } from '../../utils/reactour.utils';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dashboard: {
      maxWidth: '100vw',
      minHeight: '100vh',
      background: '#0a1437',
    },
    container: {
      maxWidth: 1300,
      margin: 'auto',
      width: '100%',
      padding: '2rem',
    },
    toggleSportContainer: {
      [theme.breakpoints.down('xs')]: {
        overflow: 'scroll',
      },
      padding: '1rem 2rem',
      background: 'rgba(25, 38, 83, .85)',
      position: 'sticky',
      top: 0,
      zIndex: 4,
      backdropFilter: 'blur(10px)',
    },
    pageBackgroundBlue: {
      zIndex: -1,
      background: '#0a1437',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
    },
    pageBackgroundBlueGradient: {
      backgroundImage:
        'linear-gradient(108deg, #6d7fff9e -1%, rgba(84, 114, 255, 0.05) 46%, rgba(84, 114, 255, 0.05) 46%)',
      zIndex: 5,
      opacity: 0.36,
      content: '""',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
      pointerEvents: 'none',
    },
    appBarShift: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${theme.spacing(7) + 1}px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${theme.spacing(9) + 1}px)`,
      },
    },
    appBarShiftOpen: {
      width: `calc(100% - ${drawerWidth}px)`,
      '& > div': {
        width: '100%',
        margin: 0,
      },
    },
    appBarShiftMobile: {
      width: '100%',
    },
  }),
);

function Dashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const [isTour1Open, setIsTour1Open] = useState(false);
  const dispatch = useDispatch();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useSelector((state: any) => state.authReducer.user);
  const [info, setInfo] = useState<any>({ ...user });
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const selectedSport: Sport = useSelector(
    (state: any) => state.sportsReducer.selectedSport,
  );
  const pronos = useSelector((state: any) => state.pronosReducer);
  const history = useHistory();

  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);

  const steps = [
    {
      selector: '[data-tour="nav-step"]',
      highlightedSelectors: ['[data-tour="toggle-sport-element"]'],
      ResizeObserver: [
        '[data-tour="nav-step"]',
        '[data-tour="toggle-sport-element"]',
      ],
      content: ({ goTon, inDom }) => (
        <div>
          {t(
            'Bienvenue chez Datawin ! Avant de débuter l’aventure et de gagner vos premiers paris, découvrez les nombreux outils qui sont à votre disposition.',
          )}
        </div>
      ),
      scrollDuration: 5,
      stepInteraction: false,
    },
    {
      selector: mobile
        ? '[data-tour="nav-pronos-step-mobile"]'
        : '[data-tour="nav-pronos-step"]',
      content: mobile
        ? t(
            'Rentrons immédiatement dans le vif du sujet en allant sur la page « Analyses de l’IA ».',
          )
        : t(
            'Rentrons immédiatement dans le vif du sujet en cochant l’onglet « Analyses de l’IA ».',
          ),
      position: 'bottom',
      scrollDuration: 5,
      stepInteraction: true,
    },
  ];

  const testFirstConnexionHome1 = async () => {
    setIsTour1Open(true);
    info['isFirstConnexion']['home1'] = false;
    const payload = {
      ...info,
    };
    setInfo({ ...info });
    await dispatch(updateUser(payload));
  };

  // DOCUMENTATION POUR LE REACTTOUR :
  //
  // Quand on clique pour fermer le tuto, la fonction "onRequestClose" est appelée, ce qui permet de fermer le tuto sur toutes les pages du site
  // Néanmoins, cette fonction se lance également lorsque l'on clique sur le bouton "Suivant" du tuto, ce qui fait que le tuto se ferme.
  // Pour palier ce problème, on a ajouté, sur le bouton "lastStepNextButton", la fonction "activateNextTour" qui permet de rouvrir le tuto sur la page suivante et de faire la redirection

  React.useEffect(() => {
    if (info.isFirstConnexion?.home1 === true) {
      testFirstConnexionHome1();
    }
  }, [testFirstConnexionHome1]);

  const spacing = mobile ? 3 : 6;
  if (!user.vip) {
    return (
      <div className={classes.dashboard}>
        <Fade in timeout={500}>
          <Grid container spacing={spacing} className={classes.container}>
            <Grid item xs={12}>
              <Navbar isDarkTheme />
            </Grid>
            <Grid item xs={12}>
              <Welcome />
            </Grid>
          </Grid>
        </Fade>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Tour
        steps={steps}
        isOpen={isTour1Open}
        disableInteraction={true}
        rounded={5}
        maskSpace={15}
        onAfterOpen={disableBody}
        onRequestClose={e =>
          disableTour(info, setIsTour1Open, dispatch, updateUser, e)
        }
        lastStepNextButton={
          <ButtonFilled
            title="Suivant"
            little
            onPress={() =>
              activateNextTour(
                info,
                setIsTour1Open,
                dispatch,
                updateUser,
                history,
                {
                  home1: false,
                  home2: false,
                },
                '/pronos',
              )
            }
          />
        }
      />
      <Box className={classes.dashboard}>
        <Fade in timeout={500}>
          <>
            <Particles />
            <div className={classes.pageBackgroundBlue} />
            <div className={classes.pageBackgroundBlueGradient} />
            {mobile && <Navbar noRadius transparent collapsed />}
            <Box display="flex">
              {!mobile && <LeftNavbar open={open} setOpen={setOpen} />}
              <Box
                className={clsx(classes.appBarShift, {
                  [classes.appBarShiftOpen]: open,
                  [classes.appBarShiftMobile]: mobile,
                })}
              >
                <Grid container>
                  <Grid item xs={12} className={classes.toggleSportContainer}>
                    <ToggleSport
                      selectedSport={selectedSport}
                      onChangeSport={(newSport: Sport) =>
                        dispatch({
                          type: 'CHANGE_SPORT',
                          payload: { newSport },
                        })
                      }
                    />
                  </Grid>
                  <Grid container className={classes.container} spacing={2}>
                    <Grid container item xs={12}>
                      <PreviewPronos
                        selectedSport={selectedSport}
                        pronos={pronos}
                      />
                    </Grid>
                    {/* <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      style={{
                        justifyContent: 'space-between',
                        marginBottom: '3rem',
                      }}
                      className="noPaddingRight"
                    > */}
                    {/* {selectedSport === 'FOOTBALL' && <PreviewLibrary />} */}
                    {/* {selectedSport === 'TENNIS' && <PreviewLibraryTennis />} */}
                    {/* {selectedSport === 'NBA' && <PreviewLibraryNBA />} */}
                    {/* {selectedSport === 'NFL' && <PreviewLibraryNFL />} */}
                    {/* </Grid> */}
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      style={{
                        justifyContent: 'space-between',
                        marginBottom: '3rem',
                      }}
                      className="noPaddingRight"
                    >
                      <Box data-tour="outils-videos-button-step">
                        <PreviewTitle title="Highlights vidéos" marginBottom />
                      </Box>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      style={{ marginBottom: '3rem' }}
                    >
                      <PreviewHighlights selectedSport={selectedSport} />
                    </Grid>
                    {(selectedSport === 'FOOTBALL' ||
                      selectedSport === 'TENNIS') && (
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          data-tour="outils-livescore-button-step"
                          width="100%"
                        >
                          <Box>
                            <PreviewTitle title="LiveScore" marginBottom />
                          </Box>
                          {/* <SimpleButtonYellow
                              title={t('de matchs')}
                              addIcon={true}
                              path={`livescore/${selectedSport.toLowerCase()}`}
                            /> */}
                        </Box>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      className="noPaddingRight"
                      style={{ marginBottom: '3rem' }}
                    >
                      {(selectedSport === 'FOOTBALL' ||
                        selectedSport === 'TENNIS') && (
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          width="100%"
                        >
                          {selectedSport === 'FOOTBALL' && (
                            <LiveScoreFootball />
                          )}
                          {selectedSport === 'TENNIS' && <LiveScoreTennis />}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} data-tour="outils-news-step">
                      <PreviewNews sport={selectedSport} />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        </Fade>
      </Box>
    </React.Fragment>
  );
}

export default Dashboard;
