import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import { Fire } from 'services';
import SinglePronoTennisLayout from './SinglePronoTennisLayout/SinglePronoTennisLayout';
import CardPronoTennisFille from './CardPronoTennisFille/CardPronoTennisFille';
import CardInfoMatch from './CardInfoMatch/CardInfoMatch';
import CardPronoTabPanel from './CardPronoTabPanel/CardPronoTabPanel';
import CardOtherProno from './CardOtherProno/CardOtherProno';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { PronoNFL } from '../../../types/pronoNFL';
import SinglePronoPredictions from './SinglePronoPredictions/SinglePronoPredictions';
import { useSelector } from 'react-redux';
import CardBookmakersUS from '../Templates/Cards/CardBookmakersUS';

const SinglePronoNFL: React.FC = () => {
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();
  const [pronos, setPronos] = useState<PronoNFL | null>(null);
  const [otherPronos, setOtherPronos] = useState<PronoNFL[]>([]);
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const smallComputer = useMediaQuery(theme.breakpoints.down('md'));
  const user = useSelector((state: any) => state.authReducer.user);
  const [bestOdd, setBestOdd] = useState<number>(-9999);
  const [bestBookmaker, setBestBookmaker] = useState<string>('');

  const getProno = useCallback(async () => {
    const ref = Fire.store().collection('pronosNFL').doc(id);
    const pronos = await Fire.doc<PronoNFL>(ref);
    setPronos(pronos);
  }, [id]);

  const getOtherPronos = useCallback(async () => {
    const now = new Date();
    now.setHours(now.getHours() - 4);
    const ref = await Fire.store()
      .collection('pronosNFL')
      .where('date', '>=', now)
      .orderBy('date', 'desc')
      .limit(4);
    const data = await Fire.list<PronoNFL>(ref);
    const otherPronos = data
      .filter(prono => prono.id !== id)
      .sort((a, b) => (a.dateHideUntil && !b.dateHideUntil ? 1 : -1));
    setOtherPronos(otherPronos);
  }, [id]);

  useEffect(() => {
    getProno();
    getOtherPronos();
  }, [getProno, getOtherPronos]);

  return (
    <>
      <SinglePronoTennisLayout
        leftChildren={
          <>
            <Grid item xs={12} sm={8}>
              {pronos && <CardPronoTennisFille prono={pronos} />}
            </Grid>
            <Grid item container xs={12} sm={4}>
              {pronos && !mobile && <CardInfoMatch prono={pronos} />}
            </Grid>
            <Grid item xs={12}>
              {pronos && (
                <CardPronoTabPanel
                  prono={pronos}
                  bestOdd={bestOdd}
                  bestBookmaker={bestBookmaker}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {pronos && <SinglePronoPredictions {...pronos} />}
            </Grid>
            {/*{pronos.cotes && (*/}
            {/*  <Grid item xs={12}>*/}
            {/*    <Box*/}
            {/*      marginBottom="1rem"*/}
            {/*      color="white"*/}
            {/*      width="100%"*/}
            {/*      fontSize="1.375rem"*/}
            {/*      fontWeight={600}*/}
            {/*    >*/}
            {/*      Trouvez la meilleure cote en direct*/}
            {/*    </Box>*/}
            {/*    <CardBookmakersTennis prono={pronos} />*/}
            {/*  </Grid>*/}
            {/*)}*/}
            {user.location?.countryCode === 'US' &&
              pronos?.matchOddsUSActive &&
              pronos?.matchOddsUS?.bookmakers && (
                <Grid item xs={12}>
                  <CardBookmakersUS
                    prono={pronos}
                    bestOdd={bestOdd}
                    setBestOdd={setBestOdd}
                    setBestBookmaker={setBestBookmaker}
                  />
                </Grid>
              )}
          </>
        }
        rightChildren={
          <Grid
            container
            item
            xs={12}
            md={3}
            id="childPredRight"
            justify={smallComputer ? 'space-around' : 'flex-start'}
            style={{ height: 'fit-content' }}
          >
            {otherPronos.map(prono => (
              <Grid
                key={prono.id}
                item
                sm={4}
                md={12}
                className="margin-bottom2rem"
              >
                <CardOtherProno prono={prono} />
              </Grid>
            ))}
          </Grid>
        }
      />
    </>
  );
};

export default SinglePronoNFL;
