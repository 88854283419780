import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardGeneral from '../../CardGeneral';
import CardPronoPourcentage from '../../CardPronoPourcentage';
import CardPronoPoints from '../../CardPronoPoints';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    padding: '2rem',
  },
  itemStats: {
    padding: '2rem',
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0',
    },
  },
  title: {
    margin: '.75rem 0 1.5rem 0',
  },
  title2: {
    margin: '4rem 0 1rem 0',
  },
}));

export const SportGPTPredictionsBasket: React.FC<{
  prono: any;
}> = ({ prono }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  const team1_name = t(prono?.player1?.name);
  const team2_name = t(prono?.player2?.name);

  const { i18n } = useTranslation();

  const winnerTeam =
    prono?.basketball?.player1Wins > prono?.basketball?.player2Wins
      ? team1_name
      : team2_name;
  const winnerValue =
    prono?.basketball?.player1Wins > prono?.basketball?.player2Wins
      ? prono?.basketball?.player1Wins
      : prono?.basketball?.player2Wins;

  const loserTeam =
    prono?.basketball?.player1Wins < prono?.basketball?.player2Wins
      ? team1_name
      : team2_name;

  return (
    <>
      <Grid container item xs={12} data-tour="presentation-algo-step">
        <Typography variant="h4" className={classes.title2}>
          {t("Probabilités calculées par l'algorithme :")}
        </Typography>
        <CardGeneral
          blue={!mobile}
          className={classes.itemStats}
          transparent={mobile}
        >
          <Grid
            container
            xs={12}
            justify="space-between"
            className="noMargin"
            spacing={mobile ? 2 : 0}
          >
            <Grid
              item
              xs={12}
              sm={4}
              style={{ paddingRight: mobile ? '.5rem' : '1rem' }}
            >
              <CardPronoPourcentage
                team1={winnerTeam}
                team2={loserTeam}
                value={winnerValue}
                legend1={winnerTeam}
                legend2={loserTeam}
                title={t('Chances de victoire')}
                subtitle={t('pour chaque équipe')}
                singleText={true}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={8}
              style={{ paddingLeft: mobile ? '.5rem' : '1rem' }}
            >
              <CardPronoPoints
                points200={prono?.basketball?.score1}
                points210={prono?.basketball?.score2}
                points220={prono?.basketball?.score3}
                points230={prono?.basketball?.score4}
                points240={prono?.basketball?.score5}
                title={t('Nombre de points dans le match')}
              />
            </Grid>
          </Grid>
        </CardGeneral>
      </Grid>
    </>
  );
};

export default SportGPTPredictionsBasket;
