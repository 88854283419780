import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useTranslation } from 'react-i18next';
import { Fire } from 'services';
import { useAsync2 } from './index';
import { useMemo } from 'react';
import { Bonus } from '../types/bonus';

type UseBonus = {
  bonus: Bonus[];
  loading: boolean;
  error: Error | null;
};

const handleCustomLocation = (
  language: string,
  userCountryCode?: string,
): string => {
  if (language === 'FR') {
    if (userCountryCode === 'BE') return 'BE';
  }
  return language;
};

const useBonus: () => UseBonus = () => {
  const { i18n } = useTranslation();
  const language = i18n.language.toUpperCase();
  const geolocation: any = useSelector(
    (state: RootState) => state.geolocationReducer,
  );
  const bonusLanguage = handleCustomLocation(
    language,
    geolocation?.country_code,
  );

  const request = useMemo(
    () =>
      Fire.list(
        Fire.store()
          .collection('bonus')
          .where('language', '==', bonusLanguage.toLowerCase())
          .where('isHidden', '==', false),
      ),
    [bonusLanguage],
  );
  const {
    data: receivedBonus,
    loading,
    error,
  } = useAsync2<Bonus[], Error>(request, []);

  console.log(receivedBonus);

  const bonus = receivedBonus.sort((a, b) => a.order - b.order);

  return {
    bonus,
    loading,
    error,
  };
};

export default useBonus;
