import React from 'react';
import { useHistory } from 'react-router-dom'
import './NextButton.scss';

import LoggedButton from '../LoggedButton/LoggedButton'

type Props = {
  title?: string;
  disabled?: boolean;
  onPress?: () => void;
}
function NextButton(props: Props) {
  const history = useHistory()

  return (
    <div className="row next-button">
      <LoggedButton
        title={<span><span style={{marginRight: 5}}>{props.title || 'Suivant'}</span> <i className="fa fa-chevron-right"></i></span>}
        classes={['cyan-btn']}
        disabled={props.disabled}
        onPress={props.onPress}
        />
    </div>
  );
}

export default NextButton;
