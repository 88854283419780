import React from 'react';
import Box from '@material-ui/core/Box';
import { Gender } from '../../../../../types/pronoTennis';
import PlayerPhoto from '../../../Templates/Tennis/PlayerPhoto';

const DoublesImages: React.FC<{
  gender: Gender;
  player1Id: string;
  player2Id: string;
}> = ({ gender, player1Id, player2Id }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      width="80px"
    >
      <Box
        width="50%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          borderRadius="50%"
          height="40px"
          width="40px"
          bgcolor="white"
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxShadow="0 0 33px 0 rgb(255 255 255 / 11%)"
        >
          <PlayerPhoto
            playerId={player1Id}
            gender={gender}
            height="100%"
            width="100%"
            style={{ borderRadius: '50%', objectFit: 'cover' }}
          />
        </Box>
      </Box>
      <Box
        width="50%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ transform: 'translate(-40%)' }}
      >
        <Box
          borderRadius="50%"
          height="40px"
          width="40px"
          bgcolor="white"
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxShadow="0 0 33px 0 rgb(255 255 255 / 11%)"
        >
          <PlayerPhoto
            playerId={player2Id}
            gender={gender}
            height="100%"
            width="100%"
            style={{ borderRadius: '50%', objectFit: 'cover' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DoublesImages;
