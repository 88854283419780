import React from 'react';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as PlayBtn } from '../../../../../../images/btn_play_red.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardGeneral from '../../../../Templates/Cards/CardGeneral';
import { LivescoreTennis } from '../../../../../../types/liveScore';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: 'inherit',
      boxShadow: '0 5px 10px 0 rgba(11, 18, 42, .06)',
    },
    content: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      height: '100%',
      padding: '9px !important',
    },
    divImg: {
      backgroundColor: 'white',
      borderRadius: '50%',
      boxShadow: '0 5px 19px 3px rgba(0, 0, 0, 0.15)',
      width: '50px',
      height: '50px',
    },

    matchLogo: {
      width: 'auto',
      maxHeight: '75%',
    },
  }),
);

type CardLivescoreTennisProps = {
  score: LivescoreTennis;
};

const CardLivescoreTennis: React.FC<CardLivescoreTennisProps> = ({ score }) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallComputer = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <CardGeneral
      className={classes.root}
      shadow
      light={theme.palette.type !== 'dark'}
      blue={theme.palette.type === 'dark'}
    >
      <CardContent className={classes.content}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="space-between"
          width="100%"
          height="100%"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            marginBottom={1}
          >
            <Box
              bgcolor="#e93e5519"
              borderRadius={7}
              p="5px"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1" component={'span'}>
                <Box
                  color="#e93e55"
                  fontWeight={600}
                  justifyContent="space-around"
                  alignItems="center"
                  display="flex"
                >
                  <React.Fragment>
                    <PlayBtn
                      fill="red"
                      height="100%"
                      style={{ marginRight: '.5rem' }}
                    />
                    Live
                  </React.Fragment>
                </Box>
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" component={'span'}>
                <Box color="#b7becc" fontWeight="fontWeightSemiBold">
                  {score.city}
                </Box>
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Grid
              container
              direction="column"
              style={{ justifyContent: 'center' }}
              alignItems="center"
            >
              <Grid
                item
                container
                className={classes.divImg}
                alignItems="center"
                style={{ justifyContent: 'center' }}
              >
                <img
                  alt="https://cdn.sportmonks.com/images/soccer/placeholder.png"
                  src="https://cdn.sportmonks.com/images/soccer/placeholder.png"
                  height="100%"
                  style={{ borderRadius: '50%' }}
                />
              </Grid>
              <Typography variant="body2" component="span">
                <Box
                  color={theme.palette.text.primary}
                  fontWeight="fontWeightSemiBold"
                  marginTop={1}
                >
                  {score.players[0].name}
                </Box>
              </Typography>
            </Grid>
            <Grid
              container
              style={{ justifyContent: 'center' }}
              alignItems="center"
            >
              <Box
                bgcolor="#192454"
                borderRadius={25}
                padding="6px 9px"
                width="fit-contant"
                height="fit-content"
              >
                <Typography
                  variant={smallComputer ? (mobile ? 'h3' : 'h6') : 'h5'}
                  style={{
                    fontSize: '0.8rem',
                    lineHeight: 'unset',
                  }}
                  component="span"
                >
                  {score.scores?.map((score, index) => (
                    <Box
                      fontWeight="fontWeightBold"
                      color={theme.palette.text.primary}
                      key={`score_${index}_${score[0]}_${score[1]}`}
                    >
                      {score[0]} : {score[1]}
                    </Box>
                  ))}
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              direction="column"
              style={{ justifyContent: 'center' }}
              alignItems="center"
            >
              <Grid
                item
                container
                className={classes.divImg}
                alignItems="center"
                style={{ justifyContent: 'center' }}
              >
                <img
                  alt="https://cdn.sportmonks.com/images/soccer/placeholder.png"
                  src="https://cdn.sportmonks.com/images/soccer/placeholder.png"
                  height="100%"
                  style={{ borderRadius: '50%' }}
                />
              </Grid>
              <Typography variant="body2" component="span">
                <Box
                  color={theme.palette.text.primary}
                  fontWeight="fontWeightSemiBold"
                  marginTop={1}
                >
                  {score.players[1].name}
                </Box>
              </Typography>
            </Grid>
          </Box>
        </Box>
      </CardContent>
    </CardGeneral>
  );
};

export default CardLivescoreTennis;
