import React from 'react';
import './ExternPaymentLoading.scss';
import datawin_logo from '../../../../images/logo_datawin.png';

type Props = {
  nobg?: boolean;
};

function ExternPaymentLoading(props: Props) {
  return (
    <div className={!props.nobg ? 'loader_background' : 'loader_no-background'}>
      <img src={datawin_logo} alt="" className="logo_loading" />
      <div className="loader" />
      <div
        style={{
          position: 'absolute',
          marginTop: '300px',
          color: 'antiquewhite',
          fontSize: '17px',
        }}
      >
        Inscription en cours... Ne fermez pas cette fenêtre.
      </div>
    </div>
  );
}

export default ExternPaymentLoading;
