import { LocalConvenienceStoreOutlined } from '@material-ui/icons';
import { enableBodyScroll } from 'body-scroll-lock';

const enableBody = target => enableBodyScroll(target);

export const disableTour = async (
  info,
  setIsTourOpen,
  dispatch,
  updateUser,
  e,
) => {
  enableBody(e);
  const isFirstConnexion = {
    home1: false,
    home2: false,
    pronos: false,
    prono: false,
    player: false,
  };
  const payload = {
    ...info,
    isFirstConnexion,
  };
  await dispatch(updateUser(payload));
  setIsTourOpen(false);
};

export const activateNextTour = async (
  info,
  setIsTourOpen,
  dispatch,
  updateUser,
  history,
  pageToDisable,
  location,
) => {
  const isFirstConnexion = {
    home1: true,
    home2: true,
    pronos: true,
    prono: true,
    player: true,
    ...pageToDisable,
  };
  const payload = {
    ...info,
    isFirstConnexion: {
      ...isFirstConnexion,
    },
  };
  await dispatch(updateUser(payload));
  setIsTourOpen(false);
  history.push(location);
};
