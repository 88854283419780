import {
  CompetitorsEntityOrValue,
  DoubleCompetitorsEntity,
  PronoTennisCardPlayer,
  TennisMatchType,
} from '../../../types/pronoTennis';

export const getCardPlayers = (
  competitors: CompetitorsEntityOrValue[] | any,
  matchType: TennisMatchType,
): PronoTennisCardPlayer[] => {
  if (matchType === 'singles') {
    return competitors.map(({ name, id, value }) => {
      const [lastName, firstName] = (name || value.name)
        .replace(',', '')
        .split(' ')
        .map(name => name.trim());
      const fullName = firstName + ' ' + lastName;
      return {
        id,
        firstName,
        lastName,
        fullName,
        abbreviation: firstName.charAt(0) + '. ' + lastName,
      };
    });
  }
  return competitors.map(({ name, id }) => {
    return {
      id,
      firstName: name,
      lastName: name,
    };
  });
};

export const getDoublesPlayersNames = (
  competitors: DoubleCompetitorsEntity[],
): PronoTennisCardPlayer[][] => {
  return competitors.map(({ players }) =>
    players.map(({ name, id }) => {
      const [lastName, firstName] = name
        .replace(',', '')
        .split(' ')
        .map(name => name.trim());
      const fullName = firstName + ' ' + lastName;
      return {
        id,
        firstName,
        lastName,
        fullName,
      };
    }),
  );
};
