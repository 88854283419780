import React from 'react';
import './Loading.scss';
import datawin_logo from '../../images/logo_datawin.png'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Theme, useTheme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        small: {
        }
    })
)

type Props = {
    nobg?: boolean;
}

function Loading(props: Props) {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <div className={!props.nobg ? "loader_background" : "loader_no-background"}>
            <img src={datawin_logo} alt="" className="logo_loading"></img>
            <div className="loader"></div>
        </div>
    );
}

export default Loading;
