import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CardLivescoreFootball from './CardLiveScoreFootball/CardLivescoreFootball';
import CardGeneral from '../../../Templates/Cards/CardGeneral';
import Typography from '@material-ui/core/Typography';
import Loading from '../../../../Loading/Loading';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Fire } from 'services';
import {
  MatchSummary,
  MatchSummaryWithLogo,
} from '../../../../../types/sportradar/soccer/sport-event';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      flexDirection: 'row',
    },
    card: {
      width: '100%',
      flexBasis: '100%',
    },
  }),
);

export default function LiveScoreFootball() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: matches, isLoading } = useQuery(
    'soccer:live-matches',
    () =>
      Fire.cloud<MatchSummaryWithLogo[]>('soccer', {
        method: 'getLiveMatchSummaries',
      }),
    { staleTime: 900000 /* 15 mn */ },
  );

  if (isLoading) return <Loading nobg />;

  return (
    <React.Fragment>
      <Grid
        container
        className={classes.root}
        spacing={2}
        style={{ justifyContent: 'space-between' }}
        wrap="nowrap"
        data-tour="outils-livescore-step"
      >
        {matches?.length ? (
          matches.slice(0, 3).map((match, index) => (
            <Grid
              container
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              style={{ justifyContent: 'center' }}
              alignItems={'flex-start'}
            >
              <CardLivescoreFootball
                status={match.sport_event_status.status}
                homeScore={match.sport_event_status.home_score}
                awayScore={match.sport_event_status.away_score}
                date={match.sport_event.start_time}
                homeTeamName={match.sport_event.competitors[0].name}
                awayTeamName={match.sport_event.competitors[1].name}
                homeTeamLogo={match.homeTeamLogo}
                awayTeamLogo={match.awayTeamLogo}
              />
            </Grid>
          ))
        ) : (
          <Box
            height="33.33%"
            width="80%"
            margin="auto"
            display="flex"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            color="white"
          >
            <Typography variant="h4">
              {t('Pas de match en live pour le moment')}
            </Typography>
          </Box>
        )}
      </Grid>
    </React.Fragment>
  );
}
