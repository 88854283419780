import React from 'react';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Gender } from '../../../../../../types/pronoTennis';
import PlayerPhoto from '../../../Tennis/PlayerPhoto';

const useStyles = makeStyles(() =>
  createStyles({
    grayScale: {
      filter: 'grayscale(1)',
    },
    backgroundGrey: {
      background: '#d2d2d2',
    },
  }),
);

type SinglesImagesProps = {
  future: boolean;
  mobile: boolean;
  gender: Gender;
  playerId: string;
  width?: string;
  small?: boolean;
};

const SinglesImages: React.FC<SinglesImagesProps> = ({
  future,
  mobile,
  gender,
  playerId,
  width = '33%',
  small = false,
}) => {
  const classes = useStyles();
  return (
    <Box
      width={width}
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginTop={!mobile && '10px'}
    >
      <Box
        borderRadius="50%"
        height={small ? '30px' : '50px'}
        width={small ? '30px' : '50px'}
        bgcolor="white"
        display="flex"
        justifyContent="center"
        overflow="hidden"
        alignItems="center"
        boxShadow={future ? '' : '0 0 33px 0 rgb(0 0 0 / 11%)'}
        className={future ? classes.backgroundGrey : ''}
      >
        <PlayerPhoto
          playerId={playerId}
          gender={gender}
          className={future ? classes.grayScale : ''}
          style={{
            width: '100%',
            borderRadius: '50%',
          }}
        />
      </Box>
    </Box>
  );
};

export default SinglesImages;
