import React from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import '../../../../theme/datawinTheme.css';
import { useTranslation } from 'react-i18next';
import BorderLinearProgress from '../Progress/BorderLinearProgress';
import { getNFLLogoURL } from '../../../../utils/nfl';
import Tooltip from '@material-ui/core/Tooltip';
import Logo from '../Logos/Logo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    redBox: {
      padding: '.5rem 0',
      border: 'solid 2px rgba(255, 0, 0, .75)',
      borderRadius: '1.5rem',

      [theme.breakpoints.down('xs')]: {
        padding: '1rem',
      },
    },
  }),
);

export default function RowNFLHiddenPrediction({ prono }) {
  const { dateHideUntil, round } = prono;
  const theme = useTheme();
  const smallComputer = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const { i18n } = useTranslation();
  const language = i18n.language.toUpperCase();

  const canceled =
    !prono.winner &&
    (!dateHideUntil || dateHideUntil.seconds <= new Date().getTime() / 1000);

  return (
    <Tooltip title={canceled ? "Match non choisi par l'algorithme" : ''} arrow>
      <Grid
        container
        style={{ color: 'white' }}
        className={canceled ? classes.redBox : ''}
      >
        <Grid
          container
          item
          xs={12}
          sm={6}
          md={6}
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={4}>
            <Typography
              variant={smallComputer ? 'h6' : 'body1'}
              component={'span'}
            >
              <Box fontWeight={600} paddingLeft="1rem">
                {prono.match.value.home?.name}
              </Box>
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={4}
            alignItems="center"
            wrap="nowrap"
            justify="space-around"
          >
            <Box
              style={{ transform: 'translate(-10%, 0)' }}
              borderRadius="50%"
              height={smallComputer ? (mobile ? '30px' : '35px') : '50px'}
              width={smallComputer ? (mobile ? '30px' : '35px') : '50px'}
              bgcolor="white"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Logo src={getNFLLogoURL(prono.match.value.home.id)} club />
            </Box>
            <Box
              style={{ transform: 'translate(10%, 0)' }}
              borderRadius="50%"
              height={smallComputer ? (mobile ? '30px' : '35px') : '50px'}
              width={smallComputer ? (mobile ? '30px' : '35px') : '50px'}
              bgcolor="white"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Logo src={getNFLLogoURL(prono.match.value.away.id)} club />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant={smallComputer ? 'h6' : 'body1'}
              component={'span'}
            >
              <Box fontWeight={600} textAlign="end">
                {prono.match.value.away?.name}
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={5}
          sm={3}
          md={3}
          alignItems="center"
          justify="center"
        >
          <Typography
            variant={smallComputer ? 'h6' : 'body1'}
            component={'span'}
          >
            <Box fontWeight={600} justifyContent="center" textAlign="center">
              {round?.[language] || round}
            </Box>
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={7}
          sm={3}
          md={3}
          alignItems="center"
          style={{ margin: mobile ? '2rem auto 1rem' : 'auto' }}
        >
          <Box
            height="80%"
            maxWidth="285px"
            width="95%"
            margin="auto"
            borderRadius="7px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding={mobile ? '.75rem' : '.5rem 1rem'}
            position="relative"
          >
            <BorderLinearProgress hide_until={dateHideUntil} red={canceled} />
          </Box>
        </Grid>
      </Grid>
    </Tooltip>
  );
}
