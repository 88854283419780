import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  useTheme,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardGeneral from '../CardGeneral';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ButtonFilled from '../../Buttons/ButtonFilled';
import { Time } from 'services';
import clsx from 'clsx';
import '../../../../../theme/datawinTheme.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import backgroundNFL from 'images/backgroundNFL.svg';
import backgroundNFL_Dark from 'images/backgroundNFL_Dark.svg';
import BorderLinearProgress from '../../Progress/BorderLinearProgress';
import Logo from '../../Logos/Logo';
import { isTooLong } from '../../../../../utils/logic';
import { getNFLLogoURL } from '../../../../../utils/nfl';
import { getInfosPronoLibre } from '../../../../../utils/pronoLibreNFL';
import { capitalize } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '20rem',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        height: '26rem',
      },
    },
    content: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      height: '100%',
      minHeight: '15rem',
    },
    backgroundNFL: {
      background: `url(${backgroundNFL}) no-repeat top right`,
      backgroundColor: '#dadde5',
    },
    backgroundNFL_Dark: {
      background: `url(${backgroundNFL_Dark}) no-repeat top right`,
      backgroundColor: 'rgba(91, 94, 105, .85)',
    },
    backgroundGrey: {
      background: '#d2d2d2',
    },
    greenShadow: {
      boxShadow: '0px 5px 17px 1px #04ff0063 !important',
    },
    orangeShadow: {
      boxShadow: '0px 5px 17px 1px #ffbc006b !important',
    },
    greenText: {
      color: '#10ff00',
    },
    orangeText: {
      color: '#ffb000b5',
    },
  }),
);

export default function CardPredictionNFL(props) {
  const {
    round,
    match,
    date,
    blue,
    future,
    id,
    hide_until,
    isPronoTest,
    name_inactive,
  } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const isDark = theme.palette.type === 'dark';
  const [mainPronoName, setMainPronoName] = React.useState<string>('');
  const [percentage, setPercentage] = React.useState<number>(0);

  const getMainPronoName = () => {
    const pronoNames = getInfosPronoLibre(props, t);
    setMainPronoName(pronoNames.pronoName);
    setPercentage(pronoNames.pronoPercentage);
  };

  React.useEffect(() => {
    getMainPronoName();
  }, [getMainPronoName]);

  const language = i18n.language.toUpperCase();

  if (!match?.value?.home) return <></>;

  return (
    <CardGeneral
      className={clsx(
        classes.card,
        !future && !name_inactive && percentage >= 50 && classes.greenShadow,
        !future &&
          !name_inactive &&
          percentage > 0 &&
          percentage < 50 &&
          classes.orangeShadow,
        future && (isDark ? classes.backgroundNFL_Dark : classes.backgroundNFL),
      )}
      blue={blue}
      cursorPointer={!future}
      shadow={!future}
      onClick={
        !future
          ? () => {
              history.push('/pronos_nfl/' + id);
            }
          : undefined
      }
    >
      <CardContent className={classes.content}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
        >
          {isPronoTest && (
            <Box
              position="absolute"
              top="0"
              right="0"
              fontSize="12px"
              color="#ff3f3f"
              fontWeight={600}
              style={{ transform: 'translate(0, -100%)' }}
            >
              <span>Prono {name_inactive ? 'f' : 'test'}</span>
            </Box>
          )}
          <Box height="10%" width="100%">
            <Box
              width="100%"
              height="100%"
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              fontWeight={600}
              justifyContent="space-between"
            >
              <Box color="#838ca6" textAlign="left" fontSize=".7rem">
                <Typography variant={mobile ? 'body1' : 'body2'}>
                  {Time.getDateFromSeconds(date?.seconds)}
                </Typography>
              </Box>
              <Box color="#838ca6" textAlign="right" fontWeight="600">
                <Typography
                  variant={mobile ? 'body1' : 'body2'}
                  component="span"
                >
                  {/* {!future && percentage >= 50 && (
                    <Box className={classes.greenText} fontWeight={600}>
                      {t('Risque faible')}
                    </Box>
                  )} */}
                  {!future && percentage < 50 && percentage > 0 && (
                    <Box className={classes.orangeText} fontWeight={600}>
                      {t('Risque élevé')}
                    </Box>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          {future ? (
            <Box height="20%" width="100%">
              <Box
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
              >
                <Box
                  color="#838ca6"
                  textAlign="center"
                  fontWeight="600"
                  width="100%"
                  height="50%"
                >
                  <Typography variant={mobile ? 'body1' : 'body2'}>
                    {round?.[language] ||
                      (typeof round === 'string' ? round : '-')}
                  </Typography>
                </Box>
                <Box
                  color={blue ? '#fff' : theme.palette.text.primary}
                  textAlign="center"
                  width="100%"
                  height="50%"
                >
                  <Typography
                    variant={mobile ? 'h3' : 'h4'}
                    style={mobile ? {} : { fontSize: '1.24rem' }}
                  >
                    {t('Prochaine analyse')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box height="20%" width="100%">
              <Box
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box
                  color={blue ? '#fff' : theme.palette.text.primary}
                  textAlign="center"
                  width="100%"
                >
                  <Typography
                    variant={mobile ? 'h3' : 'h4'}
                    style={mobile ? {} : { fontSize: '1.24rem' }}
                  >
                    {capitalize(mainPronoName.trim())}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box height="45%" width="100%">
            <Box width="100%" height="60%" display="flex">
              <Box
                width="33%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  borderRadius="50%"
                  height="50px"
                  width="50px"
                  bgcolor="white"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  boxShadow={future ? '' : '0 0 33px 0 rgb(0 0 0 / 11%)'}
                  className={future ? classes.backgroundGrey : ''}
                >
                  <Logo
                    src={getNFLLogoURL(match.value.home.id)}
                    grey={future}
                    club
                    noBg
                  />
                </Box>
              </Box>
              <Box
                width="33%"
                height="100%"
                color="#838ca6"
                textAlign="center"
                alignItems="center"
                display="flex"
                justifyContent="center"
                fontWeight="600"
              >
                <Typography variant={mobile ? 'body1' : 'body2'}>
                  {round?.[language] || round?.name}
                </Typography>
              </Box>
              <Box
                width="33%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  borderRadius="50%"
                  height="50px"
                  width="50px"
                  bgcolor="white"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  boxShadow={future ? '' : '0 0 33px 0 rgb(0 0 0 / 11%)'}
                  className={future ? classes.backgroundGrey : ''}
                >
                  <Logo
                    src={getNFLLogoURL(match.value.away.id)}
                    grey={future}
                    club
                  />
                </Box>
              </Box>
            </Box>
            <Box width="100%" height="40%" display="flex">
              <Box
                width="33%"
                height="100%"
                color={blue ? '#fff' : theme.palette.text.primary}
                display="flex"
                textAlign="center"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant={mobile ? 'h6' : 'body1'}
                  component={'span'}
                >
                  <Box fontWeight="600">
                    {isTooLong(match.value.home.name, 20, 3)
                      ? match.value.home.name
                      : match.value.home.label}
                  </Box>
                </Typography>
              </Box>
              <Box width="33%" height="100%"></Box>
              <Box
                width="33%"
                height="100%"
                color={blue ? '#fff' : theme.palette.text.primary}
                display="flex"
                textAlign="center"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant={mobile ? 'h6' : 'body1'}
                  component={'span'}
                >
                  <Box fontWeight="600">
                    {isTooLong(match.value.away.name, 20, 3)
                      ? match.value.away.name
                      : match.value.away.label}
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            height="20%"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {future ? (
              <BorderLinearProgress hide_until={hide_until} />
            ) : (
              <ButtonFilled title="Voir les statistiques" blue={blue} medium />
            )}
          </Box>
        </Box>
      </CardContent>
    </CardGeneral>
  );
}
