import { isTooLong } from './logic';
import { matchTypeValueType } from '../types/pronoLibre';

export const matchTypes = {
  byWin: 'vainqueur',
  byQualification: 'se qualifie',
  byCompetitionWin: 'remporte le trophée',
  byWinOrDraw: 'vainqueur ou match nul',
  byDraw: 'Match nul',
  byBothGoals: 'Les deux équipes marquent : oui',
  byBothNoGoals: 'Les deux équipes marquent : non',
  byMoreGoals: 'plus de 2,5 buts',
  byLessGoals: 'moins de 2,5 buts',
  byScorer: 'marque',
  byWinAndGoals: 'vainqueur',
  byWinAndGoalsAhead: 'vainqueur',
  byWinAndNoGoal: 'vainqueur',
};

export type matchTypesType =
  | 'byWin'
  | 'byQualification'
  | 'byCompetitionWin'
  | 'byWinOrDraw'
  | 'byDraw'
  | 'byBothGoals'
  | 'byBothNoGoals'
  | 'byMoreGoals'
  | 'byLessGoals'
  | 'byScorer'
  | 'byWinAndGoals'
  | 'byWinAndGoalsAhead'
  | 'byWinAndNoGoal';

export const getInfosPronoLibre = (props, t) => {
  console.log(props);
  const team1_name = t(props.team1?.name || '');
  const team1_label = t(props.team1?.name || '');
  const matchType: matchTypesType = props.matchType;
  const matchTypeValue: matchTypeValueType = props.matchTypeValue;

  const pronoPercentage: number = parseInt(
    props.name_inactive
      ? 100
      : matchType === 'byWin'
      ? props.end_results.winner
      : matchType === 'byWinOrDraw'
      ? parseInt(props.end_results.winner) + parseInt(props.end_results.equal)
      : matchType === 'byDraw'
      ? props.end_results.equal
      : matchType === 'byBothGoals'
      ? props.both_teams_scored
      : matchType === 'byBothNoGoals'
      ? 100 - props.both_teams_scored
      : matchType === 'byMoreGoals'
      ? props.goals.superior_2
      : matchType === 'byLessGoals'
      ? 100 - parseInt(props.goals.superior_2)
      : matchTypeValue?.percentage,
  );

  const teamName: string | undefined =
    (matchType === 'byWin' ||
      matchType === 'byWinOrDraw' ||
      matchType === 'byQualification' ||
      matchType === 'byCompetitionWin' ||
      matchType === 'byWinAndGoals' ||
      matchType === 'byWinAndGoalsAhead' ||
      matchType === 'byWinAndNoGoal') &&
    team1_name
      ? isTooLong(team1_name)
        ? team1_label
        : team1_name
      : matchType === 'byScorer'
      ? matchTypeValue?.name
      : '';

  const pronoName: string =
    t(teamName || '') +
    ' ' +
    t(matchTypes[matchType]) +
    (matchType === 'byWinAndNoGoal'
      ? ' ' + t('sans encaisser de but')
      : matchType === 'byWinAndGoals'
      ? ' ' + t('et plus de') + ' ' + matchTypeValue?.name + ' ' + t('buts')
      : matchType === 'byWinAndGoalsAhead'
      ? ' ' + t('avec') + ' ' + matchTypeValue?.name + ' ' + t("buts d'avance")
      : '');
  const pronoNameWithPercentage: string =
    pronoName + ' ' + pronoPercentage + '%';

  return {
    pronoName,
    pronoNameWithPercentage,
    pronoPercentage,
  };
};
