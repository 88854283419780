import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CardGeneral from './CardGeneral';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '../Progress/CircularProgress';
import BorderLinearProgress from '../Progress/BorderLinearProgress';
import '../../../../theme/datawinTheme.css';
import { useTranslation } from 'react-i18next';
import { Time } from 'services';
import { useHistory } from 'react-router-dom';
import Logo from '../Logos/Logo';
import { useMediaQuery } from '@material-ui/core';
import { getInfosPronoLibre } from '../../../../utils/pronoLibreSoccer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
    },
    content: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      height: '100%',
      padding: '1rem !important',
    },
    rowMatch: {
      borderRadius: 10,
      padding: '1rem 0',
      cursor: 'pointer',
      transition: 'all .2s ease-out',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
);

interface Props {
  prono: any;
}

export default function CardOtherPrediction(props: Props) {
  // const { fiability, date, league, team1, team2, team1Score, team2Score, team1Flag, team2Flag } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const {
    domicile,
    ext,
    end_results,
    team2Logo,
    value,
    hide_until,
    team1,
    ...prono
  } = props.prono;
  const [mainPronoName, setMainPronoName] =
    React.useState<string>('Autre analyse');
  const [percentage, setPercentage] = React.useState<number>();
  const now = new Date().getTime() / 1000;
  const canceled =
    !team1 &&
    (!hide_until || hide_until?.seconds <= new Date().getTime() / 1000);
  const future = (hide_until && hide_until?.seconds > now) || canceled;

  const getPronoInfos = () => {
    const { pronoName, pronoPercentage } = getInfosPronoLibre(props.prono, t);
    setMainPronoName(pronoName);
    setPercentage(pronoPercentage);
  };

  React.useEffect(() => {
    if (!future && !canceled) getPronoInfos();
  }, [props]);

  const title = t(future ? 'Future analyse' : mainPronoName);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles();

  return (
    <CardGeneral
      className={classes.card}
      blue
      cursorPointer
      shadow
      onClick={
        !future
          ? () => {
              history.push('/pronos/' + prono.id);
            }
          : () => {
              history.push('/pronos');
            }
      }
    >
      <CardContent className={classes.content}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="space-between"
            justifyContent="center"
            marginBottom="17"
          >
            <Typography variant="h5" component={'span'}>
              <Box
                color="white"
                fontWeight="600"
                marginBottom=".5rem"
                textAlign="center"
              >
                {t(title)}
              </Box>
            </Typography>
          </Box>
          {future ? (
            <Box width="100%" minHeight="20px" margin="2rem 0 1rem">
              <BorderLinearProgress hide_until={hide_until} r red={canceled} />
            </Box>
          ) : (
            <Box width="100%" minHeight="150px" margin="1rem 0">
              <CircularProgress
                value={percentage}
                verySmall
                text1Thin
                text1={Time.getDateFromSeconds(prono.date.seconds)}
                text2={`${percentage}%`}
              />
            </Box>
          )}
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            color="white"
            className={classes.rowMatch}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="space-around"
              style={{ marginBottom: '.75rem' }}
            >
              <Grid container item xs={5} alignItems="center" justify="center">
                <Logo
                  src={domicile.logo}
                  width={40}
                  club={domicile.country !== domicile.name}
                />
              </Grid>
              <Grid container item xs={5} alignItems="center" justify="center">
                <Logo
                  src={ext.logo}
                  club={ext.country !== ext.name}
                  width={40}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} justify="space-around">
              <Grid
                container
                item
                xs={5}
                alignItems="flex-start"
                justify="center"
              >
                <Box fontWeight={600} textAlign="center">
                  <Typography variant="body2">{t(domicile.name)}</Typography>
                </Box>
              </Grid>
              <Grid
                container
                item
                xs={5}
                alignItems="flex-start"
                justify="center"
              >
                <Box fontWeight={600} textAlign="center">
                  <Typography variant="body2">{t(ext.name)}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CardContent>
    </CardGeneral>
  );
}
