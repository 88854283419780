import React from 'react';
import './PrivacyScreen.scss';

import { HeaderBar, Footer } from '../Reusable';
import i18n from '../../i18n';

function PrivacyScreen() {
  // const ref = Fire.store().collection('privacy')
  // const [privacy, loading, error] = useAsync(Fire.list(ref), [])

  if (i18n.language === 'en')
    return (
      <div className="privacy-screen">
        <HeaderBar />

        <div className="privacy-title top-padding"></div>
        <div className="options col-center">
          <div className="content">
            <h1>Privacy Policy and Cookie Policy</h1>
            <br />
            <br />
            <h2>1. Introduction</h2>
            <br />
            <br />
            This is our privacy policy. It tells you how we collect and process
            data received from you on our site. Please read the following
            carefully to understand our views and practices regarding your
            personal data and how we will treat it.&nbsp;
            <br />
            If you have any comments on this privacy policy, please email them
            to{' '}
            <a href="mailto:support@datawin-prediction.com">
              <b>support@datawin-prediction.com</b>
            </a>
            <h2>&nbsp;</h2>
            <br />
            <br />
            <h2>2. Who We Are</h2>
            <br />
            <br />
            Here are the details that the Regulation (EU) 2016/679 of the
            European Parliament and of the Council of 27 April 2016 on the
            protection of natural persons with regards to the processing of
            personal data and on the free movement of such data, known as
            General Data Protection Regulation (<b>GDPR</b>) says we have to
            give you as a ‘data controller’:
            <br />
            <ul>
              <li>
                Our site address is <b>datawin.io</b>
              </li>
              <li>
                Our company name is <b>DATAWIN</b>
              </li>
              <li>
                Our registered address is 219 Kensington High Street,
                Kensington, London, England, W8 6BD
              </li>
              <li>
                Our [nominated representative or Data Protection Officer] can be
                contacted at <b>support@datawin-prediction.com</b>
              </li>
            </ul>
            <br />
            <br />
            <br />
            <br />
            <h2>3. What we may collect</h2>
            <br />
            <br />
            We may collect and process the following data about you:
            <br />
            <br />
            <ul>
              <li>
                Information you put into forms or surveys on our site at any
                time
              </li>
              <li>A record of any correspondence between us</li>
              <li>Details of transactions you carry out through our site</li>
              <li>
                Details of your visits to our site and the resources you use
              </li>
              <li>
                Information about your computer (e.g. your IP address, browser,
                operating system, etc.) for system administration and to report
                aggregate information to our advertisers
              </li>
            </ul>
            Under GDPR we will ensure that your personal data is processed
            lawfully, fairly, and transparently, without adversely affecting
            your rights. We will only process your personal data if at least one
            of the following basis applies:
            <br />
            <ol>
              <li>
                a) you have given consent to the processing of your personal
                data for one or more specific purposes;
              </li>
              <li>
                b) processing is necessary for the performance of a contract to
                which you are a party or in order to take steps at the request
                of you prior to entering into a contract;
              </li>
              <li>
                c) processing is necessary for compliance with a legal
                obligation to which we are subject;
              </li>
              <li>
                d) processing is necessary to protect the vital interests of you
                or of another natural person;
              </li>
              <li>
                e) processing is necessary for the performance of a task carried
                out in the public interest or in the exercise of official
                authority vested in the controller; and/or
              </li>
              <li>
                f) processing is necessary for the purposes of the legitimate
                interests pursued by us or by a third party such as our credit
                card payment processing, except where such interests are
                overridden by the fundamental rights and freedoms of the data
                subject which require protection of personal data, in particular
                where the data subject is a child.
              </li>
            </ol>
            <br />
            <br />
            <br />
            <br />
            <h2>4. Cookies</h2>
            <br />
            <br />
            [All Cookies used by and on our website are used in accordance with
            current English and EU Cookie Law.]
            <br />
            The site uses cookies or similar technology to collect information
            about your access to the site. Cookies are pieces of information
            that include a unique reference code that a website transfers to
            your device to store and sometimes track information about
            you.&nbsp;
            <br />
            A few of the cookies we use last only for the duration of your web
            session and expire when you close your browser. Other cookies are
            used to remember you when you return to the site and will last for
            longer.
            <br />
            <b>All cookies used on our site are set by us.</b>
            <br />
            Most computer and some mobile web browsers automatically accept
            cookies but, if you prefer, you can change your browser to prevent
            that or to notify you each time a cookie is set. You can prevent the
            setting of cookies by adjusting the settings on your browser. Please
            note however, that by blocking or deleting cookies you may not be
            able to take full advantage of the site.
            <br />
            <b>Our cookies will be used for:</b>
            <br />
            Essential session management
            <br />
            <ul>
              <li>
                creating a specific log-in session for a user of the site in
                order that the site remembers that a user is logged in and that
                their page requests are delivered in an effective, secure and
                consistent manner;
              </li>
              <li>
                recognising when a user of the site has visited before allowing
                us to identify the number of unique users we receive to the site
                and make sure we have enough capacity for the number of users
                that we get;
              </li>
              <li>
                recognising if a visitor to the site is registered with us in
                any way;
              </li>
              <li>
                we may also log information from your computer including the
                existence of cookies, your IP address and information about your
                browser program in order to allow us to diagnose problems,
                administer and track your usage of our site.
              </li>
            </ul>
            Functionality
            <br />
            <ul>
              <li>
                customising elements of the promotional layout and/or content of
                the pages of the site.
              </li>
            </ul>
            Performance and measurement
            <br />
            <ul>
              <li>
                collecting statistical information about how our users use the
                site so that we can improve the site and learn which parts are
                most popular to users.]
              </li>
            </ul>
            <b>OR</b>
            <br />
            [We use cookies to distinguish users and improve our site. Please
            look at our Cookie Policy{' '}
            <a href="https://datawin.io">
              <b>datawin.io</b>
            </a>{' '}
            for more cookie information.]
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>5. How we use what we collect</h2>
            <br />
            <br />
            We use information about you to:
            <br />
            <ul>
              <li>Present site content effectively to you.</li>
              <li>
                Provide information, products and services that you request, or
                (with your consent) which we think may interest you.
              </li>
              <li>Carry out our contracts with you.</li>
              <li>Allow you to use our interactive services if you want to.</li>
              <li>Tell you our charges.</li>
              <li>
                Tell you about other goods and services that might interest you.
                We will also let other people do this, and we (or they) may
                contact you.
              </li>
            </ul>
            If you are already our customer, we will only contact you
            electronically about things similar to what was previously sold to
            you.
            <br />
            If you are a new customer, you will only be contacted if you agree
            to it.
            <br />
            If you don’t want to be contacted for marketing purposes, please
            tick the relevant box that you will find on screen.&nbsp;
            <br />
            Please note: We don’t identify individuals to our advertisers, but
            we do give them aggregate information to help them reach their
            target audience, and we may use information we have collected to
            display advertisements to that audience.
            <br />
            In addition, if you don’t want us to use your personal data for any
            of the other reasons set out in this section in 5, you can let us
            know at any time by contacting us at [Email Address], and we will
            delete your data from our systems. However, you acknowledge this
            will limit our ability to provide the best possible [products and]
            services to you.
            <br />
            In some cases, the collection of personal data may be a statutory or
            contractual requirement, and we will be limited in the [products
            and] services we can provide you if you don’t provide your personal
            data in these cases.
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>6. Where we store your data</h2>
            <br />
            <br />
            We may transfer your collected data to storage outside the European
            Economic Area (EEA). It may be processed outside the EEA to fulfil
            your order and deal with payment.
            <br />
            By giving us your personal data, you agree to this arrangement. We
            will do what we reasonably can to keep your data secure.
            <br />
            Payment will be encrypted. If we give you a password, you must keep
            it confidential. Please don’t share it. Although we try to provide
            protection, we cannot guarantee complete security for your data, and
            you take the risk that any sending of that data turns out to be not
            secure despite our efforts.
            <br />
            We only keep your personal data for as long as we need to in order
            to use it as described above in section 5, and/or for as long as we
            have your permission to keep it. In any event, we will conduct an
            [annual] review to ascertain whether we need to keep your personal
            data. Your personal data will be deleted if we no longer need it.
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>7. Your rights</h2>
            <br />
            <br />
            You can ask us not to use your data for marketing. You can do this
            by ticking the relevant boxes on our forms, or by contacting us at
            any time at 219 Kensington High Street, Kensington, London, England,
            W8 6BD / support@datawin-prediction.com
            <br />
            Under the GDPR, you have the right to:
            <br />
            <ul>
              <li>
                request access to, deletion of or correction of, your personal
                data held by us at no cost to you;&nbsp;
              </li>
              <li>
                request that your personal data be transferred to another person
                (data portability);&nbsp;
              </li>
              <li>be informed of what data processing is taking place;</li>
              <li>restrict processing;</li>
              <li>to object to processing of your personal data; and</li>
              <li>complain to a supervisory authority.</li>
            </ul>
            <br />
            You also have rights with respect to automated decision-making and
            profiling as set out in section 11 below.&nbsp;
            <br />
            To enforce any of the foregoing rights or if you have any other
            questions about our site or this Privacy Policy, please contact us
            at [support@datawin-prediction.com].
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>9. Links to other sites</h2>
            <br />
            <br />
            Please note that our privacy and conditions and our policies will
            not apply to other websites that you get to via a link from our
            site. We have no control over how your data is collected, stored or
            used by other websites and we advise you to check the privacy
            policies of any such websites before providing any data to them.
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>10. Changes</h2>
            <br />
            <br />
            If we change our Privacy Policy, we will post the changes on this
            page. If we decide to, we may also email you.
            <br />
            <br />
            <ol>
              <li>
                <b>Automated Decision-Making and Profiling</b>
              </li>
            </ol>
            11. 1 In the event that we use personal data for the purposes of
            automated decision-making and those decisions have a legal (or
            similarly significant effect) on you, you have the right to
            challenge to such decisions under GDPR, requesting human
            intervention, expressing their own point of view, and obtaining an
            explanation of the decision from us.
            <br />
            11. 2 The right described in section 11. 1 does not apply in the
            following circumstances:
            <br />
            <ol>
              <li>
                a) the decision is necessary for the entry into, or performance
                of, a contract between the you and us;
              </li>
              <li>b) the decision is authorised by law; or</li>
              <li>c) you have given you explicit consent.</li>
            </ol>
            11. 3 Where we use your personal data for profiling purposes, the
            following shall apply:
            <br />
            <ol>
              <li>
                a) Clear information explaining the profiling will be provided,
                including its significance and the likely consequences;
              </li>
              <li>
                b) Appropriate mathematical or statistical procedures will be
                used;
              </li>
              <li>
                c) Technical and organisational measures necessary to minimise
                the risk of errors and to enable such errors to be easily
                corrected shall be implemented; and
              </li>
              <li>
                d) All personal data processed for profiling purposes shall be
                secured in order to prevent discriminatory effects arising out
                of profiling.
              </li>
            </ol>
            <br />
            <br />
            <br />
            <br />
            <h2>12. Dispute Resolution</h2>
            <br />
            <br />
            12. 1 The Parties will use their best efforts to negotiate in good
            faith and settle any dispute that may arise out of or relate to this
            Privacy Policy or any breach of it.
            <br />
            <br />
            12. 2 If any such dispute cannot be settled amicably through
            ordinary negotiations between the parties, or either or both is or
            are unwilling to engage in this process, either party may propose to
            the other in writing that structured negotiations be entered into
            with the assistance of a fully accredited mediator before resorting
            to litigation.
            <br />
            <br />
            12. 3 If the parties are unable to agree upon a mediator, or if the
            mediator agreed upon is unable or unwilling to act and an
            alternative mediator cannot be agreed, any party may within 14 days
            of the date of knowledge of either event apply to LawBite to appoint
            a mediator under the LawBite Mediation Procedure.
            <br />
            <br />
            12. 4 Within 14 days of the appointment of the mediator (either by
            mutual agreement of the parties or by LawBite in accordance with
            their mediation procedure), the parties will meet with the mediator
            to agree the procedure to be adopted for the mediation, unless
            otherwise agreed between the parties and the mediator.
            <br />
            <br />
            12. 5 All negotiations connected with the relevant dispute(s) will
            be conducted in confidence and without prejudice to the rights of
            the parties in any further proceedings.
            <br />
            <br />
            12. 6 If the parties agree on a resolution of the dispute at
            mediation, the agreement shall be reduced to writing and, once
            signed by the duly authorised representatives of both parties, shall
            be final and binding on them.
            <br />
            <br />
            12. 7 If the parties fail to resolve the dispute(s) within 60 days
            (or such longer term as may be agreed between the parties) of the
            mediator being appointed, or if either party withdraws from the
            mediation procedure, then either party may exercise any right to
            seek a remedy through arbitration by an arbitrator to be appointed
            by LawBite under the Rules of the LawBite Arbitration Scheme.
            <br />
            <br />
            12. 8 Any dispute shall not affect the parties’ ongoing obligations
            under this Privacy Policy.
            <br />
          </div>
        </div>
      </div>
    );
  else if (i18n.language === 'es')
    return (
      <div className="privacy-screen">
        <HeaderBar />

        <div className="privacy-title top-padding"></div>
        <div className="options col-center">
          <div className="content">
            <h1>Política de privacidad</h1>
            <br />
            <br />
            <h2>1. Introducción</h2>
            <br />
            <br />
            Esta es nuestra política de privacidad. Le informa de cómo recogemos
            y procesamos los datos que recibimos de usted en nuestro sitio. Por
            favor, lea lo siguiente cuidadosamente para entender nuestros puntos
            de vista y prácticas con respecto a sus datos personales y cómo los
            tratamos.&nbsp;
            <br />
            Si tiene algún comentario sobre esta política de privacidad, envíelo
            por correo electrónico a{' '}
            <a href="mailto:support@datawin-prediction.com">
              <b>support@datawin-prediction.com</b>
            </a>
            <h2>&nbsp;</h2>
            <br />
            <br />
            <h2>2. Quiénes somos</h2>
            <br />
            <br />
            Estos son los detalles que el Reglamento (UE) 2016/679 del
            Parlamento Europeo y del Consejo, de 27 de abril de 2016, sobre la
            protección de las personas físicas en lo que respecta al tratamiento
            de datos personales y a la libre circulación de estos datos,
            conocido como Reglamento General de Protección de Datos (<b>GDPR</b>
            ) dice que tenemos que darle como "responsable del tratamiento de
            datos":
            <br />
            <ul>
              <li>
                La dirección de nuestro sitio es <b>datawin.io</b>
              </li>
              <li>
                El nombre de nuestra empresa es <b>DATAWIN</b>
              </li>
              <li>
                Nuestro domicilio social es 219 Kensington High Street,
                Kensington, London, England, W8 6BD
              </li>
              <li>
                Nuestro [representante designado o responsable de la protección
                de datos] puede ser contactado contactar en{' '}
                <b>support@datawin-prediction.com</b>
              </li>
            </ul>
            <br />
            <br />
            <br />
            <br />
            <h2>3. Lo que podemos recoger</h2>
            <br />
            <br />
            Podemos recoger y procesar los siguientes datos sobre usted:
            <br />
            <br />
            <ul>
              <li>
                La información que introduzca en los formularios o encuestas de
                nuestro sitio web en cualquier momento
              </li>
              <li>Un registro de cualquier correspondencia entre nosotros</li>
              <li>
                Datos de las transacciones que realice a través de nuestro sitio
                web
              </li>
              <li>
                Detalles de sus visitas a nuestro sitio y los recursos que
                utiliza
              </li>
              <li>
                Información sobre su ordenador (por ejemplo, su dirección IP,
                navegador sistema operativo, etc.) para la administración del
                sistema y para informar información agregada a nuestros
                anunciantes
              </li>
            </ul>
            De acuerdo con el GDPR, nos aseguraremos de que sus datos personales
            se procesen de forma legal, justa y transparente, sin que ello
            afecte negativamente a sus derechos. Solo procesaremos sus datos
            personales si se aplica al menos una de las siguientes bases se
            aplica:
            <br />
            <ol>
              <li>
                a) ha dado su consentimiento para el tratamiento de sus datos
                personales para uno o varios fines específicos;
              </li>
              <li>
                b) el tratamiento es necesario para la ejecución de un contrato
                que usted es parte o para tomar medidas a petición suya de usted
                antes de celebrar un contrato;
              </li>
              <li>
                c) el tratamiento es necesario para el cumplimiento de una
                obligación legal obligación legal a la que estamos sujetos;
              </li>
              <li>
                d) el tratamiento es necesario para proteger sus intereses
                vitales o de otra persona física;
              </li>
              <li>
                e) el tratamiento es necesario para el cumplimiento de una tarea
                realizada en interés público o en el ejercicio de poderes
                públicos de la autoridad oficial conferida al responsable del
                tratamiento; y/o
              </li>
              <li>
                f) el tratamiento es necesario para los fines de los intereses
                legítimos intereses legítimos perseguidos por nosotros o por un
                tercero, como nuestro de pago con tarjeta de crédito, excepto
                cuando dichos intereses derechos y libertades fundamentales del
                interesado, que exigen la protección que requieren la protección
                de los datos personales, en particular cuando el interesado sea
                un niño.
              </li>
            </ol>
            <br />
            <br />
            <br />
            <br />
            <h2>4. Cookies</h2>
            <br />
            <br />
            [Todas las cookies utilizadas por y en nuestro sitio web se utilizan
            de acuerdo con la Ley de Cookies inglesa y de la UE vigente].
            <br />
            El sitio utiliza cookies o tecnología similar para recoger
            información sobre su acceso al sitio. Las cookies son piezas de
            información que incluyen un código de referencia único que un sitio
            web transfiere a su dispositivo para almacenar y a veces rastrear
            información sobre usted.&nbsp;
            <br />
            Algunas de las cookies que utilizamos duran sólo por la duración de
            su sesión y expiran cuando usted cierra su navegador. Otras cookies
            se utilizan utilizan para recordarle cuando vuelve al sitio y duran
            más tiempo. más tiempo.
            <br />
            <b>
              Todas las cookies que se utilizan en nuestro sitio web son
              establecidas por nosotros.
            </b>
            <br />
            La mayoría de los navegadores de ordenadores y algunos de móviles
            aceptan automáticamente pero, si lo prefiere, puede cambiar su
            navegador para evitarlo o para o para que le notifique cada vez que
            se instale una cookie. Puede evitar la la instalación de cookies
            ajustando la configuración de su navegador. Por favor, Tenga en
            cuenta, sin embargo, que si bloquea o borra las cookies es posible
            que no pueda no pueda aprovechar al máximo las ventajas del sitio.
            <br />
            <b>Nuestras cookies se utilizarán para:</b>
            <br />
            Gestión esencial de la sesión
            <br />
            <ul>
              <li>
                crear una sesión de inicio de sesión específica para un usuario
                del sitio para para que el sitio recuerde que un usuario está
                conectado y que sus solicitudes de página se entreguen de manera
                eficaz, segura y de manera eficaz, segura y coherente;
              </li>
              <li>
                reconocer cuando un usuario del sitio lo ha visitado antes de
                permitirnos identificar el número de usuarios únicos que recibe
                el sitio y asegurarnos de que tenemos suficiente capacidad para
                el número de usuarios que recibimos;
              </li>
              <li>
                reconocer si un visitante del sitio está registrado con nosotros
                de cualquier forma;
              </li>
              <li>
                también podemos registrar información de su ordenador,
                incluyendo la existencia de cookies, su dirección IP e
                información sobre su programa programa de navegación para
                permitirnos diagnosticar problemas, administrar y rastrear su
                uso de nuestro sitio.
              </li>
            </ul>
            Funcionalidad
            <br />
            <ul>
              <li>
                personalizar elementos de la disposición promocional y/o el
                contenido de las páginas del sitio.
              </li>
            </ul>
            Rendimiento y medición
            <br />
            <ul>
              <li>
                recopilar información estadística sobre el uso que nuestros
                usuarios hacen del sitio para que podamos mejorar el sitio y
                aprender qué partes son más populares para los usuarios].
              </li>
            </ul>
            <b>O</b>
            <br />
            [Utilizamos cookies para distinguir a los usuarios y mejorar nuestro
            sitio. Por favor, consultar nuestra Política de Cookies{' '}
            <a href="https://datawin.io">
              <b>datawin.io</b>
            </a>{' '}
            para más información sobre las cookies].
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>5. Cómo utilizamos lo que recogemos</h2>
            <br />
            <br />
            Utilizamos información sobre usted para:
            <br />
            <ul>
              <li>Presentarle el contenido del sitio de forma eficaz.</li>
              <li>
                Proporcionar información, productos y servicios que usted
                solicite, o (con su consentimiento) que creemos que pueden
                interesarle.
              </li>
              <li>Cumplir nuestros contratos con usted.</li>
              <li>
                Permitirle utilizar nuestros servicios interactivos si lo desea.
              </li>
              <li>Le informamos de nuestras tarifas.</li>
              <li>
                Informarle sobre otros productos y servicios que puedan
                interesarle. También dejaremos que otras personas lo hagan, y
                nosotros (o ellos) podremos ponerse en contacto con usted.
              </li>
            </ul>
            Si ya es nuestro cliente, sólo nos pondremos en contacto con usted
            electrónicamente sobre cosas similares a las que se le vendieron
            anteriormente usted.
            <br />
            Si es usted un nuevo cliente, sólo se le contactará si está de
            acuerdo a ello.
            <br />
            Si no desea que nos pongamos en contacto con usted con fines de
            marketing, marque marque la casilla correspondiente que encontrará
            en la pantalla.&nbsp;
            <br />
            Nota: No identificamos a las personas a nuestros anunciantes, pero
            pero sí les proporcionamos información agregada para ayudarles a
            llegar a su público objetivo, y podemos utilizar la información que
            hemos recopilado para mostrar anuncios a ese público.
            <br />
            Además, si no quiere que utilicemos sus datos personales por alguna
            de las otras razones expuestas en esta sección en el punto 5, puede
            hacérnoslo saber en cualquier momento poniéndose en contacto con
            nosotros en [Dirección de correo electrónico], y eliminaremos sus
            datos de nuestros sistemas. Sin embargo, usted reconoce que esto
            limitará nuestra capacidad para ofrecerle los mejores [productos y]
            servicios a usted.
            <br />
            En algunos casos, la recogida de datos personales puede ser un
            requisito legal o contractual, y estaremos limitados en los
            [productos y] servicios que podemos y] servicios que podemos
            ofrecerle si no nos facilita sus datos personales personales en
            estos casos.
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>6. Dónde almacenamos sus datos</h2>
            <br />
            <br />
            Podemos transferir sus datos recogidos para almacenarlos fuera del
            Espacio Económico Europeo (EEE). Económico Europeo (EEE). Pueden ser
            procesados fuera del EEE para cumplir con su pedido y tramitar el
            pago.
            <br />
            Al facilitarnos sus datos personales, usted acepta este acuerdo.
            Nosotros haremos todo lo que esté en nuestras manos para mantener la
            seguridad de sus datos.
            <br />
            El pago será encriptado. Si le damos una contraseña, debe mantenerla
            confidencial. Por favor, no la comparta. Aunque intentamos
            proporcionar protección, no podemos garantizar la completa seguridad
            de sus datos, y usted asume el riesgo de que cualquier envío de esos
            datos resulte no ser seguro a pesar de nuestros esfuerzos.
            <br />
            Sólo conservamos sus datos personales durante el tiempo necesario
            para utilizarlos como se describe en la sección 5, y/o mientras
            tengamos su tengamos su permiso para conservarlos. En cualquier
            caso, realizaremos una revisión [anual] para determinar si es
            necesario conservar sus datos personales datos personales. Sus datos
            personales se eliminarán si ya no los necesitamos.
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>7. Sus derechos</h2>
            <br />
            <br />
            Puede pedirnos que no utilicemos sus datos para el marketing. Puede
            hacerlo marcando las casillas correspondientes en nuestros
            formularios, o poniéndose en contacto con nosotros en cualquier
            momento en 219 Kensington High Street, Kensington, London, England,
            W8 6BD / support@datawin-prediction.com
            <br />
            De acuerdo con el GDPR, usted tiene derecho a:
            <br />
            <ul>
              <li>
                solicitar el acceso, la supresión o la corrección de sus datos
                personales datos personales que tenemos en nuestro poder sin
                coste alguno para usted;&nbsp;
              </li>
              <li>
                solicitar la transferencia de sus datos personales a otra
                persona (portabilidad de datos);&nbsp;
              </li>
              <li>
                ser informado del tratamiento de datos que se está llevando a
                cabo;
              </li>
              <li>restringir el tratamiento;</li>
              <li>oponerse al tratamiento de sus datos personales; y</li>
              <li>reclamar ante una autoridad de control.</li>
            </ul>
            <br />
            También tiene derechos con respecto a la toma de decisiones
            automatizada y a la elaboración de perfiles la elaboración de
            perfiles, tal como se indica en la sección 11.&nbsp;
            <br />
            Para hacer valer cualquiera de los derechos anteriores o si tiene
            cualquier otra preguntas sobre nuestro sitio web o esta Política de
            Privacidad, póngase en contacto con nosotros en
            [support@datawin-prediction.com].
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>9. Enlaces a otros sitios</h2>
            <br />
            <br />
            Tenga en cuenta que nuestras condiciones de privacidad y nuestras
            políticas no se aplicarán a otros sitios web a los que llegue a
            través de un enlace desde nuestro sitio. No tenemos ningún control
            sobre cómo se recogen, almacenan o utilizan sus datos en otros
            sitios web. almacenados o utilizados por otros sitios web y le
            aconsejamos que compruebe las políticas de de dichos sitios web
            antes de proporcionarles cualquier dato.
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>10. Cambios</h2>
            <br />
            <br />
            Si cambiamos nuestra política de privacidad, publicaremos los
            cambios en esta página. Si lo decidimos, también podemos enviarle un
            correo electrónico.
            <br />
            <br />
            <ol>
              <li>
                <b>
                  Toma de decisiones y elaboración de perfiles automatizados
                </b>
              </li>
            </ol>
            11. 1 En el caso de que utilicemos datos personales con fines de
            toma de decisiones automatizada y esas decisiones tengan un efecto
            legal (o efecto significativo similar) sobre usted, tiene derecho a
            impugnar dichas decisiones en virtud del RGPD, solicitando la
            intervención humana, expresando su punto de vista y obteniendo una
            explicación de la decisión por nuestra parte.
            <br />
            11. 2 El derecho descrito en la sección 11. 1 no se aplica en las
            siguientes circunstancias:
            <br />
            <ol>
              <li>
                a) la decisión es necesaria para la celebración o el
                cumplimiento de un contrato entre usted y nosotros de un
                contrato entre usted y nosotros;
              </li>
              <li>b) la decisión está autorizada por la ley; o</li>
              <li>c) usted ha dado su consentimiento explícito.</li>
            </ol>
            11. 3 Cuando utilicemos sus datos personales con fines de
            elaboración de perfiles, se se aplicará lo siguiente:
            <br />
            <ol>
              <li>
                a) Se proporcionará información clara que explique la
                elaboración de perfiles incluyendo su significado y las posibles
                consecuencias;
              </li>
              <li>
                b) Se utilizarán procedimientos matemáticos o estadísticos
                adecuados utilizados;
              </li>
              <li>
                c) Medidas técnicas y organizativas necesarias para minimizar
                minimizar el riesgo de errores y permitir que éstos sean
                fácilmente corregirlos; y
              </li>
              <li>
                d) Todos los datos personales tratados con fines de elaboración
                de perfiles deberán ser para evitar los efectos discriminatorios
                derivados de la elaboración de perfiles. de la elaboración de
                perfiles.
              </li>
            </ol>
            <br />
            <br />
            <br />
            <br />
            <h2>12. Resolución de litigios</h2>
            <br />
            <br />
            12. 1 Las Partes harán todo lo posible para negociar de buena fe
            buena fe y resolver cualquier disputa que pueda surgir o estar
            relacionada con esta Política de Privacidad o cualquier
            incumplimiento de la misma.
            <br />
            <br />
            12. 2 Si cualquier disputa no puede ser resuelta amistosamente a
            través de negociaciones ordinarias entre las partes, o alguna de
            ellas está o no están dispuestas a participar en este proceso,
            cualquiera de las partes podrá proponer a la a la otra por escrito
            que se inicien negociaciones estructuradas con la asistencia de un
            mediador plenamente acreditado antes de recurrir a un litigio.
            <br />
            <br />
            12. 3 Si las partes no consiguen ponerse de acuerdo sobre un
            mediador, o si el mediador acordado no puede o no quiere actuar y no
            se puede acordar un mediador alternativo, cualquiera de las partes
            podrá, en el plazo de 14 días a partir de la fecha de conocimiento
            de cualquiera de los dos hechos solicitar a LawBite la designación
            de un mediador en el marco del procedimiento de mediación de
            LawBite.
            <br />
            <br />
            12. 4 En el plazo de 14 días a partir de la designación del mediador
            (bien por acuerdo mutuo de las partes o por LawBite de acuerdo con
            su su procedimiento de mediación), las partes se reunirán con el
            mediador para acordar el procedimiento que se adoptará para la
            mediación, a menos que que las partes y el mediador acuerden otra
            cosa.
            <br />
            <br />
            12. 5 Todas las negociaciones relacionadas con la(s) controversia(s)
            correspondiente(s) se llevarán a cabo de forma confidencial y sin
            perjuicio de los derechos de las partes en cualquier otro
            procedimiento.
            <br />
            <br />
            12. 6 Si las partes llegan a un acuerdo sobre la resolución del
            conflicto en mediación, el acuerdo se reducirá por escrito y, una
            vez firmado por firmado por los representantes debidamente
            autorizados de ambas partes, será será definitivo y vinculante para
            ellas.
            <br />
            <br />
            12. 7 Si las partes no consiguen resolver la(s) controversia(s) en
            un plazo de 60 días (o el plazo más largo que puedan acordar las
            partes) de la designación del mediador, o si una de las partes se
            retira del procedimiento de mediación, cualquiera de las partes
            podrá ejercer su derecho a recurrir al arbitraje por parte de un
            árbitro designado por LawBite por LawBite en virtud del Reglamento
            de Arbitraje de LawBite.
            <br />
            <br />
            12. 8 Cualquier disputa no afectará a las obligaciones permanentes
            de las partes en virtud de la presente Política de Privacidad.
            <br />
          </div>
        </div>
      </div>
    );

  if (i18n.language === 'fr')
    return (
      <div className="privacy-screen">
        <HeaderBar />

        <div className="privacy-title top-padding"></div>
        <div className="options col-center">
          <div className="content">
            <h1>Politique de confidentialité</h1>
            <br />
            <br />
            <h2>1. Introduction</h2>
            <br />
            <br />
            Ceci est notre politique de confidentialité. Elle vous indique
            comment nous collectons et traitons les données reçues de vous sur
            notre site. Veuillez lire attentivement ce qui suit attentivement
            afin de comprendre notre point de vue et nos pratiques concernant
            vos données personnelles et la manière dont nous les traitons.&nbsp;
            <br />
            Si vous souhaitez formuler des commentaires sur cette politique de
            confidentialité, veuillez les envoyer par email à{' '}
            <a href="mailto:support@datawin-prediction.com">
              <b>support@datawin-prediction.com</b>
            </a>
            <h2>&nbsp;</h2>
            <br />
            <br />
            <h2>2. Qui sommes-nous ?</h2>
            <br />
            <br />
            Voici les détails que le règlement (UE) 2016/679 du Parlement
            européen et du Conseil du 27 avril 2016 relatif à la protection des
            personnes physiques à l'égard du traitement des données à caractère
            personnel et à la libre circulation de ces données, connu sous le
            nom de Règlement général sur la protection des données (<b>GDPR</b>)
            dit que nous devons . vous donner en tant que "responsable du
            traitement des données" :
            <br />
            <ul>
              <li>
                Notre site web est <b>datawin.io</b>
              </li>
              <li>
                Le nom de notre société est <b>DATAWIN</b>
              </li>
              <li>
                Notre siège social est 219 Kensington High Street, Kensington,
                London, England, W8 6BD
              </li>
              <li>
                Notre [représentant désigné ou délégué à la protection des
                données] peut être contacté(e) à{' '}
                <b>support@datawin-prediction.com</b>
              </li>
            </ul>
            <br />
            <br />
            <br />
            <br />
            <h2>3. Ce que nous pouvons collecter</h2>
            <br />
            <br />
            Nous pouvons recueillir et traiter les données suivantes vous
            concernant :
            <br />
            <br />
            <ul>
              <li>
                Les informations que vous saisissez dans les formulaires ou les
                enquêtes sur notre site à tout moment.
              </li>
              <li>Un enregistrement de toute correspondance entre nous</li>
              <li>
                Détails des transactions que vous effectuez sur notre site
              </li>
              <li>
                Détails de vos visites sur notre site et des ressources que vous
                utilisez
              </li>
              <li>
                Des informations sur votre ordinateur (par exemple, votre
                adresse IP, votre navigateur, système d'exploitation, etc.) pour
                l'administration du système et pour rapporter des informations
                globales à nos annonceurs
              </li>
            </ul>
            En vertu du GDPR, nous veillerons à ce que vos données personnelles
            soient traitées légalement, équitablement et de manière
            transparente, sans porter atteinte à vos droits. Nous ne traiterons
            vos données personnelles que si au moins une des bases suivantes
            s'applique :
            <br />
            <ol>
              <li>
                a) vous avez donné votre consentement au traitement de vos
                données personnelles données à caractère personnel à une ou
                plusieurs fins spécifiques ;
              </li>
              <li>
                b) le traitement est nécessaire pour l'exécution d'un contrat
                contrat auquel vous êtes partie ou afin de prendre des mesures à
                votre demande de votre part avant la conclusion d'un contrat ;
              </li>
              <li>
                c) le traitement est nécessaire au respect d'une obligation
                légale à laquelle nous sommes soumis. obligation légale à
                laquelle nous sommes soumis ;
              </li>
              <li>
                d) le traitement est nécessaire à la protection de vos intérêts
                vitaux ou d'une autre personne physique ;
              </li>
              <li>
                e) le traitement est nécessaire à l'exécution d'une mission
                effectuée dans l'intérêt général ou dans l'exercice de
                l'autorité publique officielle dont est investi le responsable
                du traitement ; et/ou
              </li>
              <li>
                ff) le traitement est nécessaire pour les besoins des intérêts
                légitimes légitimes poursuivis par nous-mêmes ou par un tiers,
                comme le traitement des traitement des paiements par carte de
                crédit, sauf si ces intérêts sont l'emportent sur les droits et
                libertés fondamentaux de la personne concernée qui libertés
                fondamentales de la personne concernée qui exigent la protection
                des données personnelles, en particulier lorsque la personne
                concernée est un enfant.
              </li>
            </ol>
            <br />
            <br />
            <br />
            <br />
            <h2>4. Cookies</h2>
            <br />
            <br />
            [Tous les cookies utilisés par et sur notre site Web sont utilisés
            conformément à la législation anglaise et européenne en vigueur sur
            les cookies. la législation anglaise et européenne en vigueur sur
            les cookies].
            <br />
            Le site utilise des cookies ou des technologies similaires pour
            collecter des informations sur votre accès au site. Les cookies sont
            des éléments d'information qui comprennent un code de référence
            unique qu'un site web transfère à votre appareil pour stocker et
            parfois suivre des informations sur vous.&nbsp;
            <br />
            Quelques-uns des cookies que nous utilisons ne durent que pendant la
            durée de votre et expirent lorsque vous fermez votre navigateur.
            D'autres cookies sont utilisés pour se souvenir de vous lorsque vous
            revenez sur le site et durent plus longtemps. plus longtemps.
            <br />
            <b>
              Tous les cookies utilisés sur notre site sont définis par nous.
            </b>
            <br />
            La plupart des ordinateurs et certains navigateurs Web mobiles
            acceptent automatiquement les cookies. cookies mais, si vous le
            préférez, vous pouvez modifier votre navigateur pour l'empêcher ou
            pour qu'il vous avertisse chaque fois qu'un cookie est placé. Vous
            pouvez empêcher la Vous pouvez empêcher l'installation de cookies en
            ajustant les paramètres de votre navigateur. Veuillez noter que
            noter toutefois qu'en bloquant ou en supprimant les cookies, vous ne
            pourrez peut-être pas profiter pleinement du site. ne pas pouvoir
            profiter pleinement du site.
            <br />
            <b>Nos cookies seront utilisés pour :</b>
            <br />
            Gestion essentielle des sessions
            <br />
            <ul>
              <li>
                la création d'une session de connexion spécifique pour un
                utilisateur du site afin que le site se souvienne qu'un
                utilisateur s'est connecté et qu'il n'y a pas de problème. afin
                que le site se souvienne qu'un utilisateur est connecté et que
                ses demandes de pages soient traitées de manière efficace, sûre
                et cohérente. de manière efficace, sûre et cohérente ;
              </li>
              <li>
                reconnaître quand un utilisateur du site l'a visité avant de
                nous permettre d'identifier le nombre d'utilisateurs uniques du
                site. d'identifier le nombre d'utilisateurs uniques que nous
                recevons sur le site et de nous assurer que nous avons une
                capacité suffisante pour le nombre d'utilisateurs que nous
                recevons ;
              </li>
              <li>
                reconnaître si un visiteur du site est enregistré auprès de nous
                de de quelque manière que ce soit ;
              </li>
              <li>
                nous pouvons également enregistrer des informations provenant de
                votre ordinateur, notamment l'existence de cookies, votre
                adresse IP et des informations sur votre programme de programme
                de navigation afin de nous permettre de diagnostiquer les
                problèmes, de gérer et de suivre votre utilisation de notre
                site.
              </li>
            </ul>
            Fonctionnalité
            <br />
            <ul>
              <li>
                personnaliser des éléments de la présentation promotionnelle
                et/ou du contenu des les pages du site.
              </li>
            </ul>
            Performance et mesure
            <br />
            <ul>
              <li>
                recueillir des informations statistiques sur la façon dont nos
                utilisateurs utilisent le afin d'améliorer le site et
                d'apprendre quelles parties sont les plus populaires auprès des
                utilisateurs].
              </li>
            </ul>
            <b>OU</b>
            <br />
            [Nous utilisons des cookies pour distinguer les utilisateurs et
            améliorer notre site. Veuillez consulter consulter notre politique
            en matière de cookies{' '}
            <a href="https://datawin.io">
              <b>datawin.io</b>
            </a>{' '}
            pour plus d'informations sur les cookies].
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>5. Comment nous utilisons ce que nous collectons</h2>
            <br />
            <br />
            Nous utilisons les informations vous concernant pour :
            <br />
            <ul>
              <li>Vous présenter efficacement le contenu du site.</li>
              <li>
                Fournir les informations, produits et services que vous
                demandez, ou (avec votre consentement) qui, selon nous,
                pourraient vous intéresser.
              </li>
              <li>Exécuter nos contrats avec vous.</li>
              <li>
                Vous permettre d'utiliser nos services interactifs si vous le
                souhaitez.
              </li>
              <li>Vous communiquer nos frais.</li>
              <li>
                Vous informer sur d'autres biens et services qui pourraient vous
                intéresser. Nous autorisons également d'autres personnes à le
                faire et nous (ou elles) pouvons vous contacter.
              </li>
            </ul>
            Si vous êtes déjà notre client, nous vous contacterons seulement
            électroniquement à propos de choses similaires à ce qui vous a été
            vendu précédemment. vous.
            <br />
            Si vous êtes un nouveau client, vous ne serez contacté que si vous y
            consentez. si vous l'acceptez.
            <br />
            Si vous ne souhaitez pas être contacté à des fins de marketing,
            veuillez cocher la case correspondante que vous trouverez à
            l'écran.&nbsp;
            <br />
            Remarque : Nous n'identifions pas les individus auprès de nos
            annonceurs, mais mais nous leur donnons des informations globales
            pour les aider à atteindre leur public cible. leur public cible, et
            nous pouvons utiliser les informations que nous avons recueillies
            pour pour afficher des publicités auprès de ce public.
            <br />
            En outre, si vous ne voulez pas que nous utilisions vos données
            personnelles pour l'une ou l'autre des raisons énoncées dans la
            présente section, vous pouvez nous en informer. pour l'une des
            autres raisons énoncées dans la présente section 5, vous pouvez nous
            le faire savoir à tout moment en nous contactant à l'adresse
            suivante nous le faire savoir à tout moment en nous contactant à
            l'adresse [adresse électronique]. supprimerons vos données de nos
            systèmes. Toutefois, vous reconnaissez que cela limitera notre
            capacité à vous fournir les meilleurs [produits et] services
            possibles. services possibles.
            <br />
            Dans certains cas, la collecte de données personnelles peut être une
            exigence légale ou contractuelle. ou contractuelle, et nous serons
            limités dans les [produits services que nous pouvons vous fournir si
            vous ne nous communiquez pas vos données données personnelles dans
            ces cas.
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>6. Où nous stockons vos données</h2>
            <br />
            <br />
            Nous pouvons transférer vos données collectées pour les stocker en
            dehors de l'Espace économique européen (EEE). économique européen
            (EEE). Elles peuvent être traitées en dehors de l'EEE afin de
            remplir votre commande et traiter le paiement.
            <br />
            En nous communiquant vos données personnelles, vous acceptez cet
            arrangement. Nous ferons ce que nous pouvons raisonnablement faire
            pour assurer la sécurité de vos données.
            <br />
            Le paiement sera crypté. Si nous vous donnons un mot de passe, vous
            devez le garder confidentiel. Veuillez ne pas le partager. Bien que
            nous nous efforcions de fournir protection, nous ne pouvons pas
            garantir la sécurité totale de vos données, et vous prenez le risque
            que tout envoi de ces données s'avère non sécurisé malgré nos
            efforts. sécurisé malgré nos efforts.
            <br />
            Nous ne conservons vos données personnelles qu'aussi longtemps que
            nous en avons besoin pour l'utiliser comme décrit ci-dessus à la
            section 5, et/ou aussi longtemps que nous avons la votre
            autorisation de les conserver. En tout état de cause, nous
            procéderons à un révision [annuelle] pour déterminer si nous devons
            conserver vos données personnelles. données personnelles. Vos
            données personnelles seront supprimées si nous n'en avons plus
            besoin.
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>7. Vos droits</h2>
            <br />
            <br />
            Vous pouvez nous demander de ne pas utiliser vos données à des fins
            de marketing. Vous pouvez le faire en cochant les cases
            correspondantes sur nos formulaires, ou en nous contactant à à tout
            moment à 219 Kensington High Street, Kensington, London, England, W8
            6BD / support@datawin-prediction.com
            <br />
            En vertu du GDPR, vous avez le droit de :
            <br />
            <ul>
              <li>
                demander gratuitement l'accès, la suppression ou la correction
                de vos données personnelles que nous détenons, sans frais pour
                vous ;&nbsp;
              </li>
              <li>
                demander que vos données personnelles soient transférées à une
                autre personne (portabilité des données) ;&nbsp;
              </li>
              <li>être informé de la nature du traitement des données ;</li>
              <li>rrestreindre le traitement ;</li>
              <li>
                de s'opposer au traitement de vos données personnelles ; et
              </li>
              <li>
                cdéposer une plainte auprès d'une autorité de surveillance.
              </li>
            </ul>
            <br />
            Vous disposez également de droits en matière de prise de décision
            automatisée et de le profilage, comme indiqué à la section 11
            ci-dessous.&nbsp;
            <br />
            Pour faire valoir l'un des droits susmentionnés ou si vous avez
            d'autres questions concernant notre site ou cette politique de
            confidentialité, veuillez nous contacter. questions concernant notre
            site ou cette politique de confidentialité, veuillez nous contacter
            à l'adresse [support@datawin-prediction.com].
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>9. Liens vers d'autres sites</h2>
            <br />
            <br />
            Veuillez noter que nos conditions de confidentialité et nos
            politiques ne s'appliquent pas à d'autres sites Web auxquels vous
            accédez par un lien depuis notre site. ne s'appliqueront pas aux
            autres sites Web auxquels vous accédez par un lien de notre site.
            site. Nous n'avons aucun contrôle sur la manière dont vos données
            sont collectées, stockées ou utilisées par d'autres sites web.
            utilisées par d'autres sites Web et nous vous conseillons de
            vérifier les politiques de confidentialité de ces sites Web avant de
            leur fournir des données. politiques de confidentialité de ces sites
            avant de leur fournir des données.
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>10. Changements</h2>
            <br />
            <br />
            Si nous modifions notre politique de confidentialité, nous
            publierons les modifications sur cette page. Si nous le décidons,
            nous pouvons également vous envoyer un courrier électronique.
            <br />
            <br />
            <ol>
              <li>
                <b>Prise de décision et profilage automatisés</b>
              </li>
            </ol>
            11. 1 Dans le cas où nous utilisons des données personnelles à des
            fins de décision automatisée et que ces décisions ont un effet
            juridique (ou un effet effet significatif similaire) sur vous, vous
            avez le droit de contester de telles décisions conformément au GDPR,
            en demandant une intervention, en exprimant votre propre point de
            vue et en obtenant une explication de la décision de notre part.
            <br />
            11. 2 Le droit décrit à l'article 11. 1 ne s'applique pas dans les
            circonstances suivantes :
            <br />
            <ol>
              <li>
                a) la décision est nécessaire pour la conclusion ou l'exécution
                d'un contrat entre vous et nous. d'un contrat entre vous et nous
                ;
              </li>
              <li>b) la décision est autorisée par la loi ; ou</li>
              <li>c) vous avez donné votre consentement explicite.</li>
            </ol>
            11. 3 Lorsque nous utilisons vos données personnelles à des fins de
            profilage, les dispositions suivantes s'appliquent suivantes
            s'appliquent :
            <br />
            <ol>
              <li>
                a) Des informations claires expliquant le profilage seront
                fournies, y compris sa signification et ses conséquences
                probables ;
              </li>
              <li>
                b) Des procédures mathématiques ou statistiques appropriées
                seront utilisées ;
              </li>
              <li>
                c) les mesures techniques et organisationnelles nécessaires pour
                réduire au minimum le risque d'erreurs et permettre à ces
                erreurs d'être facilement détectées. le risque d'erreurs et pour
                permettre de les corriger facilement, sont mises en œuvre ; et
                facilement corrigées sont mises en œuvre ; et
              </li>
              <li>
                d) Toutes les données à caractère personnel traitées à des fins
                de profilage doivent être sécurisées afin d'éviter les effets
                discriminatoires découlant du du profilage.
              </li>
            </ol>
            <br />
            <br />
            <br />
            <br />
            <h2>12. Résolution des litiges</h2>
            <br />
            <br />
            12. 1 Les parties feront de leur mieux pour négocier de bonne foi et
            de bonne foi et de régler tout litige qui pourrait découler de la
            présente politique de politique de confidentialité ou toute
            violation de celle-ci.
            <br />
            <br />
            12. 2 Si un tel différend ne peut être réglé à l'amiable par le
            biais de négociations ordinaires entre les parties, ou que l'une ou
            l'autre ou les deux sont ou ne sont pas disposées à s'engager dans
            ce processus, l'une des parties peut proposer à l'autre, par écrit,
            d'engager des négociations structurées. l'autre par écrit que des
            négociations structurées soient engagées avec l'aide d'un médiateur
            pleinement accrédité avant de recourir à un à un litige.
            <br />
            <br />
            12.3 Si les parties ne parviennent pas à s'entendre sur le choix
            d'un médiateur, ou si le médiateur convenu ne peut pas ou ne veut
            pas agir et qu'un autre et qu'il n'est pas possible de convenir d'un
            autre médiateur, toute partie peut, dans les 14 jours dans les 14
            jours suivant la date de connaissance de l'un ou l'autre de ces
            événements, demander à LawBite de désigner un médiateur dans le
            cadre de la procédure de médiation de LawBite.
            <br />
            <br />
            12.4 Dans les 14 jours qui suivent la nomination du médiateur (soit
            par d'un commun accord entre les parties ou par LawBite conformément
            à leur leur procédure de médiation), les parties se réunissent avec
            le médiateur pour convenir de la procédure à adopter pour la
            médiation, à moins qu'il n'en soit sauf accord contraire entre les
            parties et le médiateur.
            <br />
            <br />
            12.5 Toutes les négociations liées au(x) litige(s) concerné(s)
            seront seront menées de manière confidentielle et sans préjudice des
            droits des parties parties dans toute procédure ultérieure.
            <br />
            <br />
            12.6 Si les parties conviennent d'un règlement du différend lors de
            la médiation, l'accord est mis par écrit et, une fois signé par les
            représentants signé par les représentants dûment autorisés des deux
            parties, est définitif et parties, est définitif et les lie.
            <br />
            <br />
            12.7 Si les parties ne parviennent pas à résoudre le(s) litige(s)
            dans un délai de 60 jours (ou dans un délai plus long convenu entre
            les parties) après la nomination du médiateur, ou si l'une ou
            l'autre des parties se retire du processus de médiation. médiateur,
            ou si l'une ou l'autre des parties se retire de la procédure de
            médiation, l'une ou l'autre des parties peut alors exercer son droit
            de demander réparation par voie d'arbitrage auprès d'un arbitre qui
            sera nommé par LawBite par LawBite conformément au règlement du
            système d'arbitrage LawBite.
            <br />
            <br />
            12.8 Tout litige n'affectera pas les obligations permanentes des
            parties en vertu de la présente politique de confidentialité.
            <br />
          </div>
        </div>

        <Footer />
      </div>
    );
  if (i18n.language === 'it')
    return (
      <div className="privacy-screen">
        <HeaderBar />

        <div className="privacy-title top-padding"></div>
        <div className="options col-center">
          <div className="content">
            <h1>Informativa sulla privacy e politica dei cookie</h1>
            <br />
            <br />
            <h2>1. Introduzione</h2>
            <br />
            <br />
            Questa è la nostra politica sulla privacy. Vi dice come raccogliamo
            e trattiamo i dati ricevuti da voi sul nostro sito. Si prega di
            leggere attentamente quanto segue per comprendere le nostre opinioni
            e pratiche relative ai vostri dati personali e come li tratteremo.
            Se avete dei commenti su questa politica sulla privacy, vi preghiamo
            di inviarli via e-mail a
            <a href="mailto:support@datawin-prediction.com">
              <b>support@datawin-prediction.com</b>
            </a>
            <h2>&nbsp;</h2>
            <br />
            <br />
            <h2>2. Chi siamo</h2>
            <br />
            <br />
            Ecco i dettagli che il Regolamento (UE) 2016/679 del Parlamento
            europeo e del Consiglio, del 27 aprile 2016, relativo alla
            protezione delle persone fisiche con riguardo al trattamento dei
            dati personali, nonché alla libera circolazione di tali dati, noto
            come Regolamento generale sulla protezione dei dati (<b>GDPR</b>)
            dice che dobbiamo darvi come "titolare del trattamento":
            <br />
            <ul>
              <li>
                L'indirizzo del nostro sito è <b>datawin.io</b>
              </li>
              <li>
                Il nome della nostra azienda è <b>DATAWIN</b>
              </li>
              <li>
                Il nostro indirizzo registrato è 219 Kensington High Street,
                Kensington, London, England, W8 6BD
              </li>
              <li>
                Il nostro [rappresentante designato o responsabile della
                protezione dei dati] può essere contattato all'indirizzo{' '}
                <b>support@datawin-prediction.com</b>
              </li>
            </ul>
            <br />
            <br />
            <br />
            <br />
            <h2>3. Cosa possiamo raccogliere</h2>
            <br />
            <br />
            Possiamo raccogliere ed elaborare i seguenti dati su di te:
            <br />
            <br />
            <ul>
              <li>
                Informazioni che lei inserisce in moduli o sondaggi sul nostro
                sito in qualsiasi momento
              </li>
              <li>Una registrazione di qualsiasi corrispondenza tra noi</li>
              <li>
                Dettagli delle transazioni che effettui attraverso il nostro
                sito
              </li>
              <li>
                Dettagli delle tue visite al nostro sito e delle risorse che usi
              </li>
              <li>
                Informazioni sul tuo computer (ad esempio il tuo indirizzo IP,
                il browser, il sistema operativo, ecc.) per l'amministrazione
                del sistema e per riportare informazioni aggregate ai nostri
                inserzionisti
              </li>
            </ul>
            Ai sensi del GDPR ci assicureremo che i tuoi dati personali siano
            trattati in modo legale, equo e trasparente, senza pregiudicare i
            tuoi diritti. Tratteremo i tuoi dati personali solo se si applica
            almeno una delle seguenti basi:
            <br />
            <ol>
              <li>
                a) hai dato il consenso al trattamento dei tuoi dati personali
                per uno o più scopi specifici;
              </li>
              <li>
                b) il trattamento è necessario per l'esecuzione di un contratto
                di cui lei è parte o per prendere provvedimenti su sua richiesta
                prima di stipulare un contratto;
              </li>
              <li>
                c) il trattamento è necessario per il rispetto di un obbligo
                legale al quale siamo soggetti;
              </li>
              <li>
                d) il trattamento è necessario per proteggere gli interessi
                vitali tuoi o di un'altra persona fisica;
              </li>
              <li>
                e) il trattamento è necessario per l'esecuzione di un compito di
                interesse pubblico o per l'esercizio dei pubblici poteri di cui
                è investito il responsabile del trattamento; e/o
              </li>
              <li>
                f) il trattamento è necessario ai fini dei legittimi interessi
                perseguiti da noi o da una terza parte, come il nostro
                trattamento dei pagamenti con carta di credito, tranne quando
                tali interessi sono prevalenti rispetto ai diritti e alle
                libertà fondamentali dell'interessato che richiedono la
                protezione dei dati personali, in particolare se l'interessato è
                un bambino.
              </li>
            </ol>
            <br />
            <br />
            <br />
            <br />
            <h2>4. Cookie</h2>
            <br />
            <br />
            [Tutti i cookie utilizzati da e sul nostro sito web sono utilizzati
            in conformità con l'attuale legge inglese e dell'UE sui cookie].
            <br />
            Il sito utilizza cookie o tecnologie simili per raccogliere
            informazioni sul tuo accesso al sito. I cookie sono pezzi di
            informazioni che includono un codice di riferimento unico che un
            sito web trasferisce al tuo dispositivo per memorizzare e talvolta
            tracciare informazioni su di te. Alcuni dei cookie che utilizziamo
            durano solo per la durata della vostra sessione web e scadono quando
            chiudete il vostro browser. Altri cookie sono utilizzati per
            ricordarti quando torni sul sito e durano più a lungo.
            <br />
            <b>
              Tutti i cookie utilizzati sul nostro sito sono impostati da noi.
            </b>
            <br />
            La maggior parte dei browser per computer e alcuni per cellulari
            accettano automaticamente i cookie ma, se preferisci, puoi cambiare
            il tuo browser per impedirlo o per avvisarti ogni volta che viene
            impostato un cookie. Potete impedire l'impostazione dei cookie
            regolando le impostazioni del vostro browser. Si prega di notare,
            tuttavia, che bloccando o eliminando i cookie potrebbe non essere in
            grado di trarre pieno vantaggio dal sito.
            <br />
            <b>I nostri cookie saranno utilizzati per:</b>
            <br />
            Gestione essenziale della sessione
            <br />
            <ul>
              <li>
                creare una sessione di accesso specifica per un utente del sito
                in modo che il sito ricordi che un utente ha effettuato
                l'accesso e che le sue richieste di pagine siano consegnate in
                modo efficace, sicuro e coerente;
              </li>
              <li>
                riconoscendo quando un utente del sito ha visitato prima di
                permetterci di identificare il numero di utenti unici che
                riceviamo al sito e assicurarci di avere abbastanza capacità per
                il numero di utenti che riceviamo;
              </li>
              <li>
                riconoscere se un visitatore del sito è registrato con noi in
                qualche modo;
              </li>
              <li>
                possiamo anche registrare informazioni dal vostro computer,
                compresa l'esistenza di cookie, il vostro indirizzo IP e
                informazioni sul programma del vostro browser, al fine di
                permetterci di diagnosticare i problemi, amministrare e
                tracciare l'utilizzo del nostro sito.
              </li>
            </ul>
            Funzionalità
            <br />
            <ul>
              <li>
                personalizzazione di elementi del layout promozionale e/o del
                contenuto delle pagine del sito.
              </li>
            </ul>
            Performance e misurazione
            <br />
            <ul>
              <li>
                raccolta di informazioni statistiche su come i nostri utenti
                utilizzano il sito in modo da poter migliorare il sito e
                imparare quali parti sono più popolari per gli utenti].
              </li>
            </ul>
            <b>O</b>
            <br />
            [Utilizziamo i cookie per distinguere gli utenti e migliorare il
            nostro sito. Si prega di consultare la nostra Cookie Policy{' '}
            <a href="https://datawin.io">
              <b>datawin.io</b>
            </a>{' '}
            per ulteriori informazioni sui cookie].
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>5. Come usiamo ciò che raccogliamo</h2>
            <br />
            <br />
            Utilizziamo le informazioni su di te per:
            <br />
            <ul>
              <li>Presentarti il contenuto del sito in modo efficace.</li>
              <li>
                Fornire informazioni, prodotti e servizi da te richiesti o (con
                il tuo consenso) che riteniamo possano interessarti.
              </li>
              <li>Eseguire i nostri contratti con te.</li>
              <li>
                Permetterti di utilizzare i nostri servizi interattivi se lo
                desideri.
              </li>
              <li>Dirle le nostre tariffe.</li>
              <li>
                Parlarti di altri beni e servizi che potrebbero interessarti.
                Permetteremo anche ad altre persone di farlo, e noi (o loro)
                potremmo contattarla.
              </li>
            </ul>
            Se lei è già nostro cliente, la contatteremo elettronicamente solo
            per cose simili a ciò che le è stato venduto in precedenza.
            <br />
            Se sei un nuovo cliente, sarai contattato solo se sei d'accordo.
            <br />
            Se non vuole essere contattato per scopi di marketing, spunti
            l'apposita casella che troverà sullo schermo. &nbsp;
            <br />
            Nota: non identifichiamo gli individui ai nostri inserzionisti, ma
            diamo loro informazioni aggregate per aiutarli a raggiungere il loro
            pubblico di riferimento, e possiamo utilizzare le informazioni che
            abbiamo raccolto per mostrare pubblicità a quel pubblico.
            <br />
            Inoltre, se non vuoi che usiamo i tuoi dati personali per una
            qualsiasi delle altre ragioni indicate in questa sezione al punto 5,
            puoi farcelo sapere in qualsiasi momento contattandoci all'indirizzo
            [Indirizzo email], e noi cancelleremo i tuoi dati dai nostri
            sistemi. Tuttavia, lei riconosce che questo limiterà la nostra
            capacità di fornirle i migliori servizi possibili [prodotti e]
            servizi.
            <br />
            In alcuni casi, la raccolta di dati personali può essere un
            requisito di legge o contrattuale, e saremo limitati nei [prodotti
            e] servizi che possiamo fornirvi se non fornite i vostri dati
            personali in questi casi.
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>6. Dove conserviamo i suoi dati</h2>
            <br />
            <br />
            Possiamo trasferire i suoi dati raccolti per l'archiviazione al di
            fuori dello Spazio economico europeo (SEE). Potrebbero essere
            trattati al di fuori dello SEE per evadere l'ordine e gestire il
            pagamento.
            <br />
            Fornendoci i suoi dati personali, lei accetta questa disposizione.
            Faremo quanto ragionevolmente possibile per mantenere i suoi dati al
            sicuro.
            <br />
            Il pagamento sarà criptato. Se ti diamo una password, devi
            mantenerla riservata. Si prega di non condividerla. Anche se
            cerchiamo di fornire protezione, non possiamo garantire la completa
            sicurezza dei suoi dati, e lei si assume il rischio che qualsiasi
            invio di tali dati risulti non sicuro nonostante i nostri sforzi.
            <br />
            Conserviamo i suoi dati personali solo per il tempo necessario al
            fine di utilizzarli come descritto sopra nella sezione 5, e/o per il
            tempo in cui abbiamo il suo permesso di conservarli. In ogni caso,
            condurremo una revisione [annuale] per accertare se abbiamo bisogno
            di conservare i suoi dati personali. I suoi dati personali saranno
            cancellati se non ne abbiamo più bisogno.
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>7. I suoi diritti</h2>
            <br />
            <br />
            Potete chiederci di non utilizzare i vostri dati per il marketing.
            Può farlo spuntando le caselle pertinenti sui nostri moduli, o
            contattandoci in qualsiasi momento a 219 Kensington High Street,
            Kensington, London, England, W8 6BD / support@datawin-prediction.com
            <br />
            Ai sensi del GDPR, hai il diritto di:
            <br />
            <ul>
              <li>
                richiedere l'accesso, la cancellazione o la correzione dei tuoi
                dati personali in nostro possesso senza alcun costo per te;
                &nbsp;
              </li>
              <li>
                richiedere che i tuoi dati personali siano trasferiti ad
                un'altra persona (portabilità dei dati); &nbsp;
              </li>
              <li>essere informati sul trattamento dei dati in corso;</li>
              <li>limitare il trattamento;</li>
              <li>opporsi al trattamento dei suoi dati personali; </li>
              <li>presentare un reclamo a un'autorità di controllo.</li>
            </ul>
            <br />
            Lei ha anche diritti rispetto al processo decisionale automatizzato
            e alla profilazione come indicato nella sezione 11 di seguito.
            &nbsp;
            <br />
            Per far valere uno qualsiasi dei suddetti diritti o se hai altre
            domande sul nostro sito o sulla presente Informativa sulla privacy,
            contattaci all'indirizzo [support@datawin-prediction.com].
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>9. Link ad altri siti</h2>
            <br />
            <br />
            Si prega di notare che le nostre condizioni sulla privacy e le
            nostre politiche non si applicano ad altri siti web a cui si arriva
            tramite un link dal nostro sito. Non abbiamo alcun controllo su come
            i vostri dati vengono raccolti, memorizzati o utilizzati da altri
            siti web e vi consigliamo di controllare le politiche sulla privacy
            di tali siti web prima di fornire loro qualsiasi dato.
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>10. Modifiche</h2>
            <br />
            <br />
            Se cambiamo la nostra politica sulla privacy, pubblicheremo le
            modifiche su questa pagina. Se decidiamo di farlo, potremmo anche
            inviarvi un'e-mail.
            <br />
            <br />
            <ol>
              <li>
                <b>Processo decisionale automatizzato e profilazione</b>
              </li>
            </ol>
            11. 1 Nel caso in cui utilizziamo i dati personali ai fini del
            processo decisionale automatizzato e tali decisioni hanno un effetto
            legale (o similmente significativo) su di te, hai il diritto di
            contestare tali decisioni ai sensi del GDPR, richiedendo
            l'intervento umano, esprimendo il proprio punto di vista, e
            ottenendo una spiegazione della decisione da noi.
            <br />
            11. 2 Il diritto descritto nella sezione 11. 1 non si applica nelle
            seguenti circostanze:
            <br />
            <ol>
              <li>
                a) la decisione è necessaria per la stipula o l'esecuzione di un
                contratto tra voi e noi;
              </li>
              <li>b) la decisione è autorizzata dalla legge; o</li>
              <li>c) lei ha dato il suo esplicito consenso.</li>
            </ol>
            11. 3 Quando utilizziamo i suoi dati personali per scopi di
            profilazione, si applica quanto segue:
            <br />
            <ol>
              <li>
                a) Verranno fornite informazioni chiare che spiegano la
                profilazione, compreso il suo significato e le probabili
                conseguenze;
              </li>
              <li>
                b) Verranno utilizzate procedure matematiche o statistiche
                appropriate;
              </li>
              <li>
                c) saranno attuate le misure tecniche e organizzative necessarie
                per ridurre al minimo il rischio di errori e per consentire di
                correggerli facilmente; e
              </li>
              <li>
                d) tutti i dati personali trattati a fini di profilazione
                saranno protetti al fine di evitare effetti discriminatori
                derivanti dalla profilazione.
              </li>
            </ol>
            <br />
            <br />
            <br />
            <br />
            <h2>12. Risoluzione delle controversie</h2>
            <br />
            <br />
            12. 1 Le parti faranno del loro meglio per negoziare in buona fede e
            risolvere qualsiasi controversia che possa derivare da o riguardare
            la presente Informativa sulla privacy o qualsiasi violazione della
            stessa.
            <br />
            <br />
            12. 2 Se tale controversia non può essere risolta in via amichevole
            attraverso trattative ordinarie tra le parti, o se una o entrambe
            non sono disposte a impegnarsi in questo processo, ciascuna parte
            può proporre all'altra per iscritto che vengano avviate trattative
            strutturate con l'assistenza di un mediatore pienamente accreditato
            prima di ricorrere al contenzioso.
            <br />
            <br />
            12. 3 Se le parti non sono in grado di concordare un mediatore, o se
            il mediatore concordato non è in grado o non vuole agire e non è
            possibile concordare un mediatore alternativo, qualsiasi parte può,
            entro 14 giorni dalla data di conoscenza di uno dei due eventi,
            chiedere a LawBite di nominare un mediatore secondo la Procedura di
            Mediazione LawBite.
            <br />
            <br />
            12. 4 Entro 14 giorni dalla nomina del mediatore (di comune accordo
            tra le parti o da LawBite in conformità con la loro procedura di
            mediazione), le parti si incontreranno con il mediatore per
            concordare la procedura da adottare per la mediazione, salvo diverso
            accordo tra le parti e il mediatore.
            <br />
            <br />
            12. 5 Tutte le trattative connesse alla/e controversia/e in
            questione saranno condotte in modo confidenziale e senza
            pregiudicare i diritti delle parti in qualsiasi ulteriore
            procedimento.
            <br />
            <br />
            12. 6 Se le parti concordano una risoluzione della controversia
            durante la mediazione, l'accordo sarà ridotto per iscritto e, una
            volta firmato dai rappresentanti debitamente autorizzati di entrambe
            le parti, sarà definitivo e vincolante per esse.
            <br />
            <br />
            12. 7 Se le parti non riescono a risolvere la(e) controversia(i)
            entro 60 giorni (o un termine più lungo concordato tra le parti)
            dalla nomina del mediatore, o se una delle parti si ritira dalla
            procedura di mediazione, allora una delle parti può esercitare il
            diritto di cercare un rimedio attraverso l'arbitrato da parte di un
            arbitro che sarà nominato da LawBite secondo il regolamento del
            LawBite Arbitration Scheme.
            <br />
            <br />
            12. 8 Qualsiasi controversia non pregiudica gli obblighi in corso
            delle parti ai sensi della presente Informativa sulla privacy.
            <br />
          </div>
        </div>
      </div>
    );
}

export default PrivacyScreen;
