import React from 'react';
import Box from '@material-ui/core/Box';

const Logo: React.FC<{
  src: string;
  width?: number;
  imgWidth?: string;
  imgHeight?: string;
  alt?: string;
  noBg?: boolean;
  club?: boolean;
  grey?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
}> = ({
  src,
  width,
  alt,
  imgWidth,
  imgHeight,
  noBg,
  club,
  grey,
  disabled,
  style,
}) => {
  return (
    <Box
      borderRadius="50%"
      bgcolor={noBg ? 'transparent' : 'white'}
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={width || 'inherit'}
      height={width || 'inherit'}
      minWidth={width}
      boxShadow={noBg ? '' : '0 6px 25px -4px rgba(118, 148, 255, 0.42)'}
      overflow="hidden"
      style={{
        filter: grey ? 'grayscale(1)' : '',
        opacity: disabled ? 0.48 : 1,
        ...(style || {}),
      }}
    >
      <img
        height={imgHeight || '85%'}
        width={imgWidth || (club ? 'auto' : '85%')}
        alt={alt || ''}
        style={{ borderRadius: '50%' }}
        src={src}
      />
    </Box>
  );
};

export default Logo;
