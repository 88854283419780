import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './AddCardScreen.scss';
import { loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import badge_stripe from '../../../images/badge_stripe.png';
import Loading_payment from '../../Loading_payment/Loading_payment';
import badges from '../../../images/badges.png';
import { Fire } from 'services';
import {
  LoggedButton,
  LoggedRoute,
  Prono,
  Button,
  Input,
  BackButton,
} from 'reusable';
import { useTranslation, initReactI18next } from 'react-i18next';

import { addCard } from 'actions/cards.action';
import { refreshUser } from 'actions/auth.action';
import { Elements } from '@stripe/react-stripe-js';

import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme, createStyles, Theme } from '@material-ui/core';
import CardGeneral from '../../Dashboard/Templates/Cards/CardGeneral';
import ButtonFilled from '../../Dashboard/Templates/Buttons/ButtonFilled';
import TitleCard from '../../Dashboard/Templates/Account/TitleCard';
import Navbar from '../../Dashboard/Templates/Navbar/Navbar';
import { Grid, Box } from '@material-ui/core';

const creditCardType = require('credit-card-type');
const key =
  process.env.NODE_ENV == 'production'
    ? process.env.REACT_APP_STRIPE_API_KEY
    : process.env.REACT_APP_STRIPE_API_KEY;
const stripePromise = loadStripe(key!);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dashboard: {
      padding: '0 10px',
      maxWidth: '100vw',
      minHeight: '100vh',
    },
    container: {
      maxWidth: 1300,
      margin: 'auto',
      width: '100%',
    },
    snackbar: {
      position: 'static',
    },
    creditCardContainer: {},
    creditCard: {},
    cardContainer: {
      width: '100%',
    },
  }),
);

function AddCard() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const spacing = mobile ? 3 : 6;

  const user = useSelector((state: any) => state.authReducer.user);
  const [info, setInfo] = React.useState<any>({ ...user });
  const [messageOpen, setMessageOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [error, setError] = React.useState('');

  const id = document.location.href.split('/')[4];

  if (user.cardId !== id) history.push('/account');

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorOpen(false);
    setMessageOpen(false);
  };

  const showError = message => {
    setMessageOpen(false);
    setErrorOpen(true);
    setMessage(message);
  };

  const showSuccess = message => {
    setErrorOpen(false);
    setMessageOpen(true);
    setMessage(message);
  };

  // React.useEffect(() => {
  //   (async () => {
  //     try {
  //       alert("ok")
  //       await Fire.cloud('addCard', { cardId: 'lol' })
  //     } catch (err) {
  //       alert(JSON.stringify(err))
  //     }
  //   })()
  // }, [])

  // const showError = err => {
  //   setError(err);
  //   // window.scrollTo(0, 0)
  // };

  const updateCard = async () => {
    if (!stripe || !elements) return;

    const confirm = window.confirm(
      t('Souhaitez-vous changer votre moyen de paiement par défaut ?'),
    );
    if (!confirm) return;

    setLoading(true);

    try {
      const cardElement = elements!.getElement(CardElement);
      if (!cardElement) return;
      const { error, paymentMethod } = await stripe!.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {
        showError(t('Une erreur est survenue : ' + error.message));
        console.log(error);
      } else if (paymentMethod && paymentMethod.card) {
        try {
          await Fire.cloud('addCard', { cardId: paymentMethod.id });
          const stored = {
            last4: paymentMethod.card.last4,
            expiry:
              paymentMethod.card.exp_month + '/' + paymentMethod.card.exp_year,
            type: paymentMethod.card.brand,
            cardId: paymentMethod.id,
          };
          await dispatch(addCard(stored));
          // await register();
          await dispatch(
            refreshUser({
              cardId: paymentMethod.id,
            }),
          );

          // USE CARD
          try {
            await Fire.cloud('updateCard', { cardId: paymentMethod.id });
            await dispatch(
              refreshUser({
                cardId: paymentMethod.id,
              }),
            );
            showSuccess('Carte mise à jour');
            alert('Carte mise à jour');
            history.push('account');
          } catch (error) {}
        } catch (error) {
          showError('Carte invalide');
          return;
        }
      }
    } catch (err) {
      showError('Ajout de cb impossible. Veuillez vérifier votre connexion.');
      // alert(
      //   'Ajout de cb impossible. Veuillez vérifier votre connexion: ' +
      //     JSON.stringify(err),
      // );
      console.log(err);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Box className={classes.dashboard}>
      <Grid container spacing={spacing} className={classes.container}>
        <Grid item xs={12}>
          <Navbar />
        </Grid>
        <Grid item xs={12}>
          <CardGeneral shadow>
            <Box key="settings" width="100%" height="100%" padding="2rem">
              <TitleCard
                title={t('Paramètres - Réinitialiser le mot de passe')}
              />
              <Box
                className={classes.creditCardContainer}
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  width="45%"
                  minWidth="270px"
                  maxWidth="520px"
                >
                  <img
                    className="badge_stripe"
                    src={badge_stripe}
                    style={{
                      maxHeight: 30,
                      maxWidth: '30%',
                      margin: '0 0 5px auto',
                    }}
                  />
                  <Box
                    className={classes.creditCard}
                    border="2px solid #ced4da"
                    borderRadius="4px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    margin=".5rem auto"
                    padding="1rem 1.5rem"
                    width="100%"
                    maxWidth="500px"
                  >
                    <Box className={classes.cardContainer}>
                      <CardElement
                        // onChange={handleCardChange}
                        options={{
                          style: {
                            base: {
                              fontSize: '16px',
                              color: '#424770',
                              '::placeholder': {
                                color: '#aab7c4',
                              },
                            },
                            invalid: {
                              color: '#9e2146',
                            },
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <img
                    className="badges"
                    src={badges}
                    style={{
                      maxHeight: '50px',
                      maxWidth: '250px',
                      margin: '10px auto',
                    }}
                  />
                </Box>

                <Box
                  display="flex"
                  justifyContent={mobile ? 'flex-end' : 'center'}
                  alignItems="center"
                  width={mobile ? '100%' : 'fit-content'}
                  height="auto"
                  marginTop={mobile ? '1rem' : 0}
                >
                  <ButtonFilled
                    title={loading ? 'Ajout en cours...' : 'Modifier ma carte'}
                    onPress={() => updateCard()}
                    disabled={loading}
                  />
                </Box>

                {error && (
                  <div className="error-box">
                    <div className="error">{error}</div>
                  </div>
                )}
                {loading && <Loading_payment />}
              </Box>
              <Snackbar
                open={messageOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                key={'bottom_success'}
                className={classes.snackbar}
              >
                <Alert onClose={handleClose} severity="success">
                  {t(message)}
                </Alert>
              </Snackbar>
              <Snackbar
                open={errorOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                key={'bottom_error'}
                className={classes.snackbar}
              >
                <Alert onClose={handleClose} severity="error">
                  {t(message)}
                </Alert>
              </Snackbar>
            </Box>
          </CardGeneral>
        </Grid>
      </Grid>
    </Box>
  );
}

const AddCardScreen = () => {
  const { i18n } = useTranslation();

  return (
    <Elements
      stripe={stripePromise}
      options={{
        locale: i18n.language as StripeElementLocale | undefined,
      }}
    >
      <AddCard />
    </Elements>
  );
};

export default AddCardScreen;
