import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { saveInfo, updateUser } from 'actions/auth.action';
import './PaymentScrenExtern.scss';
import { useTranslation } from 'react-i18next';
import { Fire } from 'services';
import Particles from 'reusable/Particles/Particles';
import { Button, Input } from 'reusable';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Box from '@material-ui/core/Box';
import ExternPaymentLoading from '../../Dashboard/ExternPayment/ExternPaymentLoading/ExternPaymentLoading';

const PHONE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/im;

const ERROR_PHONE_BIRTH =
  'Veuillez saisir votre numéro de téléphone et votre date de naissance';
const ERROR_PHONE = 'Veuillez saisir votre numéro de téléphone';
const ERROR_BIRTH = 'Veuillez saisir votre date de naissance';
const ERROR_PHONE_REGEX = 'Le numéro de téléphone est incorrect';

const updateUserPassword = async (
  uid: string,
  newPassword: string,
): Promise<any> => {
  try {
    Fire.auth().currentUser?.updatePassword(newPassword);
    return true;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const steps = ['Signup', 'More infos'];

function Signup() {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const history = useHistory();
  const user_already = useSelector((state: any) => state.authReducer.user);
  const [data, setData] = useState({
    birth: user_already?.birth || '',
    phone:
      (user_already?.phone !== 'Non renseigné' && user_already?.phone) || '',
    password: '',
  });
  const [passwordType, setPasswordType] = React.useState('password');
  const [activeStep, setActiveStep] = React.useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user_already && !user_already.state?.isExternRegistering)
      history.replace('/pronos');
  }, [history, user_already]);

  const showError = err => {
    setError(err);
    window.scrollTo(0, 0);
  };

  const onKeyPress1 = event => {
    if (event.charCode === 13) {
      addInfos1();
    }
  };
  const onKeyPress2 = event => {
    if (event.charCode === 13) {
      addInfos2();
    }
  };

  const addInfos1 = async () => {
    setLoading(true);
    try {
      await updateUserPassword(user_already.uid, data.password);
      setActiveStep(1);
    } catch (err) {
      console.log(err.code);
      switch (err.code) {
        case 'auth/email-already-in-use':
          showError(t("L'email entré est déjà utilisé. Connectez-vous."));
          break;

        case 'auth/invalid-password':
          showError(t('Identifiants invalides'));
          break;

        case 'auth/user-not-found':
          showError(t('Identifiants invalides'));
          break;

        case 'auth/invalid-email':
          showError(t("L'email entré est invalide"));
          break;

        case 'auth/weak-password':
          showError(t('Mot de passe trop faible'));
          break;

        default:
          showError(t('Une erreur est survenue'));
          break;
      }
      // handleSubmit();
    }
    setLoading(false);
  };

  const addInfos2 = async () => {
    setLoading(true);
    const { phone, birth } = data;
    if (!phone && !birth) return setError(t(ERROR_PHONE_BIRTH));
    if (!phone) return setError(t(ERROR_PHONE));
    if (!birth) return setError(t(ERROR_BIRTH));
    if (!phone.match(PHONE_REGEX)) return setError(t(ERROR_PHONE_REGEX));

    const validPhone = phone.toString().replace(/ /g, '').split('.').join('');
    const validBirth = birth.split('-').reverse().join('-'); // Reverse of the birth date to have dd-mm-yyyy instead of yyyy-mm-dd
    window.dataLayer.push({
      event: 'subscription',
    });
    dispatch(
      saveInfo({
        phone: validPhone,
        birth: validBirth,
        state: {
          ...user_already.state,
          isExternRegistering: false,
        },
      }),
    );
    dispatch(
      updateUser({
        phone: validPhone,
        birth: validBirth,
        state: {
          ...user_already.state,
          isExternRegistering: false,
        },
      }),
    );
    history.push('/pronos');
    setLoading(false);
  };

  if (!user_already) return <ExternPaymentLoading />;
  return (
    <div className="signup-screen">
      {/* <HeaderBar /> */}
      <Particles />

      <div className="signup-container background top-padding">
        <div className="row-center">
          <div className="signup-outbox">
            <div className="info-signup-form red-box">
              <div className="page-title">{user_already.first_name || ''}</div>
              <div className="page-subtitle">
                {t("Plus qu'une étape pour obtenir les analyses de Datawin")}
              </div>
              {/* @ts-ignore */}
              <Box sx={{ width: '70%', margin: '1.5rem auto' }}>
                <Stepper
                  activeStep={activeStep}
                  style={{ background: 'transparent', padding: '1rem 0' }}
                >
                  {steps.map(label => {
                    return (
                      <Step key={label}>
                        <StepLabel
                          classes={{
                            root: 'stepper-circle',
                          }}
                        />
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
              {activeStep === 0 ? (
                <form>
                  <div className="row-center noWrap">
                    <Input
                      title={t('Email') + ' :'}
                      placeholder="example@gmail.com"
                      value={user_already.email}
                      onKeyPress={onKeyPress1}
                      dark
                      disabled
                    />
                  </div>
                  <div className="row-center noWrap">
                    <div className="password_container_login">
                      <Input
                        title={t('Mot de passe') + ' :'}
                        placeholder={t('Mot de passe')}
                        type={passwordType}
                        value={user_already.password}
                        onKeyPress={onKeyPress1}
                        onChange={val => setData({ ...data, password: val })}
                        icon={
                          passwordType === 'password' ? (
                            <Visibility
                              fontSize="inherit"
                              color="inherit"
                              onClick={() => setPasswordType('text')}
                              className="visibility-eye"
                              style={{ height: 40 }}
                            />
                          ) : (
                            <VisibilityOff
                              fontSize="inherit"
                              color="inherit"
                              onClick={() => setPasswordType('password')}
                              className="visibility-eye"
                              style={{ height: 40 }}
                            />
                          )
                        }
                      />
                    </div>
                  </div>
                  {error && (
                    <div className="error-box" style={{ textAlign: 'center' }}>
                      <div className="error">{error}</div>
                    </div>
                  )}
                  <div className="row-center" style={{ marginTop: 25 }}>
                    <Button
                      title={loading ? t('Chargement...') : t('Continuer')}
                      onPress={addInfos1}
                      classes={['linear-btn', 'big-btn', 'signup-btn']}
                      type="submit"
                    />
                  </div>
                </form>
              ) : (
                <form>
                  <div className="row-center noWrap">
                    <Input
                      title={t('Date de naissance') + ' :'}
                      type="date"
                      value={data.birth}
                      onKeyPress={onKeyPress2}
                      onChange={val => setData({ ...data, birth: val.trim() })}
                      dark
                    />
                  </div>
                  <div className="row-center noWrap">
                    <Input
                      title={t('Numéro de téléphone') + ' :'}
                      placeholder="0612345678"
                      value={data.phone}
                      onKeyPress={onKeyPress2}
                      onChange={val => setData({ ...data, phone: val.trim() })}
                      dark
                    />
                  </div>
                  {error && (
                    <div className="error-box" style={{ textAlign: 'center' }}>
                      <div className="error">{error}</div>
                    </div>
                  )}
                  <div className="row-center" style={{ marginTop: 25 }}>
                    <Button
                      title={
                        loading
                          ? t('Chargement...')
                          : t('Accédez aux analyses !')
                      }
                      onPress={addInfos2}
                      classes={['linear-btn', 'big-btn', 'signup-btn']}
                      type="submit"
                    />
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
