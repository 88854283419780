import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './App.scss';
import ScrollToTop from 'react-router-scroll-top';
import { Fire } from 'services';
import { autologin, finishLogin, updateUser } from './actions/auth.action';
import routes from './routes';
import { lightTheme } from './theme/_datawinTheme';
import { ThemeProvider } from '@material-ui/styles';
import { Sport } from './components/Dashboard/Home/ToggleSport';
import { getCollectionPath } from './components/Dashboard/Pronos/Pronos';
import { getPronosDate } from './components/Dashboard/utils/prono';
import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/it';
import { getGeolocation } from 'actions/geolocation.action';
import { Helmet } from 'react-helmet';
import { getUserLocation } from './utils/countryCodeAPI';
import { darkTheme } from './theme/_datawinDarkTheme';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    ml: true;
    lg: true;
    xl: true;
  }
}

const VerifyUserVIP = async id => {
  let breakF = false;
  let isVIP = false;
  try {
    const customerRef = await Fire.store()
      .collection('stripe_customers')
      .doc(id);
    const customer = await Fire.doc(customerRef);
    if (!customer) return null;
    let customer_infos;
    if (customer) {
      customer_infos = await Fire.cloud('listSubsByCustomer', {
        customerId: customer.customer_id,
      });
    }
    customer_infos?.data?.forEach(sub => {
      if (
        (sub.status === 'active' ||
          sub.status === 'trialing' ||
          sub.status === 'past_due') &&
        !breakF
      ) {
        isVIP = true;
        breakF = true;
      }
    });
    if (!isVIP) {
      const prosRef = await Fire.store().collection('pros').doc(id);
      await prosRef.get().then(pro => {
        if (pro.exists) {
          isVIP = true;
        } else {
          isVIP = false;
        }
      });
    }
    Fire.update('users', id, { vip: isVIP });
    return isVIP;
  } catch (error) {
    console.log(error);
  }
};

const VerifyUserLoc = async (id, dispatch) => {
  try {
    const customerRef = await Fire.store().collection('users').doc(id);
    const customer = await Fire.doc(customerRef);
    if (customer && !customer.location?.countryCode) {
      const data = await getUserLocation();
      dispatch(
        updateUser({
          location: {
            state: data?.regionName || '',
            countryCode: data?.countryCode || 0,
          },
        }),
      );
    }
    return;
  } catch (error) {
    console.log(error);
  }
};

const isUserInfoValid = user => {
  if (!user) return true;
  return (
    !!user.email?.trim().length &&
    !!user.last_name?.trim().length &&
    !!user.first_name?.trim().length
  );
};

const isThereValidPronos = prono => {
  const now = new Date().getTime() / 1000;
  return (
    !prono.hide_until ||
    (prono.team1 && prono.hide_until?.seconds <= now && prono.team1?.name)
  );
};
const isThereFuturePronos = prono => {
  const now = new Date().getTime() / 1000;
  return !prono.team1 || (prono.hide_until && prono.hide_until.seconds) > now;
};

const ALL_SPORT: Sport[] = [
  'FOOTBALL',
  'TENNIS',
  'NBA',
  'NFL',
  'NHL',
  'BASEBALL',
];

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.authReducer.user);
  const [loaded, setLoaded] = React.useState(false);
  const [title, setTitle] = React.useState(
    'Datawin - Les meilleures infos sportives',
  );
  const history = useHistory();
  const { i18n } = useTranslation();
  const selectedSport: Sport = useSelector(
    (state: any) => state.sportsReducer.selectedSport,
  );
  const initializeLanguage = (): void => {
    const query = new URLSearchParams(history.location.search);
    const language = query.get('lang');
    moment.locale(i18n.language);
    if (language) i18n.changeLanguage(language.toLowerCase());
  };

  const changeSport = (newSport: Sport) => {
    dispatch({ type: 'CHANGE_SPORT', payload: { newSport } });
  };

  const getAllPronos = async collection => {
    const collectionPath = getCollectionPath(collection);
    const ref = await Fire.store()
      .collection(collectionPath)
      .where('date', '>=', getPronosDate());
    return Fire.list(ref);
  };

  const isProUser = async (email: string) => {
    const ref = Fire.store().collection('pros').where('email', '==', email);
    const list = await Fire.list(ref);
    return list.length !== 0;
  };

  const verifyPronos = async () => {
    let indexSelectedSport = ALL_SPORT.indexOf(selectedSport);
    ALL_SPORT.splice(indexSelectedSport, 1);
    ALL_SPORT.unshift(selectedSport);
    const promises = ALL_SPORT.map(async (sport: Sport) => getAllPronos(sport));
    const allPronos = await Promise.all(promises);
    const isPro = await isProUser(user?.email || '');

    const filteredPronos = allPronos.map(pronos =>
      pronos
        .filter(prono =>
          prono.isPronoTest || prono.name_inactive ? isPro : true,
        )
        .filter(prono =>
          selectedSport === 'NBA' ||
          selectedSport === 'NFL' ||
          selectedSport === 'NHL'
            ? user?.location?.countryCode === 'US'
              ? prono.matchOddsUSActive && prono.matchOddsUS
              : true
            : true,
        ),
    );

    let isValidPronoAvailable = false;
    let collection;
    let isFuturePronoAvailable = false;
    let futureCollection;

    filteredPronos.forEach((collectionProno, i) => {
      dispatch({
        type: 'ADD_PRONOS',
        payload: { sport: ALL_SPORT[i], pronos: collectionProno },
      });
      if (!isValidPronoAvailable && collectionProno.length !== 0) {
        if (collectionProno.some(isThereValidPronos)) {
          isValidPronoAvailable = true;
          collection = ALL_SPORT[i];
        }
        if (
          !isFuturePronoAvailable &&
          collectionProno.some(isThereFuturePronos)
        ) {
          isFuturePronoAvailable = true;
          futureCollection = ALL_SPORT[i];
        }
      }
    });

    collection
      ? changeSport(collection)
      : futureCollection && changeSport(futureCollection);
  };

  const refreshPronos = async () => {
    const promises = ALL_SPORT.map(async (sport: Sport) => getAllPronos(sport));
    const allPronos = await Promise.all(promises);
    const isPro = await isProUser(user?.email || '');

    const filteredPronos = allPronos.map(pronos =>
      pronos
        .filter(prono =>
          prono.isPronoTest || prono.name_inactive ? isPro : true,
        )
        .filter(prono =>
          selectedSport === 'NBA' ||
          selectedSport === 'NFL' ||
          selectedSport === 'NHL'
            ? user.location?.countryCode === 'US'
              ? prono.matchOddsUSActive && prono.matchOddsUS
              : true
            : true,
        ),
    );

    filteredPronos.forEach((collectionProno, i) => {
      if (collectionProno.length !== 0) {
        dispatch({
          type: 'ADD_PRONOS',
          payload: { sport: ALL_SPORT[i], pronos: collectionProno },
        });
      }
    });
  };

  const verifyFirstConnexion = user => {
    if (user.isFirstConnexion === undefined) {
      const isFirstConnexion = {
        database: true,
        home1: true,
        home2: true,
        player: true,
        prono: true,
        pronos: true,
      };
      user.isFirstConnexion = isFirstConnexion;
      Fire.update('users', user.id, {
        isFirstConnexion,
      });
    }
  };

  React.useEffect(() => {
    if (
      !isUserInfoValid(user) &&
      !history.location.pathname.endsWith('oauth')
    ) {
      history.push('/secure');
    }
  }, [history, user]);

  React.useEffect(() => {
    initializeLanguage();
    Fire.init();
    dispatch(getGeolocation());
    Fire.auth().onAuthStateChanged(async user => {
      if (user?.uid) {
        VerifyUserVIP(user?.uid);
        VerifyUserLoc(user?.uid, dispatch);
        dispatch(autologin(user));
        await dispatch(finishLogin(i18n.language));
        await verifyPronos();
      }
      setLoaded(true);
    });
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setInterval(() => {
      refreshPronos();
    }, 1000 * 60 * 5);
  }, []);

  React.useEffect(() => {
    if (user) verifyFirstConnexion(user);
  }, [user]);

  React.useEffect(() => {
    switch (i18n.language) {
      case 'en':
        setTitle('Datawin - The Sports news');
        break;
      case 'it':
        setTitle('Datawin - Il primo algoritmo sportive');
        break;
      case 'es':
        setTitle('Datawin - El primer algoritmo deportivas');
        break;
      case 'fr':
      default:
        setTitle('Datawin - Les meilleures infos sportives');
    }
  }, [i18n.language]);

  if (!loaded) return null;

  const userRoutes = routes.filter(route => {
    if (route.both === true) return true;
    if (!route.userLogged && !user) return true;
    if (route.userLogged && user) return true;
    return false;
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ScrollToTop>
        <Switch>
          {userRoutes.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </ScrollToTop>
    </ThemeProvider>
  );
}

export default App;
