import React from 'react';
import './Button.scss';

type Props = {
  title: string;
  subTitle?: string;
  style?: any;
  classes?: string[];
  disabled?: boolean;
  onPress?: () => void;
  icon?: boolean;
  small?: boolean;
  type?: string;
}
function Button(props: Props) {
  return (
    <div
      className={["global-btn", props.disabled ? "disabled" : ""].concat(props.classes || []).join(" ")}
      onClick={!props.disabled ? props.onPress : undefined}
      style={props.style}
      data-testid="global-btn"
    >
      <div className="button-title">
        {props.title}
        {props.subTitle != undefined ?

          <div className="sub">{props.subTitle}</div>
          :
          ""}
      </div>
      {props.icon ? <svg className={props.small ? "arrow_btn_small" : "arrow_btn"} viewBox="0 0 32 32" version="1.1">
        {/* {false ? <svg className={props.small ? "arrow_btn_small" : "arrow_btn"} viewBox="0 0 32 32" version="1.1"> */}
        <g id="icon-arrow-faq">
          <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" id="Oval" fill="#000000" fillRule="evenodd" stroke="none" />
          <path d="M6.47709 1.40821L6.47709 2.78501C6.47709 3.47111 5.98189 4.05681 5.30539 4.17091L1.23912 4.85661C0.52375 4.97731 0 5.59671 0 6.32221C0 7.06311 0.54572 7.69091 1.27944 7.79401L5.26729 8.35441C5.96109 8.45191 6.47709 9.04551 6.47709 9.74621L6.47709 10.6219C6.47709 11.8171 7.87429 12.4668 8.78829 11.6966L14.1167 7.20571C14.7721 6.65331 14.7847 5.64791 14.1432 5.07931L8.81479 0.356413C7.90799 -0.447306 6.47709 0.196413 6.47709 1.40821Z" transform="translate(8.32031 10.035386)" id="Path" fill="#FFFFFF" fillRule="evenodd" stroke="none" />
        </g></svg> : null}

    </div >
  );
}

export default Button;
