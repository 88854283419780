import React from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { useTranslation, initReactI18next } from 'react-i18next';

const TitleCard = ({ title }) => {
  const { t, i18n } = useTranslation();

  return (
    <Box marginBottom="2rem" lineHeight="2rem">
      <Typography variant="h4" component={'span'}>
        <Box marginBottom=".5rem">{t(title)}</Box>
      </Typography>
    </Box>
  );
};

export default TitleCard;
