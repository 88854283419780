import React from 'react';
import { Box } from '@material-ui/core';
type Props = {
  width: any;
  height: any;
  image: string;
  classes?: string[];
  onPress?: () => void;
  disabled?: boolean;
  label?: string;
  marginRight?: any;
  selected?: boolean;
};
function Button(props: Props) {
  const style = {
    width: props.width,
    height: props.width,
    backgroundImage: `url(${props.image})`,
    cursor: 'pointer',
    backgroundSize: props.selected ? 'cover' : 'contain',
    backgroundPosition: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    marginRight: props.selected ? 0 : props.marginRight,
  };
  return props.selected ? (
    <Box
      borderRadius="50%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      border="solid 2px #848ca5"
      padding="2px"
      width={props.width + 10}
      height={props.width + 10}
      marginRight={props.marginRight}
    >
      <button
        className={[props.disabled ? 'disabled' : '']
          .concat(props.classes || [])
          .join(' ')}
        onClick={!props.disabled ? props.onPress : undefined}
        style={style}
      >
        {props.label ? props.label : ''}
      </button>
    </Box>
  ) : (
    <button
      className={[props.disabled ? 'disabled' : '']
        .concat(props.classes || [])
        .join(' ')}
      onClick={!props.disabled ? props.onPress : undefined}
      style={style}
    >
      {props.label ? props.label : ''}
    </button>
  );
}

export default Button;
