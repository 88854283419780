import { handleActions } from 'redux-actions';
import { createActionThunk } from 'redux-thunk-actions';
import { getDeviceGeoLocation } from '../utils/ip';
import { Geolocation } from '../types/geolocation';

export type CombinedPayloads = Geolocation;
export type State = Geolocation | null;

export const getGeolocation = createActionThunk(
  'GET_GEOLOCATION',
  async (): Promise<Geolocation | null> => {
    return await getDeviceGeoLocation();
  },
);

const initialState: State = null;
export const geolocationReducer = handleActions<State, CombinedPayloads>(
  {
    GET_GEOLOCATION_SUCCEEDED: (state: State, action) => {
      return action.payload;
    },
  },
  initialState,
);
