import React, { ReactNode } from 'react';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Navbar from '../../Templates/Navbar/Navbar';
import { Box, createStyles, makeStyles, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Particles from 'reusable/Particles/Particles';
import LeftNavbar from '../../Templates/Navbar/LeftNavbar';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 1500,
      margin: 'auto',
      width: '100%',
      zIndex: 10,
      padding: '1rem .5rem',
    },
    pageProno: {
      maxWidth: '100vw',
      minHeight: '100vh',
    },
    prono: {
      padding: '0 10px',
      zIndex: 1,
      width: '100vw',
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        width: '100%',
      },
    },
    childPredLeft: {
      height: 'fit-content',
      margin: 0,
      padding: '0px !important',
    },
    appBarShift: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${theme.spacing(7) + 1}px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${theme.spacing(9) + 1}px)`,
      },
    },
    appBarShiftOpen: {
      width: `calc(100% - ${drawerWidth}px)`,
      '& > div': {
        width: '100%',
        margin: 0,
      },
    },
    appBarShiftMobile: {
      width: '100%',
    },
  }),
);

type SinglePronoTennisLayoutProps = {
  leftChildren: ReactNode;
  rightChildren: ReactNode;
};

const SinglePronoTennisLayout: React.FC<SinglePronoTennisLayoutProps> = ({
  leftChildren,
  rightChildren,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const spacing = mobile ? 3 : 6;
  return (
    <>
      <Particles />
      <div className={classes.pageProno}>
        <Fade in={true} timeout={500}>
          <>
            {mobile && <Navbar noRadius transparent collapsed />}
            <Box display="flex">
              {!mobile && <LeftNavbar open={open} setOpen={setOpen} />}
              <Box
                className={clsx(classes.appBarShift, {
                  [classes.appBarShiftOpen]: open,
                  [classes.appBarShiftMobile]: mobile,
                })}
              >
                <Grid container spacing={spacing} className={classes.container}>
                  <div className={classes.prono}>
                    <Fade in={true} timeout={500}>
                      <Grid
                        container
                        spacing={spacing}
                        className={classes.container}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ justifyContent: 'flex-start' }}
                          className="noPaddingTop noPaddingBottom"
                        >
                          <Button
                            onClick={() => history.push('/pronos')}
                            color="inherit"
                            startIcon={<ArrowBackIcon />}
                          >
                            {t('Retour')}
                          </Button>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          md={9}
                          spacing={spacing}
                          id="childPred-left"
                          className={classes.childPredLeft}
                        >
                          {leftChildren}
                        </Grid>
                        {rightChildren}
                      </Grid>
                    </Fade>
                  </div>
                </Grid>
              </Box>
            </Box>
          </>
        </Fade>
      </div>
    </>
  );
};

export default SinglePronoTennisLayout;
