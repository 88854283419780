export const isTooLong = (
  wordToVerify: string,
  maxLetters: number = 15,
  maxWords: number = 2,
) => {
  return (
    wordToVerify.length > maxLetters ||
    wordToVerify.split(' ').length > maxWords
  );
};

export function getParameterByName(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(document.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
