import { useState, useEffect } from 'react';

export default function useAsync(
  request: Promise<any>,
  defaultValue: any = null,
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [res, setRes] = useState<any>(defaultValue);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const data = await request;
        setRes(data);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };
    fetch();
  }, []);

  return [res, loading, error];
}
