import React from 'react';
import clsx from 'clsx';
import { getYear, getISOWeek } from 'date-fns';
import { Fire } from 'services';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Box,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import { isTooLong } from '../../../utils/logic';
import { getNBALogoURL } from '../../../utils/nba';
import { getNHLLogoURL } from '../../../utils/nhl';
import { getNFLLogoURL } from '../../../utils/nfl';
import Logo from '../Templates/Logos/Logo';
import PreviewTitle from '../Templates/Misc/PreviewTitle';
import { TFunction } from 'i18next';
import { theme } from '../../../theme/_exampleTheme';
import { useTranslation } from 'react-i18next';
import { getInfosPronoLibre as getInfosPronoLibreSoccer } from '../../../utils/pronoLibreSoccer';
import { getInfosPronoLibre as getInfosPronoLibreNBA } from '../../../utils/pronoLibreNBA';
import { getInfosPronoLibre as getInfosPronoLibreNFL } from '../../../utils/pronoLibreNFL';
import { getInfosPronoLibre as getInfosPronoLibreNHL } from '../../../utils/pronoLibreNHL';
import { getInfosPronoLibre as getInfosPronoLibreTennis } from '../../../utils/pronoLibreTennis';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import DoublesImages from '../Templates/Cards/CardPredictionTennis/DoublesImages/DoublesImages';
import SinglesImages from '../Templates/Cards/CardPredictionTennis/SinglesImages/SinglesImages';
import {
  getCardPlayers,
  getDoublesPlayersNames,
} from '../SinglePronoTennis/utils';
import { DoubleCompetitorsEntity, Gender } from '../../../types/pronoTennis';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bilanPredictions: {
      marginTop: '1rem',
      marginBottom: '3rem',
      border: '1px solid white',
      borderRadius: 19,
    },
    rowLight: {
      background: 'rgba(25,38,83,0.85)',
    },
    rowTransparent: {
      background: 'rgba(25,38,83,0.20)',
    },
    lastRow: {
      borderRadius: '0 0 20px 20px',
    },
  }),
);

type HeaderRow = {
  mobile: boolean;
  t: TFunction;
};

const HeaderRow: React.FC<HeaderRow> = ({ mobile, t }) => {
  return (
    <Grid
      container
      item
      xs={12}
      style={{
        padding: '1rem 2rem',
        color: 'white',
      }}
      alignItems="center"
    >
      <Grid container item xs={1}>
        {/* {t('Sport')} */}
      </Grid>
      <Grid container item xs={4} sm={5}></Grid>
      <Grid
        container
        item
        xs={7}
        sm={6}
        alignItems="center"
        style={{ justifyContent: 'space-around' }}
      >
        <Grid container item xs={7} style={{ justifyContent: 'center' }}>
          <Typography variant="body1" component="span">
            {t('analyse')}
          </Typography>
        </Grid>
        <Grid container item xs={2} style={{ justifyContent: 'center' }}>
          <Typography variant="body1" component="span">
            {t('Date')}
          </Typography>
        </Grid>
        {!mobile && (
          <Grid container item xs={1} style={{ justifyContent: 'center' }}>
            <Typography variant="body1" component="span">
              {t('Cote')}
            </Typography>
          </Grid>
        )}
        <Grid container item xs={1} style={{ justifyContent: 'center' }}>
          <Typography variant="body1" component="span">
            {/* {t('Résultat')} */}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const TennisName = ({ matchType, team, mobile }) => {
  return matchType === 'singles' ? (
    <div>
      <Typography variant={mobile ? 'h6' : 'body1'} component={'span'}>
        <Box fontWeight={600}>{team?.firstName}</Box>
      </Typography>
      <Typography variant={mobile ? 'h6' : 'body1'} component={'span'}>
        <Box fontWeight={600}>{team?.lastName}</Box>
      </Typography>
    </div>
  ) : (
    team?.map(player => (
      <Typography variant="body1" component="span">
        <Box fontWeight={600}>
          {`${player.lastName} ${player.firstName.slice(0, 1)}.`}
        </Box>
      </Typography>
    ))
  );
};

const BilanPronos = () => {
  const [historyPronos, setHistoryPronos] = React.useState<any[]>([]);
  const [notes, setNotes] = React.useState<any[]>([]);
  const [coteUpdated, setCoteUpdated] = React.useState<boolean>(false);
  const [isProUser, setIsProUser] = React.useState<boolean>();
  const user = useSelector((state: any) => state.authReducer.user);
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const getAllHistoryPronos = async () => {
    // const week = moment().week();
    const date = new Date();
    const week: number = getISOWeek(date) - 1;

    const receivedHistoryPronos = await Fire.list(
      Fire.store()
        .collection('bilanPronos')
        .where('isActive', '==', true)
        .where('week', '>=', week),
    );
    if (receivedHistoryPronos[0]?.matchs) {
      // trier les pronos en fonction de leur date
      const history = receivedHistoryPronos[0]?.matchs.sort((a, b) => {
        return b.date - a.date;
      });
      setHistoryPronos(history);
    } else {
      setHistoryPronos([]);
    }
  };

  const verifyIsProUser = React.useCallback(async () => {
    const ref = Fire.store()
      .collection('pros')
      .where('email', '==', user.email);
    const list = await Fire.list(ref);
    setIsProUser(list.length !== 0);
  }, [user.email]);

  React.useEffect(() => {
    verifyIsProUser();
    getAllHistoryPronos();
  }, []);

  React.useEffect(() => {
    const getNotes = async () => {
      const ref = Fire.store().collection('pronos_notes');
      const receivedNotes = await Promise.all(
        historyPronos.map(({ id }) => Fire.doc(ref.doc(id))),
      );
      setNotes(receivedNotes);
      setCoteUpdated(true);
    };

    if (historyPronos.length !== 0 && !coteUpdated) getNotes();
  }, [historyPronos, coteUpdated]);

  if (historyPronos.length === 0 || !isProUser) return null;

  return (
    <Grid item xs={12} md={9} key="bilan_pronos" id="bilan_prediction">
      <Grid item xs={12}>
        <PreviewTitle
          title="Analyses de la semaine passée"
          whiteColor
          fontWeight="600"
          marginBottom
        />
      </Grid>
      <Grid item xs={12} className={classes.bilanPredictions}>
        <HeaderRow t={t} mobile={mobile} />
        {historyPronos.map((prono, index) => {
          if (
            (prono.sport === 'tennis' &&
              !prono.match?.value?.sport_event?.competitors) ||
            !prono.show_new_logo
          )
            return null;
          const matchType = prono?.competition?.value?.type;
          const [team1, team2] =
            prono.sport !== 'tennis'
              ? [null, null]
              : matchType === 'singles'
              ? [getCardPlayers([prono.player1, prono.player2], matchType)]
              : getDoublesPlayersNames(
                  prono.match?.value?.sport_event
                    ?.competitors as DoubleCompetitorsEntity[],
                );
          const gender: Gender = prono.competition?.value?.gender;

          return (
            <Grid
              container
              item
              xs={12}
              key={`bilanPronos${prono.id}`}
              className={clsx(
                index % 2 === 0 ? classes.rowLight : classes.rowTransparent,

                index === historyPronos.length - 1 && classes.lastRow,
              )}
              style={{
                padding: '1rem 2rem',
                color: 'white',
              }}
              alignItems="center"
            >
              <Grid container item xs={1}>
                <Tooltip title={t(prono.sport) as string}>
                  <Typography>
                    {prono.sport === 'soccer'
                      ? '⚽'
                      : prono.sport === 'tennis'
                      ? '🎾'
                      : prono.sport === 'NBA'
                      ? '🏀'
                      : prono.sport === 'NHL'
                      ? '🏈'
                      : prono.sport === 'NFL'
                      ? '🏒'
                      : ''}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid container item xs={4} sm={5} alignItems="center">
                <Grid
                  container
                  item
                  xs={6}
                  sm={4}
                  style={{ justifyContent: 'flex-start' }}
                >
                  <Typography
                    variant="body1"
                    component="span"
                    style={{ textAlign: 'left', paddingRight: '.5rem' }}
                  >
                    {prono.sport === 'soccer'
                      ? isTooLong(prono.domicile?.name, 20, 3)
                        ? t(prono.domicile.name)
                        : t(prono.domicile?.name)
                      : prono.sport === 'NBA' ||
                        prono.sport === 'NHL' ||
                        prono.sport === 'NFL'
                      ? isTooLong(prono.match.value.home?.name, 20, 3)
                        ? t(prono.match.value.home.alias)
                        : t(prono.match.value.home?.name)
                      : prono.sport === 'tennis' &&
                        team1 !== null && (
                          <TennisName
                            matchType={matchType}
                            team={matchType === 'singles' ? team1[0] : team1}
                            mobile={mobile}
                          />
                        )}
                  </Typography>
                </Grid>
                {!mobile && (
                  <Grid item xs={4}>
                    <Box width="100%" display="flex" alignItems={'center'}>
                      <Box
                        width="33%"
                        height="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box
                          borderRadius="50%"
                          height="30px"
                          width="30px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          boxShadow={'0 0 33px 0 rgb(0 0 0 / 11%)'}
                        >
                          {prono.sport !== 'tennis' ? (
                            <Logo
                              noBg
                              src={
                                prono.sport === 'soccer'
                                  ? prono.domicile?.logo
                                  : prono.sport === 'NBA'
                                  ? getNBALogoURL(prono.match.value.home.sr_id)
                                  : prono.sport === 'NHL'
                                  ? getNHLLogoURL(prono.match.value.home.id)
                                  : prono.sport === 'NFL' &&
                                    getNFLLogoURL(prono.match.value.home.id)
                              }
                              club={
                                prono.sport === 'soccer'
                                  ? prono.domicile.name !==
                                    prono.domicile.country
                                  : false
                              }
                            />
                          ) : (
                            team1 !== null &&
                            team2 !== null &&
                            (matchType === 'singles' ? (
                              <SinglesImages
                                small
                                width="auto"
                                mobile={mobile}
                                future={false}
                                gender={gender}
                                playerId={team1[0].id}
                              />
                            ) : (
                              <DoublesImages
                                small
                                mobile={mobile}
                                future={false}
                                gender={gender}
                                player1Id={team1[0].id}
                                player2Id={team1[1].id}
                              />
                            ))
                          )}
                        </Box>
                      </Box>
                      <Box
                        width="33%"
                        height="100%"
                        color={'#838ca6'}
                        textAlign="start"
                        alignItems="center"
                        display="flex"
                        justifyContent="center"
                        fontWeight="600"
                      >
                        -
                      </Box>
                      <Box
                        width="33%"
                        height="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box
                          borderRadius="50%"
                          height="30px"
                          width="30px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          boxShadow={'0 0 33px 0 rgb(0 0 0 / 11%)'}
                        >
                          {prono.sport !== 'tennis' ? (
                            <Logo
                              noBg
                              src={
                                prono.sport === 'soccer'
                                  ? prono.ext?.logo
                                  : prono.sport == 'NBA'
                                  ? getNBALogoURL(prono.match.value.away.sr_id)
                                  : prono.sport === 'NHL'
                                  ? getNHLLogoURL(prono.match.value.away.id)
                                  : prono.sport === 'NFL' &&
                                    getNFLLogoURL(prono.match.value.away.id)
                              }
                              club={
                                prono.sport === 'soccer'
                                  ? prono.ext.name !== prono.ext.country
                                  : false
                              }
                            />
                          ) : (
                            team1 !== null &&
                            team2 !== null &&
                            (matchType === 'singles' ? (
                              <SinglesImages
                                small
                                width="auto"
                                mobile={mobile}
                                future={false}
                                gender={gender}
                                playerId={team1[1].id}
                              />
                            ) : (
                              <DoublesImages
                                small
                                mobile={mobile}
                                future={false}
                                gender={gender}
                                player1Id={team2[0].id}
                                player2Id={team2[1].id}
                              />
                            ))
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                )}

                <Grid
                  container
                  item
                  xs={6}
                  sm={4}
                  style={{
                    justifyContent: 'flex-end',
                    paddingLeft: '.5rem',
                  }}
                >
                  <Typography
                    variant="body1"
                    component="span"
                    style={{ paddingRight: '.5rem', textAlign: 'right' }}
                  >
                    {prono.sport === 'soccer'
                      ? isTooLong(prono.ext?.name, 20, 3)
                        ? t(prono.ext.name)
                        : t(prono.ext?.name)
                      : prono.sport === 'NBA' ||
                        prono.sport === 'NHL' ||
                        prono.sport === 'NFL'
                      ? isTooLong(prono.match.value.away?.name, 20, 3)
                        ? t(prono.match.value.away.alias)
                        : t(prono.match.value.away?.name)
                      : prono.sport === 'tennis' &&
                        team1 !== null && (
                          <TennisName
                            matchType={matchType}
                            team={matchType === 'singles' ? team1[1] : team2}
                            mobile={mobile}
                          />
                        )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={7}
                sm={6}
                alignItems="center"
                style={{
                  justifyContent: 'space-around',
                }}
              >
                <Grid
                  container
                  item
                  xs={7}
                  style={{ justifyContent: 'flex-start' }}
                >
                  <Typography
                    variant="body1"
                    component="span"
                    style={{ textAlign: 'left', paddingLeft: '1.75rem' }}
                  >
                    {prono.sport === 'soccer'
                      ? getInfosPronoLibreSoccer(prono, t).pronoName
                      : prono.sport === 'NBA'
                      ? getInfosPronoLibreNBA(prono, t).pronoName
                      : prono.sport === 'NFL'
                      ? getInfosPronoLibreNFL(prono, t).pronoName
                      : prono.sport === 'NHL'
                      ? getInfosPronoLibreNHL(prono, t).pronoName
                      : prono.sport === 'tennis' &&
                        getInfosPronoLibreTennis(prono, t).pronoName}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={2}
                  style={{ justifyContent: 'center' }}
                >
                  <Typography
                    variant="body1"
                    component="span"
                    style={{ textAlign: 'center' }}
                  >
                    {moment.unix(prono.date.seconds).format('DD/MM')}
                  </Typography>
                </Grid>
                {!mobile && (
                  <Grid
                    container
                    item
                    xs={1}
                    style={{ justifyContent: 'center' }}
                  >
                    <Typography variant="body1" component="span">
                      {notes &&
                        notes.length !== 0 &&
                        notes.find(note => note?.id === prono.id)?.notes}
                    </Typography>
                  </Grid>
                )}
                <Grid
                  container
                  item
                  xs={1}
                  style={{ justifyContent: 'center' }}
                >
                  <Typography
                    variant="body1"
                    component="span"
                    style={{
                      color: prono.hasSucceeded ? '#00c853' : '#d50000',
                    }}
                  >
                    {prono.hasSucceeded ? (
                      <CheckRoundedIcon />
                    ) : (
                      <CloseRoundedIcon color="error" />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default BilanPronos;
