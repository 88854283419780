import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Fade from '@material-ui/core/Fade';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Fire } from 'services';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonYellow: {
      backgroundColor: theme.palette.secondary.main,
      border: `solid 2px ${theme.palette.secondary.main}`,
      borderRadius: '12px',
      borderBottomRightRadius: '23px',
      padding: '7px 20px',
      fontWeight: 'bold',
      minWidth: '150px',
      width: 'max-content',
      display: 'block',
      marginLeft: 'auto',
      marginRight: '.5rem',

      '&:hover': {
        backgroundColor: 'transparent',
        border: `solid 2px ${theme.palette.secondary.main}`,
        boxShadow: '0 12px 23px 0 rgb(10 22 62 / 16 %)',
      },
    },
    button: {
      [theme.breakpoints.down('sm')]: {
        minWidth: 0,
        padding: '7px 1rem',
        '&>span': {
          fontSize: '1rem',
        },
      },
      '&:hover': {
        color: 'white',
      },
    },
    buttonGrey: {
      backgroundColor: 'transparent',
      border: 'solid 2px #dbdfe8',
      borderRadius: '12px',
      borderBottomRightRadius: '23px',
      padding: '7px 20px',
      fontWeight: 'bold',
      minWidth: '150px',
      width: 'max-content',
      display: 'block',
      marginRight: 'auto',
      marginLeft: '.5rem',
      color: 'white',

      '&:hover': {
        background: '#dbdfe8',
        color: '#132053',
        boxShadow: '0 12px 23px 0 rgb(10 22 62 / 16 %)',
      },
    },
    hidden: {
      display: 'none',
    },
  }),
);

type Props = {
  subId: any;
  userId: any;
};

export default function PopoverUnsuscribe(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const history = useHistory();
  const [valueRadio, setValueRadio] = useState<any>('');
  const { subId, userId } = props;
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [cancelId, setCancelId] = React.useState<any>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueRadio((event.target as HTMLInputElement).value);
  };

  const reasonUnsuscribe = [
    {
      id: 'reason1',
      problem: "Je ne comprend pas /  je n'arrive pas à parier",
      solution:
        'Pas de problème ! Sur votre dashboard, vous avez accès à un ensemble de blog fait pour aider à comprendre l’univers des paris sportifs. Vous pouvez consulter ces articles sur la page conseil, à savoir : Comment parier efficacement ? Comment récupérer les bonus offerts ? Placer son premier pari : comment ça marche ? Comment fonctionne l’algorithme de Datawin ? ',
    },
    {
      id: 'reason2',
      problem: "Je ne gagne pas d'argent avec Datawin",
      solution:
        'Surprenant ! Nos analyses depuis le début de l’année avoisinent les 78% de réussite sur tous les matchs confondus. Il arrive que vous puissiez vous inscrire pendant la mauvaise semaine, nous vous invitons à envoyer un mail à l’adresse : support@datawin-prediction.com pour bénéficier d’un accès gratuit pendant une durée limitée afin de voir que notre solution est fiable à +80% car votre satisfaction est notre priorité.',
    },
    {
      id: 'reason3',
      problem: 'Je trouve l’offre de Datawin trop chère',
      solution:
        'Pas d’inquiétude ! Si vous trouvez que l’accès à Datawin est trop cher pour démarrer, nous pouvons vous offrir 2 mois d’accès en illimité, afin que vous puissiez devenir rentable comme nos fidèles membres.',
    },
    {
      id: 'reason4',
      problem: 'Je n’ai pas accès aux analyses',
      solution:
        'Pas d’inquiétude ! Nous allons de suite corriger cela, veuillez envoyer un email à support@datawin-prediction.com en indiquant PROBLEME TECHNIQUE. Nos conseillers résoudrons votre problème et pourrons vous offrir un accès gratuit à durée limitée pour ce désagrément.',
    },
  ];

  const tutorialSteps: any = [
    {
      title: 'Êtes-vous sur de vouloir désactiver votre abonnement ?',
      content: [
        {
          text: 'La résiliation entraîne une coupure immédiate de l’accès aux différents services. Veuillez noter que la résiliation est soumise à un délai d’application de 15 jours conformément aux CGV acceptés lors de votre inscription.',
        },
      ],
      bgGrey: false,
      contentType: 'singleText',
      buttons: {
        button1: {
          title: 'Voir les pronos du jour',
          path: '/pronos',
          onClick: function () {
            history.push('/pronos');
          },
        },
        button2: {
          title: 'Je me désabonne',
          onClick: function () {
            nextPopover();
          },
        },
      },
    },
    {
      title:
        'Attention : En continuant ce formulaire, vous allez perdre tous les avantages de Datawin',
      titleRed: true,
      content: [
        {
          text: 'Les services Datawin',
          bold: true,
        },
        { text: 'Des analyses avec +78% de réussite sur 2021' },
        { text: 'Livescore et résumé des matchs' },
        { text: 'Bibliothèque de données' },
      ],
      bgGrey: true,
      contentType: 'multipleText',
      buttons: {
        button1: {
          title: 'Je conserve mon accès Datawin',
          path: '/pronos',
          onClick: function () {
            history.push('/pronos');
          },
        },
        button2: {
          title: 'Je mets fin à mes avantages',
          onClick: function () {
            nextPopover();
          },
        },
      },
    },
    {
      title: 'Pourquoi souhaitez-vous résilier votre abonnement ?',
      contentSelect: reasonUnsuscribe,
      bgGrey: true,
      contentType: 'radio',
      buttons: {
        button1: {
          title: 'Je conserve mon PREMIUM DATAWIN',
          path: '/pronos',
          onClick: function () {
            history.push('/pronos');
          },
        },
        button2: {
          title: 'Mettre fin à mes avantages',
          onClick: function () {
            addCancellation();
            nextPopover();
          },
        },
      },
    },
    {
      title:
        'ATTENTION : VOUS ALLEZ PERDRE DÉFINITIVEMENT VOTRE ACCÈS À DATAWIN :',
      titleRed: true,
      content: [
        {
          text: 'Vous ne pourrez plus faire marche arrière. Vous vous apprêtez à mettre fin définitivement à votre offre premium. En résiliant votre compte vous perdez l’accès aux analyses exclusives de l’algorithme de Datawin avec plus de 78% de réussite enregistré en 2021, ainsi que tous les avantages liées à l’offre Premium : Bonus, Database, Actu, Live Score, Résumé, Vidéo, Classement, Historique complet des matchs...',
        },
        {
          text: "Plus de 30 000 membres fidèles gagnent de l'argent à nos côtés chaque mois grâce à notre technologie, pourquoi pas vous ? ",
          bold: true,
          margin: '1.5rem .5rem',
        },
        {
          text: `Si vous rencontrez un problème, n'hésitez pas à nous contacter par email à support@datawin-prediction.com pour que nous puissions corriger cela, et continuer à vous compter parmi nos membres satisfaits.`,
        },
      ],
      bgGrey: true,
      contentType: 'multipleText',
      buttons: {
        button1: {
          title: 'Je conserve mon PREMIUM DATAWIN',
          path: '/pronos',
          onClick: function () {
            history.push('/pronos');
          },
        },
        button2: {
          title: 'Mettre fin à mes avantages',
          onClick: function () {
            // console.log("calling cancel")
            // cancelMembership();
            nextPopover();
          },
        },
      },
    },
    {
      title: 'Dernière étape :',
      content: [
        {
          text: "Afin de compléter et de valider votre demande de résiliation, merci de nous envoyer un mail à l'adresse support@datawin-prediction.com avec en objet 'Demande de désabonnement' et votre adresse email associée à votre compte Datawin dans le corps du message.",
          // text: "Votre demande de résiliation à été transmise avec succès, vous allez recevoir un mail de confirmation sur votre messagerie. Veuillez cliquer sur le lien pour confirmer votre identité afin de valider votre désinscription. Veuillez noter que votre demande de résiliation prendra effet dans 4 jours ouvrables . Si vous souhaitez annuler votre demande de désinscription et retourner à votre espace membre cliquez-ici",
        },
      ],
      bgGrey: true,
      contentType: 'singleText',
      // buttons: {
      //     button1: {
      //         title: "Je conserve mon PREMIUM DATAWIN",
      //         path: "/pronos",
      //         onClick: function () {
      //             history.push('/pronos')
      //         }
      //     },
      //     button2: {
      //         title: "Mettre fin à mes avantages",
      //         onClick: function () {
      //             nextPopover()
      //         }
      //     }
      // }
    },
  ];

  const maxSteps = tutorialSteps.length;

  const nextPopover = () => {
    activeStep != maxSteps - 1 &&
      setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const cancelMembership = async () => {
    try {
      // console.log("in cancel :", cancelId)
      const res = await Fire.cloud('unsubscribeForm', {
        subId: subId,
        userId: userId,
        cancelId: cancelId,
      });
      // console.log("out cancel")
    } catch (error) {}
  };

  const addCancellation = async () => {
    setLoading(true);
    try {
      // console.log(reasonUnsuscribe[parseInt(valueRadio) - 1])
      await Fire.store()
        .collection('cancellations')
        .add({
          userId: userId,
          cancellation: reasonUnsuscribe[parseInt(valueRadio) - 1],
          createdAt: new Date(),
        })
        .then(function (docRef) {
          setCancelId(docRef.id);
        })
        .catch(function (error) {
          console.error('Error adding document: ', error);
        });
    } catch (err) {}
    setLoading(false);
  };

  return (
    <Box className="popover">
      <Box
        margin="1rem 0"
        color={tutorialSteps[activeStep].titleRed && 'red'}
        textAlign="center"
      >
        <Fade in={true} timeout={500}>
          <Typography variant="h4">
            {t(tutorialSteps[activeStep].title)}
          </Typography>
        </Fade>
      </Box>
      <Box
        margin="1.5rem 0"
        padding="1rem"
        lineHeight="1.5rem"
        textAlign="center"
        color="#b8b8b8"
      >
        {tutorialSteps[activeStep].contentType === 'singleText' ? (
          <Fade in={true} timeout={500}>
            <Typography variant="h5">
              {t(tutorialSteps[activeStep].content[0].text)}
            </Typography>
          </Fade>
        ) : tutorialSteps[activeStep].contentType === 'multipleText' ? (
          <Fade in={true} timeout={500}>
            <Typography variant="h5" component="span">
              {tutorialSteps[activeStep].content.map((line, index) => {
                return (
                  <Box
                    fontWeight={line.bold ? 'bold' : 500}
                    width="100%"
                    margin={line.margin ? line.margin : '.5rem'}
                    key={index}
                  >
                    {t(line.text)}
                  </Box>
                );
              })}
            </Typography>
          </Fade>
        ) : (
          tutorialSteps[activeStep].contentType === 'radio' && (
            <React.Fragment>
              <Fade in={true} timeout={500}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Choissiez une option (requis)
                  </FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={valueRadio}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label={t(
                        tutorialSteps[activeStep].contentSelect[0].problem,
                      )}
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label={t(
                        tutorialSteps[activeStep].contentSelect[1].problem,
                      )}
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio />}
                      label={t(
                        tutorialSteps[activeStep].contentSelect[2].problem,
                      )}
                    />
                    <FormControlLabel
                      value="4"
                      control={<Radio />}
                      label={t(
                        tutorialSteps[activeStep].contentSelect[3].problem,
                      )}
                    />
                  </RadioGroup>
                </FormControl>
              </Fade>
            </React.Fragment>
          )
        )}
      </Box>
      {tutorialSteps[activeStep].contentType === 'radio' && valueRadio !== '' && (
        <Fade in={true} timeout={500}>
          <Box
            margin="1.5rem 0"
            padding="1rem"
            lineHeight="1.5rem"
            textAlign="center"
            color="#6a6f75"
            bgcolor="#e0ffe0"
          >
            <Typography component="span">
              <Box fontWeight={500} width="100%" margin=".5rem">
                {t(
                  tutorialSteps[activeStep].contentSelect[
                    parseInt(valueRadio) - 1
                  ].solution,
                )}
              </Box>
            </Typography>
          </Box>
        </Fade>
      )}
      {tutorialSteps[activeStep].buttons && (
        <Box marginTop="2rem" marginBottom="2.5rem" width="100%">
          <Grid container>
            <Grid item xs={6}>
              <Fade in={true} timeout={500}>
                <Button
                  disableElevation
                  variant="contained"
                  color="secondary"
                  href="#"
                  size="small"
                  className={clsx(classes.buttonYellow, classes.button)}
                  onClick={tutorialSteps[activeStep].buttons.button1.onClick}
                >
                  {t('Voir les pronos du jour')}
                </Button>
              </Fade>
            </Grid>
            <Grid item xs={6}>
              <Fade in={true} timeout={500}>
                <Button
                  disableElevation
                  variant="contained"
                  color="secondary"
                  href="#"
                  size="small"
                  className={clsx(
                    classes.buttonGrey,
                    classes.button,
                    tutorialSteps[activeStep].contentType === 'radio' &&
                      valueRadio === '' &&
                      classes.hidden,
                  )}
                  onClick={tutorialSteps[activeStep].buttons.button2.onClick}
                >
                  {t('Je me désabonne')}
                </Button>
              </Fade>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
