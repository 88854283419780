import React from 'react';
import {
  makeStyles,
  useTheme,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import '../../../../../theme/datawinTheme.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { BackdropProps, Dialog, DialogContent, Grid } from '@material-ui/core';
import SportGPTCardPronoFille from './SportGPTDetails/SportGPTCardPronoFille';

import SportGPTSinglePronoPredictions from './SportGPTDetails/SportGPTSinglePronoPredictions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '20rem',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        height: '26rem',
      },
    },
    content: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      height: '100%',
      minHeight: '15rem',
      position: 'relative',
    },
    test: {
      '& .MuiDialog-paper': {
        height: '100%',
        width: '100%',
        background: 'rgba(41, 59, 128, 1)',
        boxShadow: 'none',
        overflow: 'auto',
        maxWidth: '100%',
        maxHeight: '90%',
        borderRadius: '2rem',
      },
    },
    prono: {
      padding: '0 10px',
      zIndex: 1,
      width: '100vw',
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        width: '100%',
      },
    },
    container: {
      maxWidth: 1500,
      margin: 'auto',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
    childPredLeft: {
      padding: '10px 0 !important',
      width: '90%',
      margin: 'auto',
    },
    cardGeneral: {
      textAlign: 'end',
      height: '15rem',
    },
    rightColumn: {
      height: 'fit-content',
    },
  }),
);

export const SportGPTModalPrediction: React.FC<{
  onClose: () => void;
  open: boolean;
  prono: any;
}> = ({ onClose, open, prono }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const spacing = mobile ? 3 : 6;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropProps={
        {
          sx: {
            backdropFilter: 'blur(10px)',
          },
        } as Partial<BackdropProps>
      }
      className={classes.test}
    >
      <DialogContent
        style={{
          width: '520px',
          minHeight: '60vh',
          overflowY: 'auto',
        }}
      >
        <Box
          borderRadius="2rem"
          width="100%"
          height="100%"
          position="absolute"
          top="50%"
          left="50%"
          style={{
            transform: 'translateX(-50%) translateY(-50%)',
          }}
        >
          <Grid
            container
            item
            xs={12}
            lg={9}
            spacing={spacing}
            id="childPred-left"
            className={classes.childPredLeft}
          >
            <Grid item xs={12} sm={12}>
              <SportGPTCardPronoFille prono={prono} />
            </Grid>
            <Grid item xs={12} sm={12}></Grid>
            <Grid item xs={12} sm={12}>
              <SportGPTSinglePronoPredictions prono={prono} />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SportGPTModalPrediction;
