/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import { Fire, Mail } from '../../services';
import { logout, saveInfo } from 'actions/auth.action';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import Fade from '@material-ui/core/Fade';
import tick from '../../images/tick2.png';
import stars from '../../images/five-stars.png';
import badges from '../../images/badges.png';
import badge_stripe from '../../images/badge_stripe.png';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '../Reusable';
import { addCard } from 'actions/cards.action';
import { refreshUser, updateUser } from 'actions/auth.action';
import Loading_payment from '../Loading_payment/Loading_payment';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './PaymentScreen.scss';
import OauthLogins from '../Reusable/OauthLogins/OauthLogins';
import BackButton from '../Dashboard/Templates/BackButton/BackButton';
import ReCAPTCHA from 'react-google-recaptcha';
import { getFeeAndSubTypeAndTrialPrice } from '../../utils/countryCodeAPI';
import { Box, Typography } from '@material-ui/core';
import ArrowImage from '../../images/fleche.png';

// Declare gTM dataLayer array.
declare global {
  interface Window {
    dataLayer: any[];
  }
}

const key =
  process.env.NODE_ENV == 'production'
    ? process.env.REACT_APP_STRIPE_API_KEY
    : process.env.REACT_APP_STRIPE_API_KEY_TEST;
const stripePromise = loadStripe(key!);

const CURRENCIES = {
  eur: '€',
  usd: '$',
  gbp: '£',
};

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { t, i18n } = useTranslation();
  const language = i18n.language.toUpperCase();
  const history = useHistory();
  const user_already = useSelector((state: any) => state.authReducer.user);
  const [price, setPrice] = useState<string>('');
  const [subType, setSubType] = useState('monthEU');
  const [fee, setFee] = useState('feeEU');
  const [userCountryCode, setUserCountryCode] = useState(language);
  const [locationObject, setLocationObject] = useState<any>({});
  const [currency, setCurrency] = useState('');

  const getUsersInfos = async trialPrice => {
    try {
      const customerRef = await Fire.store()
        .collection('stripe_customers')
        .doc(user_already.id);
      const customer = await Fire.doc(customerRef);
      let customer_infos;
      if (customer) {
        customer_infos = await Fire.cloud('listInvoicesByCustomer', {
          customerId: customer.customer_id,
        });
      }
      const newCurrency = customer_infos?.data?.[0]?.price.currency;
      newCurrency !== undefined
        ? setCurrency(CURRENCIES[newCurrency])
        : trialPrice && setCurrency(trialPrice.split('')[1]);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllPriceInfos = async () => {
    const [
      countryCodeObject,
      feeFromLocation,
      subTypeFromLocation,
      trialPrice,
    ] = await getFeeAndSubTypeAndTrialPrice();
    setPrice(trialPrice || '1€');
    setSubType(subTypeFromLocation);
    setFee(feeFromLocation);
    setUserCountryCode(countryCodeObject.countryCode);
    setLocationObject(countryCodeObject);

    if (user_already?.state) {
      getUsersInfos(trialPrice);
    } else {
      setCurrency(price.split('')[1]);
    }
  };

  React.useEffect(() => {
    getAllPriceInfos();
  }, []);

  const [step, setStep] = React.useState(
    user_already
      ? user_already.vip
        ? user_already.phone == 'Non renseigné'
          ? 2
          : 0
        : 1
      : 0,
  );
  const [loading, setLoading] = React.useState(false);
  const [freeFee, setFreeFee] = React.useState(false);
  const [error, setError] = React.useState('');
  const [advance, setAdvance] = React.useState(40);
  const dispatch = useDispatch();
  const recaptchaRef = React.useRef<ReCAPTCHA>();

  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const [user, setUser] = useState<any>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    locationObject: {},
  });

  const showError = err => {
    setError(err);
    // window.scrollTo(0, 0)
  };

  function getParameterByName(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
      results = regex.exec(document.location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  const register = async () => {
    setLoading(true);
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    const isHuman = await Fire.cloud('validateHuman', token);

    if (!isHuman) {
      setLoading(false);
      showError(t("You're a bot, we can't let you come in."));
      return;
    } else {
      setLoading(false);
    }

    const { email, first_name, last_name, password } = user;
    const utm = {
      utm_source: getParameterByName('utm_source'),
      utm_medium: getParameterByName('utm_medium'),
      utm_campaign: getParameterByName('utm_campaign'),
      utm_term: getParameterByName('utm_term'),
      utm_content: getParameterByName('utm_content'),
    };
    // const { source } = document.location
    if (!first_name?.trim().length || !last_name?.trim().length) {
      setLoading(false);
      showError(t('Veuillez saisir votre nom et votre prénom'));
      return;
    } else if (!email?.trim().length) {
      setLoading(false);
      showError(t('Veuillez saisir votre email de connexion'));
      return;
    } else if (isBtnDisabled) {
      setLoading(false);
      showError(t('Veuillez accepter nos termes et conditions'));
      return;
    }

    try {
      dispatch(
        saveInfo({
          first_name: first_name.trim(),
          last_name: last_name.trim(),
          phone: 'Non renseigné',
          vip: false,
          source: utm,
          location: {
            state: locationObject.regionName,
            countryCode: locationObject.countryCode,
          },
        }),
      );
      await Fire.auth()
        .createUserWithEmailAndPassword(email, password)
        .then(() => {
          console.log('registered');
          setStep(1);
        });
      // history.push('/account')
    } catch (err) {
      console.log(err.code);
      console.log(err);
      switch (err.code) {
        case 'auth/email-already-in-use':
          showError(t("L'email entré est déjà utilisé. Connectez-vous."));
          break;

        case 'auth/invalid-password':
          showError(t('Identifiants invalides'));
          break;

        case 'auth/user-not-found':
          showError(t('Identifiants invalides'));
          break;

        case 'auth/invalid-email':
          showError(t("L'email entré est invalide"));
          break;

        case 'auth/weak-password':
          showError(t('Mot de passe trop faible'));
          break;

        default:
          showError(t('Une erreur est survenue'));
          break;
      }
      // handleSubmit();
    }

    setLoading(false);
  };

  const addInfos = async () => {
    console.log('adding infos...');

    let { phone, birth } = user;
    if (phone) {
      phone = phone.toString().replace(/ /g, '').split('.').join('');
    }
    if ((phone === '' || !phone) && (birth === '' || !birth)) {
      showError(
        t(
          'Veuillez saisir votre numéro de téléphone et votre date de naissance',
        ),
      );
      return;
    } else if (phone === '' || !phone) {
      showError(t('Veuillez saisir votre numéro de téléphone'));
      return;
    } else if (birth === '' || !birth) {
      showError(t('Veuillez saisir votre date de naissance'));
      return;
    } else if (
      !phone.match(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im,
      )
    ) {
      showError(t('Le numéro de téléphone est incorrect'));
      return;
    }

    // Reverse of the birth date to have dd-mm-yyyy instead of yyyy-mm-dd
    birth = birth.split('-').reverse().join('-');

    phone = phone.toString().replace(/ /g, '').split('.').join('');

    setLoading(true);
    try {
      dispatch(
        saveInfo({
          phone: phone,
          birth: birth,
        }),
      );

      await dispatch(
        updateUser({
          phone: phone,
          birth: birth,
        }),
      );

      console.log('Infos added to the user');
      history.push('/');
    } catch (err) {
      console.log(err.code);
      switch (err.code) {
        case 'auth/user-not-found':
          showError(t('Identifiants invalides'));
          break;

        default:
          showError(t('Une erreur est survenue'));
          break;
      }
      // handleSubmit();
    }
    setLoading(false);
  };

  const pay = async () => {
    if (!stripe || !elements) return;
    setLoading(true);
    try {
      const cardElement = elements!.getElement(CardElement);
      if (!cardElement) return;
      const { error, paymentMethod } = await stripe!.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {
        showError(t('Une erreur est survenue : ' + error.message));
        console.log(error);
      } else if (paymentMethod && paymentMethod.card) {
        try {
          await Fire.cloud('addCard', { cardId: paymentMethod.id });
          const stored = {
            last4: paymentMethod.card.last4,
            expiry:
              paymentMethod.card.exp_month + '/' + paymentMethod.card.exp_year,
            type: paymentMethod.card.brand,
            cardId: paymentMethod.id,
          };

          await dispatch(addCard(stored));
          // await register();
          await dispatch(
            refreshUser({
              cardId: paymentMethod.id,
            }),
          );
          console.log('card added');
          // PAY
          try {
            console.log('subscribing...');
            const res = await Fire.cloud('subscribe', {
              cardId: paymentMethod.id,
              subId: subType,
              feeId: fee,
            });
            console.log('subscribed...');

            if (!res.error) {
              const sub = res.subscription;
              await dispatch(
                refreshUser({
                  cardId: paymentMethod.id,
                  choice: sub,
                  vip: true,
                  state: { trial: new Date() },
                }),
              );

              try {
                console.log('event');
                window.dataLayer.push({
                  event: 'subscription',
                });
                const confirmTemplates = {
                  FR: 9,
                  IT: 12,
                  ES: 25,
                  EN: 31,
                };
                const idTemplate = confirmTemplates[language]
                  ? confirmTemplates[language]
                  : 9;

                Mail.send(user_already || user, idTemplate);
              } catch (error) {
                console.error(error);
              } finally {
                console.log('redirecting...');
                setStep(2);
              }
            } else {
              switch (res.error) {
                case 'invalid_offer':
                  console.log(res.error);
                  alert(
                    "L'offre sélectionnée n'existe pas ou plus, veuillez raffraichir la page et recommencer",
                  );
                  break;

                default:
                  console.log(res);
                  break;
              }
            }
          } catch (err) {
            alert('Paiement impossible. Veuillez vérifier votre connexion');
            console.log(err);
            return;
          }
        } catch (error) {
          console.log('addcard error: ', error);
          showError('Carte invalide');
          return;
        }
      }
    } catch (err) {
      alert(
        'Ajout de cb impossible. Veuillez vérifier votre connexion: ' +
          JSON.stringify(err),
      );
      console.log(err);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  const setValue = (key: string, val: any) => {
    user[key] = val;
    setUser({ ...user });
  };

  React.useEffect(() => {
    step == 0 ? setAdvance(40) : step == 1 ? setAdvance(80) : setAdvance(100);
  }, [step]);

  const login = () => {
    history.push('./login');
  };

  const [passwordType, setPasswordType] = useState('password');

  if (user_already != null) {
    if (user_already.vip != false) {
      if (user_already.phone !== 'Non renseigné') {
        history.push('/pronos');
      }
    }
  } else {
    history.push('/secure');
  }

  const promptLogout = () => {
    const confirm = window.confirm(
      t('Êtes-vous sûr de vouloir vous déconnecter ?'),
    );
    if (confirm) {
      dispatch(logout());
    }
  };

  let cusSubDate;
  let dateNow;
  let isOldSub;

  try {
    cusSubDate = new Date(user_already?.state?.trial?.seconds).getTime();
    dateNow = new Date().getTime() / 1000;
    isOldSub = (dateNow - cusSubDate) / (24 * 3600) > 7;
  } catch (err) {
    console.log(err);
  }

  return (
    <React.Fragment>
      <div className="paiement">
        {user_already && <BackButton label="Quitter" onClick={promptLogout} />}
        {!user_already && <BackButton onClick={() => history.push('/')} />}
        <section className="section-0">
          <img
            className="logo"
            src={require('../../images/logo_datawin.png')}
          />
        </section>
        <section className="section-1">
          <div className="infos">
            {user_already?.state === undefined || !isOldSub ? (
              <>
                <h1>{t('Remise massive pour les nouveaux membres')}</h1>
                <h2 style={{ gap: '30px', fontSize: '50px' }}>
                  <span
                    style={{ color: '#f9f9f9' }}
                    className="old_price"
                  >{`39.90`}</span>
                  <div>
                    <img src={ArrowImage} style={{ width: '80px' }} alt="" />
                  </div>
                  <Fade in={true} timeout={1500}>
                    <span
                      style={{ color: 'rgb(138 232 100)' }}
                      className="prix_reduit"
                    >
                      {freeFee ? t('0€') : price}
                      <span
                        style={{
                          fontSize: 25,
                          margin: '5px 0 0 5px',
                        }}
                      >
                        *
                      </span>
                    </span>
                  </Fade>
                </h2>
              </>
            ) : (
              <h2 style={{ fontSize: '50px' }}>
                <span
                  style={{ color: 'rgb(138 232 100)' }}
                  className="prix_reduit"
                >
                  {`39.90${currency || ''}`}
                </span>
              </h2>
            )}

            <p>
              {t('Accès complet à l’ensemble des fonctionnalités :')}
              <br />
            </p>
            <div className="ticks">
              <div className="step">
                <img src={tick} className="tick" />
                <div className="step-text">{t('Analyses')}</div>
              </div>
              <div className="step">
                <img src={tick} className="tick" />
                <div className="step-text">{t('Database')}</div>
              </div>
              {(userCountryCode === 'FR' ||
                userCountryCode === 'IT' ||
                userCountryCode === 'ES' ||
                userCountryCode === 'UK') && (
                <div className="step">
                  <img src={tick} className="tick" />
                  <div className="step-text">
                    {t(
                      userCountryCode === 'FR'
                        ? 'Bonus'
                        : userCountryCode === 'UK'
                        ? 'Bonus'
                        : userCountryCode === 'IT'
                        ? 'Bonus'
                        : userCountryCode === 'ES'
                        ? 'Bonus'
                        : ' ',
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="formulaire">
            <form>
              <div className="top-head">{t('DEVENIR MEMBRE PREMIUM')}</div>
              <div className="form-tabs">
                <ProgressBar
                  animated
                  striped
                  now={advance}
                  label={`${advance}%`}
                />
              </div>
              {step == 0 ? (
                <div className="fields">
                  <h3 className="form_paiement_title">
                    {t('Créez votre compte Datawin')}
                  </h3>
                  <div className="name">
                    <Input
                      placeholder={t('Prénom')}
                      value={user.first_name}
                      onChange={val => setValue('first_name', val)}
                      trimSpaces
                    />
                    <Input
                      placeholder={t('Nom')}
                      value={user.last_name}
                      onChange={val => setValue('last_name', val)}
                    />
                  </div>
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Input
                      placeholder={t('Email')}
                      value={user.email}
                      onChange={val => setValue('email', val.trim())}
                    />
                  </div>
                  <div className="password_container">
                    <Input
                      placeholder={t('Mot de passe')}
                      type={passwordType}
                      value={user.password}
                      onChange={val => setValue('password', val)}
                    />
                    {passwordType === 'password' ? (
                      <Visibility
                        fontSize="inherit"
                        color="inherit"
                        onClick={() => setPasswordType('text')}
                        className="visibility-eye"
                      />
                    ) : (
                      <VisibilityOff
                        fontSize="inherit"
                        color="inherit"
                        onClick={() => setPasswordType('password')}
                        className="visibility-eye"
                      />
                    )}
                  </div>
                  <p
                    className="paiement_terms"
                    onClick={() => setIsBtnDisabled(!isBtnDisabled)}
                    style={{ cursor: 'pointer' }}
                  >
                    <input
                      style={{ cursor: 'pointer' }}
                      checked={!isBtnDisabled}
                      type="checkbox"
                      name="cgv"
                      className="paiement_terms_check"
                      id="paiement_terms_check"
                    />
                    <p className="paiement_terms_text">
                      {t("J'accepte les")}{' '}
                      <a
                        target="_blank"
                        href="/terms#anchor"
                        className="paiement_terms_link"
                      >
                        {' '}
                        {t('Termes et conditions')}
                      </a>
                    </p>
                  </p>

                  {/* <TextInput
                                        title={t('Conditions')}
                                        type='checkbox'
                                        onChange={(val) => setValue('conditions', val)}
                                        value={user.conditions}
                                        // onChange={(val) => setValue('password', val)}
                                    /> */}

                  {error && (
                    <div className="error-box">
                      <div className="error">{error}</div>
                    </div>
                  )}
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_API_KEY}
                    ref={recaptchaRef}
                    size="invisible"
                  />
                  <Button
                    title={
                      loading
                        ? t('Chargement...')
                        : freeFee
                        ? t("S'INSCRIRE GRATUITEMENT")
                        : t("S'INSCRIRE POUR") +
                          ' ' +
                          (user_already?.state === undefined
                            ? price
                            : '39.90' + (currency || ''))
                    }
                    onPress={register}
                    subTitle={loading ? '' : t('OFFRE EXCLUSIVE LIMITÉE')}
                    classes={['linear-btn', 'big-btn', 'red']}
                  />

                  <div
                    style={{
                      marginTop: 3,
                      fontWeight: 500,
                      color: '#838383',
                      fontSize: 16,
                    }}
                  >
                    OU
                    <OauthLogins typeAuth="register" />
                  </div>
                </div>
              ) : step == 1 ? (
                <React.Fragment>
                  <div className="credit_card" style={{}}>
                    <div className="cc_container">
                      <img className="badge_stripe" src={badge_stripe} />
                      <CardElement
                        // onChange={handleCardChange}
                        options={{
                          style: {
                            base: {
                              fontSize: '16px',
                              color: '#424770',
                              '::placeholder': {
                                color: '#aab7c4',
                              },
                            },
                            invalid: {
                              color: '#9e2146',
                            },
                          },
                        }}
                      />
                    </div>
                  </div>

                  {error && (
                    <div className="error-box">
                      <div className="error">{error}</div>
                    </div>
                  )}
                  {loading && (
                    <>
                      <Loading_payment />
                      <p
                        style={{
                          fontSize: '.8rem',
                          padding: '0 1rem',
                          color: '#2c2c2c',
                        }}
                      >
                        {t(
                          "Ne pas quitter la page, le traitement de vos informations peut prendre jusqu'à une minute",
                        )}
                      </p>
                    </>
                  )}
                  <Button
                    title={
                      loading
                        ? ''
                        : freeFee
                        ? t('TESTER GRATUITEMENT')
                        : t('TESTER POUR') +
                          ' ' +
                          (user_already?.state === undefined
                            ? price
                            : '39.90' + (currency || ''))
                    }
                    onPress={() => pay()}
                    classes={['linear-btn', 'big-btn']}
                    disabled={loading}
                  />
                  <img className="badges" src={badges} />
                </React.Fragment>
              ) : (
                <div className="fields" style={{ marginTop: '2rem' }}>
                  <Input
                    title={t('Date de naissance')}
                    type="date"
                    value={user.birth}
                    onChange={val => setValue('birth', val.trim())}
                  />
                  <Input
                    title={t('Numéro de téléphone')}
                    type="tel"
                    value={user.phone}
                    onChange={val => setValue('phone', val)}
                  />

                  {error && (
                    <div className="error-box">
                      <div className="error">{error}</div>
                    </div>
                  )}

                  <Button
                    title={t('Accédez aux analyses !')}
                    onPress={addInfos}
                    // subTitle="LES 7 PREMIERS JOURS !"
                    classes={['linear-btn', 'big-btn']}
                  />
                </div>
              )}
            </form>
            {user_already == null ? (
              <a className="connect-link" onClick={login}>
                {t('Déjà inscrit ? Connectez-vous')}
              </a>
            ) : null}
          </div>
        </section>
      </div>
      <div className="footer_desktop">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin="auto"
        >
          <Typography
            variant="body1"
            component={'span'}
            style={{
              width: '70%',
              margin: 'auto',
              fontSize: '11px',
              textTransform: 'none',
            }}
          >
            <Box textAlign="center">{t('TEXT_FOOTER_PAYMENT_PAGE')}</Box>
          </Typography>
        </Box>
        <br />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin="auto"
        >
          <Typography
            variant="body1"
            component={'span'}
            style={{
              width: '70%',
              margin: 'auto',
              fontSize: '11px',
              textTransform: 'none',
            }}
          >
            <Box textAlign="center">
              {t(
                'Vous pouvez vous désabonner en envoyant un mail à : support@datawin-prediction.com',
              )}
              {t(
                'Sur votre relevé, les frais apparaissent sous le nom DATAWIN',
              )}
            </Box>
          </Typography>
        </Box>
        <div className="footer_desktop_top" style={{ marginTop: '10px' }}>
          <p className="footer_desktop_top_copyrights">
            {t('© 2021 DATAWIN. Tous droits réservés')} &nbsp;{' - '}
            &nbsp;
            <a href="/privacy" target="_blank" className="footer_desktop_legal">
              {t('Confidentialité')}
            </a>{' '}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="/terms" target="_blank" className="footer_desktop_legal">
              {t('CGU & Mentions légales')}
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="mailto:support@datawin-prediction.com"
              className="footer_desktop_legal"
            >
              {t('CONTACTEZ-NOUS')}
            </a>
          </p>
        </div>
        <div className="footer_desktop_bottom">
          <img src={stars} className="footer_desktop_stars" />
          <p className="footer_desktop_avis">
            {t('Sur la base de 12.453 Avis')}
          </p>
        </div>
      </div>

      <div className="footer_phone">
        <img src={stars} className="footer_phone_stars" />
        <p>{t('Sur la base de 12.453 Avis')}</p>
        <div className="footer_phone_legal_links">
          <a
            href="/privacy"
            target="_blank"
            className="footer_phone_legal_links_link"
          >
            {t('Confidentialité')}
          </a>
          <a
            href="/terms"
            target="_blank"
            className="footer_phone_legal_links_link"
          >
            {t('Mentions légales & CGV')}
          </a>
          <a
            href="mailto:support@datawin-prediction.com"
            className="footer_phone_legal_links_link"
          >
            {t('CONTACTEZ-NOUS')}
          </a>
        </div>
        <p className="footer_phone_copyrights">
          {t('© 2021 DATAWIN. Tous droits réservés')}
        </p>
      </div>
    </React.Fragment>
  );
};

const App = () => {
  const { i18n } = useTranslation();
  const history = useHistory();

  const language = i18n.language;

  useEffect(() => {
    const handleUrl = () => {
      const url = history.location.pathname;
      const params = history.location.search;
      switch (language) {
        case 'fr': {
          if (url !== '/paiement-offre-1-euro')
            history.replace('/paiement-offre-1-euro' + params);
          break;
        }
        case 'en': {
          if (url !== '/payment-offer-1-euro')
            history.replace('/payment-offer-1-euro' + params);
          break;
        }
        case 'es': {
          if (url !== '/oferta-pago-1-euro')
            history.replace('/oferta-pago-1-euro' + params);
          break;
        }
        case 'it': {
          if (url !== '/offerta-pagamento-1-euro')
            history.replace('/offerta-pagamento-1-euro' + params);
          break;
        }
      }
    };
    handleUrl();
  }, [history, language]);

  return (
    <Elements
      stripe={stripePromise}
      options={{
        locale: i18n.language as StripeElementLocale | undefined,
      }}
    >
      <CheckoutForm />
    </Elements>
  );
};

export default App;
