import React from 'react';
import {
  useTheme,
  createStyles,
  fade,
  Theme,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Fire } from 'services';
import TitleCard from '../Templates/Account/TitleCard';
import '../../../theme/datawinTheme.css';
import { useTranslation, initReactI18next } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
      fontWeight: 600,
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '2px solid #ced4da',
      fontSize: 16,
      width: '100%',
      padding: '10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&.Mui-disabled': {
        backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
        color: '#a2a3a5 !important',
        border: 'none',
      },
    },
  }),
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
    },
    margin: {
      margin: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        margin: '.5rem 0',
      },
    },
    label: {
      fontWeight: 600,
      transform: 'translate(0, 1.5px) scale(1)',
      transformOrigin: 'top left',
      color: theme.palette.text.secondary,
      '&:focus': {
        color: theme.palette.text.secondary,
      },
    },
    snackbar: {
      position: 'static',
    },
    button: {
      backgroundColor: 'white',
      transition: 'all 0.2s ease',
    },
  }),
);

const Settings = () => {
  const [loading, setLoading] = React.useState(false);

  const user = useSelector((state: any) => state.authReducer.user);
  const dispatch = useDispatch();
  const [info, setInfo] = React.useState<any>({ ...user });

  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [messageOpen, setMessageOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);

  const handleClick = () => {
    setMessageOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorOpen(false);
    setMessageOpen(false);
  };

  const showError = message => {
    setErrorOpen(true);
    console.log(message);
  };

  const spacing = mobile ? 3 : 5;

  const { t, i18n } = useTranslation();

  const resetPassword = async () => {
    setLoading(true);

    try {
      await Fire.auth().sendPasswordResetEmail(info.email);
      handleClick();
      // alert(t('Un mail de changement de mot de passe a été envoyé à : ') + info.email)
    } catch (err) {
      switch (err.code) {
        case 'auth/email-already-exists':
          showError(t("L'email entré est déjà utilisé"));
          break;

        case 'auth/invalid-password':
          showError(t('Identifiants invalides'));
          break;

        case 'auth/user-not-found':
          showError(t('Identifiants invalides'));
          break;

        case 'auth/invalid-email':
          showError(t("L'email entré est invalide"));
          break;

        default:
          showError(t('Une erreur est survenue'));
      }
    }
    setLoading(false);
  };

  return (
    <Box key="settings" width="100%" height="100%" padding="2rem 0 0">
      <TitleCard title={t('Paramètres - Réinitialiser le mot de passe')} />
      <form className={classes.form} noValidate autoComplete="off">
        <Grid item container spacing={spacing}>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.margin} fullWidth>
              {/* <InputLabel htmlFor="password" className={classes.label} >{t("Email")}</InputLabel> */}
              <BootstrapInput
                defaultValue={user.email}
                id="password"
                disabled
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              key="childSettings"
              width="max-content"
              height="100%"
              display="flex"
              alignItems="center"
              onClick={resetPassword}
            >
              <Typography color="textSecondary" variant="h5" component="span">
                <Box fontWeight="500" color={theme.palette.text.secondary}>
                  <Button className={classes.button} variant="contained">
                    {t('Réinitialiser le mot de passe')}
                  </Button>
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={messageOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        key={'bottom_success'}
        className={classes.snackbar}
      >
        <Alert onClose={handleClose} severity="success">
          {t('Un mail de changement de mot de passe a été envoyé à : ') +
            info.email}
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        key={'bottom_error'}
        className={classes.snackbar}
      >
        <Alert onClose={handleClose} severity="error">
          {t(
            'Une erreur est surevnue lors de la réinitialisation de votre mot de passe',
          )}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Settings;
