import React from 'react';
import stars from 'images/five-stars.png';
import { TFunction } from 'i18next';

const PaymentScreenFooter: React.FC<{ t: TFunction }> = ({ t }) => {
  return (
    <>
      <div className="footer_desktop">
        <div className="footer_desktop_top">
          <p className="footer_desktop_top_copyrights">
            {t('© 2021 DATAWIN. Tous droits réservés')} -{' '}
            <a href="/terms" target="_blank" className="footer_desktop_legal">
              {t('CGU & Mentions légales')}
            </a>{' '}
            -{' '}
            <a href="/privacy" target="_blank" className="footer_desktop_legal">
              {t('Confidentialité')}
            </a>
          </p>
        </div>
        <div className="footer_desktop_bottom">
          <img src={stars} className="footer_desktop_stars" />
          <p>
            {t('Sur la base de 12.453 Avis')} - {t('Contact')} :{' '}
            <a
              href="mailto:support@datawin-prediction.com"
              className="footer_desktop_legal"
            >
              support@datawin-prediction.com
            </a>
          </p>
        </div>
      </div>
      <div className="footer_phone">
        <img src={stars} className="footer_phone_stars" />
        <p>{t('Sur la base de 12.453 Avis')}</p>
        <div className="footer_phone_legal_links">
          <a
            href="/terms"
            target="_blank"
            className="footer_phone_legal_links_link"
          >
            {t('CGU & Mentions légales')}
          </a>
          <a
            href="/privacy"
            target="_blank"
            className="footer_phone_legal_links_link"
          >
            {t('Confidentialité')}
          </a>
          <a
            href="mailto:support@datawin-prediction.com"
            className="footer_phone_legal_links_link"
          >
            {t('CONTACTEZ-NOUS')}
          </a>
        </div>
        <p className="footer_phone_copyrights">
          {t('© 2021 DATAWIN. Tous droits réservés')}
        </p>
      </div>
    </>
  );
};

export default PaymentScreenFooter;
