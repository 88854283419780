import { User } from '../../../cloud/functions/src/types/user';
import { Fire } from './index';

export default class Mail {
  /**
   * Will send mail to user with wanted mail template
   * @param {User} user - User Firestore payload for who we want to send an email
   * @param {Number | String} templateId - Id of SendinBlue email template
   */
  static async send(user: User, templateId: number) {
    return Fire.cloud('sendMail', { user, templateId });
  }
}
