import React, { useState } from "react";
import {
    Card,
    CardActions,
    Typography,
    Button,
    Box
} from "@material-ui/core/";
import { useTranslation } from "react-i18next";
import {
    makeStyles,
    Theme,
    useTheme,
    createStyles
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from 'clsx';
import HomeStars4 from 'images/homePage/HomeStars4.svg'
import HomeStars5 from 'images/homePage/HomeStars5.svg'

const Review = (props) => {
    const { name, date, review, logo, borderRadius, width, mark } = props;
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const smallComputer = useMediaQuery(theme.breakpoints.only("sm"));
    const { t } = useTranslation();

    return (
        <Box boxShadow="0 19px 62px 5px rgba(10, 22, 62, 0.06)" borderRadius="25px" padding="1.5rem" marginRight="1rem" display="flex" flexDirection="column" justifyContent="space-between">
            <Box display="flex" flexDirection="row" flexWrap="nowrap" height="4.15rem">
                <Box width={mobile ? "50px" : "67px"} height={mobile ? "50px" : "67px"} borderRadius="50%" display="flex" alignItems="center" justifyContent="center" boxShadow="0 6px 25px -4px rgba(118, 148, 255, 0.42)">
                    <img src={logo} alt="Logo" width={width} style={{ borderRadius: borderRadius }} />
                </Box>
                <Box width={"auto"} display="flex" flexDirection="column" justifyContent="center" marginLeft={"1rem"} textAlign="left" height="100%">
                    <Typography variant={mobile ? "h3" : "h4"} component={'span'}>
                        <Box color="#1a2844" fontWeight={600}>
                            {t(name)}
                        </Box>
                    </Typography>
                    <Typography variant={mobile ? "h6" : "body1"} component={'span'}>
                        <Box color="#848ca5" fontWeight={500} marginTop={mobile ? ".25rem" : 0}>
                            {date}
                        </Box>
                    </Typography>
                </Box>
            </Box>
            <Box color="#6a6f75" marginTop={mobile ? "1rem" : "1.5rem"} width={mobile ? "200px" : "280px"} textAlign="left">
                <Typography variant="h6" component={'span'}>
                    <Box fontWeight={500} lineHeight="2rem">
                        « {t(review)} »
                    </Box>
                </Typography>
            </Box>
            <Box marginTop="1rem" display="flex" justifyContent="flex-start" alignItems="center">
                <img src={mark === 4 ? HomeStars4 : HomeStars5} alt="stars" style={{ height: mobile ? "13px" : "15px" }} />
            </Box>
        </Box>
    )
}

export default Review
