import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  withStyles,
  useTheme,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardGeneral from './CardGeneral';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { useTranslation, initReactI18next } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ButtonFilled from '../Buttons/ButtonFilled';
import { useAsync } from 'hooks';
import { Fire, Time } from 'services';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: 'none',
      [theme.breakpoints.down('sm')]: {
        padding: '3px 7px',
        lineHeight: 1,
      },
    },
    head: {
      // backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 28,
      borderBottom: 'solid 1px #44507a',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    body: {
      fontSize: 22,
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    container: {
      overflowX: 'unset',
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
      backgroundColor: 'transparent',
      fontSize: 22,
      [theme.breakpoints.down('sm')]: {
        minWidth: 'unset',
        fontSize: 18,
      },
    },
    paper: {
      backgroundColor: 'transparent',
      overflowX: 'auto',
      boxShadow: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    down: {
      color: '#838ca6 !important',
      fontSize: 16,
      marginTop: 5,
      [theme.breakpoints.down('sm')]: {
        fontSize: 9,
      },
    },
    tr: {
      marginBottom: '1rem',
      '&:after': {
        content: '',
        display: 'block',
        height: '1.5em',
        width: '100%',
        background: 'transparent',
      },
    },
    tbody: {
      '&:before': {
        /* This doesn't work because of border-collapse */
        lineHeight: '1em',
        content: '.',
        color: 'white' /* bacground color */,
        display: 'block',
      },
      '&>tr>th': {
        padding: '.5rem',
      },
    },
    logoBM: {
      marginTop: 20,
    },
    bestOdd: {
      color: '#68daa8',
      fontWeight: 600,
    },
  }),
);

interface Props {
  prono: any;
  bestOdd: any;
  setBestOdd: Dispatch<SetStateAction<number>>;
  setBestBookmaker: Dispatch<SetStateAction<string>>;
}

const convertTableMarket = {
  byWin: 'h2h',
  byMorePts: 'totals',
  byLessPts: 'totals',
  byDifference: 'spreads',
};

export default function CardBookmakersUS(props: Props) {
  const { prono, bestOdd, setBestOdd, setBestBookmaker } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const ref = Fire.store().collection('bonus').where('language', '==', 'us');
  const user = useSelector((state: any) => state.authReducer.user);
  const [bonus] = useAsync(Fire.list(ref), []);
  bonus.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
  const bonusFiltered = bonus
    .filter(b =>
      b.states.some(
        state =>
          state.label.toLowerCase() === user.location.state.toLowerCase(),
      ),
    )
    .filter(b =>
      prono.matchOddsUS.bookmakers.some(
        bookmaker => b.name.toLowerCase() === bookmaker.key,
      ),
    );

  return (
    <CardGeneral blue>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        padding={mobile ? '1rem 0rem' : '0rem 1rem'}
        data-tour="presentation-cotes-step"
      >
        <TableContainer component={Paper} className={classes.paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow className={classes.tr}>
                <StyledTableCell>Bookmakers</StyledTableCell>
                <StyledTableCell align="center">
                  <div className="up" style={{ fontSize: mobile ? 12 : 20 }}>
                    {mobile
                      ? t(prono.match.value.home?.label)
                      : t(prono.match.value.home?.name)}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div className="up" style={{ fontSize: mobile ? 12 : 20 }}>
                    {mobile
                      ? t(prono.match.value.away?.label)
                      : t(prono.match.value.away?.name)}
                  </div>
                </StyledTableCell>

                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tbody}>
              <StyledTableRow style={{ height: 15 }} />
              {prono != undefined &&
                bonusFiltered.map((item, index) => {
                  const Odds = prono.matchOddsUS.bookmakers.find(
                    bookmaker => bookmaker.key === item.name.toLowerCase(),
                  ).markets[0].outcomes;
                  const maxBookmaker = prono.matchOddsUS.bookmakers.find(
                    bookmaker => bookmaker.key === item.name.toLowerCase(),
                  ).key;

                  const maxOdd = Math.max(...Odds.map(o => o.price));

                  if (maxOdd > bestOdd) {
                    setBestOdd(maxOdd);
                    maxBookmaker && setBestBookmaker(maxBookmaker);
                  }

                  return (
                    <StyledTableRow key={item.title}>
                      <StyledTableCell component="th" scope="row">
                        {item ? (
                          <div onClick={() => window.open(item.link, '_blank')}>
                            <img
                              alt={item.title}
                              style={{ borderRadius: 5, width: 'auto' }}
                              className="logoBM cursorPointer"
                              src={item.logo}
                              height={mobile ? 20 : 32}
                            />
                            <br />
                          </div>
                        ) : (
                          ''
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        className={
                          Odds[0]?.price === bestOdd ? classes.bestOdd : ''
                        }
                      >
                        {Odds[0]?.price.toString()}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        className={
                          Odds[1]?.price === bestOdd ? classes.bestOdd : ''
                        }
                      >
                        {Odds[1]?.price.toString()}
                      </StyledTableCell>
                      {item.link && (
                        <StyledTableCell align="right">
                          <ButtonFilled
                            title="Découvrir"
                            blue={true}
                            medium
                            onPress={() => window.open(item.link, '_blank')}
                          />
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </CardGeneral>
  );
}
