import { handleActions } from 'redux-actions';

const initialState = {
  FOOTBALL: [],
  TENNIS: [],
  NBA: [],
  NFL: [],
  NHL: [],
  BASEBALL: [],
};

export const pronosReducer = handleActions(
  {
    ADD_PRONOS: (state: any, action: any) => {
      return {
        ...state,
        [action.payload.sport]: action.payload.pronos,
      };
    },
  },
  initialState,
);
