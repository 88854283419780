import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { Fire } from 'services';
import { useAsync2 } from 'hooks';
import { Sport } from '../ToggleSport';
import FootballPreview from './FootballPreview/FootballPreview';
import TennisPreview from './TennisPreview/TennisPreview';
import NFLPreview from './NFLPreview/NFLPreview';
import NBAPreview from './NBAPreview/NBAPreview';
import { getPronosDate } from '../../utils/prono';
import NHLPreview from './NHLPreview/NHLPreview';
import BASEBALLPreview from './BASEBALLPreview/BASEBALLPreview';

export type PreviewPronosProps = {
  selectedSport: Sport;
  pronos: any[];
};

const getCollectionPath = (selectedSport: Sport): string => {
  switch (selectedSport) {
    case 'FOOTBALL':
      return 'pronos';
    case 'TENNIS':
      return 'pronosTennis';
    case 'NBA':
      return 'pronosNBA';
    case 'NHL':
      return 'pronosNHL';
    case 'NFL':
      return 'pronosNFL';
    case 'BASEBALL':
      return 'pronosBASEBALL';
    default:
      return 'pronos';
  }
};

const PreviewPronos: React.FC<PreviewPronosProps> = ({
  selectedSport,
  pronos,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const tablette = useMediaQuery(theme.breakpoints.only('sm'));
  const smallComputer = useMediaQuery(theme.breakpoints.only('md'));
  const bigComputer = useMediaQuery(theme.breakpoints.up('lg'));
  const spacing = tablette ? 3 : 6;

  if (selectedSport === 'FOOTBALL') {
    return (
      <FootballPreview
        res={pronos['FOOTBALL']}
        t={t}
        bigComputer={bigComputer}
        smallComputer={smallComputer}
        tablette={tablette}
        mobile={mobile}
      />
    );
  }

  if (selectedSport === 'TENNIS') {
    return (
      <TennisPreview
        res={pronos['TENNIS']}
        spacing={spacing}
        bigComputer={bigComputer}
        smallComputer={smallComputer}
        tablette={tablette}
        mobile={mobile}
        t={t}
      />
    );
  }

  if (selectedSport === 'NBA') {
    return (
      <NBAPreview
        res={pronos['NBA']}
        spacing={spacing}
        bigComputer={bigComputer}
        smallComputer={smallComputer}
        tablette={tablette}
        mobile={mobile}
        t={t}
      />
    );
  }

  if (selectedSport === 'NFL') {
    return (
      <NFLPreview
        res={pronos['NFL']}
        spacing={spacing}
        bigComputer={bigComputer}
        smallComputer={smallComputer}
        tablette={tablette}
        mobile={mobile}
        t={t}
      />
    );
  }

  if (selectedSport === 'NHL') {
    return (
      <NHLPreview
        res={pronos['NHL']}
        spacing={spacing}
        bigComputer={bigComputer}
        smallComputer={smallComputer}
        tablette={tablette}
        mobile={mobile}
        t={t}
      />
    );
  }

  if (selectedSport === 'BASEBALL') {
    return (
      <BASEBALLPreview
        res={pronos['BASEBALL']}
        spacing={spacing}
        bigComputer={bigComputer}
        smallComputer={smallComputer}
        tablette={tablette}
        mobile={mobile}
        t={t}
      />
    );
  }

  return <></>;
};

export default PreviewPronos;
