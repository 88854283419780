import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import '../../../theme/datawinTheme.css';
import SinglePronoPredictions from './SinglePronoPredictions';
import SinglePronoHistory from './History/SinglePronoHistory/SinglePronoHistory';
import { Fire } from 'services';
import { CompetitorSummary } from '../../../types/sportradar/soccer/history';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent',
    width: '100%',
  },
  tabsContainer: {
    backgroundColor: 'rgba(38, 52, 112, 1)',
    padding: '.25rem',
    width: 'fit-content',
    borderRadius: '30px',
    borderBottom: 'none',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '.5rem',
    },

    '& > div > span': {
      display: 'none',
    },
    '& > div > div': {
      display: 'inherit',
    },
  },
  tab: {
    color: '#ffffff',
    fontWeight: 600,
    zIndex: 2,
    transition: 'all .3s ease-in-out',

    [theme.breakpoints.down('xs')]: {
      width: '50%',

      '& span': {
        fontSize: '1.4rem',
      },
    },

    '&.Mui-selected': {
      color: '#212121 !important',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '3rem',
    },
  },
  itemsContainer: {
    '& > div > div > div > div': {
      padding: '1.4rem 0',
    },
  },
}));

export default function SinglePronoTabPanel(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  // const [teamHistories1, setTeamHistories1] = useState<CompetitorSummary[]>([]);
  // const [teamHistories2, setTeamHistories2] = useState<CompetitorSummary[]>([]);
  // const [teamHistories3, setTeamHistories3] = useState<CompetitorSummary[]>([]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  // useEffect(() => {
  //   const getHistories = async (): Promise<void> => {
  //     try {
  //       const competitorIds: [string, string] =
  //         props.schedule.value.sport_event.competitors.map(({ id }) => id);

  //       const [history1, history2, history3] = await Promise.all([
  //         ...competitorIds.map(async competitorId =>
  //           Fire.cloud<CompetitorSummary[]>('soccer', {
  //             method: 'getTeamHistory',
  //             competitorId,
  //           }),
  //         ),
  //         Fire.cloud<CompetitorSummary[]>('soccer', {
  //           method: 'getTeamVSTeamHistory',
  //           competitorIds,
  //         }),
  //       ]);
  //       setTeamHistories1(history1);
  //       setTeamHistories2(history2);
  //       setTeamHistories3(history3);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };
  //   getHistories();
  // }, [props.schedule.value.sport_event.competitors]);

  return (
    <div className={classes.root}>
      {/* <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.tabsContainer}
        >
          <Tab
            label={t('Algorithme')}
            {...a11yProps(0)}
            className={classes.tab}
          />
          <Tab
            label={t('Historique')}
            {...a11yProps(1)}
            className={classes.tab}
          />
           <Tab label={t("Temps fort")} {...a11yProps(1)} className={classes.tab} /> 
        </Tabs>
      </AppBar> */}
      <SinglePronoPredictions {...props} />
      {/* <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        className={classes.itemsContainer}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <SinglePronoPredictions {...props} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <SinglePronoHistory
            teamHistories1={teamHistories1}
            teamHistories2={teamHistories2}
            teamHistories3={teamHistories3}
            teamName1={t(props.domicile.name)}
            teamName2={t(props.ext.name)}
          />
        </TabPanel>
      </SwipeableViews> */}
    </div>
  );
}
