import React, { useState } from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import {
  makeStyles,
  useTheme,
  createStyles,
  Theme,
  MenuItem,
  MenuList,
  SvgIcon,
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import StorageRoundedIcon from '@material-ui/icons/StorageRounded';
import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
import SchoolIcon from '@material-ui/icons/School';
import LiveTvRoundedIcon from '@material-ui/icons/LiveTvRounded';
import LiveHelpRoundedIcon from '@material-ui/icons/LiveHelpRounded';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { ReactComponent as LivescoreImage } from 'images/LivescoreImage.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Sport } from '../../Home/ToggleSport';
import flag_fr from 'images/flag_fr.png';
import flag_en from 'images/flag_en.png';
import flag_es from 'images/flag_es.png';
import flag_it from 'images/flag_it.png';
import ImageButton from '../../../Home/Templates/ImageButton';
import i18n from '../../../../i18n';
import moment from 'moment';
import { logout, updateUser } from 'actions/auth.action';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerFooter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginTop: 'auto',
      color: 'white',
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    drawerOpen: {
      width: drawerWidth,
      padding: '.5rem 1.5rem',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      padding: '0 1rem',
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    drawerLeft: {
      background: '#2e3d81',
      borderRight: '1px solid rgba(255, 255, 255, 0.2)',
    },
    title: {
      fontWeight: 700,
      fontSize: 21,
    },
    outsideContainer: {
      padding: 0,
    },
    navbarDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`,
      alignItems: `center`,
      padding: `1.5rem`,
      [theme.breakpoints.down('md')]: {
        padding: '15px 25px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '9px 20px 5px',
      },
    },
    navDisplayFlex: {
      marginTop: '2rem',
      display: `flex`,
      flexDirection: 'column',
    },
    linkText: {
      textDecoration: `none`,
      fontWeight: 700,
      fontSize: '1rem',
      padding: `8px 18px`,
      margin: '.5rem 0',
      borderRadius: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',

      '&:hover': {
        backgroundColor: '#0a1640 !important',
      },

      '&.Mui-selected': {
        backgroundColor: `${theme.palette.secondary.main} !important`,
        boxSizing: 'border-box !important',
        color: theme.palette.primary.main,
      },

      '& p': {
        marginRight: 'auto',
      },

      '& svg': {
        color: 'white',
        fill: 'white',
        width: 25,
      },

      '&.Mui-selected svg': {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
      },
    },
    iconButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    transparent: {
      backgroundColor: 'rgba(25,38,83,0.85)',
    },
    iconUser: {
      fontSize: 40,
      padding: 0,
      '&:hover': {
        boxShadow: '0 0 33px 0 rgb(255 255 255 / 11%)',
      },
    },
    popoverMenu: {
      '& div:nth-child(0n + 3)': {
        // background: '#0b1847',
        padding: '.5rem',

        '&>ul>li': {
          fontWeight: 600,
          color: '#0b1847',
          fontSize: '.9rem',
          paddingRight: '2rem',
          '&:hover': {
            backgroundColor: '#ecf0f8',
          },
        },
      },
    },
    boxLangSelect: {
      '&>div::before': {
        borderBottom: 'none !important',
      },
    },
    drawerLinkText: {
      textDecoration: `none`,
      textTransform: `capitalize`,
      fontWeight: 700,
      color: 'white',
      fontSize: '1.5rem',
      padding: `8px 18px 8px 0`,
      margin: '0 10px',
    },
  }),
);

function LeftNavbar({ open, setOpen }) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const pathname = `/${window.location.pathname.split('/')[1]}`;
  const user = useSelector((state: any) => state.authReducer.user);
  const [info, setInfo] = React.useState<any>({ ...user });
  const selectedSport: Sport = useSelector(
    (state: any) => state.sportsReducer.selectedSport,
  );
  const dispatch = useDispatch();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    moment.locale(lng);
  };

  const handleToggleDrawer = () => {
    setOpen(prev => !prev);
  };

  const promptLogout = () => {
    const confirm = window.confirm(
      t('Êtes-vous sûr de vouloir vous déconnecter ?'),
    );
    if (confirm) {
      dispatch(logout());
      history.push('/');
    }
  };

  const resetTutoriel = async () => {
    const allSteps = ['bonus', 'home1', 'home2', 'player', 'prono', 'pronos'];

    allSteps.forEach(step => {
      info['isFirstConnexion'][step] = true;
    });
    const payload = {
      ...info,
    };
    await dispatch(updateUser(payload));
    history.push('/');
  };

  const [navLinks, setNavLinks] = React.useState([
    {
      title: t(`Accueil`),
      path: `/`,
      icon: <HomeRoundedIcon fontSize="small" />,
    },
    {
      title: t(`Analyses`),
      path: `/pronos`,
      icon: <AssessmentRoundedIcon />,
    },
    // {
    //   title: t(`Database`),
    //   path: `/library`,
    //   icon: <StorageRoundedIcon fontSize="small" />,
    // },
    // {
    //   title: t(`Highlights`),
    //   path: `/highlights`,
    //   icon: <LiveTvRoundedIcon fontSize="small" />,
    // },
    // {
    //   title: t(`Live score`),
    //   path: `/livescore/${selectedSport.toLowerCase()}`,
    //   icon: <LivescoreImage />,
    // },
    // { title: t(`Bonus`), path: `/bonus` },
    {
      title: t('FAQ'),
      path: '/faq',
      icon: <LiveHelpRoundedIcon fontSize="small" />,
    },
    // {
    //   title: t('Blog'),
    //   path: '/blog',
    //   icon: <LiveHelpRoundedIcon fontSize="small" />,
    // },
    {
      title: t('Tutoriel'),
      tuto: true,
      icon: <SchoolIcon fontSize="small" />,
      path: '',
    },
    {
      title: t('Mon compte'),
      path: '/account',
      icon: <AccountCircleIcon fontSize="small" />,
    },
    {
      title: t(`Déconnexion`),
      disconnect: true,
      path: '',
      icon: <ExitToAppIcon fontSize="small" />,
    },

    // { title: t(`Conseils`), path: `/conseils` },
  ]);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.drawerLeft, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <Typography
        variant="h4"
        className={classes.title}
        onClick={() => history.push('/')}
        style={{
          cursor: 'pointer',
          margin: '2rem auto 1rem 0',
          opacity: open ? 1 : 0,
          transition: open ? 'opacity 0.5s ease-in-out' : '',
          color: 'white',
        }}
      >
        DATA
        <span style={{ fontStyle: 'italic', color: 'white' }}>WIN</span>
      </Typography>
      <MenuList
        aria-labelledby="main navigation"
        className={classes.navDisplayFlex}
      >
        {navLinks.map(({ title, path, icon, disconnect, tuto }, index) => (
          <MenuItem
            key={index}
            component={Link}
            to={path}
            onClick={() =>
              disconnect ? promptLogout() : tuto && resetTutoriel()
            }
            className={clsx(classes.linkText)}
            selected={
              path === pathname ||
              (path === '/dashboard' && pathname === '/') ||
              (pathname.includes('/pronos') && path === '/pronos')
            }
            data-tour={
              path === '/library'
                ? 'nav-database-step'
                : path === '/dashboard' || path === '/'
                ? 'nav-home-step'
                : `nav-${
                    path !== '' ? path?.split('/')[1].toLowerCase() : ''
                  }-step`
            }
          >
            {!open ? (
              <ListItemIcon className={classes.iconButton}>{icon}</ListItemIcon>
            ) : (
              <Typography variant="body1">
                {title}
                {title === 'Database' && (
                  <span
                    style={{
                      verticalAlign: 'super',
                      fontWeight: 400,
                      fontSize: '.7rem',
                    }}
                  >
                    app
                  </span>
                )}
              </Typography>
            )}
          </MenuItem>
        ))}
        {open && (
          <ListItem className={classes.drawerLinkText}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <ImageButton
                height={21}
                width={21}
                onPress={() => changeLanguage('fr')}
                image={flag_fr}
                selected={i18n.language === 'fr'}
                marginRight="1rem"
              />
              <ImageButton
                height={21}
                width={21}
                onPress={() => changeLanguage('en')}
                image={flag_en}
                selected={i18n.language === 'en'}
                marginRight="1rem"
              />
              <ImageButton
                height={21}
                width={21}
                onPress={() => changeLanguage('es')}
                image={flag_es}
                selected={i18n.language === 'es'}
                marginRight="1rem"
              />
              <ImageButton
                height={21}
                width={21}
                onPress={() => changeLanguage('it')}
                image={flag_it}
                selected={i18n.language === 'it'}
              />
            </Box>
          </ListItem>
        )}
      </MenuList>
      <div className={classes.drawerFooter}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleToggleDrawer}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
    </Drawer>
  );
}

export default LeftNavbar;
