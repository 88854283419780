import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/it';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import './HeaderBar.scss';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import logo from 'images/logo_datawin.png';
import flag_fr from 'images/flag_fr.png';
import flag_en from 'images/flag_en.png';
import flag_es from 'images/flag_es.png';
import flag_it from 'images/flag_it.png';

import Button from '../Button/Button';
import ImageButton from '../ImageButton/ImageButton';

function HeaderBar() {
  const history = useHistory();
  const [shown, showMenu] = React.useState(false);
  const user = useSelector((state: any) => state.authReducer.user);
  const [headerbar, setheaderbar] = useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    moment.locale(lng);
  };

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setheaderbar(true);
    } else {
      setheaderbar(false);
    }
  };

  window.addEventListener('scroll', changeBackground);
  return (
    <React.Fragment>
      <header
        className={[
          'main-header-bar font',
          headerbar ? 'headerbar active' : '',
          shown ? 'shown' : '',
        ].join(' ')}
      >
        {isMobile && shown ? (
          <a className="logo-link" href="/">
            <img src={logo} className="main-logo" alt="logo" />
          </a>
        ) : (
          ''
        )}
        <div className="menu">
          {isMobile && shown ? (
            <React.Fragment>
              <div className="links">
                <div className="link">
                  <a onClick={() => showMenu(false)} href="/#intro">
                    {t('Accueil')}
                  </a>
                </div>
                <div className="link" onClick={() => history.push('/login')}>
                  {t('Pronos')}
                </div>
                <div className="link">
                  <a onClick={() => showMenu(false)} href="/#avantages">
                    {t('Avantages')}
                  </a>
                </div>
                <div className="link" onClick={() => history.push('/bonus')}>
                  {t('Bonus')}
                </div>
              </div>

              <div className="row login-btns">
                {user ? (
                  <Button
                    title={t('Mon compte')}
                    classes={['teal-btn', 'link-btn']}
                    onPress={() => history.push('/account')}
                  />
                ) : (
                  <React.Fragment>
                    <Button
                      title={t("S'inscrire")}
                      classes={['linear-btn', 'link-btn', 'small']}
                      onPress={() => history.push('/paiement-offre-1-euro')}
                      icon
                      small
                    />
                    <Button
                      title={t('Me connecter')}
                      classes={['clear-btn', 'link-btn', 'small']}
                      onPress={() => history.push('/login')}
                      small
                    />
                    <div className="flags">
                      <ImageButton
                        height={32}
                        width={32}
                        onPress={() => changeLanguage('fr')}
                        image={flag_fr}
                      />
                      &nbsp;
                      <ImageButton
                        height={32}
                        width={32}
                        onPress={() => changeLanguage('en')}
                        image={flag_en}
                      />
                      &nbsp;
                      <ImageButton
                        height={32}
                        width={32}
                        onPress={() => changeLanguage('es')}
                        image={flag_es}
                      />
                      &nbsp;
                      <ImageButton
                        height={32}
                        width={32}
                        onPress={() => changeLanguage('it')}
                        image={flag_it}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>

              <div className="close" onClick={() => showMenu(false)}>
                <i className="fa fa-close"></i>
              </div>
            </React.Fragment>
          ) : isMobile == false ? (
            <React.Fragment>
              <a className="logo-link" href="/">
                <img src={logo} className="main-logo" alt="logo" />
              </a>
              <div className="link">
                <a onClick={() => showMenu(false)} href="/#intro">
                  {t('Accueil')}
                </a>
              </div>
              <div className="link">
                <a
                  onClick={() => {
                    showMenu(false);
                    history.push('/paiement-offre-1-euro');
                  }}
                >
                  {t('Pronos')}
                </a>
              </div>
              <div className="link">
                <a onClick={() => showMenu(false)} href="/#avantages">
                  {t('Avantages')}
                </a>
              </div>
              <div className="link" onClick={() => history.push('/bonus')}>
                {t('Bonus')}
              </div>

              <div className="close" onClick={() => showMenu(false)}>
                <i className="fa fa-close"></i>
              </div>
              <div className="row login-btns">
                {user ? (
                  <Button
                    title={t('Mon compte')}
                    classes={['teal-btn', 'link-btn']}
                    onPress={() => history.push('/account')}
                  />
                ) : (
                  <React.Fragment>
                    <Button
                      title={t("S'inscrire")}
                      classes={['linear-btn', 'link-btn', 'small']}
                      onPress={() => history.push('/paiement-offre-1-euro')}
                      icon
                      small
                    />
                    <Button
                      title={t('Me connecter')}
                      classes={['clear-btn', 'link-btn', 'small']}
                      onPress={() => history.push('/login')}
                      small
                    />
                    <div className="flags">
                      <ImageButton
                        height={32}
                        width={32}
                        onPress={() => changeLanguage('fr')}
                        image={flag_fr}
                      />
                      &nbsp;
                      <ImageButton
                        height={32}
                        width={32}
                        onPress={() => changeLanguage('en')}
                        image={flag_en}
                      />
                      &nbsp;
                      <ImageButton
                        height={32}
                        width={32}
                        onPress={() => changeLanguage('es')}
                        image={flag_es}
                      />
                      &nbsp;
                      <ImageButton
                        height={32}
                        width={32}
                        onPress={() => changeLanguage('it')}
                        image={flag_it}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </header>

      <a href="/" className={headerbar ? 'header-overlay' : ''}>
        <img src={logo} className="main-logo responsive-logo" alt="logo" />
      </a>
      <div className="hamburger" onClick={() => showMenu(true)}>
        <a onClick={() => showMenu(true)}>
          <i className="fa fa-list"></i>
        </a>
      </div>
    </React.Fragment>
  );
}

export default HeaderBar;
