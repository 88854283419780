export type matchTypesWithNames = 'byScorer' | 'byPerf' | 'byDifference';

export type matchTypesWithPercentage =
  | 'byMorePts'
  | 'byLessPts'
  | 'byScorer'
  | 'byPerf'
  | 'byDifference';

export type matchTypesWithPts =
  | 'byLessPts'
  | 'byMorePts'
  | 'byScorer'
  | 'byPerf'
  | 'byDifference';

export const matchTypes = {
  byWin: 'Vainqueur : ',
  byResult: 'Résultat : ',
  byDraw: 'Match nul',
  byWinOrDraw: 'vainqueur ou match nul',
  byMorePts: 'plus de',
  byLessPts: 'moins de',
  byScorer: 'marque plus de',
  byPerf: '',
  byDifference: '',
} as const;

export type matchTypesType = keyof typeof matchTypes;

export type matchTypeValueType = {
  name?: string;
  percentage?: string;
  pts?: number;
};
