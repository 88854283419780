import React from 'react';
import './ResponseBoxInput.scss';

type Props = {
  value: string;
  placeholder?: string;
  disabled?: boolean;

  onChange: (str: string) => void;
}
function ResponseBoxInput(props: Props) {
  return (
    <div
      className={[
        "response-box-input",
      ].join(" ")}
      >
      <textarea
        value={props.value}
        disabled={props.disabled}
        onChange={(e) => props.onChange(e.target.value)}
        placeholder={props.placeholder}
        >
      </textarea>
    </div>
  );
}

export default ResponseBoxInput;