import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonFilled from '../../Dashboard/Templates/Buttons/ButtonFilled';
import logo_psg from 'images/logo_psg.png';
import Logo from '../../Dashboard/Templates/Logos/Logo';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { TFunction } from 'i18next';
import logoVillareal from 'images/logo_villareal.png';
import logoMilan from 'images/logo_milan.png';
import logoLiverpool from 'images/logo_liverpool.png';
import logoKyrgios from 'images/Kyrgios.png';
import logoTsitsipas from 'images/Tsitsipas.png';
import logoAtalanta from 'images/logo_atalanta.png';
import logoMinnesota from 'images/logo_minnesota.png';
import logoMemphis from 'images/logo_memphis.png';
import logoFribourg from 'images/logo_fribourg.png';
import logoLeipzig from 'images/logo_leipzig.png';
import logoBoston from 'images/logo_boston.png';
import logoGoldenState from 'images/logo_golden_state.png';

interface team {
  name: string;
  logo: string;
}

interface match {
  id: string;
  sport: 'soccer' | 'NBA' | 'NHL' | 'NFL' | 'tennis';
  domicile: team;
  ext: team;
  succeded: boolean;
  winner: string;
  odd: number;
  borderBottom?: boolean;
}

interface matchRow extends match {
  t: TFunction;
}

const redirectWithUTMUrl = url => {
  const target_url = (url += document.location.search || '');
  return target_url;
};

const ResultBox = ({ succeded }) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  return succeded ? (
    <Box
      width={mobile ? '40px' : '30px'}
      height={mobile ? '40px' : '30px'}
      borderRadius="50%"
      style={{ background: '#62688454' }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CheckRoundedIcon
        style={{ color: '#3fff3f', maxWidth: '70%' }}
        fontSize={mobile ? 'default' : 'small'}
      />
    </Box>
  ) : (
    <Box
      width={mobile ? '40px' : '30px'}
      height={mobile ? '40px' : '30px'}
      borderRadius="50%"
      style={{ background: '#62688454' }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CloseRoundedIcon
        style={{ color: 'red', maxWidth: '70%' }}
        fontSize={mobile ? 'default' : 'small'}
      />
    </Box>
  );
};

const LogoBox: React.FC<any> = ({ succeded, logo1, logo2 }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      padding="1.5rem 10%"
    >
      <Logo src={logo1} width={50} club />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <ResultBox succeded={succeded} />
      </Box>
      <Logo src={logo2} width={50} club />
    </Box>
  );
};

const MatchRowDesktop: React.FC<matchRow> = ({
  id: string,
  sport,
  domicile,
  ext,
  succeded,
  winner,
  odd,
  borderBottom,
  t,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      style={{
        color: 'white',
        borderBottom: borderBottom ? 'solid 1px #62688454' : 'none',
        alignItems: 'center',
      }}
    >
      <Grid item xs={1}>
        {sport === 'tennis'
          ? '🎾'
          : sport === 'NBA'
          ? '🏀'
          : sport === 'NFL'
          ? '⚾️'
          : sport === 'NHL'
          ? '🏈'
          : '⚽️'}
      </Grid>
      <Grid item container xs={3} style={{ justifyContent: 'center' }}>
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          {domicile.name}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <LogoBox succeded={succeded} logo1={domicile.logo} logo2={ext.logo} />
      </Grid>
      <Grid item container xs={3} style={{ justifyContent: 'center' }}>
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          {ext.name}
        </Typography>
      </Grid>
      <Grid item container xs={2}>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          padding=".5rem 1rem"
          style={{ background: '#62688424' }}
          borderRadius="10px"
          width="100%"
        >
          <Typography
            variant="body1"
            style={{
              marginBottom: '.5rem',
              color: theme.palette.text.secondary,
            }}
          >
            {t('Victoire')}
          </Typography>
          <Typography variant="h6">{winner}</Typography>
        </Box>
      </Grid>
      {/* <Grid item xs={2}>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          padding=".5rem 2rem"
          style={{
            background: '#62688424',
            border: `2px solid rgb(40, 191, 252)`,
          }}
          borderRadius="10px"
          width="fit-content"
        >
          <Typography
            variant="body1"
            style={{
              marginBottom: '.5rem',
              color: theme.palette.text.secondary,
            }}
          >
            Cote
          </Typography>
          <Typography variant="h6">{odd}</Typography>
        </Box>
      </Grid> */}
    </Grid>
  );
};
const MatchRowMobile: React.FC<matchRow> = ({
  id: string,
  sport,
  domicile,
  ext,
  succeded,
  winner,
  odd,
  borderBottom,
  t,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      style={{
        color: 'white',
        borderBottom: borderBottom ? 'solid 1px #62688454' : 'none',
        alignItems: 'center',
        padding: '2rem 0',
      }}
    >
      <Grid
        container
        item
        xs={12}
        style={{ marginBottom: '2rem', justifyContent: 'space-between' }}
      >
        <Grid item xs={3}>
          <Box
            style={{
              background: '#62688424',
              padding: '.75rem 1.25rem',
              borderRadius: '5px',
              width: 'fit-content',
              textTransform: 'capitalize',
            }}
          >
            <Typography variant="h6">{t(sport)}</Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          xs={8}
          style={{ justifyContent: 'end' }}
        >
          <Typography
            variant="h5"
            style={{ fontWeight: 600, textAlign: 'center' }}
          >
            {domicile.name} - {ext.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={3}>
          <ResultBox succeded={succeded} />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Logo src={domicile.logo} width={40} />
            <Logo src={ext.logo} width={40} style={{ marginLeft: '-1rem' }} />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            padding=".5rem 1rem"
            style={{ background: '#62688424' }}
            borderRadius="10px"
            width="100%"
          >
            <Typography
              variant="body1"
              style={{
                marginBottom: '.5rem',
                color: theme.palette.text.secondary,
              }}
            >
              {t('Victoire')}
            </Typography>
            <Typography variant="h6">{winner}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

const NewInPronos = () => {
  const { t } = useTranslation();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const matchs: match[] = [
    {
      id: 'milan_villa_match',
      sport: 'soccer',
      domicile: {
        name: 'Villarreal',
        logo: logoVillareal,
      },
      ext: {
        name: 'Liverpool',
        logo: logoLiverpool,
      },
      succeded: true,
      winner: 'Liverpool',
      odd: 1.71,
    },
    {
      id: 'Kyrgios_Tsitsipas_match',
      sport: 'tennis',
      domicile: {
        name: 'Kyrgios',
        logo: logoKyrgios,
      },
      ext: {
        name: 'Tsitsipas',
        logo: logoTsitsipas,
      },
      succeded: true,
      winner: 'Kyrgios',
      odd: 1.74,
    },
    {
      id: 'milan_atalanta_match',
      sport: 'soccer',
      domicile: {
        name: 'Atalanta',
        logo: logoAtalanta,
      },
      ext: {
        name: 'Milan AC',
        logo: logoMilan,
      },
      succeded: true,
      winner: 'Milan AC',
      odd: 1.73,
    },
    {
      id: 'Minnesota_Memphis_match',
      sport: 'NBA',
      domicile: {
        name: 'Minnesota',
        logo: logoMinnesota,
      },
      ext: {
        name: 'Memphis',
        logo: logoMemphis,
      },
      succeded: true,
      winner: 'Memphis',
      odd: 1.82,
    },
    {
      id: 'Fribourg_Leipzig_match',
      sport: 'soccer',
      domicile: {
        name: 'Fribourg',
        logo: logoFribourg,
      },
      ext: {
        name: 'Leipzig',
        logo: logoLeipzig,
      },
      succeded: false,
      winner: 'Leipzig',
      odd: 1.97,
    },
    {
      id: 'Boston_Golden_State_match',
      sport: 'NBA',
      domicile: {
        name: 'Boston',
        logo: logoBoston,
      },
      ext: {
        name: 'Golden State',
        logo: logoGoldenState,
      },
      succeded: true,
      winner: 'Boston',
      odd: 1.7,
    },
  ];

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Typography
        variant="h3"
        style={{
          width: '100%',
          textAlign: 'center',
          margin: 'auto',
          color: '#fff',
          fontWeight: 500,
          fontSize: '3rem',
        }}
      >
        {t('Dernières rencontres analysées')}
      </Typography>
      <Box
        margin="2rem auto"
        padding="2rem"
        maxWidth="950px"
        style={{
          background:
            'radial-gradient(rgb(39 180 239 / 10%), rgba(39, 187, 248, 1%))',
          borderRadius: '32px',
        }}
        display="flex"
        flexDirection="column"
        width="100%"
      >
        {matchs.map((match, index) =>
          mobile ? (
            <MatchRowMobile
              key={match.id}
              {...match}
              borderBottom={index !== matchs.length - 1}
              t={t}
            />
          ) : (
            <MatchRowDesktop
              t={t}
              key={match.id}
              {...match}
              borderBottom={index !== matchs.length - 1}
            />
          ),
        )}
      </Box>
      <Box margin="auto">
        <ButtonFilled
          title={t('Démarrer')}
          path={redirectWithUTMUrl(
            // 'https://datawin-pronos.net/sales-page'
            'secure',
          )}
          blank
        />
      </Box>
    </Box>
  );
};

export default NewInPronos;
