import React from 'react';


export default function GradientSVG(props) {
    let { colors, idCSS, rotation } = props;

    let gradientTransform = `rotate(${rotation})`;

    return (
        <svg style={{ height: 0, position: "absolute" }}>
            <defs>
                <linearGradient id={idCSS} gradientTransform={gradientTransform}>
                    {
                        colors.map((item, index) => {
                            if (item) {
                                return (
                                    <stop offset={item.offset} stopColor={item.color} key={index} />
                                )
                            }
                        })
                    }
                </linearGradient>
            </defs>
        </svg>
    );
}