import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import CardGeneral from '../../Templates/Cards/CardGeneral';
import clsx from 'clsx';
import CardPronoPourcentage from '../../Templates/Cards/CardPronoPourcentage';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isTooLong } from '../../../../utils/logic';
import BorderLinearProgress from '../../Templates/Progress/BorderLinearProgress';
import { getInfosPronoLibre } from '../../../../utils/pronoLibreTennis';
import { capitalize, TextField } from '@material-ui/core';
import { Fire } from 'services';
import Simulateur from '../../Templates/Simulateur/Simulateur';
import CardPronoPoints from '../../Templates/Cards/CardPronoPoints';
import CardPronoGoalsTotal from '../../Templates/Cards/CardPronoGoalsTotal';

const useStyles = makeStyles((theme: Theme) => ({
  outBox: {
    // background: 'linear-gradient(98deg, #63ee59 2%, #5dcc3a 35%, #2ae113 70%)',
    borderRadius: 25,
    height: '100%',
    width: '100%',
  },
  item: {
    padding: '2rem',
  },
  itemStats: {
    padding: '2rem',
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0',
    },
  },
  title: {
    margin: '.75rem 0 1.5rem 0',
  },
  title2: {
    margin: '2rem 0 1.5rem 0',
  },
  riskText: {
    marginBottom: '.5rem',
    marginLeft: '.5rem',
    fontWeight: 400,
    fontSize: '1.3rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1rem',
    },
  },
  greenText: {
    color: '#10ff00',
  },
  orangeText: {
    color: '#ffa500',
  },
}));

export default function SinglePronoPredictions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  const team1_name = t(props.winner.value.name);
  const team1_label = t(props.winner.value.label);
  const team2_name = t(props.loser.value.name);
  const team2_label = t(props.loser.value.label);
  const isMorePronos =
    props.teamResults?.winner || props.goals4 || props.goals5 || props.goals6;

  const { i18n } = useTranslation();
  const language = i18n.language;

  if (!isMorePronos) return null;

  return (
    <Grid container item xs={12} data-tour="presentation-algo-step">
      <Typography variant="h4" className={classes.title2}>
        {t("Probabilités calculées par l'algorithme :")}
      </Typography>
      <CardGeneral
        blue={!mobile}
        className={classes.itemStats}
        transparent={mobile}
      >
        <Grid
          container
          xs={12}
          justify="space-between"
          className="noMargin"
          // className="noMargin margin-bottom2rem"
          spacing={mobile ? 2 : 0}
        >
          {props.teamResults?.winner && (
            <Grid
              item
              xs={12}
              sm={4}
              style={{ paddingRight: mobile ? '.5rem' : '1rem' }}
            >
              <CardPronoPourcentage
                team1={isTooLong(team1_name, 8) ? team1_label : team1_name}
                team2={isTooLong(team2_name, 8) ? team2_label : team2_name}
                value={props.teamResults.winner}
                // value2={props.teamResults.looser}
                legend1={isTooLong(team1_name, 15) ? team1_label : team1_name}
                legend2={isTooLong(team2_name, 15) ? team2_label : team2_name}
                title={t('Chances de victoire')}
                subtitle={t('pour chaque équipe')}
                singleText={props.matchType !== 'byWin'}
              />
            </Grid>
          )}
          {props.goals4 && props.goals5 && props.goals6 && (
            <Grid
              item
              xs={12}
              sm={8}
              style={{ paddingLeft: mobile ? '.5rem' : '1rem' }}
            >
              <CardPronoGoalsTotal
                goals4={props.goals4}
                goals5={props.goals5}
                goals6={props.goals6}
                title={t('Nombre de touchdowns dans le match')}
                units="touchdowns"
              />
            </Grid>
          )}
          {props.loser_scored?.yes && (
            <Grid
              item
              xs={12}
              sm={4}
              style={{ paddingLeft: mobile ? '.5rem' : '1rem' }}
            >
              <CardPronoPourcentage
                team1={isTooLong(team2_name, 8) ? team2_label : team2_name}
                value={props.loser_scored.yes}
                legend1={'Oui'}
                legend2={'Non'}
                title={team2_name + ' ' + t('gagne au moins un set')}
                singleText
              />
            </Grid>
          )}
        </Grid>
      </CardGeneral>
    </Grid>
  );
}
