import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import PreviewTitle from '../../../Templates/Misc/PreviewTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { GridSpacing } from '@material-ui/core/Grid/Grid';
import { TFunction } from 'i18next';
import moment from 'moment';
import { PronoNBA } from '../../../../../types/pronoNBA';
import CardPredictionNBA from '../../../Templates/Cards/CardPredictionNBA/CardPredictionNBA';
import SimpleButtonYellow from '../../../Templates/Buttons/SimpleButtonYellow';
import { Fire } from 'services';
import { useSelector } from 'react-redux';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import SportGPTCardPrediction from '../../../Templates/Cards/SportGPTCardPrediction/SportGPTCardPrediction';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    singleCard: {
      maxWidth: 400,
      width: '30%',
      marginRight: '2rem',
      [theme.breakpoints.down('sm')]: {
        marginRight: '2rem',
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 280,
      },
    },
    last: {
      [theme.breakpoints.down('md')]: {
        margin: 'auto',
      },
    },
    fullWidth: {
      [theme.breakpoints.down('xs')]: {
        marginRight: '0px !important',
      },
    },
    singleCardContainer: {
      flexFlow: 'row nowrap',
      overflowX: 'scroll',
      marginBottom: '2rem',
      scrollbarColor: theme.palette.text.secondary + ' transparent',
      scrollbarWidth: 'thin',
      paddingBottom: '2rem',
      paddingTop: '1rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      position: 'relative',
      '&::-webkit-scrollbar': {
        height: '10px',
        paddingBottom: '1rem',
      },

      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.text.secondary,
        transition: 'background-color .3s ease-in-out',
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: theme.palette.text.primary,
        },
      },

      [theme.breakpoints.down('sm')]: {
        overflowX: 'visible',
        paddingLeft: 0,
      },
    },
  }),
);

type NBAPreviewProps = {
  res: PronoNBA[];
  spacing: GridSpacing;
  bigComputer: boolean;
  smallComputer: boolean;
  tablette: boolean;
  mobile: boolean;
  t: TFunction;
};

const orderPronos = (
  res: PronoNBA[],
  isProUser: boolean | undefined,
): PronoNBA[] => {
  const now = moment();
  const pronosToShow = res
    .filter(prono => {
      if ((prono.isPronoTest || prono.name_inactive) && !isProUser)
        return false;
      if (!prono.winner) return false;
      if (!prono.dateHideUntil) return true;
      return now.isAfter(prono.dateHideUntil.toDate());
    })
    .sort((a, b) => a.date?.toMillis() - b.date?.toMillis());
  const pronosToHide = res
    .filter(prono => {
      if ((prono.isPronoTest || prono.name_inactive) && !isProUser)
        return false;
      return now.isBefore(prono.dateHideUntil?.toDate());
    })
    .sort((a, b) => a.dateHideUntil?.toMillis() - b.dateHideUntil?.toMillis());
  return [...pronosToShow, ...pronosToHide];
};

const NBAPreview: React.FC<NBAPreviewProps> = ({
  res,
  spacing,
  mobile,
  smallComputer,
  tablette,
  bigComputer,
  t,
}) => {
  const [customText, setCustomText] = React.useState(
    "Pas d'analyse disponible pour le moment",
  );
  const [isProUser, setIsProUser] = React.useState<boolean>();
  const user = useSelector((state: any) => state.authReducer.user);
  const pronos = orderPronos(res, isProUser);
  const classes = useStyles();
  const now = new Date().getTime() / 1000;
  const theme = useTheme();

  const getCustomText = async () => {
    try {
      const ref = Fire.store().collection('custom_text').doc('preview_pronos');
      const text = await Fire.doc(ref);
      if (text.nba) setCustomText(text.nba);
    } catch (err) {
      console.log(err);
    }
  };

  const verifyIsProUser = async () => {
    const ref = Fire.store()
      .collection('pros')
      .where('email', '==', user.email);
    const list = await Fire.list(ref);
    setIsProUser(list.length !== 0);
  };

  React.useEffect(() => {
    getCustomText();
    verifyIsProUser();
  }, []);

  const [newPronos, setNewPronos] = useState<any>(pronos);

  const { data: matches, isLoading: SportGPTLoading } = useInfiniteQuery(
    'soccer:pronos',
    async () =>
      await axios.get(
        'https://api.sport-gpt.ai/api/public/pronostics?sport=basketball&limit=3&page=0',
      ),
  );

  useEffect(() => {
    setNewPronos(matches?.pages[0]?.data?.pronostics);
  }, [matches, setNewPronos]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
      >
        <PreviewTitle title="Analyses" marginBottom />
        <SimpleButtonYellow
          title={t("d'analyses")}
          addIcon={true}
          path="pronos"
        />
      </Box>
      <Grid
        item
        container
        xs={12}
        className={clsx(classes.singleCardContainer, 'noPaddingRight')}
      >
        {!SportGPTLoading ? (
          newPronos?.map((prono: any, index: number) => (
            <Grid
              item
              xs={12}
              sm={4}
              data-tour={index === 0 ? 'click-prono-step' : ''}
              className={clsx(classes.singleCard, classes.fullWidth)}
              key={index}
            >
              <SportGPTCardPrediction prono={prono} />
            </Grid>
          ))
        ) : (
          <Box
            height="100%"
            minHeight="150px"
            width="80%"
            margin="auto"
            display="flex"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            color="primary.dark"
          >
            <Typography variant="h4">
              {t('Chargement des analyses...')}
            </Typography>
          </Box>
        )}
      </Grid>
      {/* <Grid
        item
        container
        xs={12}
        className={clsx(classes.singleCardContainer, 'noPaddingRight')}
      >
        {pronos.length !== 0 ? (
          pronos.map(
            (prono, index) =>
              ((mobile && index < 1) ||
                (tablette && index < 2) ||
                ((smallComputer || bigComputer) && index < 3)) && (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={clsx(
                    classes.singleCard,
                    index === pronos.length - 1 &&
                      pronos.length % 2 === 1 &&
                      pronos.length !== 1 &&
                      classes.last,
                    pronos.length === 1 && classes.fullWidth,
                  )}
                  key={index}
                >
                  <CardPredictionNBA
                    future={prono.dateHideUntil?.seconds > now}
                    prono={prono}
                  />
                </Grid>
              ),
          )
        ) : (
          <Typography variant="h6" component={'span'}>
            <Box fontWeight={500} color={theme.palette.text.secondary}>
              {t(customText)}

            </Box>
          </Typography>
        )}
      </Grid> */}
    </>
  );
};

export default NBAPreview;
