import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const MainProno = [
  {
    fr: {
      football_team_wins: 'Résultat du match : %PLAYER%',
      football_team_qualified: '%PLAYER% se qualifie',
      football_team_wins_competition: '%PLAYER% remporte le trophée',
      football_team_wins_or_draw: '%PLAYER% ou nul',
      football_both_teams_score_false: 'Les deux équipes marquent : non',
      football_both_teams_score_true: 'Les deux équipes marquent : oui',
      football_more_than_x_goals: 'Plus de %SCORE% buts',
      football_less_than_x_goals: 'Moins de %SCORE% buts',
      football_team_wins_x_goals:
        '%PLAYER% gagne le match et + de %SCORE% buts',
      football_team_wins_more_x_goals:
        "%PLAYER% gagne le match avec au moins %SCORE% buts d'avance",
      football_team_wins_or_draw_more_than_x_goals:
        '%PLAYER% ou nul et + de %SCORE% buts',
      football_team_wins_or_draw_less_than_x_goals:
        '%PLAYER% ou nul et - de %SCORE% buts',
      football_team_wins_perfect: '%PLAYER% gagne sans encaisser de but',
      football_player_scores: '%NAME% buteur',
      number_of_goals: 'Nombre de buts',
      both_teams_score: 'Les deux équipes marquent',
      chances_of_victory: 'Chances de victoire',
      tennis_player_wins_match: '%PLAYER% gagne le match',
      tennis_player_wins_within_x_sets: '%PLAYER% gagne en %SCORE% sets',
      tennis_more_than_x_games: 'Plus de %SCORE% jeux',
      tennis_less_than_x_games: 'Moins de %SCORE% jeux',
      tennis_player_wins_at_least_one_set:
        '%PLAYER% gagne au moins %SCORE% set',
      tennis_both_players_wins_a_set: 'Les deux joueurs gagnent un set',
      tennis_tie_break_true: 'Tie-break dans le match: oui',
      tennis_tie_break_false: 'Tie-break dans le match non',
      chances_of_victory_per_player: 'Chances de victoire par joueur',
      basketball_match_result: 'Résultat du match : %PLAYER%',
      basketball_match_winner: 'Vainqueur du match : %PLAYER%',
      basketball_more_than_x_points: 'Plus de %SCORE% points dans le match',
      basketball_less_than_x_points: 'Moins de %SCORE% points dans le match',
      basketball_player_more_than_x_points:
        '%NAME% inscrit %SCORE% points ou plus',
      basketball_player_performance:
        '%NAME% + %SCORE% Performance du joueur ( points + rebonds + passes)',
      basketball_team_loose_or_draw_x_points:
        '%PLAYER% ne perd pas ou perd de %SCORE% points',
      basketball_team_wins_x_points: '%PLAYER% gagne de %SCORE% points ou plus',
    },
    en: {
      football_team_wins: 'Match result: %PLAYER%',
      football_team_qualified: '%PLAYER% qualifies',
      football_team_wins_competition: '%PLAYER% wins the trophy',
      football_team_wins_or_draw: '%PLAYER% or draw',
      football_both_teams_score_false: 'Both teams score: no',
      football_both_teams_score_true: 'Both teams score: yes',
      football_more_than_x_goals: 'More than %SCORE% goals',
      football_less_than_x_goals: 'Less than %SCORE% goals',
      football_team_wins_x_goals:
        '%PLAYER% wins the match and more than %SCORE% goals',
      football_team_wins_more_x_goals:
        '%PLAYER% wins the match with at least %SCORE% goals ahead',
      football_team_wins_or_draw_more_than_x_goals:
        '%PLAYER% or draw and more than %SCORE% goals',
      football_team_wins_or_draw_less_than_x_goals:
        '%PLAYER% or draw and less than %SCORE% goals',
      football_team_wins_perfect: '%PLAYER% wins without conceding a goal',
      football_player_scores: '%NAME% scores',
      number_of_goals: 'Number of goals',
      both_teams_score: 'Both teams score',
      chances_of_victory: 'Chances of victory',
      tennis_player_wins_match: '%PLAYER% wins the match',
      tennis_player_wins_within_x_sets: '%PLAYER% wins in %SCORE% sets',
      tennis_more_than_x_games: 'More than %SCORE% games',
      tennis_less_than_x_games: 'Less than %SCORE% games',
      tennis_player_wins_at_least_one_set: '%PLAYER% wins at least one set',
      tennis_both_players_wins_a_set: 'Both players win a set',
      tennis_tie_break_true: 'Tie-break in the match: yes',
      tennis_tie_break_false: 'Tie-break in the match: no',
      chances_of_victory_per_player: 'Chances of victory per player',
      basketball_match_result: 'Match result: %PLAYER%',
      basketball_match_winner: 'Match winner: %PLAYER%',
      basketball_more_than_x_points: 'More than %SCORE% points in the match',
      basketball_less_than_x_points: 'Less than %SCORE% points in the match',
      basketball_player_more_than_x_points:
        '%NAME% scores %SCORE% points or more',
      basketball_player_performance:
        '%NAME% + %SCORE% player performance (points + rebounds + assists)',
      basketball_team_loose_or_draw_x_points:
        '%PLAYER% does not lose or loses by %SCORE% points',
      basketball_team_wins_x_points: '%PLAYER% wins by %SCORE% points or more',
    },
    es: {
      football_team_wins: 'Resultado del partido: %PLAYER%',
      football_team_qualified: '%PLAYER% se clasifica',
      football_team_wins_competition: '%PLAYER% gana el trofeo',
      football_team_wins_or_draw: '%PLAYER% o empate',
      football_both_teams_score_false: 'Ambos equipos marcan: no',
      football_both_teams_score_true: 'Ambos equipos marcan: sí',
      football_more_than_x_goals: 'Más de %SCORE% goles',
      football_less_than_x_goals: 'Menos de %SCORE% goles',
      football_team_wins_x_goals:
        '%PLAYER% gana el partido y más de %SCORE% goles',
      football_team_wins_more_x_goals:
        '%PLAYER% gana el partido con al menos %SCORE% goles de ventaja',
      football_team_wins_or_draw_more_than_x_goals:
        '%PLAYER% o empate y más de %SCORE% goles',
      football_team_wins_or_draw_less_than_x_goals:
        '%PLAYER% o empate y menos de %SCORE% goles',
      football_team_wins_perfect: '%PLAYER% gana sin encajar un gol',
      football_player_scores: '%NAME% anota',
      number_of_goals: 'Número de goles',
      both_teams_score: 'Ambos equipos marcan',
      chances_of_victory: 'Probabilidades de victoria',
      tennis_player_wins_match: '%PLAYER% gana el partido',
      tennis_player_wins_within_x_sets: '%PLAYER% gana en %SCORE% sets',
      tennis_more_than_x_games: 'Más de %SCORE% juegos',
      tennis_less_than_x_games: 'Menos de %SCORE% juegos',
      tennis_player_wins_at_least_one_set: '%PLAYER% gana al menos un set',
      tennis_both_players_wins_a_set: 'Ambos jugadores ganan un set',
      tennis_tie_break_true: 'Tie-break en el partido: sí',
      tennis_tie_break_false: 'Tie-break en el partido: no',
      chances_of_victory_per_player: 'Probabilidades de victoria por jugador',
      basketball_match_result: 'Resultado del partido: %PLAYER%',
      basketball_match_winner: 'Ganador del partido: %PLAYER%',
      basketball_more_than_x_points: 'Más de %SCORE% puntos en el partido',
    },
    it: {
      football_team_wins: 'Risultato della partita: %PLAYER%',
      football_team_qualified: '%PLAYER% si qualifica',
      football_team_wins_competition: '%PLAYER% vince il trofeo',
      football_team_wins_or_draw: '%PLAYER% o pareggio',
      football_both_teams_score_false: 'Entrambe le squadre segnano: no',
      football_both_teams_score_true: 'Entrambe le squadre segnano: sì',
      football_more_than_x_goals: 'Più di %SCORE% gol',
      football_less_than_x_goals: 'Meno di %SCORE% gol',
      football_team_wins_x_goals:
        '%PLAYER% vince la partita e più di %SCORE% gol',
      football_team_wins_more_x_goals:
        '%PLAYER% vince la partita con almeno %SCORE% gol di vantaggio',
      football_team_wins_or_draw_more_than_x_goals:
        '%PLAYER% o pareggio e più di %SCORE% gol',
      football_team_wins_or_draw_less_than_x_goals:
        '%PLAYER% o pareggio e meno di %SCORE% gol',
      football_team_wins_perfect: '%PLAYER% vince senza subire gol',
      football_player_scores: '%NAME% segna',
      number_of_goals: 'Numero di gol',
      both_teams_score: 'Entrambe le squadre segnano',
      chances_of_victory: 'Probabilità di vittoria',
      tennis_player_wins_match: '%PLAYER% vince la partita',
      tennis_player_wins_within_x_sets: '%PLAYER% vince in %SCORE% set',
      tennis_more_than_x_games: 'Più di %SCORE% giochi',
      tennis_less_than_x_games: 'Meno di %SCORE% giochi',
      tennis_player_wins_at_least_one_set: '%PLAYER% vince almeno un set',
      tennis_both_players_wins_a_set: 'Entrambi i giocatori vincono un set',
      tennis_tie_break_true: 'Tie-break nella partita: sì',
      tennis_tie_break_false: 'Tie-break nella partita: no',
      chances_of_victory_per_player: 'Probabilità di vittoria per giocatore',
      basketball_match_result: 'Risultato della partita: %PLAYER%',
      basketball_match_winner: 'Vincitore della partita: %PLAYER%',
    },
  },
];

export const SportGPTMainProno: React.FC<{
  prono: any;
  card?: boolean;
}> = ({ prono, card = false }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  console.log('i18n', i18n.language);
  const lang = i18n.language || 'en';

  if (card) {
    return (
      <Typography
        variant={mobile ? 'h3' : 'h4'}
        style={mobile ? {} : { fontSize: '1.24rem' }}
      >
        {
          getMainPronosticString(MainProno[0][lang][prono?.pronostic], prono)
            .label
        }
      </Typography>
    );
  }

  return (
    <Typography
      variant={mobile ? 'h3' : 'h2'}
      style={{
        marginBottom: mobile ? '.4rem' : '-1rem',
        maxWidth: '65%',
      }}
    >
      {
        getMainPronosticString(MainProno[0][lang][prono?.pronostic], prono)
          .label
      }
    </Typography>
  );
};

export default SportGPTMainProno;

const getMainPronosticString = (
  translation: string | null,
  prono: any,
): { label: string; status: 'pending' | 'complete' } => {
  if (!translation) {
    return {
      status: 'pending',
      label: 'En attente de pronostic',
    };
  }

  return {
    status: 'complete',
    label: translation
      .replaceAll('%PLAYER%', prono?.player?.name || '')
      .replaceAll('%SCORE%', `${prono?.score || ''}`)
      .replaceAll('%NAME%', prono?.player?.name || ''),
  };
};
