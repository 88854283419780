import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Navbar from '../Templates/Navbar/Navbar';
import classes from '*.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import '../../../theme/datawinTheme.css';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Fire } from 'services';
import { useAsync2 } from 'hooks';
import { useTranslation, initReactI18next } from 'react-i18next';
import Loading from '../../Loading/Loading';
import Fade from '@material-ui/core/Fade';
import SingleBonus from './SingleBonus';
import useBonus from 'hooks/useBonus';
import Tour from 'reactour';
import { updateUser } from 'actions/auth.action';
import ButtonFilled from '../Templates/Buttons/ButtonFilled';

type Props = {
  match: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      background: '#fcfcfa',
    },
    container: {
      maxWidth: 1300,
      margin: 'auto',
      width: '100%',
    },
    bonusContainer: {
      position: 'relative',
    },
    middleLine: {
      width: 4,
      top: 0,
      height: 'calc(100% - 4rem)',
      position: 'absolute',
      background: '#eaecf3',
      left: '50%',
      transform: 'translate(-50%, 0)',
      zIndex: 0,
    },
  }),
);

export default function PageBonus() {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const user = useSelector((state: any) => state.authReducer.user);
  const [info, setInfo] = React.useState<any>({ ...user });
  const dispatch = useDispatch();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const spacing = mobile ? 3 : 6;
  const { bonus, loading, error } = useBonus();
  const [isTourOpen, setIsTourOpen] = React.useState(false);

  const testFirstConnexion = async () => {
    setIsTourOpen(true);
    info['isFirstConnexion']['bonus'] = false;
    const payload = {
      ...info,
    };
    setInfo({ ...info });
    await dispatch(updateUser(payload));
  };

  React.useEffect(() => {
    if (user.isFirstConnexion?.bonus === true) {
      testFirstConnexion();
    }
  }, [testFirstConnexion]);

  const steps = [
    {
      selector: '[data-tour="bonus-presentation-step"]',
      highlightedSelectors: ['[data-tour="bookmaker-presentation-step"]'],
      content: t(
        'Dans cette rubrique, vous retrouverez de nombreux bookmakers français.',
      ),
      position: 'bottom',
      scrollDuration: 5,
    },
    {
      selector: '[data-tour="bookmaker-Betclic-price-step"]',
      content: t(
        "Afin de bénéficier du bonus de bienvenue d'un bookmaker, cliquez sur « j'en profite. »",
      ),
      position: 'top',
      scrollDuration: 5,
    },
    {
      selector: '[data-tour="final-step"]',
      content: t(
        'Vous pouvez désormais profiter de tous nos outils et placer votre premier pari. Bonne chance !',
      ),
      position: 'top',
      scrollDuration: 5,
    },
  ];

  return (
    <React.Fragment>
      <Fade in={true} timeout={500}>
        <Grid container className={classes.background} data-tour="final-step">
          <Grid container spacing={spacing} className={classes.container}>
            <Tour
              steps={steps}
              isOpen={isTourOpen}
              onRequestClose={() => setIsTourOpen(false)}
              disableInteraction={true}
              rounded={5}
              maskSpace={15}
              lastStepNextButton={
                <ButtonFilled title="Commencer" little path="pronos" />
              }
            />
            <Grid item xs={12}>
              <Navbar mediumExtend />
            </Grid>
            <Grid item xs={12} style={{ zIndex: 1 }}>
              <Box
                margin="auto"
                textAlign="center"
                padding="0 2rem"
                zIndex="1"
                data-tour="bonus-presentation-step"
              >
                <Typography variant="h1" component={'span'}>
                  <Box
                    color="white"
                    textAlign="center"
                    width="100%"
                    marginBottom="2rem"
                  >
                    {t('Les Meilleurs Sites De Paris Sportifs En 2021')}
                  </Box>
                </Typography>
                <Typography variant="body1" component={'span'}>
                  <Box
                    color="white"
                    textAlign="center"
                    width="100%"
                    marginBottom="2rem"
                    lineHeight="1.5rem"
                  >
                    {t(
                      'En utilisant les bonus de paris offerts par Datawin vous profitez des avantages suivants :',
                    )}
                    <br />
                    <br />
                    {t('Des bonus fantastiques')}
                    <br />
                    {t('Meilleures cotes du marché')}
                    <br />
                    {t('Plateformes fiables et sécurisés')}
                    <br />
                    {t('Tous les sites sont accrédités par l’Arjel')}
                  </Box>
                </Typography>
              </Box>
            </Grid>
            <Grid item container xs={12} justify="center">
              <Grid item container xs={10} className={classes.bonusContainer}>
                {loading ? (
                  <Grid container item xs={12}>
                    <Loading nobg />
                  </Grid>
                ) : error ? (
                  <Grid container item xs={12}>
                    <Box>Vérifiez votre connexion internet</Box>
                  </Grid>
                ) : bonus.length === 0 ? (
                  <div className="col-center">
                    <div className="page-subtitle" style={{ color: 'white' }}>
                      {t('Aucun bonus disponible pour le moment')}
                    </div>
                  </div>
                ) : (
                  <Grid
                    item
                    container
                    xs={12}
                    data-tour="bookmaker-presentation-step"
                  >
                    {bonus.map((item, index) => (
                      <SingleBonus {...item} key={item.id} number={index} />
                    ))}
                    <Fade in={true} timeout={1000}>
                      <Box className={classes.middleLine}></Box>
                    </Fade>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    </React.Fragment>
  );
}
